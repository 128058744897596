import React, { useRef } from 'react';
import { bet, toCurrency } from '../../newCore/helpers/betActionHelpers';
import { changeInputValue } from '../../newCore/helpers/changeBetAmount';
import selectedStore from '../../state/selectedState';
import CurrencyInput from '../bet-input/input';
import Spinner from '../Loading/bet-button-spinner';
import { handleClickNumber, removeSymbol } from '../../newCore/helpers/helperNumpadFunction';

const NumPad = ({ t, demoMode ,checkClick, selectedGameType, betAmountRef, currencyCode, partnerSettings, betAmount, setBetAmount, handleClickAddBet, spinnerShower, showSpinner, tick, showNumPad }) => {

 return (
  <div className="mobile-numpad-cnt" ref={betAmountRef}>
   <div className={`mobile-numpad ${showNumPad ? 'open' : ''}`}>
    <div className="mobile-n-head">
     <div className={`c-4 ${betAmount > selectedGameType?.minBet ? '' : 'disabled'}`}
          onClick={(e) => changeInputValue(e, betAmount, setBetAmount, selectedGameType)}
          data-name={`-${selectedGameType?.minBet}`}>-
     </div>
     <div className="c-2 span-2" onClick={() => {
      document.activeElement.blur();
      selectedStore.setCheckClick('betAmount');
     }}>
      <CurrencyInput type="text" value={betAmount} max={selectedGameType?.maxBet?.toString().length}
                     userCurrencyName={
                      currencyCode || partnerSettings?.data?.currencyCode
                     }
                     demoMode={demoMode}
                     onChange={handleClickNumber}
                     onPaste={e => {
                      e.preventDefault();
                      return false;
                     }}
      />
     </div>
     <div className={`c-4 ${+betAmount < selectedGameType?.maxBet ? '' : 'disabled'}`}
          onClick={(e) => changeInputValue(e, +betAmount, setBetAmount, selectedGameType)}
          data-name={`+${selectedGameType?.minBet}`}>+
     </div>
     <div className={`c-4 ${+betAmount > selectedGameType?.minBet ? '' : 'disabled'}`}
          onClick={(e) => changeInputValue(e, +betAmount, setBetAmount, selectedGameType)}
          data-name={`min`}>{t('MIN')}</div>
     <div className={`c-4 span-2 ${betAmount < selectedGameType?.maxBet ? '' : 'disabled'}`}
          onClick={(e) => changeInputValue(e, +betAmount, setBetAmount, selectedGameType)}
          data-name={`*3`}>+ {toCurrency(
      Math.round10(selectedGameType?.minBet * 3, -2),
      currencyCode || partnerSettings?.data?.currencyCode
     )}</div>

     <div className={`c-4 ${+betAmount < selectedGameType?.maxBet ? '' : 'disabled'}`}
          onClick={(e) => changeInputValue(e, +betAmount, setBetAmount, selectedGameType)}
          data-name={`*2`}>X2
     </div>
    </div>
    <div className="mobile-n-content">
     <div className="c-1" data-name={'7'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>7
     </div>
     <div className="c-1" data-name={'8'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>8
     </div>
     <div className="c-1" data-name={'9'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>9
     </div>
     <div className="c-1" data-name={'0'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>0
     </div>
     <div className="c-1" data-name={'4'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>4
     </div>
     <div className="c-1" data-name={'5'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>5
     </div>
     <div className="c-1" data-name={'6'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>6
     </div>
     <div className="c-1" data-name={'c'} onClick={() => removeSymbol(setBetAmount,checkClick)}>c</div>
     <div className="c-1" data-name={'1'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>1
     </div>
     <div className="c-1" data-name={'2'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>2
     </div>
     <div className="c-1" data-name={'3'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>3
     </div>
     <div className={`c-1 ${checkClick === 'number' ? 'disabled' : ''}`} data-name={'.'}
          onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>.
     </div>
    </div>
    <div className="mobile-n-bottom">
     <i className="icon-down-arrow c-5" onClick={() => {
      selectedStore.setShowNumPad(false);
      if (betAmount < selectedGameType.minBet) {
       setBetAmount(selectedGameType.minBet);
      }
     }}/>
     <button className={`bet-btn green span-3 ${tick ? 'disabled' : ''}`} onClick={() => {
      spinnerShower();
      handleClickAddBet();
      selectedStore.setShowNumPad(false);
     }}>{showSpinner ? <Spinner/> : <span>{t('BET')}</span>}
     </button>
    </div>
   </div>
  </div>
 );
};
export default NumPad;