import { HeaderTypes, ContentTypes } from "../constants/constants";

export default function buildHeaders(
  contentType,
  includeTimeZone = true,
  token
) {
  let headers = {};

  if (includeTimeZone) {
    headers = {
      [HeaderTypes.timeZone]: (new Date().getTimezoneOffset() / -60).toString(),
      ...headers
    };
  }
  if (token && token.length > 0) {
    headers = {
      [HeaderTypes.contentType]: ContentTypes.applicationJson,
      [HeaderTypes.authorization]: `Bearer ${token}`,
      ...headers
    };
  }
  switch (contentType) {
    case ContentTypes.applicationJson:
      headers = {
        [HeaderTypes.contentType]: ContentTypes.applicationJson,
        [HeaderTypes.cacheControl] : 'no-cache',
        ...headers
      };
      break;
    case ContentTypes.formData:
      break;
    default:
      break;
  }
  return headers;
}
