import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";

const defaultMaskOptions = {
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false
};

const CurrencyInput = ({
  maskOptions,
  max,
  userCurrencyName,
 demoMode,
  ...inputProps
}) => {
  const pref = demoMode ? '' : toCurrency("", userCurrencyName, "currency", 0).toString();
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    integerLimit: max,
    prefix: pref.substring(0, pref.length - 1),
    ...maskOptions
  });

  return (
    <MaskedInput mask={currencyMask} inputMode="numeric" {...inputProps}/>
  );
};

export default CurrencyInput;
