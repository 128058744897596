import React, { memo, useEffect, useState } from "react";
import LiveBets from "./LiveBets";
import useTranslation from "../../newCore/helpers/useTranslation";
import selectedStore from "../../state/selectedState";
import BetsWinnersContainer from "./BetsWinnersContainer";
import is from "is_js";

const AllBets = memo(({ userSettings , demoMode}) => {
  const [showLiveBet, setShowLiveBet] = useState(true);
  const [currency, setCurrency] = useState(selectedStore.initialState.userCurrency);
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  const [showDemoWin, setShowDemoWin] = useState(selectedStore.initialState.showDemoWin);
  const [showRealWin, setShowRealWin] = useState(selectedStore.initialState.showRealWin);
  const [results, setResults] = useState(selectedStore.initialState.liveBetsResults);
  // const [skip, setSkip] = useState(0);
  // const [take, setTake] = useState(30);
  //
  // useEffect(() => {
  //   const partnerBetTypes = selectedStore.subscribe(data =>
  //    setPartnerBetTypes(data.partnerBetTypes)
  //   );
  //   const partnerWinning = partnerService.partnerTopWinning.subscribe(data => {
  //     let dataArray = [];
  //
  //     if (data.data?.topWiningReports) {
  //       dataArray = [...data.data?.topWiningReports];
  //     }
  //
  //     return setPartnerTopWinners(dataArray);
  //   });
  //   selectedStore.init();
  //   return () => {
  //     partnerWinning.unsubscribe();
  //     partnerBetTypes.unsubscribe();
  //   };
  // }, []);

  // const partnerTopWinnersByBetType = useMemo(() => {
  //   const partnerBetTypesId = partnerBetTypes.map(item => item.id);
  //   return partnerTopWinners?.filter(item => partnerBetTypesId?.includes(item.betTypeId));
  // }, [partnerTopWinners, partnerBetTypes]);

  useEffect(() => {
    const showLiveBet = selectedStore.subscribe(data => setShowLiveBet(data.showLiveBet));
    const userCurrency = selectedStore.subscribe(data => setCurrency(data.userCurrency));
    const results = selectedStore.subscribe(data => setResults(data.liveBetsResults));
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    const showDemoWin = selectedStore.subscribe(data => setShowDemoWin(data.showDemoWin));
    const showRealWin = selectedStore.subscribe(data => setShowRealWin(data.showRealWin));
    selectedStore.init();
    return () => {
      showDemoWin.unsubscribe()
      showRealWin.unsubscribe()
      userCurrency.unsubscribe();
      partnerSettings.unsubscribe();
      showLiveBet.unsubscribe();
      results.unsubscribe();
    };
  }, []);

  const t = useTranslation();

  return (
    <div className="statistics-holder">
      <div className="st-grid-holder">
        <div className="st-tabs-row">
          <div className="st-tabs-container">
            <div
              className={`st-tub-v ${showLiveBet ? "active" : ""}`}
              onClick={() => selectedStore.setShowLiveBet(true)}
            >
              <span> {t("LIVE_BETS")}</span>
            </div>
            <div
              className={`st-tub-v ${!showLiveBet ? "active" : ""}`}
              onClick={() => selectedStore.setShowLiveBet(false)}
            >
              <span> {t("TOP_WINNERS")}</span>
            </div>
          </div>
        </div>

        <div className="st-tab-content">
          <div className="scroll-b-holder">
            <div className="scroll-container" id={"scroll-container-top-win"}>
              <div className="live-bets-cnt">
                <div className="table-title-row">
                  <p title={t("PLAYER_ID")}>{t("PLAYER_ID")}</p>
                  <p title={t("ODD")}>{t("ODD")}</p>
                  <p
                    title={`${t("BET")}  (${currency?.[0]?.name ||
                      partnerSettings?.data?.currencyCode})`}
                  >
                    {t("BET")} ({demoMode ? 'Fun' : currency?.[0]?.name || partnerSettings?.data?.currencyCode})
                  </p>
                  <p
                    title={
                     showLiveBet ? !is.empty(results) && (demoMode ? showDemoWin : showRealWin)
                        ? `${t("WIN")} (${currency?.[0]?.name ||
                            partnerSettings?.data?.currencyCode})`
                        : `${t("BET_TYPE")}` : t('WIN')
                    }
                  >
                    {showLiveBet ? !is.empty(results) && (demoMode ? showDemoWin : showRealWin)
                      ? `${t("WIN")} (${currency?.[0]?.name ||
                          partnerSettings?.data?.currencyCode})`
                      : `${t("BET_TYPE")}` : t("WIN")}
                  </p>
                </div>
                {showLiveBet ? (
                  <LiveBets userSettings={userSettings} t={t} demoMode={demoMode}/>
                ) : (
                  <BetsWinnersContainer />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AllBets;
