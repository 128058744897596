import React, { memo, useMemo } from "react";
import Win from "../win/win";
import useTranslation from "../../newCore/helpers/useTranslation";
import AnimalByBet from '../all-bets/animalsByBet';

const PlayerStatisttic = memo(({ bet, betResult, currencyName, currencies, showWin }) => {

  const userBetWinning = useMemo(() => {
    return betResult?.filter(item => item?.id === bet?.id);
  }, [betResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const convertCurrencyToUSD = (amount, id) =>
    +amount / +currencies?.find(c => c.name === id)?.rate;

  const t = useTranslation();
  return (
    <div className="st-content-info selected">
      {/*add class selected*/}
      <p>{t("ME")}</p>
      <p>X{bet?.odds?.[0] ? bet?.odds?.[0] : null}</p>
      <p>
        {Math.round10(convertCurrencyToUSD(bet?.defaultRateStakeAmount, bet?.currencyCode), -2)}
      </p>
      <p className={`${userBetWinning?.[0]?.winningAmount && showWin ? "win" : ""}`}>
        {userBetWinning?.[0]?.winningAmount && showWin ? (
          <Win currency={currencyName} userBetWinning={userBetWinning} />
        ) : userBetWinning?.[0]?.winningAmount === 0 && showWin ? (
          "0"
        ) : (
         <AnimalByBet bet={bet} />
        )}
      </p>
    </div>
  );
});

export default PlayerStatisttic;
