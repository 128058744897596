import React from 'react';

const NavigationInLobby = ({t,setShowLiveBets,showLiveBets}) =>{
  return (
   <div className="navigation-row">
    <div className="navigation-holder">
     <a href="#" className={`nav-a-m ${showLiveBets?'active':''}`} onClick={()=>setShowLiveBets(true)}>
      <span className="icon-bet"/>
      <i>{t('BET')}</i>
     </a>
     <a href="#" className={`nav-a-m ${showLiveBets?'':'active'}`} onClick={()=>setShowLiveBets(false)}>
      <span className="icon-game"/>
      <i>{t('GAME')}</i>
     </a>
    </div>
   </div>
  )
}

export default NavigationInLobby