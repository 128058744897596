import { BehaviorSubject } from "rxjs";
import { doneSetter, pendingSetter } from "../../helpers/helperFunctions";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { catchError, map } from "rxjs/operators";
import selectedStore from "../../../state/selectedState";

const END_POINT = {
  prefix: "Token",
  token: "token",
  refresh: "refresh"
};

class TokenService {
  expiresInInsurance = 10;

  tokenResult = new BehaviorSubject(doneSetter([]));

  auth = (partnerId, token) => {
    if (token) {
      this.tokenResult.next(pendingSetter([]));
      FromFetchService.post(END_POINT.prefix, {
        authScheme: 3,
        data: { token, partnerId }
      })
        .pipe(
            map(async data =>{
                  if(data.ok){
                    const result = await data.json();
                    if(result?.result?.accessToken){
                      FromFetchService.TOKEN = result.result?.accessToken;
                      selectedStore.setIsAuth(true)
                    }else{
                      selectedStore.setIsAuth(false)
                    }
                 return  this._tokenAccessConfig(result)
                  }
            }
               ),
            )
        .subscribe(async data => this.tokenResult.next(doneSetter(await data)));
    } else {
      selectedStore.setIsAuth(false)
    }
    return true;
  };

  attachTimer = data => {
    const timer = (data.expiresIn - this.expiresInInsurance) * 1000;
    // const timer = 5000;
    FromFetchService.refreshTimer = setInterval(() => {
      this.authRefresh()
        .pipe(
          catchError(e => {
            window.location.reload();
            clearInterval(FromFetchService.refreshTimer);
          })
        )
        .subscribe(async data => this._tokenAccessConfig(await data.json()));
    }, timer);
  };

  authRefresh() {
    return FromFetchService.post(`${END_POINT.prefix}/${END_POINT.refresh}`);
  }

  _tokenAccessConfig = response => {
    FromFetchService.TOKEN = response.result?.accessToken;
    return response;
  };
}
const tokenService = new TokenService();

export default tokenService;
