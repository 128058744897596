import React, { useEffect, useRef, useState } from 'react';
import HeaderMobile from "../../components/header/header.Mobile";
import NavigationMobile from "../../components/navigation/navigation.Mobile";
import HelpLeftContentMobile from "../../components/help/helpLeftContentMobile";
import AllBets from "../../components/all-bets";
import MobileCenterContainer from "../../components/mobile-center-container/mobile-center-container";
import selectedStoreMobile from "../../state/selectedStateMobile";
import BetHistoryMobile from "../../components/mobile-components/BetHistoryContent";
import LastResultsMobile from "../../components/mobile-components/LastResultContent";
import selectedStore from "../../state/selectedState";
import useTranslation from "../../newCore/helpers/useTranslation";
import ConnectionLostPopup from "../../components/ConectionLostPopup/ConectionLostPopup";
import HashCheckerMobile from '../../components/hash/HashMobile';
import LobbyMobile from '../../components/lobby/mobile/lobbyMobile';
import BetHistoryDemoMobile from '../../components/demoMode/betHistoryDemoMobile';
import RebetPopUp from '../../components/modal/reBet';
import { useOutsideClick } from '../../newCore/helpers/useClickOutside';


const GameMobile = ({ path, urlSearchParams, animation }) => {
  const [showBetType, setShowBetType] = useState(selectedStoreMobile.initialState.showBetType);
  const [showBetTypeInfo, setShowBetTypeInfo] = useState(
    selectedStore.initialState.showBetTypeInfo
  );
  const [amount, setAmount] = useState('');
  const [demoMode , setDemoMode] = useState(selectedStore.initialState.demoMode);
  const [repeatBet , setRepeatBet] = useState(selectedStore.initialState.rebetPopUp);
  const [stopConnectionTimeout, setStopConnectionTimeout] = useState(0);
  const [showPopup, setShowPopup] = useState(selectedStore.initialState.isShowConnectionLostPopup);
   const [ , setPartnerSettings] = useState(selectedStore.initialState.partnerSettings);
  const [selectedGameType, setSelectedGameType] = useState(selectedStore.initialState.selectedGameType);

  // const [orientAlert, setOrientAlert] = useState(false);
  // const [orientAlert, setOrientAlert] = useState(
  //   window.orientation === 0 ||
  //     window.screen.orientation.angle === 0 ||
  //     window.orientation === 180 ||
  //     window.screen.orientation.angle === 180
  // );

  const showConnectionLostStatus = () => {
    document.activeElement.blur()
    checkVisibility();
  };

  const checkVisibility = () => {
    if (!document.hidden) {
      if (stopConnectionTimeout) {
        clearTimeout(stopConnectionTimeout);
        selectedStore.setConnectionLost(false);
      }
    } else {
      setStopConnectionTimeout(
        setTimeout(() => {
          if (document.hidden) {
            selectedStore.setConnectionLost(true);
            document.removeEventListener("visibilitychange", showConnectionLostStatus);
          }
          clearTimeout(stopConnectionTimeout);
        }, 15000)
      );
    }
  };
  const [selectedAnimal, setSelectedAnimal] = useState([]);
  const [selectedBetType, setSelectedBetType] = useState(selectedStore.state.selectedBetType);

  const [userSettings, setUserSettings] = useState(selectedStore.initialState.userSettings);
  const t = useTranslation();
  const hidePopUp = useRef();

  useEffect(()=>{
    setAmount(selectedGameType.minBet);
  },[selectedGameType]);

  useEffect(() => {
    const selectedGameTypeSub = selectedStore.subscribe(data => {
      setSelectedGameType(data.selectedGameType)
    });
    const repeatBet = selectedStore.subscribe(data => setRepeatBet(data.rebetPopUp));
    document.querySelector('html').classList.add('mobile');
    const demoModeSubscription = selectedStore.subscribe(data =>setDemoMode(data.demoMode));
    const popup = selectedStore.subscribe(data => setShowPopup(data.isShowConnectionLostPopup));
    const partnerSettings = selectedStore.subscribe(data => setPartnerSettings(data.partnerSettings));
    document.addEventListener("visibilitychange", showConnectionLostStatus);
    checkVisibility();
    const showBetType = selectedStoreMobile.subscribe(data => setShowBetType(data.showBetType));
    const showBetTypeInfo = selectedStore.subscribe(data =>
      setShowBetTypeInfo(data.showBetTypeInfo)
    );
    const userSettings = selectedStore.subscribe(data => setUserSettings(data.userSettings));
    const selectedBetTypeSubscribtion = selectedStore.subscribe(data =>
      setSelectedBetType(data.selectedBetType)
    );
    const selectedAnimalSubscribtion = selectedStore.subscribe(data =>
      setSelectedAnimal(data.selectedAnimal)
    );
    selectedStoreMobile.init();

    return () => {
      selectedGameTypeSub.unsubscribe()
      repeatBet.unsubscribe();
      document.querySelector('html').classList.remove('mobile');
      demoModeSubscription.unsubscribe()
      selectedBetTypeSubscribtion.unsubscribe();
      showBetType.unsubscribe();
      selectedAnimalSubscribtion.unsubscribe();
      showBetTypeInfo.unsubscribe();
      userSettings.unsubscribe();
      popup.unsubscribe();
      partnerSettings.unsubscribe()
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   window.addEventListener("orientationchange", event => {
  //     if (!isSafari) {
  //       if (
  //         event.currentTarget.screen.orientation.angle === 0 ||
  //         event.currentTarget.screen.orientation.angle === 180
  //       ) {
  //         setOrientAlert(true);
  //       } else {
  //         setOrientAlert(false);
  //       }
  //     } else {
  //       if (!window.matchMedia("(orientation: portrait)")) {
  //         setOrientAlert(true);
  //       } else {
  //         setOrientAlert(false);
  //       }
  //     }
  //   });
  // }, [orientAlert]);
  useOutsideClick(hidePopUp, () => {
    if (repeatBet){
      selectedStore.setRebetPopUp(false)
    }
  });
  useEffect(()=>{
    selectedStore.selectedBetAmount(amount)
  },[amount])

  return (
    <>
      {showPopup ? <ConnectionLostPopup /> : null}

      <div
        className={`mobile-wrapper ${animation ? "" : "animation-off"} ${path==='game'?'game-page':path}`}
        id={"mobile-wrapper"}
      >
        {path === "game" && !showBetType && !showBetTypeInfo  && (
          <HeaderMobile t={t} selectedAnimal={selectedAnimal} selectedBetType={selectedBetType} demoMode={demoMode} urlSearchParams={urlSearchParams} path={path}/>
        )}
        <div className={`m-content-row ${path === "game" && !showBetType ? "game" : ""}`}>
          {/*add class "game" */}
          <div className="m-scroll-holder" >
            <div className=" m-scroll-b" id="scroll-container-last-result-mob" >
              {repeatBet  ? <RebetPopUp t={t} hidePopUp={hidePopUp}/> : null}
              {path === "game" && (
                <MobileCenterContainer
                 mobile
                  path={path}
                  showBetType={showBetType}
                  t={t}
                  selectedBetType={selectedBetType}
                  selectedAnimal={selectedAnimal}
                 demoMode={demoMode}
                 amount={amount}
                 setAmount={setAmount}
                 selectedGameType={selectedGameType}
                />
              )}
              {path === "bets" && <AllBets userSettings={userSettings} t={t} demoMode={demoMode} />}
              {path === "lastResult" && <LastResultsMobile t={t}/>}
              {(path === "help" || (path === "betHistory" && showBetTypeInfo)) && (
                <HelpLeftContentMobile />
              )}
              {path === "betHistory" && !showBetTypeInfo ? demoMode ? <BetHistoryDemoMobile/> : <BetHistoryMobile /> :null}


              {/*<LobbyMobile/>*/}

            </div>
          </div>
        </div>
        <NavigationMobile path={path} urlSearchParams={urlSearchParams} demoMode={demoMode} />
      </div>
      {/*<div className="landscape-icon-cnt">*/}
      {/*  <i className="icon-screen-rotate" />*/}
      {/*</div>*/}
    </>
  );
};

export default GameMobile;
