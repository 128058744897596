import React, { useEffect, useState } from "react";

const notificationIcons = {
  accept: "bet",
  warning: "warning",
  error: "Error"
};

let timeOutId;

const Toaster = function() {
  const [notificationType, setNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);

  useEffect(() => {
    Toaster.success = message => {
      clearTimeout(timeOutId);
      setNotificationMessage(message);
      setNotificationType("accept");
     timeOutId = setTimeout(() => {
        setNotificationType(null);
      }, 2000);
    };

    Toaster.warning = message => {
      setNotificationMessage(message);
      clearTimeout(timeOutId);
      setNotificationType("warning");
      timeOutId = setTimeout(() => {
        setNotificationType(null);
      }, 2000);
    };

    Toaster.error = message => {
      setNotificationMessage(message);
      clearTimeout(timeOutId);
      setNotificationType("error");
      timeOutId = setTimeout(() => {
        setNotificationType(null);
      }, 2000);
    };
  }, []);

  if (!notificationType) return null;

  return (
    <div className="notification-holder-b">
      <div className={`notification-container ${notificationType}`}>
        <p>{notificationMessage}</p>
        <span className={`icon-${notificationIcons[notificationType]}_notification`} />
      </div>
      {/*<div className="notification-container warning">*/}
      {/*    <p>Your bet has been accepted.</p>*/}
      {/*    <span className="icon-warning_notification"/>*/}
      {/*</div>*/}
      {/*<div className="notification-container error">*/}
      {/*    <p>Your bet has been accepted.</p>*/}
      {/*    <span className="icon-Error_notification"/>*/}
      {/*</div>*/}
    </div>
  );
};

export default Toaster;
