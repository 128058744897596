import moment from 'moment';

const dateConverter = (date, fullYear = false, seperator = '/') => {

  let start = 2;
  let end = 2;

  if (fullYear) {
    start = 0;
    end = 4;
  }

  if (date) {
    let splitedDate = date.split('T')[0].split('-');
    return `${splitedDate[2]}${seperator}${splitedDate[1]}${seperator}${splitedDate[0].substr(start, end)}`
  }
  return '';
};

export const timeConverter = (date) => {
  const timeDate = moment(date);
  return `${timeDate.hours()}:${timeDate.minutes()}`
};

export const convertStringDate = (date) => {
  return date.split(' ').splice(1, 3).join('-');
};

export const addOneDayToStart = date => {
  const nextDay = moment(date).format('L');
  const tomorrow = moment(nextDay).add(1, 'days');
  return tomorrow.format('L');
};

const plusZero = number => {
  if (number < 10) {
    return `0${number}`
  } else {
    return `${number}`
  }
};

export const setTime = () => {
  return `${plusZero(new Date().getHours())} : ${plusZero(new Date().getMinutes())}`
};

export const getLocalTime = (date) => {
  return `${plusZero(new Date(date).getHours())} : ${plusZero(new Date(date).getMinutes())} : ${plusZero(new Date(date).getSeconds())}`
};

export default dateConverter;
