import React, { memo, useEffect, useState } from "react";
import gamelogo from "../../assets/images/Talismanlogo.png";
import gamelogoBrasil from "../../assets/images/jogo.png";
import selectedStore from "../../state/selectedState";

const GameLogo = memo(({ selectedAnimal, showBetTypeInfo, selectedBetType, path }) => {
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );



  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    return () => {
      partnerSettings.unsubscribe();
    };
  }, []);
  return (
    <div className="game-logo-row">
      {(selectedAnimal || showBetTypeInfo || selectedBetType?.type === 17) && (
        <i
          className="icon-arrow_back game-back-icon"
          onClick={() => selectedStore.clearSelectedState()}
        />
      )}
      <div className="g-logo-holder">
        <img src={partnerSettings?.data?.viewMode ? gamelogoBrasil : gamelogo} alt="logo" />
      </div>
    </div>
  );
});

export default GameLogo;
