import React from "react";
import selectedStoreMobile from '../../state/selectedStateMobile';


const HelpBetTypeMobile = ({betType, t} ) => {
  return (
   <div
    className={`help-h-item`}
    onClick={() => selectedStoreMobile.selectBetTypeHelpInfo(betType)}
   >
     {t(betType.name)}
   </div>
  )
};
export default HelpBetTypeMobile;
