export const parseNumberByPrecision = (
 odd,
 betAmount,
 precision = 2,
 isForOdd = false
) => {
 const digitsAfterDotAtOdd = odd.toString().split(".")[1]?.length || 0;
 const digitsAfterDotAtBetAmount = betAmount.toString().split(".").length || 0;
 const digitsCountAfterDot = digitsAfterDotAtBetAmount + digitsAfterDotAtOdd;
 let result;
 const re = new RegExp(`^-?\\d+(?:.\\d{0,${precision || 2}})?`);
 const numMulty =
  (Math.round(odd * 10 ** digitsCountAfterDot) *
   Math.round(betAmount * 10 ** digitsCountAfterDot)) /
  10 ** (digitsCountAfterDot + digitsCountAfterDot);

 // eslint-disable-next-line prefer-const
 result = isForOdd
  ? +numMulty
  : +numMulty.toString().match(re) && +numMulty.toString().match(re)[0];

 return result;
};
