import React from 'react';
import ReactDOM from 'react-dom';
import './assets/font-icons/style.css';
import './index.scss';
import App from './App';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'



ReactDOM.render(
    <App />,
  document.getElementById('root')
);
