import React from "react";
import selectedStoreMobile from "../../state/selectedStateMobile";
import LeftResultRowAnimalByNumber from "../last-result/left-resultRow-animal-by-number";
import { resultRows } from "../../newCore/helpers/resultRows";
import useTranslation from "../../newCore/helpers/useTranslation";

const LastResultListMobile = ({ result, selectedLastResult, animals }) => {
  const rows = resultRows(result);
  const t = useTranslation();
  return (
    <div
      className={`result-filters-changes-row ${
        result?.gameId === selectedLastResult?.gameId ? "active" : ""
      }`}
      onClick={() => selectedStoreMobile.selectLastResult(result)}
    >
      {" "}
      <div className="result-filters-c-column">
        {t("ID")} {result.gameId}
      </div>
      <div className="result-filters-c-column">
        <div className="mini-animals-row">
          {rows[0] && rows.map(number => (
            <LeftResultRowAnimalByNumber number={number} key={number} animals={animals} />
          ))}
        </div>
        <span>{new Date(result?.endDate).toLocaleTimeString()}</span>
      </div>
    </div>
  );
};
export default LastResultListMobile;

//      <LeftResultRowAnimalByNumber number={number} key={number} animals={animals} />
