export const possibleWinBalls = betHistoryItem => {
  let infoObject = {
    rowNumber: [0],
    ballsCount: 2
  };

  switch (betHistoryItem?.betType) {
    case 0:      //! DEZENA
    case 3:      //! ON_THE_HEAD
    case 14:     //! EASY_WIN
      return { ...infoObject };
    case 1:      //! CENTENA
      return { ...infoObject, ballsCount: 3 };
    case 2:      //! MILHAR
      return { ...infoObject, ballsCount: 4 };
    case 4:      //! DUQUE_DE_DEZENA
    case 5:      //! DUQUE_DE_GRUPO
    case 6:      //! TERNO_DE_GRUPO
    case 7:      //! TERNOS_DE_DEZENA
    case 8:      //! CERCADO_ON_THE_HEAD
    case 9:      //! CERCADO_DEZENA
    case 15:     //! PASSE_VAI
    case 16:     //! PASSE_VAI_VEM
      return { ...infoObject, rowNumber: [0, 1, 2, 3, 4], ballsCount: 2 };

    case 10:     //! CERCADO_CENTENA
      return { ...infoObject, rowNumber: [0, 1, 2, 3, 4], ballsCount: 3 };
    case 11:     //! CERCADO_MILHAR
    case 12:     //! SALTEADO
    case 13:     //! MODERNO
    case 17:     //! FOUR_CHANCE
    case 18:     //! LUCKY_ANIMAL
      return { ...infoObject, rowNumber: [0, 1, 2, 3, 4], ballsCount: 4 };
    default:
      break;
  }
};


