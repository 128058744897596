import React, {memo, useEffect, useMemo, useState} from "react";
import selectedStore from "../../../../state/selectedState";
import MultiPlyAnimalTable from "../multiPlyAnimalTable";

const TwoAnimalWithNumberSelection = memo(({ selectedAnimal, animalsCount }) => {

  const [selectedNumberInFirstRow, setSelectedNumberInFirstRow] = useState("");
  const [selectedNumberInSecondRow, setSelectedNumberInSecondRow] = useState(
    ""
  );

  const selectedNumberGroup = useMemo(() => {
    return [selectedNumberInFirstRow, selectedNumberInSecondRow];
  }, [selectedNumberInFirstRow, selectedNumberInSecondRow]);

  const setSelectedNumberGroup = useMemo(() => {
    return [setSelectedNumberInFirstRow, setSelectedNumberInSecondRow];
  }, []);



  useEffect(() => {
    if (selectedNumberGroup?.[0]){
      selectedStore.selectNumbers(selectedNumberGroup.join(","));

    }else{
      selectedStore.selectNumbers(selectedNumberGroup.join(""));
    }
  }, [selectedNumberGroup]);

  return (
    <MultiPlyAnimalTable
      selectedAnimal={selectedAnimal}
      selectedNumberGroup={selectedNumberGroup}
      setSelectedNumberGroup={setSelectedNumberGroup}
      animalsCount={animalsCount}
    />
  );
});
export default TwoAnimalWithNumberSelection;
