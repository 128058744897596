import React, { memo, useEffect, useState } from "react";
import { DOMAIN } from "../../../../newCore/constants/environment";
import SingleNumberWeb from "../single-number.Web";
import selectedStore from "../../../../state/selectedState";
import useTranslation from "../../../../newCore/helpers/useTranslation";
import * as brazilMainImages from '../../../ulits/brazilMainAnimals';

const ChooseSingleNumber = memo(
  ({ selectedAnimal, setSelectedNumberForInput, setBackToNumberSelection, selectedNumberForInput }) => {
    const [selectedNumber, setSelectedNumber] = useState();
    const t = useTranslation();
   const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
   );
   useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
     setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return () => {
     partnerSettings.unsubscribe();
    };
   }, []);

   useEffect(() => {
      selectedStore.selectNumbers(selectedNumber);
    }, [selectedNumber]);
   const src =
    partnerSettings?.data?.viewMode === 0
     ? `${DOMAIN}/${selectedAnimal.animalPicUrl}`
     : brazilMainImages[`a${selectedAnimal?.sortNumber}`];


    return (
      <>
        {!selectedNumberForInput && (
          <img className="ch-numbers-img" src={src} alt="" />
        )}
        {!selectedNumberForInput && <p className="numbers-ch-title">{t("CHOOSE_NUMBER")}</p>}
           <div className="ch-numbers-box">
            {selectedAnimal.numbers?.split(",").map((item, index) => (
             <SingleNumberWeb
              key={index}
              selectedNumber={selectedNumber}
              setSelectedNumber={setSelectedNumber}
              setSelectedNumberForInput={setSelectedNumberForInput}
              setBackToNumberSelection={setBackToNumberSelection}
             >
              {item}
             </SingleNumberWeb>
            ))}
           </div>
      </>
    );
  }
);
export default ChooseSingleNumber;
