import React from "react";
import selectedStore from "../../state/selectedState";

const NumpadForSearchGameResult = ({ showNumpad, inputRef, setSearchValue, searchValue }) => {
  const handleNumpadClick = symbol => {
    if (symbol === "c") {
      selectedStore.clearGameResult();
      selectedStore.clearBetHistory();
      setSearchValue("");
    } else if (searchValue.length < 15) {
      selectedStore.clearGameResult();
     selectedStore.clearBetHistory();
     setSearchValue(prev => prev + "" + symbol);
    }
  };
  return (
    <div className="mob-search-num" ref={inputRef}>
      <div className={`numpad ${showNumpad ? "open" : ""}`}>
        <span onClick={() => handleNumpadClick(7)}>7</span>
        <span onClick={() => handleNumpadClick(8)}>8</span>
        <span onClick={() => handleNumpadClick(9)}>9</span>
        <span onClick={() => handleNumpadClick(0)}>0</span>
        <span onClick={() => handleNumpadClick(4)}>4</span>
        <span onClick={() => handleNumpadClick(5)}>5</span>
        <span onClick={() => handleNumpadClick(6)}>6</span>
        <span onClick={() => handleNumpadClick("c")}>c</span>
        <span onClick={() => handleNumpadClick(1)}>1</span>
        <span onClick={() => handleNumpadClick(2)}>2</span>
        <span onClick={() => handleNumpadClick(3)}>3</span>
        <span onClick={() => selectedStore.setShowNumPad(false)}>
          <i className="icon-down-arrow" />
        </span>
      </div>
    </div>
  );
};
export default NumpadForSearchGameResult;
