import React, { useEffect, useMemo, useRef, useState } from 'react';
import GameTitleMobile from '../game-title/gameTitle.Mobile';
import BetAction from '../bet-action/bet-action.Web';
import selectedStore from '../../state/selectedState';
import ChooseAnimalByBetType from '../chooseAnimalDependToBetType';
import selectedStoreMobile from '../../state/selectedStateMobile';
import UserBetMobile from '../mobile-components/userBetMobile';
import TotalWinPopupWeb from '../total-win-popup/total-win-popup.WEB';
import is from 'is_js';
import GameHelpMobile from '../game-help/gameHelpMobile';
import NewResultMobile from '../mobile-components/newResultMobile';
import { AnimatePresence, motion } from 'framer-motion';
import demoState from '../../state/demoModeState';
import RebetPopUp from '../modal/reBet';
import { useOutsideClick } from '../../newCore/helpers/useClickOutside';

const MobileCenterContainer = ({ path, t, selectedAnimal, selectedBetType, mobile, demoMode, amount, setAmount, selectedGameType }) => {
 const [showNewResult, setShowNewResult] = useState(selectedStore.initialState.showNewResult);
 const [userBet, setUserBet] = useState(selectedStore.initialState.userBet);
 const [demoBets, setDemoBets] = useState(demoState.initialState.userBet);
 const [demoBetsResult, setDemoBetsResult] = useState(demoState.initialState.betResults);
 const [animationOn, setAnimationOn] = useState(selectedStore.initialState.animation);
 const [showBetTypeInfo, setShowBetTypeInfo] = useState(
  selectedStore.initialState.showBetTypeInfo
 );
 const [limits, setLimits] = useState({})


 const [showTotalWinPopup, setShowTotalWinPopup] = useState(
  selectedStore.initialState.showTotalWinPopup
 );
 const [winning, setWinning] = useState(selectedStore.initialState.allWinning);

 const [showUserBetNumber, setShowUserBetNumber] = useState(
  selectedStoreMobile.initialState.showUserBetNumber
 );
 const [resultWithHistory, setResultWithHistory] = useState(
  selectedStore.initialState.gameResultWithHistory
 );
 const [betTypeHelp, setBetTypeHelp] = useState(
  selectedStoreMobile.initialState.selectedBetTypeHelpInfo
 );
 const [showTitle, setShowTitle] = useState(selectedStoreMobile.initialState.showTitle);

 const [writeNumber, setWriteNumber] = useState(selectedStore.initialState.writeNumberAnimation);

 const [chooseNumber, setChooseNumber] = useState(
  selectedStore.initialState.chooseNumberAnimation
 );
 const [chooseAnimal, setChooseAnimal] = useState(
  selectedStore.initialState.chooseAnimalAnimation
 );
 const [betTypeInfo, setBetTypeInfo] = useState(selectedStore.initialState.betTypesInfo);
 const [showBetType, setShowBetType] = useState(selectedStoreMobile.initialState.showBetType);
 const [autoOpenResult, setAutoOpenResult] = useState(selectedStore.initialState.autoOpenResult);

 const [showBetsInDemo, setShowBetsInDemo] = useState(demoState.initialState.showUserBetNumber);
 const [demoWinning, setDemoWinning] = useState(demoState.initialState.winning);

 const setBetAmount = (arg) =>{
  if (arg !== undefined){
   setAmount(arg)
  }
 };
 useEffect(() => {
  const demoBetResults = demoState.subscribe(data => setDemoBetsResult(data.betResults));
  const demoBet = demoState.subscribe(data => setDemoBets(data.userBet));
  const demoWin = demoState.subscribe(data => setDemoWinning(data.winning));
  const showBetsDemo = demoState.subscribe(data => setShowBetsInDemo(data.showUserBetNumber));
  const animation = selectedStore.subscribe(data => setAnimationOn(data.animation));
  const openResult = selectedStore.subscribe(data => setAutoOpenResult(data.autoOpenResult));
  const gameResultWithHistorySubscribtion = selectedStore.subscribe(data =>
   setResultWithHistory(data.gameResultWithHistory)
  );
  const showNewResultSubscribtion = selectedStore.subscribe(data =>
   setShowNewResult(data.showNewResult)
  );
  const betTypesInfoSubscribtion = selectedStore.subscribe(data =>
   setBetTypeInfo(data.betTypesInfo)
  );
  const showBetTypeSubscribtion = selectedStoreMobile.subscribe(data =>
   setShowBetType(data.showBetType)
  );
  const UserBetSubscribtion = selectedStore.subscribe(data =>
   setUserBet(data.userBet)
  );
  const selectedBetTypeHelpInfoSubscribtion = selectedStoreMobile.subscribe(data =>
   setBetTypeHelp(data.selectedBetTypeHelpInfo)
  );
  const allWinningSubscribtion = selectedStore.subscribe(data => setWinning(data.allWinning));
  const showUserBetNumberSubscribtion = selectedStoreMobile.subscribe(data =>
   setShowUserBetNumber(data.showUserBetNumber)
  );

  const showTitleSubscribtion = selectedStoreMobile.subscribe(data =>
   setShowTitle(data.showTitle)
  );
  const chooseNumberAnimationSubscribtion = selectedStore.subscribe(data =>
   setChooseNumber(data.chooseNumberAnimation)
  );
  const chooseAnimalAnimationSubscribtion = selectedStore.subscribe(data =>
   setChooseAnimal(data.chooseAnimalAnimation)
  );
  const showBetTypeInfoSubscribtion = selectedStore.subscribe(data =>
   setShowBetTypeInfo(data.showBetTypeInfo)
  );
  const writeNumberAnimationSubscribtion = selectedStore.subscribe(data =>
   setWriteNumber(data.writeNumberAnimation)
  );
  const showTotalWinPopupSubscribtion = selectedStore.subscribe(data =>
   setShowTotalWinPopup(data.showTotalWinPopup)
  );
  selectedStore.init();
  selectedStoreMobile.init();
  demoState.init();
  return () => {
   demoBetResults.unsubscribe();
   demoBet.unsubscribe();
   demoWin.unsubscribe();
   showBetsDemo.unsubscribe();
   openResult.unsubscribe();
   animation.unsubscribe();
   selectedStore.clearSlectedAnimal();
   gameResultWithHistorySubscribtion.unsubscribe();
   showNewResultSubscribtion.unsubscribe();
   betTypesInfoSubscribtion.unsubscribe();
   showBetTypeSubscribtion.unsubscribe();
   UserBetSubscribtion.unsubscribe();
   selectedBetTypeHelpInfoSubscribtion.unsubscribe();
   allWinningSubscribtion.unsubscribe();
   showUserBetNumberSubscribtion.unsubscribe();
   showTitleSubscribtion.unsubscribe();
   chooseNumberAnimationSubscribtion.unsubscribe();
   chooseAnimalAnimationSubscribtion.unsubscribe();
   showBetTypeInfoSubscribtion.unsubscribe();
   writeNumberAnimationSubscribtion.unsubscribe();
   showTotalWinPopupSubscribtion.unsubscribe();
  };
 }, []);

 useEffect(()=>{
  if (Object.keys(selectedGameType).length){
  setLimits({
   minBet:selectedGameType?.minBet,
   maxBet:selectedGameType?.maxBet,
  });
  }
 },[selectedGameType]);

 const helpBetType = useMemo(() => betTypeInfo.filter(item => item?.type === betTypeHelp?.type), [
  betTypeHelp,
  betTypeInfo
 ]);


 return (
  <div
   className={`mobile-game-grid ${
    chooseAnimal || chooseNumber || writeNumber ? 'disabled-type-1' : ''
   }`}
  >
   {(path === 'game' &&
    !showNewResult &&
    !showUserBetNumber &&
    is.empty(helpBetType) &&
    showTitle && !showBetsInDemo) ||
   (path === 'game' &&
    selectedAnimal.length !== selectedBetType?.animalsCount &&
    !autoOpenResult &&
    is.empty(helpBetType) && !showBetsInDemo) ||
   (path === 'game' &&
    selectedAnimal.length === selectedBetType?.animalsCount &&
    selectedBetType.animalNumbersCount === 4 && !autoOpenResult && !showBetsInDemo) ? (
    <GameTitleMobile
     path={path}
     t={t}
     selectedBetType={selectedBetType}
     selectedAnimal={selectedAnimal}
     showBetType={showBetType}
    />
   ) : null}

   {(path === 'game' &&
    !showBetType &&
    is.empty(helpBetType) &&
    showBetTypeInfo !== true &&
    !showUserBetNumber &&
    !showNewResult && !showBetsInDemo) ||
   (path === 'game' && !showBetType && selectedAnimal.length === selectedBetType?.animalsCount && selectedBetType.animalNumbersCount !== 4 && !showBetsInDemo) ||
   (path === 'game' &&
    is.empty(helpBetType) &&
    !showBetType &&
    showBetTypeInfo !== true &&
    !autoOpenResult && !showBetsInDemo) ? (
    <div className="mob-game-t-holder">
     <ChooseAnimalByBetType
      mobile={mobile}
      selectedAnimal={selectedAnimal}
      selectedBetType={selectedBetType}
      demoMode={demoMode}
     />
    </div>
   ) : null}

   <AnimatePresence>
    {((path === 'game' &&
     showNewResult &&
     is.empty(helpBetType) &&
     autoOpenResult && selectedBetType.animalsCount !== selectedAnimal.length) || path === 'game' &&
     showNewResult &&
     is.empty(helpBetType) &&
     autoOpenResult && selectedBetType.animalsCount === selectedAnimal.length && selectedBetType.animalNumbersCount === 4) && (
     <motion.div
      key="NewResultMobile"
      className="animated-element"
      transition={{ duration: animationOn ? 0.1 : 0 }}
      initial={{ transform: 'translate(0, -140%)' }}
      animate={{ transform: 'translate(0, 0)' }}
      exit={{ transform: 'translate(0, -140%)' }}
     >
      <NewResultMobile result={resultWithHistory}/>
     </motion.div>
    )}
   </AnimatePresence>
   <AnimatePresence>
    {((path === 'game' &&
     is.empty(helpBetType) &&
     autoOpenResult &&
     showUserBetNumber &&
     !is.empty(userBet) && selectedBetType.animalsCount !== selectedAnimal.length) || (path === 'game' &&
     is.empty(helpBetType) &&
     autoOpenResult &&
     showUserBetNumber &&
     !is.empty(userBet) && selectedBetType.animalsCount === selectedAnimal.length && selectedBetType.animalNumbersCount === 4) || (
     path === 'game' && demoMode && showBetsInDemo
    )) && (
     <motion.div
      key="UserBetMobile"
      className="animated-element"
      transition={{ duration: animationOn ? 0.1 : 0 }}
      initial={{ transform: 'translate(0, 140%)' }}
      animate={{ transform: 'translate(0, 0)' }}
      exit={{ transform: 'translate(0, 140%)' }}
     >
      <UserBetMobile userBet={userBet} result={resultWithHistory} demoBets={demoBets} demoMode={demoMode}
                     demoBetsResult={demoBetsResult}/>
     </motion.div>
    )}
   </AnimatePresence>
   {((path === 'game' &&
    winning !== 0 &&
    showTotalWinPopup &&
    is.empty(helpBetType) &&
    selectedAnimal.length !== selectedBetType?.animalsCount) || (path === 'game' &&
    winning !== 0 &&
    showTotalWinPopup &&
    is.empty(helpBetType) &&
    selectedBetType.animalsCount === selectedAnimal.length && selectedBetType.animalNumbersCount === 4) || (path === 'game' && demoWinning)) ? (
    <TotalWinPopupWeb winning={winning} demoMode={demoMode} demoWinning={demoWinning}/>
   ) : null}
   <div className="mob-action-holder">
    {(path === 'game' &&
     !showBetTypeInfo &&
     !showNewResult &&
     !showUserBetNumber &&
     !showBetType &&
     is.empty(helpBetType)) ||
    selectedAnimal.length === selectedBetType?.animalsCount && selectedBetType.animalNumbersCount !== 4 ||
    (path === 'game' &&
     !showBetTypeInfo &&
     !showBetType &&
     !autoOpenResult) ? (
     <BetAction
      mobile={mobile}
      demoMode={demoMode}
      betAmount={amount}
      setBetAmount={setBetAmount}
      selectedGameType={selectedGameType}
      limits={limits}
     />
    ) : null}
   </div>
   {!is.empty(helpBetType) && <GameHelpMobile helpBetType={helpBetType?.[0]}/>}
  </div>
 );
};
export default MobileCenterContainer;
