import moment from "moment";
import { timerConstants } from '../constants/constants';

class TimerHelper {
    static intervalConvertValues = [
        24 * 60 * 60 * 1000,
        60 * 60 * 1000,
        60 * 1000,
        1000
    ];

    /**
     * timerWorker
     * @param distance: milliSeconds
     * @param getArray
     */
    static timerWorker(distance, getArray = false) {
        if (distance < 0) {
            return '';
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (getArray) {
            return [
                {
                    format: 365,
                    key: timerConstants.days,
                    time: TimerHelper.timeUiHelper(days)
                },
                {
                    format: 24,
                    key: timerConstants.hours,
                    time: TimerHelper.timeUiHelper(hours)
                },
                {
                    format: 60,
                    key: timerConstants.minutes,
                    time: TimerHelper.timeUiHelper(minutes)
                },
                {
                    format: 60,
                    key: timerConstants.seconds,
                    time: TimerHelper.timeUiHelper(seconds)
                }
            ];
        }

        return `${TimerHelper.timeUiHelper(days)}:${TimerHelper.timeUiHelper(
            hours
        )}:${TimerHelper.timeUiHelper(minutes)}:${TimerHelper.timeUiHelper(
            seconds
        )}`;
    }

    static getEndDateByInterval(interval) {
        return moment.utc().valueOf() + TimerHelper.intervalConverter(interval);
    }

    static getDistance(time) {
        return moment(time) - moment();
    }

    static intervalConverter(interval) {
        return Math.floor(
            interval.split(':').reduce((a, c, index) => {
                const temp = TimerHelper.intervalConvertValues[index] * c;
                a += temp;
                return a;
            }, 0)
        );
    }

    static timeUiHelper(time) {
        if (time || time === 0) {
            if (time < 10) {
                return `0${time}`;
            }
            return time;
        }
        return '';
    }
}

export default TimerHelper;
