import React from 'react';
import selectedStore from '../../state/selectedState';

const NumpadForHashMobile = ({numpadRef , showNumpad , checkInput}) => {

 const handleSelectNumberFromNumPad = (number) => {
  if (checkInput==='key'){
   if (number==='c'){
    selectedStore.clearKey()
   }else{
   selectedStore.setKey(number)
   }
  }else if (checkInput==='salt'){
    if (number==='c'){
     selectedStore.clearSalt()
    }else{
     selectedStore.setSalt(number)
    }
  }
 };

 return (
  <div className="mob-search-num" ref={numpadRef}>
   <div className={`numpad ${showNumpad ? "open" : ""}`}>
    <span onClick={() => handleSelectNumberFromNumPad(7)}>7</span>
    <span onClick={() => handleSelectNumberFromNumPad(8)}>8</span>
    <span onClick={() => handleSelectNumberFromNumPad(9)}>9</span>
    <span onClick={() => handleSelectNumberFromNumPad(0)}>0</span>
    <span onClick={() => handleSelectNumberFromNumPad(4)}>4</span>
    <span onClick={() => handleSelectNumberFromNumPad(5)}>5</span>
    <span onClick={() => handleSelectNumberFromNumPad(6)}>6</span>
    <span onClick={() => handleSelectNumberFromNumPad("c")}>c</span>
    <span onClick={() => handleSelectNumberFromNumPad(1)}>1</span>
    <span onClick={() => handleSelectNumberFromNumPad(2)}>2</span>
    <span onClick={() => handleSelectNumberFromNumPad(3)}>3</span>
    <span onClick={() => selectedStore.setShowNumPadForHash(false)}>
          <i className="icon-down-arrow" />
        </span>
   </div>
  </div>
 );
};

export default NumpadForHashMobile