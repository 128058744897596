import React, { useEffect, useMemo, useState } from 'react';
import gameService from '../../newCore/services/Games/games';
import selectedStore from '../../state/selectedState';
import HashRow from './hashRow';
import useAnimals from '../../hooks/servicesHooks/Animals/Animals';
import useTranslation from '../../newCore/helpers/useTranslation';

const HashChecker = ({ hashRef , t}) => {
  const [lastResult, setLastResult] = useState(selectedStore.initialState.allGamesResult);
  const [partnerSettings, setPartnerSettings] = useState(
   selectedStore.initialState.partnerSettings
  );
  const [resultSalt , setResultSalt] = useState(selectedStore.initialState.resultSalt);
  const [resultKey , setResultKey] = useState(selectedStore.initialState.resultKey);
  const [hash , setHash] = useState(selectedStore.initialState.hash);
  const { animals } = useAnimals();
  const translation = useTranslation();

  const checkResult = () =>{
    const formData = {
      key : resultKey,
     salt : resultSalt,
    };
    gameService.checkHash(formData)
  };
  useEffect(()=>{
    const gameResultSubscription = selectedStore.subscribe(data =>
     setLastResult(data.allGamesResult)
    );
    const resultSalt = selectedStore.subscribe(data =>setResultSalt(data.resultSalt));
    const resultKey = selectedStore.subscribe(data =>setResultKey(data.resultKey));
    const hash = selectedStore.subscribe(data =>setHash(data.hash));
    const partnerSettings = selectedStore.subscribe(data =>
     setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return ()=>{
      selectedStore.setResultKey('');
      selectedStore.setResultSalt('');
      selectedStore.setHash('');
      hash.unsubscribe();
      resultKey.unsubscribe();
      resultSalt.unsubscribe();
      gameResultSubscription.unsubscribe();
      partnerSettings.unsubscribe();
    }
  },[]);

  const gamesResult = useMemo(()=>{
    return lastResult.filter((item,index)=>index<29)
  },[lastResult]);

  return (
    <>
      <div className="result-popup-container" >
        <div className="result-popup-content" ref={hashRef}>
          <div className="result-popup-c-head">
            <div className="result-popup-h-title">
              <span>{translation('RESULTS')}</span>
              <i className="icon-clear" onClick={()=>selectedStore.setShowHash(false)}></i>
            </div>
            <div className="result-popup-key-salt-row">
              <input
                type="text"
                className="result-popup-input"
                placeholder={translation('KEY')}
                value={resultKey}
                onChange={(e)=>selectedStore.setResultKey(e.target.value)}
              />
              <input
                type="text"
                className="result-popup-input "
                placeholder={translation('SALT')}
                value={resultSalt}
                onChange={(e)=>selectedStore.setResultSalt(e.target.value)}
              />
              <button className={`result-popup-button ${resultSalt && resultKey ? '':'disabled'}`} onClick={checkResult}>{t('CHECK')}</button>
            </div>
            <div className="result-popup-hash-code-row">
              <input
                type="text"
                className="result-popup-input "
                placeholder={translation('HASH_CODE')}
                value={hash}
                onChange={(e)=>selectedStore.setHash(e.target.value)}
              />
            </div>
          </div>
          <div className="result-popup-table">
            <div className="result-popup-t-head">
              <div className="result-popup-t-row">
                <div className="result-popup-t-row-cell type-1">{t('GAME_ID')}</div>
                <div className="result-popup-t-row-cell type-2">{t('RESULT')}</div>
                <div className="result-popup-t-row-cell">{t('KEY')}</div>
                <div className="result-popup-t-row-cell">{t('SALT')}</div>
                <div className="result-popup-t-row-cell type-3">{t('HASH_CODE')}</div>
              </div>
            </div>
            <div className="result-popup-t-content">
              <div className="scroll-container">
                { gamesResult.map(gameResult =>(
                 <HashRow t={t} gameResult={gameResult} animals={animals} partnerSettings={partnerSettings}/>
                )) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HashChecker;
