export default function MathHelper() {
 /**
  * Decimal rounding adjustment.
  *
  * @param {String}  type  type of adjustment.
  * @param {Number}  value Number.
  * @param {Integer} exp   Exponent (decimal logarithm of the basis of adjustment).
  * @returns {Number} Rounded number.
  */
 function decimalAdjust(type, value, exp) {
  // If the degree is not defined, or is equal to zero...
  if (typeof exp === 'undefined' || +exp === 0) {
   return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number, or the degree is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
   return NaN;
  }
  // Bit shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Reverse shift
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
 }

 // Decimal rounding to the nearest
 if (!Math.round10) {
  Math.round10 = function(value, exp) {
   return decimalAdjust('round', value, exp);
  };
 }
  // Decimal rounding down
 if (!Math.floor10) {
  Math.floor10 = function(value, exp) {
   return decimalAdjust('floor', value, exp);
  };
 }
 // Decimal rounding up
 if (!Math.ceil10) {
  Math.ceil10 = function(value, exp) {
   return decimalAdjust('ceil', value, exp);
  };
 }
};
