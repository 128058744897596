import React, { useEffect, useState, useRef, memo, useMemo } from 'react';
import selectedStore from '../../state/selectedState';
import '../../assets/styles/date-picker.css';
import is from 'is_js';
import useTranslation from '../../newCore/helpers/useTranslation';
import demoState from '../../state/demoModeState';
import Toaster from '../toaster';
import selectedStoreMobile from '../../state/selectedStateMobile';


const BetHistoryDemo = memo(() => {
 const [selectedItem, setSelectedItem] = useState(selectedStore.initialState.selectedBetHistory);
 const [ animation , setAnimation] = useState(selectedStore.initialState.animation);
 const [betHistory, setBetHistory] = useState(demoState.initialState.betHistory);

 const [selectedGame, setSelectedGame] = useState(selectedStore.initialState.selectedGame);
 const [betIsAdded, setBetIsAdded] = useState(selectedStore.initialState.betIsAdded);
 const [error, setError] = useState(selectedStore.initialState.betError);
 const [doBet , setDoBet] = useState(selectedStore.initialState.doBet);
 const [repeatedBet , setRepeatedBet] = useState({});
 const [showPopUp , setShowPopUp] = useState(selectedStore.initialState.dontShowPopUp);
 const [betIdForDemoBet, setBetIdForDemoBet] = useState(demoState.initialState.betId);
const [ demoBalance , setDemoBalance] = useState(demoState.initialState.balance);
const [roundId , setRoundId] = useState(selectedStore.initialState.lastRoundId);
 const t = useTranslation();

 useEffect(() => {
  selectedStore.setShowBetTypeInfo(false);
  selectedStore.setHasUnseenBets(false);
  selectedStore.setNewGameResultModal(true);
  const roundId = selectedStore.subscribe(data=>setRoundId(data.lastRoundId));
  const balance = demoState.subscribe(data =>setDemoBalance(data.balance));
  const betId = demoState.subscribe(data => setBetIdForDemoBet(data.betId));
  const doBet = selectedStore.subscribe(data => setDoBet(data.doBet));
  const error = selectedStore.subscribe(data => setError(data.betError));
  const betisAdded = selectedStore.subscribe(data => setBetIsAdded(data.betIsAdded));
  const showPopup = selectedStore.subscribe(data => setShowPopUp(data.dontShowPopUp));
  const selectedGame = selectedStore.subscribe(data => setSelectedGame(data.selectedGame));

  const selectedBetHistory = selectedStore.subscribe(data =>
   setSelectedItem(data.selectedBetHistory)
  );
  const animation = selectedStore.subscribe(data => setAnimation(data.animation));
  const betHistory = demoState.subscribe(data => setBetHistory(data.betHistory));
  selectedStore.clearSlectedAnimal();
  selectedStore.init();
  demoState.init();
  return () => {
   roundId.unsubscribe();
   selectedStore.setDoBet(false);
   balance.unsubscribe();
   betId.unsubscribe();
   showPopup.unsubscribe();
   doBet.unsubscribe();
   error.unsubscribe();
   betisAdded.unsubscribe();
   selectedGame.unsubscribe();
   animation.unsubscribe();
   selectedStore.clearBetHistory();
   selectedBetHistory.unsubscribe();
   betHistory.unsubscribe();
   selectedStore.setShowSearchinGameHistory(false)
  };
 }, []);


 const betHistoryByGame = useMemo(()=>{
   const arr = betHistory?.reduce((acum,current)=>{
    current['totalBetWinningAmount'] = current.bets?.reduce((acum,current)=>acum+current.winningAmount,0)
    acum.push(current);
    return acum
   },[]);
  selectedStore.setSelectedBetHistoryResult(arr[0]?.gameResults);
  selectedStore.selectBetHistory(arr[0]?.bets[0]);
  return arr
 },[betHistory]);

 const reBet = (betHistory) =>{
  const bet = {
   gameId: selectedGame.id,
   betTypeId: betHistory.betTypeId,
   betTypeName:betHistory.betTypeName,
   number: betHistory.number,
   stakeAmount: betHistory.stakeAmount,
   odds: betHistory.odds,
   betType : betHistory.betType,
   id:betIdForDemoBet,
   animalIds:betHistory.animalIds
  };
  if (demoBalance<bet.stakeAmount){
   Toaster.error(t('LOW_BALANCE'));
  }else{
   demoState.setBet(bet);
   demoState.setBetId();
   Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
  }
 };

 useEffect(()=>{
  if (doBet){
   reBet(repeatedBet)
  }
 },[doBet])

 useEffect(() => {
  if (betIsAdded) {
   Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
   selectedStore.setBetIsAdded(false);
   selectedStoreMobile.setHasUnseenBetsMobile(true);
  }
  if (error) {
   Toaster.warning(t(error));
   selectedStore.setBetError('');
  }
 }, [betIsAdded, error, demoBalance]);
 return (
  <div className="scroll-container" id={'scroll-container-bet-history'}>
   <div className="history-holder" style={{ position: 'relative' }}>
    <div className="sticky-container">
     <div className={`history-head`}>
      {/*<div className="history-h-column icon" >*/}
      {/* <i className={`icon-filter history-h-icon filter `}*/}
      {/*  />*/}

      {/*</div>*/}
      <div className="history-h-column text">
       <span className="history-h-text">{t('BET_HISTORY')}</span>
      </div>
      <div
       className={`history-h-column icon `}
      >

        <i
         className={`icon-search history-h-icon search `}
        />
      </div>
      <div
       className="search-cnt"
       style={{ transition: animation ? null : '0s' }}
      >

       <i
        className="icon-clear"
       />
      </div>
     </div>
     <div className={`history-date-picker-container`}>
      <div
       className={`history-sort-row`}
      >
       <div className="h-sort-r">
        <span> {t('SORT')}</span>
       </div>
      </div>
     </div>
    </div>
      {betHistoryByGame?.map((item,index) => {
       return (
        <div className="history-content" key={item?.gameId}>
         <div className="history-c-title">
                    <span>
                      {t('ROUND_ID')} {item?.gameId}
                    </span>
          <span>
                      {t('TOTAL')}{' '}
           {isNaN(item?.totalBetWinningAmount)? '0' : item?.totalBetWinningAmount}
                    </span>
         </div>
         {item?.bets?.map((historyItem) => (
          <div
           className={`history-c-row status-${historyItem.result} ${
            selectedItem?.id === historyItem?.id ? 'active' : ''
           }`}
           key={historyItem?.id}
           onClick={() => {
            selectedStore.selectBetHistory(historyItem);
            selectedStore.setSelectedBetHistoryResult(item?.gameResults);
            selectedStore.setShowBetTypeInfo(false);
           }}
          >
           {/*add class status-(1-3) and active*/}
           <span>
                        {t('ID')} {historyItem.id}
                      </span>
           <span>{historyItem.winningAmount}</span>
           <i className="circle"/>
           {item.gameId ===roundId ? <button onClick={()=>{
            if (!showPopUp){
             selectedStore.setRebetPopUp(true);
             selectedStore.setDoBet(false);
             setRepeatedBet(historyItem)
            }else{
             reBet(historyItem)
            }
           }}>
            <i className="icon-reset rebet-icon"/>
           </button> : null }
          </div>
         ))}
        </div>
       );
      })}
    { is.empty(betHistoryByGame) ? (
     <div className="history-c-title" style={{ display: 'block', textAlign: 'center' }}>
      {t('NO_MATCHING_RESULTS')}
     </div>
    ) : null}
   </div>
  </div>
 );
});

export default BetHistoryDemo;

// (
//  <p className="history-date-text">
//    {new Date().toLocaleDateString("en-US", {
//      year: "numeric",
//      month: "long",
//      day: "numeric",
//      timeZone: "UTC"
//    })}
//  </p>
// )
