export const SignalRConstants = {
 Notify: 'Notify',
 actionType: 'actionType',
 actionObject: 'actionObject',
 actionId: 'actionId',
 MessageSent: "MessageSent",
 GameFinished: "GameFinished",
 LiveBets: "BetPlaced",
 GameCreated: "GameCreated",
 GameCanceled: "GameCanceled",
 LiveBetsResults: "BetResultChanged",
 GameCalculated: "GameCalculated",
 GameCreation : 'GameCreation',
 GameStarted:'GameStarted'
};

export const SignalrNotificationActionType = {
 GameFinished: 0,
 LiveBets: 1,
 GameCreated: 2,
 MessageSent: 3,
 GameCanceled: 4,
 LiveBetsResults: 5,
 GameCalculated: 6,
};
