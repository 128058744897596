import React, { useEffect, useRef, useState } from 'react';
import postMessageService from '../../../newCore/services/PostMessages/PostMessagesService';
import { toCurrency } from '../../../newCore/helpers/betActionHelpers';
import UserConfigWeb from '../../user-config-dropdown/UserConfig.WEB';
import animal from '../../../assets/images/Animals/16.png'
import { useOutsideClick } from '../../../newCore/helpers/useClickOutside';
import selectedStore from '../../../state/selectedState';
import selectedStoreMobile from '../../../state/selectedStateMobile';


const HeaderInLobby = ({t}) =>{
 const [userBalance, setUserBalance] = useState(selectedStore.initialState.userBalance);
 const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
 const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);
 const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );
 const [openConfig, setOpenConfig] = useState(false);
 const settingsRef = useRef();

 useEffect(() => {
  const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
  const userBalanceSub = selectedStore.subscribe(data => setUserBalance(data.userBalance));
  const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));

  selectedStoreMobile.init();
  return () => {
   userCurrency.unsubscribe();
   userBalanceSub.unsubscribe();
   isAuth.unsubscribe();
  };
 }, []);

 useOutsideClick(settingsRef, event => {
  if (openConfig) {
   setOpenConfig(false);
  }

 });
 return (
  <div className="m-header-row">
   <div className="header-m-holder">
    <div className="h-m-icon-box">

      <span
       className="icon-home"
       onClick={() => {
        postMessageService.goToHome();
       }}
      />
    </div>
    <div className="balance-holder-m">
     {isAuth ? (
      <p className="balance-m-text">
       {t("BALANCE")}: {toCurrency(userBalance?.data, userCurrency?.[0]?.name)}
      </p>
     ) : null}
    </div>
    <div className="user-menu-holder-m" ref={settingsRef} onClick={()=>setOpenConfig(true)}>
     <img src={animal} alt=""/>
     <i className="icon-arrow_drop_down"/>
    </div>
    {
     openConfig && <UserConfigWeb/>
    }
   </div>
  </div>
 )
}

export default HeaderInLobby