import React, { useEffect, useState, useRef, memo } from 'react';
import selectedStore from '../../state/selectedState';
import DatePicker from 'react-datepicker';
import '../../assets/styles/date-picker.css';
import Query from '../../newCore/helpers/queryBuilder';
import { calendarQueryModel } from '../../newCore/constants/queryModels';
import gameService from '../../newCore/services/Games/games';
import {useOutsideClick } from '../../newCore/helpers/useClickOutside';
import is from 'is_js';
import { toCurrency } from '../../newCore/helpers/betActionHelpers';

import SortList from './sort-list';
import Loading from '../Loading/loading';
import useTranslation from '../../newCore/helpers/useTranslation';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingInline from '../Loading/loadingInline';
import { queryGet } from '../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../newCore/constants/constants';
import NumpadForTerminal from '../numPad/numpadForTerminal';
import { addBet } from '../../newCore/helpers/addBet';
import betHistoryService from '../../newCore/services/Bets/bet';
import useUser from '../../hooks/servicesHooks/User/userHook';
import Toaster from '../toaster';
import selectedStoreMobile from '../../state/selectedStateMobile';

const sortModel = {
 '0': 'PENDING',
 '1': 'WIN',
 '2': 'LOSE',
 '3': 'RETURNED'
};
const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

const sevenDaysBefore = new Date(today);
sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);

const BetHistoryLeftContent = memo(() => {
 const [showSearch, setShowSearch] = useState(selectedStore.initialState.showSearchinGameHistory);
 let betHistoryQuery = new Query(calendarQueryModel);
 const [startDate, setStartDate] = useState(null);
 const [endDate, setEndDate] = useState(startDate);
 const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);

 const [isShowCalendar, setIsShowCalendar] = useState(false);
 const [isOpenedSort, setIsOpenedSort] = useState(false);
 const [sort, setSort] = useState(null);
 const [isLoadingBetHistory, setIsLoadingBetHistory] = useState(
  selectedStore.initialState.betHistoryIsLoading
 );
 const isCashDesk = queryGet(APP_CONSTANTS.IS_CASH_DESK) === 'true';
 const inputRef = useRef([]);
 inputRef.current = new Array(2);
 const [searchValue, setSearchValue] = useState('');

 const [selectedItem, setSelectedItem] = useState(selectedStore.initialState.selectedBetHistory);
 const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
 const [ animation , setAnimation] = useState(selectedStore.initialState.animation)
 const [take, setTake] = useState(15);
 const [skip, setSkip] = useState(0);
 const [betTypes, setBetTypes] = useState(selectedStore.initialState.partnerBetTypes);
 const [betHistory, setBetHistory] = useState(selectedStore.initialState.betHistory);
 const [betHistoryCount, setBetHistoryCount] = useState(selectedStore.initialState.betHistoryCount);
 const [isFiltersChanged, setIsFiltersChanged] = useState(false);
 const [selectedGame, setSelectedGame] = useState(selectedStore.initialState.selectedGame);
 const { userBalance } = useUser();
 const [betIsAdded, setBetIsAdded] = useState(selectedStore.initialState.betIsAdded);
 const [error, setError] = useState(selectedStore.initialState.betError);
 const [doBet , setDoBet] = useState(selectedStore.initialState.doBet);
 const [repeatedBet , setRepeatedBet] = useState({});
 const [showPopUp , setShowPopUp] = useState(selectedStore.initialState.dontShowPopUp);

 const resetFilers = () => {
  selectedStore.clearBetHistory();
  setIsFiltersChanged(false);
  // setStartDate(today);
  // setEndDate(tomorrow);
  setTake(15);
  setSort(0);
  setStartDate(null);
  setEndDate(null);
  setSearchValue('');
  setSort(null);
  setIsOpenedSort(false);
 };
 const t = useTranslation();

 useEffect(() => {
  selectedStore.setShowBetTypeInfo(false)
  const doBet = selectedStore.subscribe(data => setDoBet(data.doBet))
  selectedStore.setHasUnseenBets(false);
  selectedStore.setNewGameResultModal(true);
  setIsFiltersChanged(false);
  const selectedGame = selectedStore.subscribe(data => setSelectedGame(data.selectedGame));

  const selectedBetHistory = selectedStore.subscribe(data =>
   setSelectedItem(data.selectedBetHistory)
  );
  const betHistoryCount = selectedStore.subscribe(data =>
   setBetHistoryCount(data.betHistoryCount)
  );
  const animation = selectedStore.subscribe(data => setAnimation(data.animation))
  const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));
  const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
  const betHistory = selectedStore.subscribe(data => setBetHistory(data.betHistory));
  const showSearch = selectedStore.subscribe(data => setShowSearch(data.showSearchinGameHistory));
  const isLoadingBetHistory = selectedStore.subscribe(data =>
   setIsLoadingBetHistory(data.betHistoryIsLoading)
  );
  const betisAdded = selectedStore.subscribe(data => setBetIsAdded(data.betIsAdded));
  const partnerBetTypes = selectedStore.subscribe(data => setBetTypes(data.partnerBetTypes));
  const error = selectedStore.subscribe(data => setError(data.betError));
  const showPopup = selectedStore.subscribe(data => setShowPopUp(data.dontShowPopUp))

  selectedStore.clearSlectedAnimal();
  selectedStore.init();
  return () => {
   selectedStore.setDoBet(false);
   showPopup.unsubscribe();
   doBet.unsubscribe();
   error.unsubscribe();
   betisAdded.unsubscribe();
   selectedGame.unsubscribe();
   animation.unsubscribe();
   selectedStore.clearBetHistory();
   partnerBetTypes.unsubscribe();
   selectedBetHistory.unsubscribe();
   betHistoryCount.unsubscribe();
   userCurrency.unsubscribe();
   betHistory.unsubscribe();
   showSearch.unsubscribe();
   isLoadingBetHistory.unsubscribe();
   isAuth.unsubscribe();
   selectedStore.setShowSearchinGameHistory(false)
  };
 }, []);

 useEffect(() => {
  if (sort) {
   setIsFiltersChanged(true);
   setTake(100);
  }
 }, [sort]);

 // const handleScroll = () => {
 //   setSkip(skip + 30);
 // };
 const handleSearch = e => {
  const reg = /^[0-9\b]+$/;
  if (e.target.value === '' || reg.test(e.target.value)) {
   selectedStore.clearBetHistory();
   setIsFiltersChanged(true);
   setSearchValue(e.target.value);
  }
 };

 useOutsideClick(inputRef, () => {
  if (showSearch){
   selectedStore.setShowSearchinGameHistory(false)
  }
 });

 const onChange = dates => {
  selectedStore.clearBetHistory();
  setIsFiltersChanged(true);
  const [start, end] = dates;
  setStartDate(start);
  setEndDate(end);
  setTake(100);
 };
 const partnerBetTypesType = betTypes?.map(bt => bt?.type);

 useEffect(() => {
  isAuth &&
  gameService.getBetHistory(
   betHistoryQuery
    .betId(searchValue)
    .orderColumnName('id')
    .orderByAsc(false)
    .skip(skip)
    .take(take)
    .result(sort)
    .toString(),
   startDate,
   endDate
  );
 }, [searchValue, startDate, endDate, sort, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

 const fetchMoreData = () => {
  setSkip(skip + 15);
 };
 useEffect(() => {
  if (skip >= 15)
   gameService.getBetHistory(
    betHistoryQuery
     .betId(searchValue)
     .orderColumnName('id')
     .orderByAsc(false)
     .skip(skip)
     .take(take)
     .result(sort)
     .toString(),
    startDate,
    endDate
   );
 }, [skip]); // eslint-disable-line react-hooks/exhaustive-deps

 const handleOpenCalendar = () => {
  setIsShowCalendar(!isShowCalendar);
 };

 const reBet = (bet) =>{
  const selectedBetType = {
   id : bet.betTypeId,
   type : bet.betType,
   name:bet.betTypeName,
   odds: bet.odds,
  };
  if (userBalance<bet.stakeAmount){
   Toaster.error(t('LOW_BALANCE'));
  }else{
  betHistoryService.bet({selectedGame,selectedBetType},bet.stakeAmount,bet.number,bet.animalIds)
  }
 };


 useEffect(()=>{
  if (doBet){
   reBet(repeatedBet)
  }
 },[doBet])

 useEffect(() => {
  if (betIsAdded) {
   Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
   selectedStore.setBetIsAdded(false);
   selectedStoreMobile.setHasUnseenBetsMobile(true);
  }
  if (error) {
   Toaster.warning(t(error));
   selectedStore.setBetError('');
  }
 }, [betIsAdded, error, userBalance]);

 return (
  <div className="scroll-container" id={'scroll-container-bet-history'}>
   {isLoadingBetHistory ? <Loading/> : null}
   <div className="history-holder" style={{ position: 'relative' }}>
    <div className="sticky-container">
     <div className={`history-head ${showSearch ? 'search-visible' : ''}`}>
      {/*<div className="history-h-column icon" onClick={handleOpenCalendar}>*/}
      {/* {isShowCalendar ? (*/}
      {/*  <i className="icon-close_filter history-h-icon animated"/>*/}
      {/* ) : (*/}
      {/*  <i*/}
      {/*   className={`icon-filter history-h-icon filter ${*/}
      {/*    isFiltersChanged ? 'active' : ''*/}
      {/*   }`}*/}
      {/*  />*/}
      {/* )}*/}
      {/*</div>*/}
      <div className="history-h-column text">
       <span className="history-h-text">{t('BET_HISTORY')}</span>
      </div>
      <div
       className={`history-h-column icon ${
        !isFiltersChanged && isShowCalendar ? 'disabled' : ''
       }`}
      >
       {isShowCalendar ? (
        <i
         className={`icon-reset history-h-icon ${isFiltersChanged ? 'active' : ''}`}
         onClick={resetFilers}
        />
       ) : (
        <i
         className={`icon-search history-h-icon search `}
         onClick={() => selectedStore.setShowSearchinGameHistory(true)}
        />
       )}
       {/*add class animated for refresh icon animation*/}
      </div>
      <div
       className="search-cnt"
       style={{ transition: animation ? null : '0s' }}
      >
       <input
        type="text"
        placeholder={t('BET_ID')}
        onChange={handleSearch}
        value={searchValue}
        ref={inputRef}
        maxLength={19}
        onClick={()=>setShowSearch(true)}
       />
       <i
        className="icon-clear"
        onClick={() => selectedStore.setShowSearchinGameHistory(false)}
       />
      </div>
     </div>
     <div className={`history-date-picker-container ${isShowCalendar ? 'open' : ''}`}>
      {!isOpenedSort ? (
       <div className="date-picker">
        <DatePicker
         selected={startDate}
         onChange={onChange}
         startDate={startDate}
         endDate={endDate}
         minDate={new Date(sevenDaysBefore)}
         maxDate={new Date(today)}
         showDisabledMonthNavigation
         selectsRange
         inline
        />
       </div>
      ) : null}
      <div
       className={`history-sort-row ${isOpenedSort ? 'open' : ''}`}
       onClick={() => setIsOpenedSort(!isOpenedSort)}
      >
       <div className={`history-sort-r-inner `}>
        <SortList
         sort={sort}
         setSort={setSort}
         setTake={setTake}
         isOpenedSort={isOpenedSort}
         setIsOpenedSort={setIsOpenedSort}
         setIsShowCalendar={setIsShowCalendar}
        />
       </div>
       <div className="h-sort-r">
        {sort === null && (
         <i className="icon-arrow_drop_down down" style={{ fontSize: '28px' }}/>
        )}
        <span> {sort !== null ? t(sortModel[sort]) : t('SORT')}</span>
       </div>
      </div>
     </div>
     {
      isCashDesk && showSearch &&
      <NumpadForTerminal   inputRef={inputRef} setIsFiltersChanged={setIsFiltersChanged} setSearchValue={setSearchValue}/>
     }
    </div>

    {!isLoadingBetHistory && !is.empty(betHistory) ? (
     <InfiniteScroll
      dataLength={betHistory?.length || 35}
      next={fetchMoreData}
      hasMore={betHistory?.length <= betHistoryCount}
      loader={
        <LoadingInline/>
      }
      scrollableTarget="scroll-container-bet-history"
     >
      {betHistory?.map((item,index) => {
       item.bets = item?.bets?.filter(b => partnerBetTypesType.includes(b.betType));
       if (is.empty(item?.bets)) {
        return null;
       }
       return (
        <div className="history-content" key={item?.gameId}>
         <div className="history-c-title">
                    <span>
                      {t('ROUND_ID')} {item?.gameId}
                    </span>
          <span>
                      {t('TOTAL')}{' '}
           {toCurrency(item?.totalBetWinningAmount, userCurrency?.[0]?.name)}
                    </span>
         </div>
         {item?.bets?.map(historyItem => (
          <div
           className={`history-c-row status-${historyItem.result} ${
            selectedItem?.id === historyItem?.id ? 'active' : ''
           }`}
           key={historyItem?.id}
           onClick={() => {
            selectedStore.selectBetHistory(historyItem);
            selectedStore.setSelectedBetHistoryResult(item?.result);
            selectedStore.setShowBetTypeInfo(false);
           }}
          >
           {/*add class status-(1-3) and active*/}
           <span>
                        {t('ID')} {historyItem.id}
                      </span>
           <span>{toCurrency(historyItem.winningAmount, userCurrency?.[0]?.name)}</span>
           <i className="circle"/>
           {index ? null : <button onClick={()=>{
            if (!showPopUp){
             selectedStore.setDoBet(false)
             selectedStore.setRebetPopUp(true)
             setRepeatedBet(historyItem)
            }else{
             reBet(historyItem)
            }
           }}>
            <i className="rebet-icon icon-reset"/>
           </button>}
          </div>
         ))}
        </div>
       );
      })}
     </InfiniteScroll>
    ) : null}
    {!isLoadingBetHistory && is.empty(betHistory) && betHistoryCount === 0 ? (
     <div className="history-c-title" style={{ display: 'block', textAlign: 'center' }}>
      {t('NO_MATCHING_RESULTS')}
     </div>
    ) : null}
   </div>
  </div>
 );
});

export default BetHistoryLeftContent;

// (
//  <p className="history-date-text">
//    {new Date().toLocaleDateString("en-US", {
//      year: "numeric",
//      month: "long",
//      day: "numeric",
//      timeZone: "UTC"
//    })}
//  </p>
// )
