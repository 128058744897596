import { HEADERS } from "../../constants/constants";
import FromFetchService from "../fromFetchService/fromFetchService";
// import * as signalR from "@aspnet/signalr";
import { DOMAIN } from '../../constants/environment';
import {HubConnectionBuilder} from '@microsoft/signalr';


export default class SignalRConnect {

  static createConnectionRx(partnerId) {
    const signalRUrl = `${DOMAIN}/MessageHub?partnerId=${partnerId}&${HEADERS.TimeZoneKey}=${HEADERS.TimeZone}&access_token=${FromFetchService.TOKEN}`;
    return new HubConnectionBuilder().withUrl(signalRUrl).withAutomaticReconnect([300, 600]).build();
  }
}
