import { addOneDayToStart } from "./DateConverter";
import { EDateConstants } from "../../newCore/constants/constants";

export const createCalendarQuery = (startDate, endDate) => {
  let dateQuery = [];
  let query = "?";
  if (startDate && endDate) {
    const newDay = addOneDayToStart(endDate);
    dateQuery = [
      { key: EDateConstants.startDate, value: startDate },
      { key: EDateConstants.endDate, value: newDay }
    ].forEach((queryField, index) => {
      if (index) {
        query += `&${queryField.key}=${queryField.value}`;
      } else {
        query += `${queryField.key}=${queryField.value}`;
      }
    });
  } else if (startDate && !endDate) {
    const newDay = addOneDayToStart(startDate);
    dateQuery = [
      { key: EDateConstants.startDate, value: startDate },
      { key: EDateConstants.endDate, value: newDay }
    ];
  }
  return dateQuery;
};
