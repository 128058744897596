import React from 'react';

const GameResultSkeleton = () =>{

 return (
  <div className="result-skeleton-cnt ">
   <div className="result-skeleton-img sk"></div>
   <div className="result-skeleton-txt sk"></div>
   <div className="result-skeleton-txt sk"></div>
   <div className="result-skeleton-singles-cnt">
    <div className="result-skeleton-s-time">
     <div className="result-s-s-t-time sk"></div>
     <div className="result-s-s-t-time sk"></div>
    </div>
    <div className="result-skeleton-s-r">
     <div className="result-skeleton-num sk"></div>
     <div className="result-skeleton-s-row sk"></div>
    </div>
    <div className="result-skeleton-s-r">
     <div className="result-skeleton-num sk"></div>
     <div className="result-skeleton-s-row sk"></div>
    </div>
    <div className="result-skeleton-s-r">
     <div className="result-skeleton-num sk"></div>
     <div className="result-skeleton-s-row sk"></div>
    </div>
    <div className="result-skeleton-s-r">
     <div className="result-skeleton-num sk"></div>
     <div className="result-skeleton-s-row sk"></div>
    </div>
    <div className="result-skeleton-s-r">
     <div className="result-skeleton-num sk"></div>
     <div className="result-skeleton-s-row sk"></div>
    </div>
   </div>
  </div>
 )
}
export default GameResultSkeleton