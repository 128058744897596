import SignalRConnect from "../../global/SignalRService/SignalRConnection";
import { Observable } from "rxjs";
import { SignalRConstants } from "../../../newCore/constants/signalRConstants";
import { useSignalR } from "../../../hooks/servicesHooks/SignalR/useSignalR";
import selectedStore from "../../../state/selectedState";

class SignalRx {

  static USER_ID = '';
  static USER_BET = false;
  static IsLobby = true;

  startConnectionRx = async (partnerId, target) => {
    this.connectionRx = SignalRConnect.createConnectionRx(partnerId);

    this.connectionRx.onreconnecting(() => {
      this.isReconnect = false;
      selectedStore.setConnectionLost(true);
      selectedStore.setConnection(false)
    });

    this.connectionRx.onreconnected(() => {
      this.isReconnect = true;
      selectedStore.setConnectionLost(false);
      selectedStore.setConnection(true)
    });

    this.connectionRx.onclose(async () => {
      selectedStore.setConnectionLost(true);
      selectedStore.setConnection(false)
      await this.startSignalRConnection(target);
    });

    await this.startSignalRConnection(target);
  };

  subscribeMethods() {
    return new Observable(observer => {
      this.connectionRx.on(SignalRConstants.GameFinished, data => {
        observer.next({ key: SignalRConstants.GameFinished, data });
      });
      this.connectionRx.on(SignalRConstants.GameCalculated, data => {
        observer.next({ key: SignalRConstants.GameCalculated, data });
      });
      this.connectionRx.on(SignalRConstants.GameCreated, data => {
        observer.next({ key: SignalRConstants.GameCreated, data });
      });
      this.connectionRx.on(SignalRConstants.GameCanceled, data => {
        observer.next({ key: SignalRConstants.GameCanceled, data });
      });
      this.connectionRx.on(SignalRConstants.LiveBets, data => {
        SignalRx.USER_BET=true;
        observer.next({ key: SignalRConstants.LiveBets, data });
      });
      this.connectionRx.on(SignalRConstants.LiveBetsResults, data => {
        observer.next({ key: SignalRConstants.LiveBetsResults, data });
      });
      this.connectionRx.on(SignalRConstants.GameStarted, data => {
        observer.next({ key: SignalRConstants.GameStarted, data });
      });

      return () => {
        //unsubscribe
      };
    });
  }

  async startSignalRConnection(target) {
    // this.target = target;
    try {
      await this.connectionRx.start();
      this.joinGroup(target);
      selectedStore.setConnectionLost(false);
      selectedStore.setConnection(true)
      this.isReconnect = true;
    } catch (e) {
      selectedStore.setConnectionLost(true);
      selectedStore.setConnection(false)
      setTimeout(async () => {
        await this.startSignalRConnection(target);
      }, 5000);
    }
  }

  async joinGroup(type){
    await this.connectionRx.invoke("JoinGroup", type);
  }

 async removeGroup(type){
    await this.connectionRx.invoke('RemoveFromGroup', type)
  }

  isConnected() {
    return this.connectionRx && this.isReconnect;
  }

  subscribeConnection = async store => {
    const { partnerId, isLobby, gameId } = store;
    await this.startConnectionRx(partnerId,isLobby ? 'lobby' : `Group_${gameId}`);
    this.subscribeMethods().subscribe(data => {
      switch (data["key"]) {
        case SignalRConstants.GameFinished:
          useSignalR.gameFinished(store, data.data);
          break;
        case SignalRConstants.GameCreated:
         selectedStore.setGameFromSocket(data.data);
          // useSignalR.gameCreated(store, data.data);
          break;
        case SignalRConstants.GameCanceled:
          useSignalR.gameCanceled(store, data.data);
          break;
        case SignalRConstants.GameCalculated:
          useSignalR.gameCalculated({ partnerId, id:SignalRx.USER_ID }, data.data);
          break;
        case SignalRConstants.LiveBets:
          SignalRx.USER_BET=true;
          useSignalR.liveBets(data.data);
          break;
           case SignalRConstants.GameStarted:
          useSignalR.gameCreated(store, data.data);
           break;
        case SignalRConstants.LiveBetsResults:
            if (data.data?.userId===SignalRx.USER_ID){
              SignalRx.USER_BET=true
            }
          useSignalR.liveBetsResults(data.data);
          break;

        default:
          break;
      }
    });
  };
}
const signalRSubscription = new SignalRx();

export default signalRSubscription