import React, { useEffect, useState, useRef, memo } from "react";
import selectedStore from "../../state/selectedState";
import DatePicker from "react-datepicker";
import "../../assets/styles/date-picker.css";
import Query from "../../newCore/helpers/queryBuilder";
import { calendarQueryModel } from "../../newCore/constants/queryModels";
import { useOutsideClick } from "../../newCore/helpers/useClickOutside";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import Loading from "../Loading/loading";
import useTranslation from "../../newCore/helpers/useTranslation";
import SortList from "../bet-history/sort-list";
import selectedStoreMobile from "../../state/selectedStateMobile";
import is from "is_js";
import GameBetHistoryMobile from "./gameBetHistoryMobile";
import gameService from "../../newCore/services/Games/games";
import NumpadForSearchGameResult from "../numPad/numpadForSearchGameResult";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactDOM from "react-dom";
import LoadingInline from "../Loading/loadingInline";
import useUser from '../../hooks/servicesHooks/User/userHook';
import Toaster from '../toaster';
import betHistoryService from '../../newCore/services/Bets/bet';

const sortModel = {
  "0": "PENDING",
  "1": "WIN",
  "2": "LOSE",
  "3": "RETURNED"
};
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
const sevenDaysBefore = new Date(today);
sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);

const BetHistoryMobile = memo(({ hasUnseenBets }) => {
  const [showSearch, setShowSearch] = useState(false);
  let betHistoryQuery = new Query(calendarQueryModel);
  const [showNumpad, setShowNumpad] = useState(selectedStore.initialState.showNumPad);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [isOpenedSort, setIsOpenedSort] = useState(false);
  const [sort, setSort] = useState(null);

  const inputRef = useRef([]);
  inputRef.current = new Array(2);

  const [searchValue, setSearchValue] = useState("");

  const [selectedItem, setSelectedItem] = useState(
    selectedStoreMobile.initialState.selectedBetHistory
  );
  const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);

  const [take, setTake] = useState(15);
  const [skip, setSkip] = useState(0);

  const [betHistory, setBetHistory] = useState(selectedStore.initialState.betHistory);
  const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  const [isLoadingBetHistory, setIsLoadingBetHistory] = useState(
    selectedStore.initialState.betHistoryIsLoading
  );
  const [animation , setAnimation] = useState(selectedStore.initialState.animation)
  const [repeatedBet , setRepeatedBet] = useState({});
  const [showPopUp , setShowPopUp] = useState(selectedStore.initialState.dontShowPopUp);
  const { userBalance } = useUser();
  const [betIsAdded, setBetIsAdded] = useState(selectedStore.initialState.betIsAdded);
  const [error, setError] = useState(selectedStore.initialState.betError);
  const [doBet , setDoBet] = useState(selectedStore.initialState.doBet);
  const [selectedGame, setSelectedGame] = useState(selectedStore.initialState.selectedGame);
  const [betHistoryCount, setBetHistoryCount] = useState(selectedStore.initialState.betHistoryCount);

  const resetFilers = () => {
    selectedStore.clearBetHistory()
    setIsFiltersChanged(false);
    setTake(15);
    setSort(0);
    setStartDate(null);
    setEndDate(null);
    setSearchValue("");
    setSort(null);
    setIsOpenedSort(false);
  };
  const t = useTranslation();

  useEffect(() => {
    const selectedGame = selectedStore.subscribe(data => setSelectedGame(data.selectedGame));
    const doBet = selectedStore.subscribe(data => setDoBet(data.doBet));
    const betisAdded = selectedStore.subscribe(data => setBetIsAdded(data.betIsAdded));
    const error = selectedStore.subscribe(data => setError(data.betError));
    const showPopup = selectedStore.subscribe(data => setShowPopUp(data.dontShowPopUp))
    const betHistoryCount = selectedStore.subscribe(data =>
     setBetHistoryCount(data.betHistoryCount)
    );
    const animation = selectedStore.subscribe(data =>setAnimation(data.animation))
    setIsFiltersChanged(false);
    selectedStore.setHasUnseenBets(false);
    const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));

    const selectedBetHistory = selectedStoreMobile.subscribe(data =>
      setSelectedItem(data.selectedBetHistory)
    );
    const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
    const betHistory = selectedStore.subscribe(data => setBetHistory(data.betHistory));
    const isLoadingBetHistory = selectedStore.subscribe(data =>
      setIsLoadingBetHistory(data.betHistoryIsLoading)
    );
    const shownumpadSubscription = selectedStore.subscribe(data => setShowNumpad(data.showNumPad));
    selectedStore.clearSlectedAnimal();
    selectedStore.setNewGameResultModal(true);
    selectedStoreMobile.init();
    selectedStore.init();
    return () => {
      betHistoryCount.unsubscribe();
      selectedGame.unsubscribe();
      doBet.unsubscribe();
      betisAdded.unsubscribe();
      error.unsubscribe();
      showPopup.unsubscribe();
      animation.unsubscribe();
      selectedStore.clearBetHistory();
      isLoadingBetHistory.unsubscribe();
      shownumpadSubscription.unsubscribe();
      selectedBetHistory.unsubscribe();
      userCurrency.unsubscribe();
      betHistory.unsubscribe();
      isAuth.unsubscribe();
      selectedStore.setBetHistoryIsLoading(true)
      selectedStore.setDoBet(false)
    };
  }, []);

  useEffect(() => {
    if (sort) {
      setIsFiltersChanged(true);
      setTake(100);
    }
  }, [sort]);

  const onChange = dates => {
    selectedStore.clearBetHistory();
    setIsFiltersChanged(true);

    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    isAuth &&
      gameService.getBetHistory(
        betHistoryQuery
          .betId(searchValue)
          .orderColumnName("id")
          .orderByAsc(false)
          .skip(skip)
          .result(sort)
          .take(take)
          .toString(),
        startDate,
        endDate
      );
  }, [searchValue, startDate, endDate, sort, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoreData = () => {
    setSkip(skip + 15);
  };
  useEffect(() => {
    if (skip >= 15)
      gameService.getBetHistory(
        betHistoryQuery
          .betId(searchValue)
          .orderColumnName("id")
          .orderByAsc(false)
          .skip(skip)
          .result(sort)
          .take(take)
          .toString(),
        startDate,
        endDate
      );
  }, [skip]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };

  useOutsideClick(inputRef, () => {
    selectedStore.setShowNumPad(false);
    setShowSearch(false);
  });


  const reBet = (bet) =>{
    const selectedBetType = {
      id : bet.betTypeId,
      type : bet.betType,
      name:bet.betTypeName,
      odds: bet.odds,
    };
    if (userBalance<bet.stakeAmount){
      Toaster.error(t('LOW_BALANCE'));
    }else{
      betHistoryService.bet({selectedGame,selectedBetType},bet.stakeAmount,bet.number,bet.animalIds)
    }
  };

  useEffect(()=>{
    if (doBet){
      reBet(repeatedBet)
    }
  },[doBet]);

  useEffect(() => {
    if (betIsAdded) {
      Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
      selectedStore.setBetIsAdded(false);
      selectedStoreMobile.setHasUnseenBetsMobile(true);
    }
    if (error) {
      Toaster.warning(t(error));
      selectedStore.setBetError('');
    }
  }, [betIsAdded, error, userBalance]);


  if (!is.empty(selectedItem)) {
    return <GameBetHistoryMobile selectedBetHistory={selectedItem} isFromBetHistory={true} demoBet={reBet} setRepeatedBet={setRepeatedBet} showPopUp={showPopUp} />;
  }
  return (
    <>
      {isLoadingBetHistory ? <Loading /> : null}
      <div className="history-holder" style={{ position: "relative" }}>
        <div className="sticky-container">
          <div className={`history-head ${showSearch ? "search-visible" : ""}`}>
            {/*<div className={`history-h-column icon`} onClick={handleOpenCalendar}>*/}
            {/*  {isShowCalendar ? (*/}
            {/*    <i className="icon-close_filter history-h-icon animated active" />*/}
            {/*  ) : (*/}
            {/*    <i*/}
            {/*      className={`icon-filter history-h-icon filter ${hasUnseenBets ? "active" : ""}`}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</div>*/}
            <div className="history-h-column text">
              <span className="history-h-text">{t("BET_HISTORY")}</span>
            </div>
            <div className={`history-h-column icon ${!isFiltersChanged && isShowCalendar ? "disabled" : ""}`}>
              {isShowCalendar ? (
                <i
                  className={`icon-reset history-h-icon animated ${
                    isFiltersChanged ? "active" : ""
                  }`}
                  onClick={resetFilers}
                />
              ) : (
                <i
                  className={`icon-search history-h-icon search `}
                  onClick={() => setShowSearch(true)}
                />
              )}
            </div>
            <div
              className="search-cnt"
              style={{ transition: animation ? null : "0s" }}
            >
              <input
                type="text"
                placeholder={t("BET_ID")}
                value={searchValue}
                ref={inputRef}
                onClick={() => {
                  selectedStore.setShowNumPad(true);
                  document.activeElement.blur();
                }}
                maxLength={19}
              />
              <i
                className="icon-clear"
                onClick={() => {
                  setShowSearch(false);
                  selectedStore.setShowNumPad(false);
                }}
              />
            </div>
          </div>
          <div className={"date-picker-m"}>
            <div className={`history-date-picker-container ${isShowCalendar ? "open" : ""}`}>
              <div className="date-picker">
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date(sevenDaysBefore)}
                  maxDate={new Date(today)}
                  showDisabledMonthNavigation
                  selectsRange
                  inline
                />
              </div>

              <div
                className={`history-sort-row ${isOpenedSort ? "open" : ""}`}
                onClick={() => setIsOpenedSort(!isOpenedSort)}
              >
                <div className={`history-sort-r-inner `}>
                  <SortList
                    sort={sort}
                    setSort={setSort}
                    setTake={setTake}
                    isOpenedSort={isOpenedSort}
                    setIsOpenedSort={setIsOpenedSort}
                    setIsShowCalendar={setIsShowCalendar}
                  />
                </div>
                <div className="h-sort-r">
                  {sort === null && (
                    <i className="icon-arrow_drop_down down" style={{ fontSize: "28px" }} />
                  )}
                  <span> {sort !== null ? t(sortModel[sort]) : t("SORT")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoadingBetHistory && !is.empty(betHistory) ? (
          <InfiniteScroll
            dataLength={betHistory?.length || 30}
            next={fetchMoreData}
            hasMore={betHistory?.length <= 1500}
            loader={
              <div className="result-filters-changes-row">
                <LoadingInline />
              </div>
            }
            scrollableTarget="scroll-container-last-result-mob"
          >
            {betHistory?.map((item,index) => (
              <div className="history-content" key={item?.id}>
                <div className="history-c-title">
                  <span>
                    {t("ROUND_ID")} {item?.id}
                  </span>
                  <span>
                    {t("TOTAL")} {toCurrency(item?.totalBetWinningAmount, userCurrency?.[0]?.name)}
                  </span>
                </div>
                {item?.bets?.map(historyItem => (
                  <div
                    className={`history-c-row status-${historyItem.result} ${
                      selectedItem?.id === historyItem?.id ? "active" : ""
                    }`}
                    key={historyItem?.id}
                  >
                    {/*add class status-(1-3) and active*/}
                    <span  onClick={() => {
                      selectedStoreMobile.selectBetHistory(historyItem);
                      selectedStore.setSelectedBetHistoryResult(item?.result);
                    }}>
                      {t("ID")} {historyItem.id}
                    </span>
                    <span>{toCurrency(historyItem.winningAmount, userCurrency?.[0]?.name)}</span>
                    <i className="circle"/>
                   {index ? null : <button onClick={()=>{
                      if (!showPopUp){
                        selectedStore.setDoBet(false)
                        selectedStore.setRebetPopUp(true)
                        setRepeatedBet(historyItem)
                      }else{
                        reBet(historyItem)
                      }
                    }}>
                      <i className="icon-reset rebet-icon"/>
                    </button>}
                  </div>
                ))}
              </div>
            ))}
          </InfiniteScroll>
        ) : null}
        {ReactDOM.createPortal(
          <NumpadForSearchGameResult
            showNumpad={showNumpad}
            inputRef={inputRef}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />,
          document.getElementById("root")
        )}

        {!isLoadingBetHistory && is.empty(betHistory) && betHistoryCount === 0 ? (
          <div className="history-c-title" style={{ display: "block", textAlign: "center" }}>
            {t("NO_MATCHING_RESULTS")}
          </div>
        ) : null}
      </div>
    </>
  );
});

export default BetHistoryMobile;
