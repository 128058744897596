import React, { useEffect, useState } from "react";
import { DOMAIN } from "../../newCore/constants/environment";
import selectedStore from "../../state/selectedState";
import * as brazilAnimals from "../ulits/brazilAnimals";

const LeftResultRowAnimalByNumber = ({ number, animals }) => {
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );

  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return () => {
      partnerSettings.unsubscribe();
    };
  }, []);

  const brazilianCircleImages = {};
  animals.data.forEach(el => {
    brazilianCircleImages[`a${el.sortNumber}`] = brazilAnimals[`a${el.sortNumber}`];
  });
  const animal = animals.data.filter(animal => animal?.numbers?.includes(number?.slice(2)));
  const imgUrl = animal?.[0]?.animalCirclePicUrl;
  const src =    // * TOFIX
    partnerSettings?.data?.viewMode === 0
      ? `${DOMAIN}/${imgUrl}`
      : `${brazilianCircleImages[`a${animal?.[0]?.sortNumber}`]}`;

  // const animal = animals.data.filter(animal => animal.numbers.includes(number?.slice(2)));
  // const imgUrl = animal?.[0]?.animalCirclePicUrl;
  return <img src={src} alt="animalImg" className="mini-animals-img" />;
};

export default LeftResultRowAnimalByNumber;
