import React, { useEffect } from 'react';
import LobbyContest from './lobbyContests';
import LobbyRightContent from './lobbyRightContent';
import gameService from '../../../newCore/services/Games/games';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../../newCore/constants/constants';

const LobbyWeb = ({urlSearchParams , t , viewMode , isAuth, currencyCode}) =>{
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);

 useEffect(()=>{
  if (currencyCode)
  gameService.getGameTypes(partnerId, currencyCode)
 },[currencyCode]);

 return (
  <div className="lobby-web">
   <div className="lobby-web-content">
    <LobbyContest urlSearchParams={urlSearchParams} t={t} viewMode={viewMode} currencyCode={currencyCode} isAuth={isAuth}/>
    <LobbyRightContent t={t}/>
   </div>
  </div>
 )
}

export default LobbyWeb
