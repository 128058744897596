import React, { useEffect, useState } from "react";
import NavigationItemMobile from "./navigationItem.Mobile";
import selectedStore from "../../state/selectedState";
import selectedStoreMobile from "../../state/selectedStateMobile";

const NavigationMobile = ({ path, urlSearchParams , demoMode}) => {
  const [hasUnseenBets, setHasUnseenBets] = useState(selectedStore.initialState.hasUnseenBets);
  const [hasUnseenBetsMobile, setHasUnseenBetsMobile] = useState(
    selectedStoreMobile.initialState.hasUnseenBetsMobile
  );
  const [newGameResultModal, setNewGameResultModal] = useState(
    selectedStore.initialState.newGameResultModal
  );
  const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);

  useEffect(() => {
    const unsenBetMobile = selectedStoreMobile.subscribe(data =>
      setHasUnseenBetsMobile(data.hasUnseenBetsMobile)
    );
    const unsenBets = selectedStore.subscribe(data => setHasUnseenBets(data.hasUnseenBets));
    const resultModal = selectedStore.subscribe(data =>
      setNewGameResultModal(data.newGameResultModal)
    );
    const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));

    selectedStore.init();
    return () => {
      unsenBets.unsubscribe();
      unsenBetMobile.unsubscribe();
      resultModal.unsubscribe();
      isAuth.unsubscribe();
    };
  }, []);

  return (
    <div className="navigation-row">
      <div className="navigation-holder">
        {/*<NavigationItemMobile*/}
        {/* icon="lobby"*/}
        {/* text="lobby"*/}
        {/* isActive={path === "lobby"}*/}
        {/* path={"lobby"}*/}
        {/* hasUnseenBetsMobile={hasUnseenBetsMobile}*/}
        {/* urlSearchParams={urlSearchParams}*/}
        {/*/>*/}
        <NavigationItemMobile
          icon="bet"
          text="BET"
          isActive={path === "bets"}
          path={"bets"}
          hasUnseenBetsMobile={hasUnseenBetsMobile}
          urlSearchParams={urlSearchParams}
        />
        <NavigationItemMobile
          icon="Result"
          text="RESULT"
          isActive={path === "lastResult"}
          path={"lastResult"}
          urlSearchParams={urlSearchParams}
        />
        <NavigationItemMobile
          icon="game"
          text="GAME"
          isActive={path === "game"}
          path={"game"}
          urlSearchParams={urlSearchParams}
        />
        <NavigationItemMobile
          icon="History"
          text="HISTORY"
          isActive={path === "betHistory"}
          path={"betHistory"}
          hasUnseenBets={hasUnseenBets}
          newGameResultModal={newGameResultModal}
          isAuth={isAuth}
          urlSearchParams={urlSearchParams}
          demoMode={demoMode}
        />
        <NavigationItemMobile
          icon="info"
          text="HELP"
          isActive={path === "help"}
          path={"help"}
          urlSearchParams={urlSearchParams}
        />
      </div>
    </div>
  );
};

export default NavigationMobile;
