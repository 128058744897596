import selectedStore from '../../state/selectedState';

export const handleClickNumber = (e,setBetAmount,betAmount,selectedGameType,checkClick) => {
 if (checkClick==='number'){
  selectedStore.setWritenNumberByUser(e.target.dataset.name)
 }else if(checkClick==='betAmount'){
  let bet='';
  if (e.target.dataset.name === '.' && !betAmount.includes('.') && betAmount.length) {
   bet = betAmount + e.target.dataset.name;
   let index = String(bet).indexOf('.')
   let betString = index===-1 ? String(bet).slice(0,4): String(bet).slice(0,index+3)
   setBetAmount(betString);
  } else if (e.target.dataset.name !== 'c' && e.target.dataset.name!=='.') {
   if (String(betAmount).length){
    if (betAmount!=='0'){
     bet = betAmount + ''+ e.target.dataset.name;
    }else{
     bet = e.target.dataset.name
    }
    let index = String(bet).indexOf('.')
    let betString = index===-1 ? String(bet).slice(0,4): String(bet).slice(0,index+3)
    setBetAmount(betString);
   }else if (e.target.dataset.name !== '.'){
    bet = bet+''+e.target.dataset.name;
    let index = String(bet).indexOf('.')
    let betString = index===-1 ? String(bet).slice(0,4): String(bet).slice(0,index+3)
    setBetAmount(betString);
   }
   if (+bet > selectedGameType?.maxBet) {
    setBetAmount(selectedGameType?.maxBet);
   }
  }
 }

};
export  const  removeSymbol = (setBetAmount,checkClick) => {
 if (checkClick==='number'){
  selectedStore.setWritenNumberByUser('')
 }else if (checkClick==='betAmount'){
  setBetAmount('')
 }
};
