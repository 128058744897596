import {parse} from "./betActionHelpers";

export const changeInputValue = (e, betAmount, setBetAmount, selectedGameType) => {
    const name = e.target.dataset.name;
    const max = selectedGameType.maxBet;
    const min = selectedGameType.minBet;
    if (name === "*2" && betAmount * 2 < max) {
            setBetAmount(Math.round10(betAmount  * 2,-2));
    }
    else if (name === "*2" && betAmount * 2 < min) {
        setBetAmount(min);
    } else if (name === "*2" && betAmount * 2 > min && betAmount * 2 <= max) {
        setBetAmount(Math.round10(betAmount * 2,-2));
    }else if (name === '*2' && betAmount * 2 > max){
        setBetAmount(max)
    }
    if (name === "min") {
        setBetAmount(min);
    } else{
        if (parse(betAmount + name) > max && name !== "*2") {
            setBetAmount(max);
        } else if (parse(betAmount + name) < min && name !== "*2") {
            setBetAmount(min);
        } else if (name !== "*2") {
            if(betAmount){
            setBetAmount(Math.round10(parse(betAmount + name),-2));
            }else{
                setBetAmount(Math.round10(parse(selectedGameType.minBet),-2));
            }
        }
    }
};
