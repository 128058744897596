import React, {memo, useEffect, useMemo, useState} from "react";
import ResultImg from "../../assets/images/game-result.png";
import useTranslation from "../../newCore/helpers/useTranslation";
import {resultRows} from "../../newCore/helpers/resultRows";
import ResultRowWeb from "../last-result/result-row.Web";
import UserBetMobile from "./userBetMobile";
import selectedStore from "../../state/selectedState";
import is from "is_js";

const NewResultMobile = memo(({result}) => {

    const [activeBalls, setActiveBalls] = useState(selectedStore.initialState.activeColors);

    const rows = resultRows(result);
    const t = useTranslation();

    const date = useMemo(()=>{
        return new Date(result?.endDate).constructor();
    },[result])

    useEffect(() => {
        const activeColors = selectedStore.subscribe(data => setActiveBalls(data.activeColors));
        selectedStore.setNewGameResultModal(false);
        selectedStore.init();
        return () => {
            activeColors.unsubscribe()
            setTimeout(() => {
                selectedStore.setNewGameResultModal(true)
                selectedStore.setHasUnseenBets(false)
            }, 2000)
        }
    }, []);

    return (
        <>
            <div className={`game-results-container`} id={"new-result"}>
                <div className="game-results-row">
                    <img className="game-results-img" src={ResultImg} alt=""/>
                    <p className="game-results-text">{t('NEW_RESULT')}</p>
                </div>
                <div className="game-results-row">
                    <div className="game-results-content">
                        <div className="last-result-container">
                            <div className="last-r-title-time">
                                <p className="res-b-title">{t('GAME_RESULT')}</p>
                                <p className="res-b-time">
                                    {!is.empty(result)
                                        ? new Date(date).toLocaleTimeString()
                                        : ""}
                                </p>
                            </div>

                            <div className="last-r-list-holder">
                                {rows.map((item, index) => (
                                    <ResultRowWeb index={index} item={item} key={index} activeBalls={activeBalls}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default NewResultMobile;
