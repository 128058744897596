import React from "react";
import {resultRows} from "../../newCore/helpers/resultRows";
import ResultRowWeb from "../last-result/result-row.Web";

const BetHistoryResult = ({ selectedLastResult, betHistoryResult, selectedBetHistory, isFromBetHistory, isFromLastResult }) => {
    const rows = resultRows(betHistoryResult ? betHistoryResult : selectedLastResult);


    return (
        <div className="last-result-container">
            <div className="last-r-title-time">
                {rows[1] && (
                  <p className="res-b-title">
                   {selectedLastResult
                    ? new Date(selectedLastResult.endDate).toLocaleDateString()
                    : new Date(betHistoryResult?.date || betHistoryResult?.endDate).toLocaleDateString()}
                  </p>
                )}
             {
              rows[1] &&
              <p
               className={`res-b-time ${
                selectedLastResult?.endDate  || selectedBetHistory?.date ? "" : ""
               }`}
              >
               {selectedLastResult
                ? new Date(selectedLastResult.endDate).toLocaleTimeString()
                : new Date(betHistoryResult?.date || betHistoryResult?.endDate).toLocaleTimeString()}
              </p>
             }
            </div>

            <div className="last-r-list-holder">
                {rows.map((item, index) => (
                    <ResultRowWeb
                        index={index}
                        item={item}
                        key={index}
                        selectedBetHistory={selectedBetHistory}
                        isFromBetHistory={isFromBetHistory}
                        isFromLastResult={isFromLastResult}
                    />
                ))}
            </div>
        </div>
    );
};

export default BetHistoryResult;
