import React, {memo, useRef} from 'react';
import SingleNumberWeb from '../../single-number.Web';
import { useDidUpdate } from '../../../../../newCore/helpers/helperFunctions';
import selectedStore from '../../../../../state/selectedState';



const TwoInputsFields = memo(({isCashDesk,handleChange , value , mobile , inputValueMobile}) =>{
 const textInput = useRef(false);

 useDidUpdate(()=>{
  if (value.input1){
   textInput.current.focus()
  }
 },[value])

  return <>
   <SingleNumberWeb value={value[`input1`]} inputValueMobile={inputValueMobile['input1']}>
    <input
     type="text"
     name={`input1`}
     className="four-chance-input"
     onChange={e => handleChange(e,)}
     value={mobile || isCashDesk ? inputValueMobile['input1'] : value[`input1`]}
     maxLength={1}
     autoFocus={mobile ? false :  true}
     autoComplete={'off'}
     onClick={()=>{
      if (isCashDesk){
       selectedStore.setShowAnimalNumpad(true)
       selectedStore.setSelectedInput('input1')
      }
      if (mobile){
       selectedStore.setCheckClick('number')
       document.activeElement.blur()
       selectedStore.setShowNumPad(true)
       selectedStore.setSelectedInput('input1')
      }
     }}
    />
   </SingleNumberWeb>
   <SingleNumberWeb value={value[`input2`]} inputValueMobile={inputValueMobile['input2']}>
    <input
     type="text"
     name={`input2`}
     className="four-chance-input"
     onChange={e => handleChange(e)}
     value={mobile || isCashDesk ? inputValueMobile['input2'] : value[`input2`]}
     maxLength={1}
     ref={textInput}
     autoComplete={'off'}
     onClick={()=>{
      if (isCashDesk){
       selectedStore.setShowAnimalNumpad(true)
       selectedStore.setSelectedInput('input2')
      }
      if (mobile){
       selectedStore.setCheckClick('number')
       document.activeElement.blur()
       selectedStore.setShowNumPad(true)
       selectedStore.setSelectedInput('input2')
      }
     }}
    />
   </SingleNumberWeb>
  </>
})
export default TwoInputsFields
