import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../../newCore/helpers/useTranslation';
import selectedStore from '../../state/selectedState';
import selectedStoreMobile from '../../state/selectedStateMobile';
import postMessageService from '../../newCore/services/PostMessages/PostMessagesService';

const NavigationItemMobile = ({
                               isActive,
                               icon,
                               text,
                               path,
                               hasUnseenBets,
                               hasUnseenBetsMobile,
                               newGameResultModal,
                               urlSearchParams,
                               isAuth,
                               demoMode
                              }) => {
 const t = useTranslation();

 return (
  <>
   {isAuth ? (
    <Link
     to={`/${path}?${urlSearchParams}`}
     className={`nav-a-m ${isActive ? 'active' : ''}`}
     style={{
      pointerEvents: path === 'game' ? 'auto' : '',
      cursor: 'pointer'
     }}
     onClick={() => {
      selectedStore.setShowBetTypeInfo(false);
      selectedStoreMobile.selectBetTypeHelpInfo({});
     }}
    >
     {icon === 'History' ? (
      <span
       className={`icon-${icon} ${
        hasUnseenBets && newGameResultModal && icon === 'History' ? 'active' : ''
       }`}
      />
     ) : (
      <span
       className={`icon-${icon} ${hasUnseenBetsMobile && icon === 'bet' ? 'active' : ''}`}
      />
     )}
     <i className="a-m-text ellipsis">{t(text)}</i>
    </Link>
   ) : icon === 'History' && !demoMode ? (
    <a
     onClick={() => postMessageService.openLoginPopup()}
     className={`nav-a-m ${isActive ? 'active' : ''}`}
     style={{
      pointerEvents: path === 'game' ? 'auto' : '',
      cursor: 'pointer'
     }}
    >
          <span
           className={`icon-${icon} ${
            hasUnseenBets && newGameResultModal && icon === 'History' ? 'active' : ''
           }`}
          />
     <i className="a-m-text ellipsis">{t(text)}</i>
    </a>
   ) : (
    <Link
     to={`/${path}?${urlSearchParams}`}
     className={`nav-a-m ${isActive ? 'active' : ''}`}
     style={{
      pointerEvents: path === 'game' ? 'auto' : '',
      cursor: 'pointer',
     }}
     onClick={() => {
      selectedStore.clearSelectedState();
      selectedStoreMobile.clearSelectState();
     }}
    >
     {icon === 'History' ? (
      <span
       className={`icon-${icon} ${
        hasUnseenBets && newGameResultModal && icon === 'History' ? 'active' : ''
       }`}
      />
     ) : (
      <span
       className={`icon-${icon} ${hasUnseenBetsMobile && icon === 'bet' ? 'active' : ''}`}
       style={{
        transform: icon === 'info' ? 'scale(1.5)' :''
       }}
      />
     )}
     <i className="a-m-text ellipsis">{t(text)}</i>
    </Link>
   )}
  </>
 );
};

export default NavigationItemMobile;
