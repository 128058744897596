import React, { memo } from "react";
import selectedStore from "../../../state/selectedState";

const SingleNumberWeb = memo(
  ({
    children,
    active,
    selectedNumber,
    setSelectedNumberForInput,
    backToNumberSelection,
    setBackToNumberSelection,
    setSelectedNumber,
    selected,
    green,
    isImg,
    inputValueMobile,
    value
  }) => {


    return (
      <div
        className={`single-ch-number ${active ? "active" : ""} ${
          selectedNumber === children ? "selected" : ""
        } ${selected ? "active" : ""} ${typeof children !== "string" ? "has-border" : ""} ${
          green ? "selected" : ""
        } ${value || inputValueMobile || inputValueMobile===0? "selected" : ""}`}
        // style={{ pointerEvents: selected ? "none" : "default" }}
        onClick={() => {
          if (setBackToNumberSelection && !selected) {
            if (backToNumberSelection) {
              setBackToNumberSelection(false);
            } else {
              setBackToNumberSelection(true);
            }
          }

          if (setSelectedNumberForInput && !selected) {
            setSelectedNumberForInput(children);
            setSelectedNumber(children);
            selectedStore.selectNumbers(children);
          }
          if (typeof children === "string" && !isImg && !selected) {
            setSelectedNumber(children);
          }
        }}
      >
        <span>{children}</span>
        <i className="single-ch-n-shadow" />
      </div>
    );
  }
);

export default SingleNumberWeb;
