import React, { useEffect, useState } from 'react';
import './App.scss';
import GameContainer from './container/GameContainer';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import useApp from './hooks/servicesHooks/App/appHook';
import MathHelper from './newCore/helpers/MathHelper';
import { TranslationContext } from './newCore/helpers/TranslationContext';
import languageService from './newCore/services/Language/language';
import selectedStore from './state/selectedState';
import Toaster from './components/toaster';
import Lobby from './components/lobby/lobby';
import { useSignalR } from './hooks/servicesHooks/SignalR/useSignalR';
import { queryGet } from './newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from './newCore/constants/constants';
import signalRSubscription from './newCore/services/SignalR/signalR';
import Snowfall from 'react-snowfall'
// import {isChrome , isIOS} from 'react-device-detect'


function App() {
 const gameVersion = '3.5.5'
 const [isLoaded, setIsLoaded] = useState(selectedStore.initialState.initIsLoaded);
 const gameId = queryGet(APP_CONSTANTS.GAME_ID);
 const urlSearchParams = new URLSearchParams(window.location.search).toString();
 const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );

 const [showPopup, setShowPopup] = useState(selectedStore.initialState.isShowConnectionLostPopup);
 const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);

 const [userSettings, setUserSettings] = useState(selectedStore.initialState.userSettings);
 const currencyCode = userSettings.currencyCode || partnerSettings?.data?.currencyCode;

 const [gameTypesIds , setGameTypesIds] = useState(selectedStore.initialState.gameTypesIds);
 const [connection , setConnection] = useState(selectedStore.initialState.connectionStart)

 const checkDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
 };


 const [isWeb, setIsWeb] = useState(!checkDevice());

 const { init } = useApp();

 // useEffect(()=>{
 //  if (isChrome && isIOS && sessionStorage.getItem('locationReload')!=='true'){
 //   sessionStorage.setItem('locationReload','true');
 //   window.location.reload()
 //  }
 // },[]);


 useEffect(() => {
  const partnerSettings = selectedStore.subscribe(data =>
   setPartnerSettings(data.partnerSettings)
  );
  const lostConnection = selectedStore.subscribe(data =>
      setShowPopup(data.isShowConnectionLostPopup)
  );
  const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));
  const gameTypes = selectedStore.subscribe(data=>setGameTypesIds(data.gameTypesIds));
 const connection = selectedStore.subscribe(data => setConnection(data.connectionStart));
  const userSettingsSubscription = selectedStore.subscribe(data => setUserSettings(data.userSettings));
  const url = urlSearchParams.split(`&gameTypeId=${gameId}`).join('');
  if (window.location.pathname.toString().replace('/', '').length > 0) {
   window.location.href = `${window.location.origin}/?${url}`;
  }
   return ()=>{
   connection.unsubscribe();
   lostConnection.unsubscribe();
    isAuth.unsubscribe();
    gameTypes.unsubscribe();
    userSettingsSubscription.unsubscribe();
     partnerSettings.unsubscribe();
   }
 }, []);

 window.appVersion = gameVersion;
 // console.log(colors, '???');
 const [translations, setTranslations] = useState({});
 const [zoom, setZoom] = useState();

 const browserHardReloadOnFirstStart = () => {
  const appVersionOnBrowser = localStorage.getItem('app-version');
  if (appVersionOnBrowser !== window.appVersion) {
   localStorage.setItem('app-version', window.appVersion);
   if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
     for (let name of names) caches.delete(name).then(console.log);
    });
   }

   window.location.reload();
  }
 };


 useSignalR();

 // useEffect(()=>{
 //  if (connection){
 //   if (Array.isArray(gameTypesIds) && gameTypesIds?.length){
 //    for (let i=0;i<gameTypesIds.length;i++){
 //     signalRSubscription.joinGroup(`GameTypeCreationGroup_${gameTypesIds[i]}`).then(data => data);
 //    }
 //   }
 //  }
 // },[connection,gameTypesIds])

 MathHelper();

 const resizeGame = () => {
  let maxHeight = 700;
  let maxWidth = 1196;
  let wWidth;
  let wHeight;
  wWidth = window.innerWidth;
  wHeight = window.innerHeight;
  // if (isWeb && wWidth < MOBILE_SIZE) {
  //  setIsWeb(false);
  // } else {
  //  setIsWeb(true);
  // }
  setIsWeb(!checkDevice())
  const widthToHeight = maxWidth / maxHeight;
  const wWidthToHeight = wWidth / wHeight;
  if (wWidthToHeight > widthToHeight) {
   wWidth = wHeight * widthToHeight;
   setZoom(wWidth / maxWidth);
  } else {
   wHeight = wWidth / widthToHeight;
   setZoom(wHeight / maxHeight);
  }
  // if (dir === "ltr") {
  //   el.style.zoom = `${zoom}`;
  // } else {
  //   el.style.zoom = `${zoom}`;
  // }
  // if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
  //   if (dir === 'ltr') {
  //     this.elementRef.nativeElement.style.transform = `translate(-50%, -50%) scale(${scale})`;
  //   } else {
  //     this.elementRef.nativeElement.style.transform = `translate(-50%, 50%) scale(${scale})`;
  //   }
  // } else {
  //   this.elementRef.nativeElement.style.zoom = scale;
  // }
 };

 useEffect(() => {
  // _orientationChange();
  if (isWeb) {
   window.addEventListener('resize', () => resizeGame());
   resizeGame();
  }

  // if (!isWeb && orientation === "landscape-primary" || orientation === "landscape-secondary" ) {
  //   orientation.lock(['landscape-primary', 'landscape-secondary']);
  // } else {
  //   orientation.unlock()
  // }
  // window.addEventListener("load", () => resizeGame());
 }, [isWeb]); // eslint-disable-line react-hooks/exhaustive-deps

 useEffect(() => {
  browserHardReloadOnFirstStart();
  //   const root = document.documentElement;
  //   root.style.setProperty(
  //     "$colors-list",
  //     `(
  //    "global-color-1": #111
  // )`
  //   );

  const loaded = selectedStore.subscribe(data => setIsLoaded(data.initIsLoaded));

  console.log(`%c v${gameVersion} ', 'font-size: 34px; color: #00d924`); // ? IMPORTANT
  const subscribeId = languageService.translation.subscribe(data => setTranslations(data?.data));



  init();
  return () => {
   subscribeId.unsubscribe();
   loaded.unsubscribe();
  };
 }, []); // eslint-disable-line react-hooks/exhaustive-deps




 return (
  <>
   <Toaster/>
   {/*<Snowfall style={{ zIndex: 100000000 }}/>*/}
   <BrowserRouter>
    <TranslationContext.Provider value={translations}>
     <Switch>
      <Route exact path="/" render={() => <Lobby path={'/'} zoom={zoom} urlSearchParams={urlSearchParams} isWeb={isWeb}
                                                 isLoaded={isLoaded} partnerSettings={partnerSettings}
                                                 userSettings={userSettings} currencyCode={currencyCode} isAuth={isAuth} showPopup={showPopup} gameTypesIds={gameTypesIds} connection={connection}/>}/>
      <Route exact path="/game" render={() => <GameContainer path="game" zoom={zoom} isWeb={isWeb} showPopup={showPopup}/>}/>
      <Route exact path="/betHistory" render={() => <GameContainer path="betHistory" isWeb={isWeb} zoom={zoom} showPopup={showPopup}/>}/>
      <Route exact path="/lastResult" render={() => <GameContainer path="lastResult" isWeb={isWeb} zoom={zoom} showPopup={showPopup}/>}/>
      <Route exact path="/help" render={() => <GameContainer path="help" zoom={zoom} isWeb={isWeb} showPopup={showPopup}/>}/>
      <Route exact path="/bets" render={() => <GameContainer path={'bets'} zoom={zoom} isWeb={isWeb} showPopup={showPopup}/>}/>
      <Redirect to={`/`}/>
     </Switch>
    </TranslationContext.Provider>
   </BrowserRouter>
  </>
 );
}

export default App;
