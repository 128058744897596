import React, { useEffect, useState } from "react";
import selectedStore from "../../state/selectedState";
import is from "is_js";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import AnimalByBet from './animalsByBet';

const LiveBetsList = ({
  liveBetItem,
  partnerBetTypes,
  currencies,
  currencyName,
  partnerCurrency,
  showWin,
}) => {
  const [results, setResults] = useState(selectedStore.initialState.liveBetsResults);
  const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
  useEffect(() => {
    const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
    const liveBets = selectedStore.subscribe(data => setResults(data.liveBetsResults));
    selectedStore.init();
    return () => {
      userCurrency.unsubscribe();
      liveBets.unsubscribe();
    };
  }, []);

  const findCorrectOdd = id => partnerBetTypes.find(betType => betType?.id === id)?.odds;

  const getOdds = odds => (odds?.length === 1 ? `${odds[0]}` : `${odds?.[0]}-${odds?.[3]}`);

  const calculateWinningAmount = () =>
    convertCurrencyToUSD(liveBetItem?.defaultRateStakeAmount) *
    getOdds(findCorrectOdd(liveBetItem?.betTypeId));

  const getCurrentBetData = id => results?.find(userResult => id === userResult?.id);

  const findRateByCurrencyCode =
    currencies?.find(c => c.name === currencyName)?.rate ||
    currencies?.find(c => c.name === partnerCurrency)?.rate;

  const convertCurrencyToUSD = amount => {
    return +amount / +findRateByCurrencyCode;
  };

  return (
    <>
      {!is.empty(liveBetItem) && (
        <div
          className={`st-content-info ${
            getCurrentBetData(liveBetItem?.id)?.result === 1 && showWin ? "other-win" : ""
          }`}
        >
          {/*add class selected*/}
          <p>
            {`${liveBetItem.userId}`?.[0]}***
            {`${liveBetItem.userId}`?.[`${liveBetItem.userId}`?.length - 1]}
          </p>

          <p>
            X
            {getOdds(findCorrectOdd(liveBetItem?.betTypeId))
              ? getOdds(findCorrectOdd(liveBetItem?.betTypeId))
              : null}
          </p>
          <p>{Math.round10(convertCurrencyToUSD(liveBetItem?.defaultRateStakeAmount), -2)}</p>
          <p>
            <span>{
              (getCurrentBetData(liveBetItem?.id)?.result === 1 && showWin)
                ? `${toCurrency(
                    Math.round10(calculateWinningAmount(), -2),
                    userCurrency?.[0]?.name || partnerCurrency
                  )}`
                : (getCurrentBetData(liveBetItem?.id)?.result === 2 && showWin)
                ? "0"
                : <AnimalByBet bet={liveBetItem}/>
            }</span>
          </p>
        </div>
      )}
    </>
  );
};

export default LiveBetsList;

// animalIds: "40"
// betTypeId: null
// betTypeName: null
// gameId: 334877
// id: 0
// number: null
// odds: [95]
// result: 0
// stakeAmount: 10
// userId: 167
