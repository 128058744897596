import React, { useMemo } from "react";

import Win from "../win/win";

const PlayerStatistticMobile = ({ bet, betResult , currency , demoMode}) => {

 const userBetWinning = useMemo(() => {
  return betResult?.filter(item => item?.id === bet?.id);
 }, [betResult]); // eslint-disable-line react-hooks/exhaustive-deps

 return (<>
  <div className="st-content-info">
   {/*add class selected*/}
   <p className={'ellipsis'}>{bet?.number}</p>
   <p>X{bet?.odds?.[0]}</p>
   <p>{demoMode ? Math.round10(bet?.stakeAmount,-2) : Math.round10(bet?.defaultRateStakeAmount,-2)}</p>
   <p className={`${userBetWinning?.[0]?.winningAmount ? "win" : ""}`}>
    {
     userBetWinning?.[0]?.winningAmount
         ?  <Win currency={currency} userBetWinning={userBetWinning} demoMode={demoMode}/>
         : userBetWinning?.[0]?.winningAmount===0? '0':"- - - - - -"
    }
   </p>
  </div>
  </>
 );
};

export default PlayerStatistticMobile;
