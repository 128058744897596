import React, { useEffect, useMemo, useState } from 'react';
import selectedStore from '../../state/selectedState';
import { DOMAIN } from '../../newCore/constants/environment';
import useTranslation from '../../newCore/helpers/useTranslation';
import * as brazilHelpImages from './BrazilianHelpImages';
import { ABOUT_GAME } from '../../newCore/constants/constants';
import AboutGameInfo from '../aboutGameInfo';
import BetTypeInfo from '../betTypeInfo';

const GameHelp = () => {
 const [selectedBetType, setSelectedBetType] = useState(
  selectedStore.initialState.selectedBetType
 );
 const [betTypeInfo, setBetTypeInfo] = useState(selectedStore.initialState.betTypesInfo);
 const [betTypes, setBetTypes] = useState(selectedStore.initialState.partnerBetTypes);
 const t = useTranslation();

 const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );

 useEffect(() => {
  const partnerSettings = selectedStore.subscribe(data =>
   setPartnerSettings(data.partnerSettings)
  );
  const betTypesSub = selectedStore.subscribe(data => setBetTypes(data.partnerBetTypes));
  const selectedBetType = selectedStore.subscribe(data =>
   setSelectedBetType(data.selectedBetType)
  );
  const betTypesInfo = selectedStore.subscribe(data => setBetTypeInfo(data.betTypesInfo));
  selectedStore.init();
  return () => {
   betTypesSub.unsubscribe();
   partnerSettings.unsubscribe();
   // selectedStore.setDefaultSelectedBetType();
   selectedBetType.unsubscribe();
   betTypesInfo.unsubscribe();
  };
 }, []);


 const helpBetType = useMemo(() => {
   return selectedBetType.type === ABOUT_GAME ? selectedBetType : betTypeInfo.find(item => item?.type === selectedBetType?.type);
  },
  [selectedBetType, betTypeInfo]
 );


 const src = partnerSettings?.data?.viewMode === 0 ? `${DOMAIN}/${helpBetType?.imageUrl}` : brazilHelpImages[helpBetType?.betTypeName];
 return (<>
   {helpBetType?.type === ABOUT_GAME ?
    <div className="game-help-new-section">
     <AboutGameInfo aboutGame={helpBetType} t={t} betTypes={betTypes}/>
    </div> :
    <BetTypeInfo t={t} helpBetType={helpBetType} src={src}/>}
  </>
 );
};

export default GameHelp;
