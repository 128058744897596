import React from "react";
import BetType from "../bet-type";
import useTranslation from '../../newCore/helpers/useTranslation';
const BetTypes = ({ data }) => {
 const t = useTranslation()
  return (
    <div className="bet-type-holder">
      {data.map(betType => (
        <BetType betType={betType} key={betType.id} t={t}/>
      ))}
    </div>
  );
};

export default BetTypes;
