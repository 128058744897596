import React from "react";

const BetHistorySkeleton = () => {
  return (
    <div className="bet-history-skeleton-cnt">
      <div className="bet-h-skeleton-win-lose sk"/>
      <div className="result-skeleton-txt sk"/>
      <div className="bet-h-skeleton-r sk"/>
      <div className="result-skeleton-txt sk"/>
      <div className="bet-h-skeleton-money sk"/>
      <div className="result-skeleton-singles-cnt">
        <div className="result-skeleton-s-time">
          <div className="result-s-s-t-time sk"/>
          <div className="result-s-s-t-time sk"/>
        </div>
        <div className="result-skeleton-s-r">
          <div className="result-skeleton-num sk"/>
          <div className="result-skeleton-s-row sk"/>
        </div>
        <div className="result-skeleton-s-r">
          <div className="result-skeleton-num sk"/>
          <div className="result-skeleton-s-row sk"/>
        </div>
        <div className="result-skeleton-s-r">
          <div className="result-skeleton-num sk"/>
          <div className="result-skeleton-s-row sk"/>
        </div>
        <div className="result-skeleton-s-r">
          <div className="result-skeleton-num sk"/>
          <div className="result-skeleton-s-row sk"/>
        </div>
        <div className="result-skeleton-s-r">
          <div className="result-skeleton-num sk"/>
          <div className="result-skeleton-s-row sk"/>
        </div>
      </div>
    </div>
  );
};

export default BetHistorySkeleton;
