import {useState, useEffect} from 'react';
import partnerService from '../../../newCore/services/Partner/partner';
import {queryGet} from '../../../newCore/helpers/helperFunctions';
import {APP_CONSTANTS} from '../../../newCore/constants/constants';

const usePartner = () => {
    const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);


    const [partnerSettings, setPartnerSettings] = useState(partnerService.partnerSettings.getValue());
    const [partnerHelpItems, setPartnerHelpItems] = useState(
        partnerService.partnerHelpItems.getValue()
    );
    const [animals, setPartnerAnimals] = useState(partnerService.animals.getValue());
    const [partnerBetTypes, setPartnerBetTypes] = useState(partnerService.partnerBetTypes.getValue());
    const [customizationConfig, setCustomizationConfig] = useState(
        partnerService.customizationConfig.getValue()
    );
    const [selectedBetType, setSelectedBetType] = useState(null);

    useEffect(() => {
        const partnerSettingsSubscription = partnerService.partnerSettings.subscribe(
            setPartnerSettings
        );
        const helpItemsSubscription = partnerService.partnerHelpItems.subscribe(setPartnerHelpItems);
        const animalsSubscription = partnerService.animals.subscribe(setPartnerAnimals);
        const partnerBetTypesSubscription = partnerService.partnerBetTypes.subscribe(
            setPartnerBetTypes
        );
        const selectedBetTypesSubscription = partnerService.partnerBetTypes.subscribe(betType => {
            return betType.data.length ? setSelectedBetType(betType.data[0]) : null;
        });
        const customizationConfigSubscription = partnerService.customizationConfig.subscribe(
            setCustomizationConfig
        );

        return () => {
            helpItemsSubscription.unsubscribe();
            partnerSettingsSubscription.unsubscribe();
            animalsSubscription.unsubscribe();
            partnerBetTypesSubscription.unsubscribe();
            selectedBetTypesSubscription.unsubscribe();
            customizationConfigSubscription.unsubscribe();
        };
    }, []);

    return {
        partnerSettings,
        getPartnerSettings: () => partnerService.getPartnerSettings(partnerId),
        partnerHelpItems,
        getPartnerHelpItems: () => partnerService.getPartnerHelpItems(partnerId),
        animals,
        getAnimals: () => partnerService.getAnimals(partnerId),
        partnerBetTypes,
        getPartnerBetTypes: () => partnerService.getPartnerBetTypes(partnerId),
        selectedBetType,
        setSelectedBetType,
        customizationConfig,
        getCustomizationConfig: () => partnerService.getCustomizationConfig(partnerId)
    };
};

export default usePartner;
