import React from "react";

export const pointNumber = (bettingNumber, numbers, animal) => {
  if (bettingNumber?.animalIds?.split(",").length === 1) {
    if (bettingNumber?.number?.split(",").length === 4) {
      return (
        <div className={`g-item-numbers`}>
          {numbers.split(",").map((number, index) => (
            <span
              key={index}
              className={`${
                number === bettingNumber?.number.split(",")[index] ? "g-item-numbers-active" : ""
              }`}
            >
              {number}
            </span>
          ))}
        </div>
      );
    }
    if (
      bettingNumber?.number?.split(",").length === 1 &&
      bettingNumber?.number?.length !== 3 &&
      bettingNumber?.number?.length !== 4
    ) {
      return (
        <div className={`g-item-numbers`}>
          {numbers.split(",").map((number, i) => (
            <span
              key={i}
              className={`${number === bettingNumber?.number ? "g-item-numbers-active" : ""}`}
            >
              {number}
            </span>
          ))}
        </div>
      );
    }

    if (bettingNumber?.number.length === 3) {
      return (
        <div className={`g-item-numbers`}>
          {animal.id === +bettingNumber?.animalIds ? (
            <span className={"g-item-numbers-active"}>{`${
              bettingNumber?.number[0]
            } ${bettingNumber?.number.slice(1, 3)}`}</span>
          ) : (
            numbers.split(",").map(item => <span>{item}</span>)
          )}
        </div>
      );
    }
    if (bettingNumber?.number.length === 4) {
      return (
        <div className={`g-item-numbers`}>
          {animal.id === +bettingNumber?.animalIds ? (
            <span className={"g-item-numbers-active"}>{`${bettingNumber?.number.slice(
              0,
              2
            )} ${bettingNumber?.number.slice(2, 4)}`}</span>
          ) : (
            numbers.split(",").map(item => <span>{item}</span>)
          )}
        </div>
      );
    }
  } else if (bettingNumber?.animalIds?.split(",").length > 1) {
    return (
      <div className="g-item-numbers">
        {numbers.split(",").map((number, i) => (
          <span
            key={i}
            className={`${
              bettingNumber?.number.indexOf(number) === -1 ? "" : "g-item-numbers-active"
            }`}
          >
            {number}
          </span>
        ))}
      </div>
    );
  } else {
    return (
      <div className={`g-item-numbers`}>
        {numbers.split(",").map((number, i) => (
          <span key={i}>{number}</span>
        ))}
      </div>
    );
  }
};
