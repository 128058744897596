import React, { useEffect, useState } from "react";
import usePartner from "../../hooks/servicesHooks/Partner/partnerHook";
import { DOMAIN } from "../../newCore/constants/environment";
import * as brazilAnimals from "../../components/ulits/brazilAnimals";
import selectedStore from "../../state/selectedState";

const AnimalByNumber = ({
  number,
  fillBalls = false,
  activeBalls,
  isFromLastResult,
  winRowsIndexes
}) => {
  const { animals } = usePartner();
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return () => {
      partnerSettings.unsubscribe();
    };
  }, []);

  const brazilianCircleImages = {};
  animals.data.forEach(el => {
    brazilianCircleImages[`a${el.sortNumber}`] = brazilAnimals[`a${el.sortNumber}`];
  });
  const animal = animals.data.filter(animal => animal?.numbers?.includes(number?.slice(2)));
  const imgUrl = animal?.[0]?.animalCirclePicUrl;
  const src = // * TOFIX
    partnerSettings?.data?.viewMode === 0
      ? `${DOMAIN}/${imgUrl}`
      : `${brazilianCircleImages[`a${animal?.[0]?.sortNumber}`]}`;

  // const imgUrl = animal?.[0]?.animalPicUrl;
  useEffect(() => {
    selectedStore.setBrazilImages(brazilianCircleImages);
  }, [brazilianCircleImages]);

  return (
    <div className={`ball ${isFromLastResult || activeBalls || fillBalls ? "active" : ""} `}>
      {number && <img src={src} alt="animalName" />}
    </div>
  );
};

export default AnimalByNumber;

