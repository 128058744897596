import React from 'react';
import animal from '../../assets/images/Animals/16.png';
import * as brazilAnimals from '../ulits/brazilAnimals';
import { DOMAIN } from '../../newCore/constants/environment';

const AnimalByNumber = ({number , animals , partnerSettings})=>{

 const brazilianCircleImages = {};
 animals.data.forEach(el => {
  brazilianCircleImages[`a${el.sortNumber}`] = brazilAnimals[`a${el.sortNumber}`];
 });
 const animal = animals.data.filter(animal => animal?.numbers?.includes(number?.slice(2)));
 const imgUrl = animal?.[0]?.animalCirclePicUrl;
 const src =    // * TOFIX
  partnerSettings?.data?.viewMode === 0
   ? `${DOMAIN}/${imgUrl}`
   : `${brazilianCircleImages[`a${animal?.[0]?.sortNumber}`]}`;

 return (
  <img src={src} alt="" className="mini-animals-img" />
 )
};

export default AnimalByNumber