import { queryGet } from "../../helpers/helperFunctions";
import { APP_CONSTANTS } from "../../constants/constants";

const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
const gameId = 1181;
// const gameId = queryGet(APP_CONSTANTS.EXTERNAL_GAME_ID);
const exitURL = queryGet(APP_CONSTANTS.EXIT_URL);


class PostMessageService {
  // constructor() {
  //   this.partnerDomainWithProtocol = `${window.location.protocol}//${window.location.host.replace(
  //     /^([a-zA-Z]+)\./,
  //     ""
  //   )}`;
  //   this.encodedRedirectUrl = encodeURIComponent(
  //     `${window.location.protocol}//games.${window.location.host.replace(
  //       /^([a-zA-Z]+)\./,
  //       ""
  //     )}/authorization.php?partnerId=${partnerId}&gameId=${gameId}&openType=real&isMobile=true&language={language}&token={token}&exitURL=${
  //       this.partnerDomainWithProtocol
  //     }`
  //   );
  // }
   partnerDomainCreator() {
    if (exitURL) {
      return decodeURIComponent(exitURL).split('id=')[1].replace(/^([a-zA-Z]+)\./, '').split('/')[0];
    } else {
      return window.location.host.replace(/^([a-zA-Z]+)\./, '');
    }
  }

  openLoginPopup(
   obj = {
     action: 'togglePlayMode',
     gameId: `${gameId}`
   },
    target = "*"
  ) {
    if (!this.isInIframe()) {
      this.loginInBet();
    } else {
      window.top.postMessage(obj, target);
    }
  }
  // redirectPartnerPage(type = null) {
  //   // if (exitURL) {
  //   //     const redirectUrl = encodeURIComponent(`${window.location.protocol}//games.${this.partnerDomainCreator()}/authorization.php?partnerId=${partnerId}&gameId=${gameId}&openType=real&isMobile=true&language={language}&token={token}&exitURL=${exitURL}`);
  //   //     const splitResult = exitURL.split('id=');
  //   //     const partnerHomePageRedirectUrl = encodeURIComponent(`${decodeURIComponent(splitResult[1])}#?sign-in&action=login&redirectUrl=${redirectUrl}`);
  //   //     window.location.href = `${splitResult[0]}id=${partnerHomePageRedirectUrl}`;
  //   // }
  // }
  loginInBet() {
    // const partnerDomain = window.location.host.replace(/^([a-zA-Z]+)\./, '');
    // const partnerDomainWithProtocol = `${window.location.protocol}//${partnerDomain}`;
    // const encodedRedirectUrl = encodeURIComponent(
    //   `${window.location.protocol}//games.${partnerDomain}/authorization.php?partnerId=${partnerId}&gameId=${gameId}&openType=real&isMobile=true&language={language}&token={token}&exitURL=${partnerDomainWithProtocol}`
    // );
    //
    // if (exitURL) {
    //   window.location.href = `${exitURL}#?sign-in&action=login&redirectUrl=${encodedRedirectUrl}`;
    // } else {
    //   window.location.href = `${partnerDomainWithProtocol}#?sign-in&action=login&redirectUrl=${encodedRedirectUrl}`;
    // }
    if (exitURL) {
      const redirectUrl = encodeURIComponent(`${window.location.protocol}//games.${this.partnerDomainCreator()}/authorization.php?partnerId=${partnerId}&gameId=${gameId}&openType=real&isMobile=true&language={language}&token={token}&exitURL=${exitURL}`);
      const splitResult = exitURL.split('id=');
      const partnerHomePageRedirectUrl = encodeURIComponent(`${decodeURIComponent(splitResult[1])}#?sign-in&action=login&redirectUrl=${redirectUrl}`);
      window.location.href = `${splitResult[0]}id=${partnerHomePageRedirectUrl}`;
    }
  }



  depositInBet() {
    window.location.href = `${window.location.protocol}//${window.location.host.replace(
      /^([a-zA-Z]+)\./,
      ""
    )}#?action=deposit`;
  }

  openDeposit(
    obj = {
      action: "openSlider",
      tab: "deposit"
    },
    target = "*"
  ) {
    if (!this.isInIframe()) {
      this.depositInBet();
    } else {
      window.top.postMessage(obj, target);
    }
  }

  listen(action, callback) {
    this.eventHandler = event => {
      const message = event.data.action;
      // if (!message) return;
      //
      // if (action) {
      //   action === message && callback(event);
      // } else {
      //   throw new Error(`There is no such an event ${action}`);
      // }
    };

    window.addEventListener("message", this.eventHandler, false);
    // window.attachEvent("onmessage", this.eventHandler,);
    return this.eventHandler;
  }

  remove(handler) {
    window.removeEventListener("message", handler);
  }

  isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  //mobile
  goToHome() {
    // window.location.href = `${window.location.protocol}//${window.location.host.replace(
    //   /^([a-zA-Z]+)\./,
    //   ""
    // )}`;
    window.location.href = exitURL;
  }
}

const postMessageService = new PostMessageService(window.top);
export default postMessageService;
