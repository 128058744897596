import React, { useEffect, useMemo, useRef, useState } from 'react';
import selectedStore from '../../state/selectedState';
import {
 betHelpers,
 toCurrency
} from '../../newCore/helpers/betActionHelpers';
import CurrencyInput from '../bet-input/input';
import Spinner from '../Loading/bet-button-spinner';
import Toaster from '../toaster';
import useUser from '../../hooks/servicesHooks/User/userHook';
import useTranslation from '../../newCore/helpers/useTranslation';
import selectedStoreMobile from '../../state/selectedStateMobile';
import tickState from '../../state/tickState';
import { useOutsideClick } from '../../newCore/helpers/useClickOutside';
import { changeInputValue } from '../../newCore/helpers/changeBetAmount';
import NumPad from '../numPad/numPad';
import TerminalNumpad from '../numPad/TerminalNumpad';
import { queryGet } from '../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../newCore/constants/constants';
import demoState from '../../state/demoModeState';
import { addBet } from '../../newCore/helpers/addBet';
import is from 'is_js';
import betHistoryService from '../../newCore/services/Bets/bet';
import { useAmountValidation } from '../../newCore/helpers/useAmountValidation';
import { parseNumberByPrecision } from '../../newCore/helpers/utils';


const BetAction = ({ mobile, demoMode, betAmount, setBetAmount, selectedGameType, limits }) => {
 const [selectedGame, setSelectedGame] = useState(selectedStore.initialState.selectedGame);
 const [tick, setTick] = useState(tickState.initialState.tick);
 const [showNumPad, setShowNumPad] = useState(selectedStore.initialState.showNumPad);
 const [betIdForDemoBet, setBetIdForDemoBet] = useState(demoState.initialState.betId);
 const [demoBalance, setDemoBalance] = useState(demoState.initialState.balance);
 const [lastBet, setLastBet] = useState(selectedStore.initialState.lastBet);
 const [showPopUp, setShowPopUp] = useState(selectedStore.initialState.dontShowPopUp);

 const betAmountRef = useRef();

 const [showSpinner, setShowSpinner] = useState(false);
 const [chooseNumber, setChooseNumber] = useState(
  selectedStore.initialState.chooseNumberAnimation
 );

 const [chooseAnimal, setChooseAnimal] = useState(
  selectedStore.initialState.chooseAnimalAnimation
 );
 const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );
 const [selectedBetType, setSelectedBetType] = useState(
  selectedStore.initialState.selectedBetType
 );
 const [checkClick, setCheckClick] = useState(selectedStore.initialState.checkClick);
 const [selectedAnimal, setSelectedAnimal] = useState(selectedStore.initialState.selectedAnimal);
 const [selectedNumber, setSelectedNumber] = useState(selectedStore.initialState.selectedNumbers);
 const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
 const [userFavoriteBet, setUserFavoriteBet] = useState(selectedStore.state.userFavoriteBets);
 const [betIsAdded, setBetIsAdded] = useState(selectedStore.initialState.betIsAdded);
 const [error, setError] = useState(selectedStore.initialState.betError);
 const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);
 const [writeNumber, setWriteNumber] = useState(selectedStore.initialState.writeNumberAnimation);
 const [showTerminalNumpad, setShowTerminalNumpad] = useState(selectedStore.showTerminalNumpad);
 const [doBet , setDoBet] = useState(selectedStore.initialState.doBet);
 const [lastBetInDemo , setLastBetInDemo] = useState(demoState.initialState.lastBet);
 const [limitsOverTakenMessage, setLimitsOverTakenMessage] = useState(selectedStore.initialState.betLimit);
 const t = useTranslation();
 const { userBalance } = useUser();
 const cashDesk = queryGet(APP_CONSTANTS.IS_CASH_DESK) === 'true';

 const { onChange:changeAmount } = useAmountValidation(setBetAmount, limits);


 useEffect(() => {
  const lastBetInDemo = demoState.subscribe(data => setLastBetInDemo(data.lastBet));
  const limitsMessage = selectedStore.subscribe(data => setLimitsOverTakenMessage(data.betLimit));
  const doBet = selectedStore.subscribe(data => setDoBet(data.doBet));

  const demoBalanceSub = demoState.subscribe(data => setDemoBalance(data.balance));
  const betId = demoState.subscribe(data => setBetIdForDemoBet(data.betId));
  const terminalNumpad = selectedStore.subscribe(data => setShowTerminalNumpad(data.showTerminalNumpad));
  const checkClickSub = selectedStore.subscribe(data => setCheckClick(data.checkClick));
  const betSubscription = selectedStore.subscribe(data =>
   setUserFavoriteBet(data.userFavoriteBets)
  );
  const showPopup = selectedStore.subscribe(data => setShowPopUp(data.dontShowPopUp));
  const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));
  const tickSub = tickState.subscribe(data => setTick(data.tick));
  const betisAdded = selectedStore.subscribe(data => setBetIsAdded(data.betIsAdded));
  const selectedBetTypeSubscription = selectedStore.subscribe(data =>
   setSelectedBetType(data.selectedBetType)
  );
  const selectedAnimal = selectedStore.subscribe(data => setSelectedAnimal(data.selectedAnimal));
  const selectedGame = selectedStore.subscribe(data => setSelectedGame(data.selectedGame));
  const selectedNumber = selectedStore.subscribe(data => setSelectedNumber(data.selectedNumbers));
  const chooseNumber = selectedStore.subscribe(data =>
   setChooseNumber(data.chooseNumberAnimation)
  );
  const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
  const writeNumber = selectedStore.subscribe(data => setWriteNumber(data.writeNumberAnimation));
  const chooseAnimal = selectedStore.subscribe(data =>
   setChooseAnimal(data.chooseAnimalAnimation)
  );
  const error = selectedStore.subscribe(data => setError(data.betError));
  const partnerSettings = selectedStore.subscribe(data =>
   setPartnerSettings(data.partnerSettings)
  );
  const showNumPad = selectedStore.subscribe(data => setShowNumPad(data.showNumPad));
  const lastBet = selectedStore.subscribe(data => setLastBet(data.lastBet));
  selectedStore.init();
  tickState.init();
  demoState.init();
  return () => {
   limitsMessage.unsubscribe();
   lastBetInDemo.unsubscribe();
   selectedStore.setDoBet(false);
   doBet.unsubscribe();
   showPopup.unsubscribe();
   lastBet.unsubscribe();
   demoBalanceSub.unsubscribe();
   betId.unsubscribe();
   checkClickSub.unsubscribe();
   terminalNumpad.unsubscribe();
   isAuth.unsubscribe();
   showNumPad.unsubscribe();
   tickSub.unsubscribe();
   betSubscription.unsubscribe();
   betisAdded.unsubscribe();
   selectedBetTypeSubscription.unsubscribe();
   selectedAnimal.unsubscribe();
   selectedGame.unsubscribe();
   selectedNumber.unsubscribe();
   chooseNumber.unsubscribe();
   userCurrency.unsubscribe();
   writeNumber.unsubscribe();
   chooseNumber.unsubscribe();
   error.unsubscribe();
   chooseAnimal.unsubscribe();
   partnerSettings.unsubscribe();
  };
 }, [selectedBetType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
   // setBetAmount(
   //  userFavoriteBet?.[userFavoriteBet.length - 1]?.stakeAmount
   //    ? userFavoriteBet?.[userFavoriteBet.length - 1]?.stakeAmount
   //    : selectedGameType?.minBet
   // );
   if (userFavoriteBet?.[userFavoriteBet.length - 1]?.stakeAmount){
    setBetAmount(userFavoriteBet?.[userFavoriteBet.length - 1]?.stakeAmount)
   }
  },[userFavoriteBet])


 useEffect(() => {
  if (betIsAdded) {
   Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
   selectedStore.setBetIsAdded(false);
   selectedStoreMobile.setHasUnseenBetsMobile(true);
  }
  if (error) {
   Toaster.warning(t(error));
   selectedStore.setBetError('');
   // setBetAmount(selectedGameType?.minBet);

  }

 }, [betIsAdded, error, userBalance]); // eslint-disable-line react-hooks/exhaustive-deps
 // useEffect(() => {
 //  selectedStore.selectedBetAmount(betAmount);
 // }, [betAmount]);


 const spinnerShower = () => {
  setShowSpinner(true);
  setTimeout(() => {
   setShowSpinner(false);
  }, 500);
 };
 const bettingNumbers = useMemo(() => {
  const filterBetByBetType = userFavoriteBet?.filter(item => item?.betTypeId === selectedBetType?.id);
  return filterBetByBetType;
 }, [userFavoriteBet, selectedBetType]); // eslint-disable-line react-hooks/exhaustive-deps

 const handleClickAddBet = () => {
  addBet(selectedBetType,
   selectedAnimal,
   selectedNumber,
   selectedGame,
   betAmount,
   demoMode,
   userBalance,
   isAuth,
   demoBalance,
   setBetAmount,
   selectedGameType,
   betIdForDemoBet,
   bettingNumbers,
   showSpinner,
   userFavoriteBet,
   t,
  );
 };

 useOutsideClick(betAmountRef, event => {
  if (showTerminalNumpad){
   selectedStore.setShowTerminalNumpad(false)
  }
  if (showNumPad) {
   selectedStore.setShowNumPad(false);
  }
 });






 const reBet = (bet)=>{
  const selectedBetType = {
   id : bet.betTypeId,
   type : bet.betType,
   name:bet.betTypeName,
   odds: bet.odds,
  };
  if (demoMode){
    if (+demoBalance<+lastBetInDemo.stakeAmount){
     Toaster.error(t('LOW_BALANCE'));
    }else{
     let lastBet = {
      gameId: selectedGame.id,
     betTypeId: lastBetInDemo.betTypeId,
      betTypeName:lastBetInDemo.betTypeName,
      number: lastBetInDemo.number,
      stakeAmount: lastBetInDemo.stakeAmount,
      odds: lastBetInDemo.odds,
      betType : lastBetInDemo.betType,
      id:betIdForDemoBet,
      animalIds:lastBetInDemo.animalIds
     };
     demoState.setBet(lastBet);
     demoState.setBetId()
     Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
    }
  }else {
   if (userBalance<bet.stakeAmount){
    Toaster.error(t('LOW_BALANCE'));
   }else{
    betHistoryService.bet({selectedGame,selectedBetType},bet.stakeAmount,bet.number,bet.animalIds)
   }
  }
 };

 const handleAddOrMinusBetAmount = (action) => {
  let changedBetAmount;
  if (action === '-') {
   changedBetAmount = +betAmount - limits.minBet;
   changedBetAmount = changedBetAmount > limits.minBet ? changedBetAmount : limits.minBet;
  } else {
   changedBetAmount = +betAmount + limits.minBet;
   changedBetAmount = changedBetAmount < limits.maxBet ? changedBetAmount : limits.maxBet;
  }
  setBetAmount(parseNumberByPrecision(1, changedBetAmount));
 };
 useEffect(()=>{
  if (doBet){
   reBet(lastBet)
  }
 },[doBet,mobile]);


 return (
  <div className="game-action-row">
   <div className="action-b-container">
    <div className="action-b-grid">
     <div className="left-col-action">
      {chooseAnimal ? (
       <div className="bet-action-info">
        <span className="icon-animal-_notification_icon"/>
        <p>
         {t('SELECT')} {selectedBetType?.animalsCount} {t('ANIMAL')}
        </p>
       </div>
      ) : chooseNumber ? (
       <div className="bet-action-info">
        <span className="icon-animal-_notification_icon"/>
        <p>{t('CHOOSE_NUMBER')}</p>
       </div>
      ) : writeNumber ? (
       <div className="bet-action-info">
        <span className="icon-animal-_notification_icon"/>
        <p>{t('WRITE_NUMBER')}</p>
       </div>
      ) : limitsOverTakenMessage ? (
       <div className="bet-action-info">
        <span className="icon-animal-_notification_icon"/>
        <p>{`${t(limitsOverTakenMessage.message)} ${limitsOverTakenMessage.type ? selectedGameType.maxBet : selectedGameType.minBet} ${userCurrency?.[0]?.name || partnerSettings?.data?.currencyCode}`}</p>
       </div>
      ) : (
       <div className="action-mini-grid">
        <div
         className={`action-btn size-b ${betAmount > selectedGameType?.minBet ? '' : 'disabled'}`}
         data-name={`-${selectedGameType?.minBet}`}
         // onClick={e => changeInputValue(e, betAmount, setBetAmount, selectedGameType)}
         onClick={() => handleAddOrMinusBetAmount('-')}
        >
         -
        </div>
        <div className="price-input-box"
             onClick={() => {
              if (cashDesk) {
               selectedStore.setShowTerminalNumpad(true);
              }
              if (mobile) {
               selectedStore.setShowNumPad(true);
               document.activeElement.blur();
               selectedStore.setCheckClick('betAmount');
              }
             }}
        >
         <input
          className="bet-prise-inp"
          type="text"
          value={betAmount}
          maxLength={9}
          onChange={changeAmount}
          userCurrencyName={
           userCurrency?.[0]?.name || partnerSettings?.data?.currencyCode
          }
          demoMode={demoMode}
          onPaste={e => {
           e.preventDefault();
           return false;
          }}
         />
        </div>
        <div
         className={`action-btn size-b ${betAmount < selectedGameType?.maxBet ? '' : 'disabled'}`}
         data-name={`+${selectedGameType?.minBet}`}
         onClick={() => handleAddOrMinusBetAmount('+')}
        >
         +
        </div>
        <div
         className={`action-btn ${betAmount > selectedGameType?.minBet ? '' : 'disabled'}`}
         data-name={'min'}
         onClick={()=>setBetAmount(selectedGameType.minBet)}
        >
         {t('MIN')}
        </div>
        <div
         className={`action-btn ${betAmount > selectedGameType?.minBet ? '' : 'disabled'}`}
         onClick={e => changeInputValue(e, betAmount, setBetAmount, selectedGameType)}
         data-name={`/2`}
        >
         1/2
        </div>
        <div
         className={`action-btn ${betAmount < selectedGameType?.maxBet ? '' : 'disabled'}`}
         onClick={e => changeInputValue(e, betAmount, setBetAmount, selectedGameType)}
         data-name={`*2`}
        >
         X2
        </div>

        <div className={`action-btn rebet ${is.empty(lastBet) && is.empty(lastBetInDemo) ? 'disabled' : ''}`} onClick={() => {
         if (!showPopUp) {
          selectedStore.setDoBet(false);
          selectedStore.setRebetPopUp(true);
         }else{
          reBet(lastBet)
         }
        }}>
         <i className="icon-reset rebet-icon"/>
        </div>
       </div>
      )}
     </div>
     <div className="bet-b-holder">
      <button
       className={`bet-btn green ${tick ? 'disabled' : ''}`}
       onClick={() => {
        spinnerShower();
        handleClickAddBet();
       }}
      >
       {showSpinner ? <Spinner/> : <span>{t('BET')}</span>}
      </button>
     </div>
    </div>
    <NumPad t={t} selectedGameType={selectedGameType}
            currencyCode={userCurrency?.[0]?.name}
            partnerSettings={partnerSettings}
            betAmount={betAmount}
            demoMode={demoMode}
            setBetAmount={setBetAmount} tick={tick}
            handleClickAddBet={handleClickAddBet}
            spinnerShower={spinnerShower}
            showSpinner={showSpinner}
            showNumPad={showNumPad}
            betAmountRef={betAmountRef}
            checkClick={checkClick}

    />
    <TerminalNumpad isCashDesk={cashDesk} showTerminalNumpad={showTerminalNumpad} t={t}
                    selectedGameType={selectedGameType}
                    demoMode={demoMode}
                    currencyCode={userCurrency?.[0]?.name}
                    partnerSettings={partnerSettings}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount} tick={tick}
                    handleClickAddBet={handleClickAddBet}
                    showSpinner={showSpinner}
                    spinnerShower={spinnerShower}
                    showNumPad={showNumPad}
                    checkClick={'betAmount'}
                    mobile={mobile}
    />
   </div>
  </div>
 );
};

export default BetAction;
