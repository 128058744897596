import React, { useEffect, useState } from "react";
import usePartner from "../../hooks/servicesHooks/Partner/partnerHook";
import { DOMAIN } from "../../newCore/constants/environment";
import * as brazilAnimals from "../../components/ulits/brazilAnimals";
import selectedStore from "../../state/selectedState";

const AnimalByBet = ({ bet }) => {
  const { animals } = usePartner();
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return () => {
      partnerSettings.unsubscribe();
    };
  }, []);
  const brazilianCircleImages = {};
  animals.data.forEach(el => {
    brazilianCircleImages[`a${el.sortNumber}`] = brazilAnimals[`a${el.sortNumber}`];
  });
  const animalIds = bet?.animalIds?.split(",");
  const animal = animals.data.filter(animal => animalIds?.includes(String(animal?.id)));

  const src = a =>
    partnerSettings?.data?.viewMode === 0
      ? `${DOMAIN}/${a?.animalCirclePicUrl}`
      : `${brazilianCircleImages[`a${a?.sortNumber}`]}`;

  // const imgUrl = animal?.[0]?.animalPicUrl;
  useEffect(() => {
    selectedStore.setBrazilImages(brazilianCircleImages);
  }, [brazilianCircleImages]);

  return (
    <div className="mini-animals-row">
      {!!bet && animal.map((a, i) => <img src={src(a)} alt="animalName" className="mini-animals-img" key={i}/>)}
    </div>
  );
};

export default AnimalByBet;
