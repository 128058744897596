import {useEffect, useState} from "react";
import partnerService from "../../../newCore/services/Partner/partner";

const useAnimals = ()=>{
    const [animals, setPartnerAnimals] = useState(partnerService.animals.getValue());

    useEffect(() => {

        const animalsSubscription = partnerService.animals.subscribe(setPartnerAnimals);

        return () => {
            animalsSubscription.unsubscribe();
        };
    }, []);
    return {
        animals
    }
}

export default useAnimals
