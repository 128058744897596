import React from 'react';
import selectedStore from "../../state/selectedState";
import LeftResultRowAnimalByNumber from "./left-resultRow-animal-by-number";
import { resultRows } from "../../newCore/helpers/resultRows";
import useTranslation from "../../newCore/helpers/useTranslation";

const LastResulList = ({ result, selectedLastResult,animals }) => {
  const rows = resultRows(result);
  const t = useTranslation();

  return (
    <div
      className={`result-filters-changes-row ${
        result?.gameId === selectedLastResult?.gameId ? "active" : ""
      }`}
      onClick={() => selectedStore.selectGameResult(result)}
    >
      <div className="result-filters-c-column">
        {t("ID")} {result.gameId}
      </div>
      <div className="result-filters-c-column">
        <div className="mini-animals-row">
          {rows[0] && rows.map(number => (
            <LeftResultRowAnimalByNumber number={number} key={number} animals={animals} />
          ))}
        </div>
        <span>{new Date(result.endDate).toLocaleTimeString()}</span>
      </div>
    </div>
  );
};
export default LastResulList;
