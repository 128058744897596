import React, { useEffect, useMemo, useState } from "react";
import { resultRows } from "../../newCore/helpers/resultRows";
import ResultRowWeb from "../last-result/result-row.Web";
import is from "is_js";
import selectedStore from "../../state/selectedState";

const NewGameResultLeftContent = ({ selectedLastResult, t, isShowLastResult }) => {
  const rows = resultRows(selectedLastResult);
  const [activeBalls, setActiveBalls] = useState(selectedStore.initialState.activeColors);
  const [animated , setAnimated] = useState(selectedStore.initialState.lastResultAnimated)
  const [animation , setAnimation] = useState(selectedStore.initialState.animation)

  useEffect(() => {
    const activeBalls = selectedStore.subscribe(data => setActiveBalls(data.activeColors));
    const animated = selectedStore.subscribe(data => setAnimated(data.lastResultAnimated));
    const animation = selectedStore.subscribe(data => setAnimation(data.animation));
    selectedStore.init();
    return () => {
      animation.unsubscribe();
      animated.unsubscribe();
      activeBalls.unsubscribe();
    };
  }, []);

  const date = useMemo(() => {
    return new Date(selectedLastResult?.endDate).constructor();
  }, [selectedLastResult]);
  return (
    <div
      className={`last-result-container ${isShowLastResult ? "open" : ""} `}
      id={"left-last-result"}
    >
      <div className="last-r-title-time">
        {rows[1] && (
          <p className="res-b-title">
            {t("ID")} {selectedLastResult?.gameId}
          </p>
        )}

        <p className="res-b-time">
          {!is.empty(selectedLastResult) ? new Date(date).toLocaleTimeString() : ""}
        </p>
      </div>

      <div className="last-r-list-holder">
        {rows.map((item, index) => (
          <ResultRowWeb
            index={index}
            item={item}
            key={index}
            animated={animated}
            activeBalls={activeBalls}
            animation={animation}
          />
        ))}
      </div>
    </div>
  );
};

export default NewGameResultLeftContent;
