import React from 'react';
import selectedStore from '../../state/selectedState';
import Spinner from '../Loading/bet-button-spinner';
import { handleClickNumber, removeSymbol } from '../../newCore/helpers/helperNumpadFunction';
import CurrencyInput from '../bet-input/input';


const TerminalNumpad = ({ mobile, demoMode, currencyCode, partnerSettings, isCashDesk, showTerminalNumpad, t, setBetAmount, selectedGameType, checkClick, betAmount, tick, showSpinner, spinnerShower, handleClickAddBet }) => {
 return (
  <>
   <div className={`terminal-numpad ${isCashDesk && showTerminalNumpad && !mobile ? 'active' : ''}`}>
    <i className="terminal-n-icon icon-back" onClick={() => selectedStore.setShowTerminalNumpad(false)}/>
    <div className="terminal-n-content">
     <span className="item c-1" data-name={'1'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>1</span>
     <span className="item c-1" data-name={'2'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>2</span>
     <span className="item c-1" data-name={'3'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>3</span>
     <span className="item c-1" data-name={'4'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>4</span>
     <span className="item c-1" data-name={'5'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>5</span>
     <span className="item c-1" data-name={'c'} onClick={() => removeSymbol(setBetAmount, checkClick)}>c</span>
     <span className="item c-1" data-name={'6'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>6</span>
     <span className="item c-1" data-name={'7'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>7</span>
     <span className="item c-1" data-name={'8'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>8</span>
     <span className="item c-1" data-name={'9'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>9</span>
     <span className="item c-1" data-name={'0'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>0</span>
     <span className="item c-1" data-name={'.'}
           onClick={(e) => handleClickNumber(e, setBetAmount, betAmount, selectedGameType, checkClick)}>.</span>
    </div>
    <div className="i-bet-act">
     <CurrencyInput type="text" value={betAmount}
                    max={selectedGameType?.maxBet?.toString().length}
                    userCurrencyName={
                     currencyCode || partnerSettings?.data?.currencyCode
                    }
                    demoMode={demoMode}
                    onChange={handleClickNumber}
     />
     <button className={`bet-btn green ${tick ? 'disabled' : ''}`} onClick={() => {
      spinnerShower();
      handleClickAddBet();
      selectedStore.setShowTerminalNumpad(false);
     }}>{showSpinner ? <Spinner/> : <span>{t('BET')}</span>}</button>
    </div>
   </div>
  </>
 );
};

export default TerminalNumpad;
