import { Subject } from "rxjs";

const subject = new Subject();

const initialState = {
    tick: false,

};

let state = initialState;

const tickState = {
    init: () => {
        subject.next(state);
    },
    setTick: tick => {
        state = {
            ...state,
            tick
        };
        subject.next(state);
    },
    subscribe: setState => subject.subscribe(setState),
    unsubscribe: () => subject.unsubscribe(),
    initialState,
    state
};

export default tickState;
