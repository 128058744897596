import React, { useEffect, useState } from 'react';
import signalRSubscription from '../../newCore/services/SignalR/signalR';
import selectedStore from '../../state/selectedState';
import GameLoading from '../gameLoading/gameLoading';
import useTranslation from '../../newCore/helpers/useTranslation';
import LobbyMobile from './mobile/lobbyMobile';
import LobbyWeb from './web/lobbyWeb';
import { isFirefox } from 'react-device-detect';
import ConnectionLostPopup from '../ConectionLostPopup/ConectionLostPopup';
import demoState from '../../state/demoModeState';


const Lobby = ({ urlSearchParams, isWeb, isAuth, isLoaded, zoom , partnerSettings , currencyCode ,showPopup , connection , gameTypesIds}) => {

 const t = useTranslation();


 useEffect(() => {
  selectedStore.setLastBet({});
  demoState.setLastBet({});
  demoState.clearBetHistory();
  if (signalRSubscription?.isConnected()) {
   signalRSubscription.joinGroup('lobby').then(data => data);
  }

  return () => {
   selectedStore.clearLiveBetsForLobby();
   demoState.clearUserBet();
   signalRSubscription.removeGroup('lobby').then(data => data);
  };
 }, []);

 useEffect(()=>{
  if (connection){
   if (Array.isArray(gameTypesIds) && gameTypesIds?.length){
    for (let i=0;i<gameTypesIds.length;i++){
     signalRSubscription.joinGroup(`GameTypeCreationGroup_${gameTypesIds[i]}`).then(data => data);
    }
   }
  }
 },[connection,gameTypesIds])

 return (
  <div
   className={`main-lobby-wrapper ${isWeb?'':'mobile'}`}
   id="main-wrapper"
   style={!isFirefox ? { zoom } : { transform: `scale(${zoom})` }}
  >
   {showPopup ? <ConnectionLostPopup /> : null}
   {
    !isLoaded  ? <GameLoading isWeb={isWeb} viewMode={partnerSettings?.data?.viewMode}/> :
     isWeb ? <LobbyWeb t={t} urlSearchParams={urlSearchParams} viewMode={partnerSettings?.data?.viewMode} currencyCode={currencyCode} isAuth={isAuth}/> :
      <LobbyMobile t={t} urlSearchParams={urlSearchParams} viewMode={partnerSettings?.data?.viewMode} currencyCode={currencyCode} isAuth={isAuth}/>
   } </div>
 );
};

export default Lobby;
