import React, { memo, useEffect, useRef, useState } from 'react';
import LeftContent from "../../layouts/web/left-content";
import MainContent from "../../layouts/web/main-content";
import RightContent from "../../layouts/web/right-content";
import selectedStore from "../../state/selectedState";
import ConnectionLostPopup from "../../components/ConectionLostPopup/ConectionLostPopup";
import HashChecker from '../../components/hash/hash';
import { useOutsideClick } from '../../newCore/helpers/useClickOutside';
import useTranslation from '../../newCore/helpers/useTranslation';

const GameWeb = memo(({ path, zoom, urlSearchParams, animation, isFirefox }) => {
  const [showPopup, setShowPopup] = useState(selectedStore.initialState.isShowConnectionLostPopup);
  const [demoMode , setDemoMode] = useState(selectedStore.initialState.demoMode);
  const [showHash , setShowHash] = useState(selectedStore.initialState.showHash);
  const hash = useRef();

  const t = useTranslation();
  useEffect(() => {
    const showHash = selectedStore.subscribe(data=>setShowHash(data.showHash));
    const lostConnection = selectedStore.subscribe(data =>
      setShowPopup(data.isShowConnectionLostPopup)
    );
    const demoModeSubscription = selectedStore.subscribe(data =>setDemoMode(data.demoMode))

    return () => {
      demoModeSubscription.unsubscribe();
      showHash.unsubscribe();
      lostConnection.unsubscribe();
    };
  }, []);

  useOutsideClick(hash, () => {
    if (showHash)
    selectedStore.setShowHash(false);
  });

  return (
    <>
      {showPopup ? <ConnectionLostPopup /> : null}
      <div
        className={`main-wrapper ${animation ? "" : "animation-off"}`}
        id="main-wrapper"
        style={!isFirefox ? { zoom } : { transform: `scale(${zoom})` }}
      >
        {/*left column start*/}
        <LeftContent path={path} urlSearchParams={urlSearchParams} demoMode={demoMode}/>
        {/*left column END*/}

        {/*center column start*/}
        <MainContent path={path} demoMode={demoMode}/>
        {/*center column END*/}

        {/*right column start*/}
        <RightContent demoMode={demoMode}/>
        {/*right column END*/}


        {showHash ? <HashChecker hashRef={hash} t={t}/> : null}
        {/*<LobbyWeb/>*/}

      </div>
    </>
  );
});

export default GameWeb;
