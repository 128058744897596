import React from "react";
import LostConnection from "../../assets/images/lost-connection.png";
import useTranslation from "../../newCore/helpers/useTranslation";
const ConnectionLostPopup = () => {
  const t = useTranslation();

  return (
    <div className="lost-connection-popup">
      <div className="lost-connection-p-content">
        <img src={LostConnection} alt="" className="lost-connection-img" />
        <h3>{t("YOUR_CONNECTION_IS_LOST")}</h3>
        <span>{t("PLEASE_REFRESH_PAGE")}</span>
        <button onClick={() => window.location.reload()}>{t("OK")}</button>
      </div>
    </div>
  );
};

export default ConnectionLostPopup;
