import React, { useEffect, useState } from "react";
import selectedStore from "../../state/selectedState";
import useTranslation from "../../newCore/helpers/useTranslation";
import PlayerStatistticMobile from "./playerStatisticMobile";
import { resultRows } from "../../newCore/helpers/resultRows";
import demoState from '../../state/demoModeState';

const UserBetMobile = ({ result, userBet , demoBets , demoMode , demoBetsResult }) => {
  const [betResult, setBetResult] = useState(selectedStore.initialState.eachWinning);
  const [currency, setCurrency] = useState(selectedStore.initialState.userCurrency);
  const rows = resultRows(result);

  useEffect(() => {
    setTimeout(()=>{
      demoState.setShowUserBetNumber(false)
    },2000);
    const eachWinning = selectedStore.subscribe(data => setBetResult(data.eachWinning));
    const userCurrency = selectedStore.subscribe(data => setCurrency(data.userCurrency));
    selectedStore.init();
    return () => {
      userCurrency.unsubscribe();
      eachWinning.unsubscribe();
    };
  }, []);
  const t = useTranslation();

  return (
    <div className="result-r-cnt-m">
      <div className="result-r-head">
        <p className="result-r-h">{t("RESULT")}</p>
        <div className="result-r-row">
          {rows.map((item, index) => {
            return (
              <div className="result-column">
                <i>{index + 1}.</i>
                <span>{item}</span>
              </div>
            );
          })}
        </div>
        <div className="table-title-row">
          <p>{t("BET_NUMBER")}</p>
          <p>{t("ODD")}</p>
          <p>{t("BET")}</p>
          <p>{t("WIN")}</p>
        </div>
      </div>
      <div className="result-r-content">
        <div className="m-scroll-holder">
          <div className="m-scroll-b">
            {demoMode ? demoBets?.map(data => (
              <PlayerStatistticMobile
                bet={data}
                betResult={demoBetsResult}
                currency={currency?.[0]?.name}
                t={t}
                demoMode
              />
            )) : userBet?.map(data => (
              <PlayerStatistticMobile
                bet={data}
                betResult={betResult}
                currency={currency?.[0]?.name}
                t={t}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBetMobile;
