import React, { useEffect, useState } from "react";
import bals from "../../assets/images/bals.png";

import selectedStore from "../../state/selectedState";

const GameLoading = ({ isWeb }) => {
  const [progress, setProgress] = useState(selectedStore.initialState.initLoadingProgress);

  useEffect(() => {
    const progress = selectedStore.subscribe(data => setProgress(data.initLoadingProgress));
    return () => {
      progress.unsubscribe();
    };
  }, []);

  return (
    <div className={`loading-wrapper ${isWeb ? "" : "mobile"}`}>
      <div className="loading-w-image">
        {/*<div className="image-block-inner">*/}
        {/*  <img src={viewMode ? monkeyBr : monkey} alt="" />*/}
        {/*</div>*/}
        <div className="image-block-inner center">
          <img src={bals} alt="" />
        </div>
        {/*<div className="image-block-inner">*/}
        {/*  <img src={viewMode ? chickenBr : chicken} alt="" />*/}
        {/*</div>*/}
      </div>
      {/*<div className="logo-talisman">*/}
      {/*  <img src={viewMode ? logoBrazil : logo} alt="" />*/}
      {/*</div>*/}
      <div className="loading-item">
        <span style={{ transform: `translate(-${100 - progress}%, 0)` }} />
      </div>
      <div className="betconstruct-word">
        <p>
          bet<span>construct</span>
        </p>
      </div>
    </div>
  );
};

export default GameLoading;
