import React from 'react';

const BetTypeInfo = ({ t, helpBetType, src }) => {
 return (
  <div className="game-help-container">
   <div className="game-help-row">
    <div className="game-help-r-empty">
     <img
      style={{ width: '100%' }}
      src={src}
      alt="animal"
     />
    </div>
   </div>
   <div className="game-help-row">
    <div className="game-help-r-info">
     <h3 className="game-help-r-i-title">{t(helpBetType?.betTypeName)}</h3>
     <p className="game-help-r-i-text">{t(helpBetType?.descriptions?.[0])}</p>
    </div>
   </div>
  </div>
 );
};
export default BetTypeInfo;
