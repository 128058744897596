import React, { useEffect, useMemo, useState } from 'react';
import usePartner from '../../hooks/servicesHooks/Partner/partnerHook';
import HelpBetType from '../help-bet-types';
import selectedStore from '../../state/selectedState';
import useTranslation from '../../newCore/helpers/useTranslation';
import Loading from '../Loading/loading';
import { APP_CONSTANTS, GENERAL_GAME_INFO } from '../../newCore/constants/constants';
import { queryGet } from '../../newCore/helpers/helperFunctions';

const HelpLeftContent = () => {
 const {
  partnerBetTypes: { isLoading, data }
 } = usePartner();
 const t = useTranslation();

 const [selectedItem, setSelectedItem] = useState(
  selectedStore.initialState.selectedBetType
 );
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);

 useEffect(() => {
  const selectedBetType = selectedStore.subscribe(data => setSelectedItem(data.selectedBetType));
  selectedStore.init();
  selectedStore.clearSlectedAnimal();
  return () => {
   selectedBetType.unsubscribe();
  };
 }, []);

 const betTypeForHelp = useMemo(() => {
  return [GENERAL_GAME_INFO, ...data]
 },[data, isLoading]);

 if (isLoading) {
  return <Loading/>;
 }
 return (
  <div className="scroll-container">
   <div className="help-holder">
    <p className="help-h-title">{t('HELP')}</p>
    {betTypeForHelp.map((item, index) => {
     return <HelpBetType selectedItem={selectedItem} betType={item} t={t} key={index}/>;
    })}
   </div>
  </div>
 );
};

export default HelpLeftContent;
