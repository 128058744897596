import React from 'react';
import { Link } from 'react-router-dom';
import selectedStore from '../../state/selectedState';
import postMessageService from '../../newCore/services/PostMessages/PostMessagesService';
import demoState from '../../state/demoModeState';
import { queryGet } from '../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS, GENERAL_GAME_INFO } from '../../newCore/constants/constants';

const NavItem = ({
                  iconName,
                  active,
                  path,
                  hasUnseenBets,
                  newGameResultModal,
                  isAuth,
                  urlSearchParams,
                  title,
                  demoMode
                 }) => {
 const gameId = queryGet(APP_CONSTANTS.GAME_ID);
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
 const url = urlSearchParams.split(`&gameTypeId=${gameId}`).join('');

 return (
  <>
   {isAuth ? (
    <Link
     to={`/${path}?${urlSearchParams}`}
     className={`single-tab-b ${active ? 'active' : ''}`}
     style={{
      pointerEvents: path === '/' ? 'auto' : '',
      cursor: 'pointer'
     }}
     onClick={() => {
      selectedStore.clearSelectedState();
     }}
     title={title}

    >
          <span
           title={title}
           className={`icon-${iconName} ${
            hasUnseenBets && newGameResultModal && iconName === 'History' ? 'active' : ''
           }`}
          />
    </Link>
   ) : iconName === 'History' && !demoMode ? (
    <a
     onClick={() => {
      postMessageService.openLoginPopup();
       selectedStore.selectBetType(GENERAL_GAME_INFO)
     }}
     className="single-tab-b"
     style={{
      pointerEvents: path === '/' ? 'auto' : '',
      cursor: 'pointer',
      fontSize: path === 'help' ? '36px' : ''
     }}
     title={title}

    >
          <span
           title={title}
           className={`icon-${iconName} ${
            hasUnseenBets && newGameResultModal && iconName === 'History' ? 'active' : ''
           }`}
          />
    </a>
   ) : path ==='/' ? (
    <Link
     to={`${path}?${url}`}
     className={`single-tab-b ${active ? 'active' : ''}`}
     // style={{
     //  pointerEvents: path === '/' ? 'auto' : '',
     //  cursor: 'pointer'
     // }}
     onClick={() => {
      selectedStore.clearSelectedState();
     }}
     title={title}

    >
          <span
           title={title}
           className={`icon-${iconName} ${
            hasUnseenBets && newGameResultModal && iconName === 'History' ? 'active' : ''
           }`}
          />
    </Link>
   ) : (
    <Link
     to={`/${path}?${urlSearchParams}`}
     className={`single-tab-b ${active ? 'active' : ''}`}
     style={{
       pointerEvents: path === '/' ? 'auto' : '',
       cursor: 'pointer',
       fontSize: path === 'help' ? '36px' : ''
     }}
     onClick={() => {
      selectedStore.clearSelectedState();
      selectedStore.selectBetType(GENERAL_GAME_INFO)
     }}
     title={title}

    >
          <span
           title={title}
           className={`icon-${iconName} ${
            hasUnseenBets && newGameResultModal && iconName === 'History' ? 'active' : ''
           }`}
          />
    </Link>
   )}
  </>
 );
};

export default NavItem;
