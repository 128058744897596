import React, {memo} from 'react';
import SingleNumberWeb from '../../single-number.Web';
import selectedStore from '../../../../../state/selectedState';

const OneInputField = memo(({ isCashDesk,handleChange , value , mobile , inputValueMobile}) => {

 return (
  <SingleNumberWeb value={value['input1']} inputValueMobile={inputValueMobile['input1']}>
   <input
    type="text"
    name={`input1`}
    className="four-chance-input"
    onChange={handleChange}
    value={mobile || isCashDesk ? inputValueMobile['input1'] : value[`input1`]}
    maxLength={1}
    autoFocus={!mobile}
    autoComplete={'off'}
    onClick={()=>{
     if (isCashDesk){
      selectedStore.setShowAnimalNumpad(true);
      selectedStore.setSelectedInput('input1')
     }
     if (mobile){
     document.activeElement.blur()
     selectedStore.setShowNumPad(true)
      selectedStore.setCheckClick('number')
      selectedStore.setSelectedInput('input1')
     }
    }}
   />
  </SingleNumberWeb>
 );
});
export default OneInputField;
