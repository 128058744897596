import React, { useEffect, useState } from "react";
import UserInfo from "../../../components/user-info";
import AllBets from "../../../components/all-bets";
import selectedStore from "../../../state/selectedState";

const RightContent = ({demoMode}) => {
  const [userBalance, setUserBalance] = useState(selectedStore.initialState.userBalance);
  const [userSettings, setUserSettings] = useState(selectedStore.initialState.userSettings);
  const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.currency);

  useEffect(() => {
    const userSettings = selectedStore.subscribe(data => setUserSettings(data.userSettings));
    const userBalanceSub = selectedStore.subscribe(data => setUserBalance(data.userBalance));
    const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
    selectedStore.init();

    return () => {
      userBalanceSub.unsubscribe();
      userCurrency.unsubscribe();
      userSettings.unsubscribe();
    };
  }, []);



  return (
    <div className="right-col-b">
      <div className="grid-wrap-b">
        <div className="r-col-grid">
          <UserInfo balance={userBalance} settings={userSettings} currency={userCurrency?.[0]} demoMode={demoMode}/>
          <AllBets
            userSettings={userSettings}
            demoMode={demoMode}
          />
        </div>
      </div>
    </div>
  );
};

export default RightContent;
