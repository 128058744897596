import React, {memo, useEffect, useMemo, useState} from 'react';
import selectedStore from "../../state/selectedState";
import Choose from "./choose-number-container.WEB";
import FourChanceComponent from "./FourChance";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import selectedStoreMobile from "../../state/selectedStateMobile";
import useTranslation from '../../newCore/helpers/useTranslation';

const ChooseNumberWeb = memo(({ selectedAnimal, selectedBetType,mobile , demoMode }) => {
  const [selectedBetAmount, setSelectedBetAmount] = useState(
    selectedStore.state.betAmount
  );
  const [userCurrency, setUserCurrency] = useState(
    selectedStore.initialState.userCurrency
  );
  const [betTypeInfo, setBetTypeInfo] = useState(
   selectedStore.initialState.betTypesInfo
  );
  const  t = useTranslation()

  useEffect(() => {

    selectedStoreMobile.setArrowIcon(true)
   const betAmount = selectedStore.subscribe(data => setSelectedBetAmount(data.betAmount));
   const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
   const betTypesInfo = selectedStore.subscribe(data => setBetTypeInfo(data.betTypesInfo));
    selectedStore.init();
    selectedStoreMobile.setShowTitle(false);
    return () => {
      selectedStoreMobile.setShowTitle(true);
      betAmount.unsubscribe();
      userCurrency.unsubscribe();
      betTypesInfo.unsubscribe()
  }}, []);
  const helpBetType = useMemo(
   () => betTypeInfo.filter(item => item?.type === selectedBetType?.type),
   [selectedBetType, betTypeInfo]
  );
  return (
    <div className="choose-number-t-holder" id="choose-number">
      <div className="ch-title-text-r">
        <h3 className="ch-title-info" style={{pointerEvents:"none"}}>{t(helpBetType?.[0]?.betTypeName)}</h3>
      </div>
      {selectedBetType.type === 17 ? (
        <FourChanceComponent selectedBetType={selectedBetType} t={t}/>
      ) : (
        <Choose
          selectedBetType={selectedBetType}
          selectedAnimal={selectedAnimal}
          t={t}
          mobile={mobile}
        />
      )}
      <div className="bet-amount-row">
        <h4 className="win-text">{`${t("ODD")} X ${
          selectedBetType?.odds?.length > 1
            ? `${selectedBetType?.odds?.[0]} - ${selectedBetType?.odds?.[3]}`
            : selectedBetType?.odds?.[0]
        }`}</h4>
        <h5 className="money-v">{demoMode ? `${Math.round10(selectedBetType?.odds?.[0] * selectedBetAmount, -2)} Fun` : `${toCurrency(
          Math.round10(selectedBetType?.odds?.[0] * selectedBetAmount, -2),
          userCurrency?.[0]?.name
        )}`}</h5>
      </div>
    </div>
  );
});

export default ChooseNumberWeb;
