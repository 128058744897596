import { BehaviorSubject } from "rxjs";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { pendingSetter, doneSetter } from "../../helpers/helperFunctions";
import { map, switchMap } from "rxjs/operators";
import moment from "moment";
import is from "is_js";
import selectedStore from "../../../state/selectedState";

const END_POINT = {
  prefix: "Games",
  gameWithBets: "GameWithBets",
  lastResult: "lastResult",
  resultWithSettings: "resultWithSettings",
  topWinning: "TopWinning",
  topBetType: "topBetType",
  bets: "bets",
  lobby:'Lobby',
  checkHash:'checkHash',
  gameHistory:'gameHistory'
};
const getDay = day => new Date(day).getDate();
// const nextDay = day => new Date(new Date(day) + getDay(day));

const addDays = (date, addDays, time = null) => {
  const d = new Date(date);
  const next = new Date(d);
  if (time){
    next.setTime(new Date(`${d.toLocaleDateString()} ${time}`).getTime());
    // next.setTime(new Date().getTime());
  } else {
    next.setTime(new Date(`${d.toLocaleDateString()} ${new Date().toLocaleTimeString()}`).getTime());
  }

  // next.setTime(new Date().getTime())
  next.setDate(next.getDate() + addDays );
  var dateString = String(next).replace(/-/g, '/').replace('T', ' ');
  // return next.toISOString();
  return dateString
};

class GameService {
  lastResults = new BehaviorSubject(doneSetter([]));
  gamesTopBetType = new BehaviorSubject(doneSetter([]));
  games = new BehaviorSubject(doneSetter([]));
  gameResultById = new BehaviorSubject(doneSetter([]));
  allGameResults = new BehaviorSubject(doneSetter([]));
  betHistory = new BehaviorSubject(doneSetter([]));
  gameTypes = new BehaviorSubject(doneSetter([]));
  hesh = new BehaviorSubject(doneSetter([]))


  checkHash = (form) =>{
    const path = `${END_POINT.prefix}/${END_POINT.checkHash}`;
    this.hesh.next(pendingSetter([]));
    FromFetchService.post(path,form)
     .pipe(
      switchMap(async data => await data.json()),
      map(data => data.result),
     )
     .subscribe(data => this.hesh.next({ isLoading: false, data }));
  };

  getLastResult = (queryParams = moment().format("L"), partnerId = 4) => {
    // const { startDate, endDate } = queryParams;
    // const query = createCalendarQuery(startDate, endDate);
    const path = `${END_POINT.prefix}/${END_POINT.lastResult}`;
    this.lastResults.next(pendingSetter([]));
    FromFetchService.get(path, queryParams)
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
        // map(data => {
        //   if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
        //   return data;
        // })
      )
      .subscribe(data => this.lastResults.next({ isLoading: false, data }));
  };

  getAllGameResults = (queryParams, startDate, endDate, startTime, endTime) => {
    if (startDate) {
      if (!endDate) {
        endDate = addDays(startDate, 1, endTime);
      } else {
        endDate = addDays(endDate, 0, endTime);
      }
      startDate = addDays(startDate, 0, startTime);
    }
    const query =
      startDate && endDate
        ? `${queryParams}&StartDate=${startDate}&EndDate=${endDate}`
        : queryParams;
    // const { startDate, endDate } = queryParams;
    // const query = createCalendarQuery(startDate, endDate);
    const path = `${END_POINT.prefix}/${END_POINT.gameHistory}`;
    this.lastResults.next(pendingSetter([]));
    FromFetchService.get(path, query).subscribe(async data =>
      this.allGameResults.next(doneSetter(await data.json()))
    );
  };
  getBetHistory = (queryParams, startDate, endDate) => {
    const path = `${END_POINT.prefix}/${END_POINT.bets}`;
    if (startDate) {
      if (!endDate) {
        endDate = addDays(startDate, 1);
      } else {
        endDate = addDays(endDate, 0);
      }
      startDate = addDays(startDate, 0);
    }

    const query =
        startDate && endDate
            ? `${queryParams}&StartDate=${startDate}&EndDate=${endDate}`
            : queryParams;
    FromFetchService.get(path, query).subscribe(async data => {
      this.betHistory.next(doneSetter(await data.json()))
    });
  };
  getGameTypes = (partnerId , currencyCode) =>{
    const path = END_POINT.lobby;
    this.gameTypes.next(pendingSetter([]))
    FromFetchService.get(path,{partnerId , currencyCode})
     .pipe(
      switchMap(async data => await data.json()),
      map(data => data.result),
     ).subscribe(data => this.gameTypes.next({isLoading: false,data}))
  };

  getGames =( partnerId , gameId )=> {
    // const query = createCalendarQuery(queryParams.startDate, queryParams.endDate);
    const path = `${END_POINT.prefix}/${END_POINT.gameWithBets}/${partnerId}`;
    this.games.next(pendingSetter([]));
    FromFetchService.get(path, `OrderColumnName=endDate&OrderByAsc=true&GameTypeId=${gameId}`) //2020-06-26T18:01:00
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
      )
      .subscribe(data => this.games.next({ isLoading: false, data }));
  };

  getGameResultById = id => {
    const path = `${END_POINT.prefix}/${id}/${END_POINT.resultWithSettings}`;
    this.gameResultById.next(pendingSetter([]));
    FromFetchService.get(path).subscribe(async data =>
      this.gameResultById.next(doneSetter(await data.json()))
    );
  };

  // getTopBetTypeStatisticByGameId = (gameId) =>{
  //     const path = `${END_POINT.prefix}/${gameId}/${END_POINT.topBetType}`;
  //     this.gamesTopBetType.next(pendingSetter([]));
  //     FromFetchService.get(path)
  //         .subscribe(async data => this.lastResults.next(doneSetter(await data.json())))
  // }
}

const gameService = new GameService();

export default gameService;
