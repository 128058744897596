import React, { memo, useEffect, useMemo, useState } from "react";
import Query from "../../newCore/helpers/queryBuilder";
import { topWinningModel } from "../../newCore/constants/queryModels";
import TopWinnersList from "../player-statistic/live-bet-statistic";
import partnerService from "../../newCore/services/Partner/partner";
import selectedStore from "../../state/selectedState";
import { queryGet } from "../../newCore/helpers/helperFunctions";
import { APP_CONSTANTS } from "../../newCore/constants/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInline from "../Loading/loadingInline";

const BetsWinnersContainer = memo(() => {
  const [skip, setSkip] = useState(0);
  const [take] = useState(30);
  const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
  let topWinnersQuery = new Query(topWinningModel);

  const [partnerTopWinners, setPartnerTopWinners] = useState([]);
  const [partnerBetTypes, setPartnerBetTypes] = useState(
    selectedStore.initialState.partnerBetTypes
  );
  const [topWinnersCount, setTopWinnersCount] = useState(
    selectedStore.initialState.partnerTopWinnersCount
  );

  useEffect(() => {
    const partnerBetTypes = selectedStore.subscribe(data =>
      setPartnerBetTypes(data.partnerBetTypes)
    );
    const partnerWinning = selectedStore.subscribe(data =>
      setPartnerTopWinners(data.partnerTopWinners)
    );
    const topWinnersCountSubscription = selectedStore.subscribe(data =>
      setTopWinnersCount(data.partnerTopWinnersCount)
    );
    selectedStore.init();
    return () => {
      selectedStore.clearTopWinners()
      partnerWinning.unsubscribe();
      partnerBetTypes.unsubscribe();
      topWinnersCountSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    partnerService.getPartnerTopWinning(
      partnerId,
      topWinnersQuery
        .skip(skip)
        .take(take)
        .toString()
    );
  }, [skip]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoreData = () => {
    setSkip(skip + 30);
  };

  const partnerTopWinnersByBetType = useMemo(() => {
    const partnerBetTypesId = partnerBetTypes.map(item => item.id);
    return partnerTopWinners?.filter(item => partnerBetTypesId?.includes(item.betTypeId));
  }, [partnerTopWinners, partnerBetTypes]);

  return (
    <>
      <InfiniteScroll
        dataLength={partnerTopWinnersByBetType.length}
        next={fetchMoreData}
        hasMore={partnerTopWinnersByBetType.length <= topWinnersCount}
        loader={
            <LoadingInline />
        }
        scrollableTarget="scroll-container-top-win"
      >
        {!!partnerTopWinnersByBetType?.length &&
          partnerTopWinnersByBetType.map((data, index) => (
            <TopWinnersList bet={data} key={index} />
          ))}
      </InfiniteScroll>
    </>
  );
});

export default memo(BetsWinnersContainer);
