import { BehaviorSubject } from "rxjs";
import { doneSetter, pendingSetter } from "../../helpers/helperFunctions";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { TRANSLATION_HEADERS } from "../../constants/constants";
import { map, switchMap } from "rxjs/operators";
import is from "is_js";
import selectedStore from "../../../state/selectedState";

const END_POINT = {
  LANGUAGES: "Languages",
  TRANSLATION: "Translations",
  GAME: "code/game"
};

class LanguageService {
  languages = new BehaviorSubject(doneSetter([]));
  translation = new BehaviorSubject(doneSetter([]));

  getLanguages = () => {
    this.languages.next(pendingSetter([]));
    FromFetchService.getTranslation(END_POINT.LANGUAGES).subscribe(async data =>
      this.languages.next(doneSetter(await data.json()))
    );
  };

  getTranslationsByID = code => {
    this.getLanguages();
    const query = [
      { key: "code", value: code },
      { key: "ApplicationId", value: TRANSLATION_HEADERS.ApplicationId }
    ];
    const defaultQuery = [
      { key: "code", value: "en" },
      { key: "ApplicationId", value: TRANSLATION_HEADERS.ApplicationId }
    ];

    const path = `${END_POINT.TRANSLATION}/${END_POINT.GAME}`;
    const trResp = query => {
      FromFetchService.getTranslation(path, [...query])
        .pipe(
          switchMap(async data => await data.json()),
          map(data => {
            if (data.errorCode) {
              trResp(defaultQuery);
              return null
            }
            return data;
          }),
          map(data => data.result),
          map(data => {
            if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
            return data;
          })
        )
        .subscribe(data => this.translation.next({ isLoading: false, data }));
    };
    trResp(query);
  };
}
const languageService = new LanguageService();
export default languageService;
