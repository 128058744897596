import React, { useEffect, useState } from "react";
import selectedStore from "../../state/selectedState";
import Countdown, { zeroPad } from 'react-countdown';
import TimerHelper from "../../newCore/helpers/timerHelper";
import tickState from "../../state/tickState";
import LoadingInline from '../Loading/loadingInline';

const TimerWeb = () => {
  const [game,setGame] = useState(selectedStore.initialState.selectedGame)
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return  <div><LoadingInline /></div>;
    } else {
      return (
       <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
      );
    }
  };
  useEffect(() => {
    const game = selectedStore.subscribe(data =>setGame(data.selectedGame))
    selectedStore.init();
    tickState.init()
    return () => {
      game.unsubscribe()
    };
  }, []);

  return (
    <div className="timer-container">
      <Countdown
        key={game?.id}
       date={game?.endDate}
        renderer={renderer}
        onComplete={()=>tickState.setTick(true)}
        onStart={()=>tickState.setTick(false)}
      />
    </div>
  );
};

export default TimerWeb;
