import React from "react";
import selectedStore from "../../state/selectedState";

const HelpBetType = ({ betType, selectedItem , t }) => {
  return (
    <div
      className={`help-h-item ${
        selectedItem?.type === betType.type ? "active" : ""
      }`}
      onClick={() => selectedStore.selectBetType(betType)}
    >
      {t(betType.name)}
    </div>
  );
};
export default HelpBetType;
