import React, { useEffect, useState } from "react";
import NavItem from "./NavItem";
import selectedStore from "../../state/selectedState";
import useTranslation from "../../newCore/helpers/useTranslation";

const NavBar = ({ path, urlSearchParams , demoMode }) => {
  const [hasUnseenBets, setHasUnseenBets] = useState(selectedStore.initialState.hasUnseenBets);
  const [newGameResultModal, setNewGameResultModal] = useState(
    selectedStore.initialState.newGameResultModal
  );
  const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);
  const t = useTranslation();

  useEffect(() => {
    const unseenBets = selectedStore.subscribe(data => setHasUnseenBets(data.hasUnseenBets));
    const resultModal = selectedStore.subscribe(data =>
      setNewGameResultModal(data.newGameResultModal)
    );
    const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));
    selectedStore.init();
    return () => {
      unseenBets.unsubscribe();
      resultModal.unsubscribe();
      isAuth.unsubscribe();
    };
  }, []);

  return (
    <div className="tab-row-b">
      <div className="tabs-container">
        <NavItem
         active={path === "/"}
         iconName={"lobby"}
         title={t('LOBBY')}
         path={"/"}
         clickable
         urlSearchParams={urlSearchParams}
        />
        <NavItem
          active={path === "game"}
          iconName={"game"}
          title={t('GAME')}
          path={"game"}
          clickable
          urlSearchParams={urlSearchParams}
        />
        <NavItem
          active={path === "lastResult"}
          iconName={"Result"}
          title={t('GAME_RESULT')}
          path={"lastResult"}
          urlSearchParams={urlSearchParams}
        />
        <NavItem
          active={path === "betHistory"}
          iconName={"History"}
          title={t('BET_HISTORY')}
          path={"betHistory"}
          demoMode={demoMode}
          hasUnseenBets={hasUnseenBets}
          newGameResultModal={newGameResultModal}
          isAuth={isAuth}
          urlSearchParams={urlSearchParams}
        />
        <NavItem
          active={path === "help"}
          iconName={"info"}
          title={t('HELP')}
          path={"help"}
          urlSearchParams={urlSearchParams}
        />
      </div>
    </div>
  );
};

export default NavBar;
