import React from "react";
import useTranslation from "../../newCore/helpers/useTranslation";
import selectedStore from '../../state/selectedState';

const SortList = ({ isOpenedSort, setIsOpenedSort,sort, setSort, setIsShowCalendar, setTake }) => {
  const t = useTranslation();
  return (
    <>
      <div
        className={`history-c-row status-1`}
        onClick={() => {
         if (sort!==1){
         selectedStore.clearBetHistory()
         }
          setSort(1);
          setTake(100);
          setIsShowCalendar(true);
        }}
      >
        <span>{t("WIN")}</span>
      </div>
      <div
        className={`history-c-row status-2`}
        onClick={() => {
         if (sort!==2){
          selectedStore.clearBetHistory()
         }         setSort(2);
          setTake(100);
          setIsShowCalendar(true);
        }}
      >
        <span>{t("LOSE")}</span>
      </div>
      <div
        className={`history-c-row status-3`}
        onClick={() => {
         if (sort!==0){
          selectedStore.clearBetHistory()
         }
         setSort(0);
          setTake(100);
          setIsShowCalendar(true);
        }}
      >
        <span>{t("PENDING")}</span>
      </div>
      <div
        className={`history-c-row status`}
        onClick={() => {
         if (sort!==3){
          selectedStore.clearBetHistory()
         }
         setSort(3);
          setTake(100);
          setIsShowCalendar(true);
        }}
      >
        <span>{t("RETURNED")}</span>
      </div>
      {/*<div className={`history-sort-row `} onClick={() => setIsOpenedSort(!isOpenedSort)}>*/}
      {/*  <div style={{ textAlign: "center", width: "100%", fontSize: "30px" }}>*/}
      {/*    {isOpenedSort ? (*/}
      {/*      <i className="icon-close_filter" />*/}
      {/*    ) : (*/}
      {/*      <i className="icon-arrow_drop_down down" />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default SortList;
