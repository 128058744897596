import selectedStore from '../../state/selectedState';
import betHistoryService from '../services/Bets/bet';
import demoState from '../../state/demoModeState';

export const toCurrency = (price, currencyName = 'usd', style = 'currency', minFrDigits = 0) => {
 let formatter = new Intl.NumberFormat('en-US', {
  style,
  currency: currencyName,
  minimumFractionDigits: minFrDigits
 });
 let toEnd = false;
 const symbolToEnd = arr => {
  if (toEnd) {
   arr.push({ type: 'literal', value: ' ' }, arr.shift());
   arr.shift();
   return arr;
  }
  return arr;
 };

 return symbolToEnd(formatter.formatToParts(price))
  .map(({ type, value }) => {
   switch (value) {
    case 'AMD':
     toEnd = true;
     return `֏`;
    case 'TRY':
     return '₺';
    default:
     return value;
   }
  })
  .reduce((string, part) => string + part);

 // return new Intl.NumberFormat("en-EN", {
 //
 //   currency: currencyName,
 //   style,
 //   // maximumSignificantDigits: 1,
 //   minimumFractionDigits: minFrDigits
 //   // minimumIntegerDigits: 2
 //   // currencySign:"accounting"
 // }).format(price); //.replace(/\D00(?=\D*$)/, '')
};





export const toDate = date => {
 return new Intl.DateTimeFormat('en-EN', {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
 }).format(new Date(date));
};

export function parse(value = '') {
 if (value) {
  try {
   return eval(value);
  } catch (e) {
   return value;
  }
 }
 return value;
}

export const currencyToNumber = currency => {
 return Number(currency.replace(/[^0-9.-]+/g, ''));
};

export const bet = (
 selectedGame,
 selectedBetType,
 selectedNumber,
 selectedAnimal,
 betAmount,
 showSpiner,
 userFavoriteBet,
 demoMode,
 betIdForDemoBet
) => {
 const formData = {
  selectedGame,
  selectedBetType
 };
 const userWritingNumber = selectedNumber;
 const animalId = selectedAnimal?.map(animal => animal.id).join(',');
 if (!showSpiner) {
  if (demoMode) {
   const bet = {
    gameId: selectedGame.id,
    betTypeId: selectedBetType.id,
    betTypeName: selectedBetType.name,
    number: userWritingNumber,
    animalIds: animalId,
    stakeAmount: betAmount,
    odds: selectedBetType.odds,
    betType: selectedBetType.type,
    id:betIdForDemoBet
   };
   demoState.setBet(bet);
   demoState.setBetId()
   selectedStore.setBetIsAdded(true)
  } else {
   betHistoryService.bet(formData, betAmount, userWritingNumber, animalId);
  }
  selectedStore.setShowLiveBet(true);
  const bet = {
   betTypeId: selectedBetType.id,
   stakeAmount: betAmount,
   number: userWritingNumber,
   animalIds: animalId
  };
  if (userFavoriteBet.length) {
   let favoriteBets;
   const arr = userFavoriteBet?.filter(item => item.betTypeId !== bet.betTypeId);
   favoriteBets = [...arr, bet];
   selectedStore.setUserFavoriteBet(favoriteBets);
  } else {
   selectedStore.setUserFavoriteBet(bet);

  }
  selectedStore.clearSelectedState();
 }
};

export const betHelpers = (
 selectedBetType,
 selectedAnimal,
 selectedNumber,
 selectedGame,
 betAmount,
 bettingNumbers,
 showSpiner,
 userFavoriteBet,
 demoMode,
 betIdForDemoBet
) => {
 if (selectedBetType.type === 17) {
  if (selectedNumber?.length !== 4) {
   selectedStore.setWriteNumberAnimation(true);
   setTimeout(() => selectedStore.setWriteNumberAnimation(false), 1000);
  }
 }
 if (selectedBetType.type === 17 && selectedNumber?.length === 4) {
  const formData = {
   selectedGame,
   selectedBetType
  };
  const userWritingNumber = selectedNumber;
  if (!showSpiner) {
   if (demoMode) {
    const bet = {
     gameId: selectedGame.id,
     betTypeId: selectedBetType.id,
     betTypeName: selectedBetType.name,
     number: userWritingNumber,
     stakeAmount: betAmount,
     odds: selectedBetType.odds,
     betType: selectedBetType.type,
     id:betIdForDemoBet
    };
    demoState.setBet(bet);
    demoState.setBetId()
    selectedStore.setBetIsAdded(true)

   } else {
    betHistoryService.bet(formData, betAmount, userWritingNumber);
   }
   selectedStore.setShowLiveBet(true);
   selectedStore.clearSelectedState();
  }
 }
 if (selectedBetType.type !== 17) {
  if ((selectedAnimal.length !== selectedBetType.animalsCount && !bettingNumbers?.length) || (selectedAnimal.length !== selectedBetType.animalsCount && bettingNumbers[0].animalIds.split(',').length !== selectedBetType.animalsCount)) {
   selectedStore.chooseAnimal(true);
   setTimeout(() => selectedStore.chooseAnimal(false), 1000);
  } else if (!selectedAnimal.length && selectedBetType.type !== 17 && !bettingNumbers?.length && selectedBetType.animalNumbersCount !== 4) {
   selectedStore.chooseAnimal(true);
   setTimeout(() => selectedStore.chooseAnimal(false), 1000);
  } else if (bettingNumbers?.length && !selectedAnimal.length) {
   const formData = {
    selectedGame,
    selectedBetType
   };
   if (!showSpiner) {
    const userWritingNumber = selectedNumber;
    let bet = {
     betTypeId: selectedBetType.id,
     stakeAmount: betAmount,
     number: userWritingNumber,
     animalIds: bettingNumbers[0].animalIds
    };
    if (userFavoriteBet.length) {
     let favoriteBets;
     if (Array.isArray(userFavoriteBet)) {
      const arr = userFavoriteBet?.filter(item => item.betTypeId !== bet.betTypeId);
      bet = {
       betTypeId: selectedBetType.id,
       stakeAmount: betAmount,
       number: bettingNumbers[0].number,
       animalIds: bettingNumbers[0].animalIds
      };
      favoriteBets = [...arr, bet];
     } else if (userFavoriteBet.betTypeId !== bet.betTypeId) {
      favoriteBets = [...userFavoriteBet, bet];
     } else {
      favoriteBets = [bet];
     }
     selectedStore.setUserFavoriteBet(favoriteBets);
    } else {
     selectedStore.setUserFavoriteBet(bet);
    }
    if (demoMode) {
     const bet = {
       gameId: selectedGame.id,
       betTypeId: selectedBetType.id,
       betTypeName: selectedBetType.name,
       number: bettingNumbers[0].number,
      animalIds: bettingNumbers[0].animalIds,
      stakeAmount: betAmount,
      odds: selectedBetType.odds,
      betType: selectedBetType.type,
      id:betIdForDemoBet
      };
     demoState.setBet(bet);
     selectedStore.setBetIsAdded(true);
     demoState.setBetId()


    } else {
     betHistoryService.bet(
      formData,
      betAmount,
      bettingNumbers[0].number,
      bettingNumbers[0].animalIds
     );
    }
    selectedStore.setShowLiveBet(true);

    selectedStore.clearSelectedState();
   }
  } else if (selectedBetType.animalsCount > selectedAnimal.length && selectedBetType.animalNumbersCount !== 4) {
   selectedStore.chooseAnimal(true);
   setTimeout(() => selectedStore.chooseAnimal(false), 1000);
  } else if (selectedAnimal.length === 2 && !selectedNumber?.split(',')[1] && selectedBetType.animalNumbersCount !== 4) {
   selectedStore.chooseNumber(true);
   setTimeout(() => selectedStore.chooseNumber(false), 1000);
  } else if (selectedAnimal.length === 3 && !selectedNumber?.split(',')[2] && selectedBetType.animalNumbersCount !== 4) {
   selectedStore.chooseNumber(true);
   setTimeout(() => selectedStore.chooseNumber(false), 1000);
  } else if (
   selectedAnimal.length === 1 &&
   !selectedBetType.addingNumbersCount &&
   !selectedNumber && selectedBetType.animalNumbersCount !== 4
  ) {
   selectedStore.chooseNumber(true);
   setTimeout(() => selectedStore.chooseNumber(false), 1000);
  } else if (
   selectedAnimal.length === 1 &&
   selectedBetType.addingNumbersCount === 2 &&
   selectedNumber.length !== 4 &&
   selectedNumber.length !== 0
  ) {
   selectedStore.setWriteNumberAnimation(true);
   setTimeout(() => selectedStore.setWriteNumberAnimation(false), 1000);
  } else if (
   selectedAnimal.length &&
   selectedBetType.addingNumbersCount === 1 &&
   selectedNumber.length !== 3 &&
   selectedNumber.length !== 0
  ) {
   selectedStore.setWriteNumberAnimation(true);
   setTimeout(() => selectedStore.setWriteNumberAnimation(false), 1000);
  } else if (
   selectedAnimal.length === 1 &&
   selectedBetType.addingNumbersCount > 0 &&
   selectedNumber.length === 0 && selectedBetType.animalNumbersCount !== 4
  ) {
   selectedStore.chooseNumber(true);
   setTimeout(() => selectedStore.chooseNumber(false), 1000);
  } else if (selectedAnimal.length === selectedBetType.animalsCount) {
   bet(selectedGame, selectedBetType, selectedNumber, selectedAnimal, betAmount, showSpiner, userFavoriteBet, demoMode,betIdForDemoBet);
  }
 }
};
