import React, { useEffect, useMemo, useState } from 'react';
import talisman_img from '../../../assets/images/talisman.png';
import jogo from '../../../assets/images/jogo.png';
import GameTypeInLobby from './gameTypeInLobby';
import selectedStore from '../../../state/selectedState';
import { useHistory } from 'react-router';
import { APP_CONSTANTS, GENERAL_GAME_INFO } from '../../../newCore/constants/constants';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import lobbyBgByPartner from '../../../assets/images/lobbybg.png'

const LobbyContest = ({ urlSearchParams, t, viewMode, currencyCode, isAuth }) => {
 const [gameTypes, setGameTypes] = useState(selectedStore.initialState.gameTypes);
 const history = useHistory();
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);

 useEffect(() => {
  const gameTypes = selectedStore.subscribe(data => setGameTypes(data.gameTypes));
  selectedStore.init();
  return () => {
   gameTypes.unsubscribe();
  };
 }, []);
 return (
  <div className="lobby-w-left-column">
   <div className="l-left-col-ins">
    <div className="l-left-col-ins-top">
     {/*<div className="l-top-ins-s">*/}
     {/* <span className="l-top-ins-s-c"> </span>*/}
     {/* <span className="l-top-ins-s-c active"> </span>*/}
     {/* <span className="l-top-ins-s-c"> </span>*/}
     {/*</div>*/}
     <span className="icon-info for-lobby" onClick={() => {
      history.push({
       pathname: '/help',
       search: `${urlSearchParams}&gameTypeId=${gameTypes?.gameTypes?.[0]?.id}`
      });
      selectedStore.selectBetType(GENERAL_GAME_INFO);
      selectedStore.setSelectedGameType(gameTypes?.gameTypes?.[0], !isAuth);
     }}/>
     <div className="l-top-ins-img-wr">
      <div className="l-top-ins-img-inside">
       <img src={+partnerId === 1875787 ? lobbyBgByPartner : viewMode ? jogo : talisman_img} alt=""/>
      </div>
     </div>
    </div>
    <div className="l-left-col-ins-bottom">

     <div className="left-c-b-ins "> {/*add remove   transform_X-left,transform_X-right  class*/}
      {
       gameTypes?.gameTypes?.map(data => {
        return <GameTypeInLobby gameType={data} game={data.game} urlSearchParams={urlSearchParams} t={t}
                                key={data.id} history={history} currencyCode={currencyCode} isAuth={isAuth}/>;
       })
      }
     </div>
    </div>
   </div>
  </div>
 );
};

export default LobbyContest;
