import React, { useEffect, useMemo, useState } from 'react';
import usePartner from '../../hooks/servicesHooks/Partner/partnerHook';
import is from 'is_js';
import selectedStoreMobile from '../../state/selectedStateMobile';
import HelpBetTypeMobile from '../help-bet-types/HelpBetTypeMobile';
import GameHelpMobile from '../game-help/gameHelpMobile';
import selectedStore from '../../state/selectedState';
import useTranslation from '../../newCore/helpers/useTranslation';
import Loading from '../Loading/loading';
import { ABOUT_GAME, APP_CONSTANTS, GENERAL_GAME_INFO } from '../../newCore/constants/constants';
import { queryGet } from '../../newCore/helpers/helperFunctions';

const HelpLeftContentMobile = () => {
 const {
  partnerBetTypes: { isLoading, data }
 } = usePartner();

 const [selectedItem, setSelectedItem] = useState(
  {}
 );
 const [betTypeInfo, setBetTypeInfo] = useState(
  selectedStore.initialState.betTypesInfo
 );
 const [showBetTypeInfo, setShowBetTypeInfo] = useState(selectedStore.initialState.showBetTypeInfo);
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);

 useEffect(() => {
  const selectedBetTypeHelpInfo = selectedStoreMobile.subscribe(data => setSelectedItem(data.selectedBetTypeHelpInfo));
  const betTypesInfo = selectedStore.subscribe(data => setBetTypeInfo(data.betTypesInfo));
  const showBetTypeInfo = selectedStore.subscribe(data => setShowBetTypeInfo(data.showBetTypeInfo));
  selectedStore.init();
  selectedStoreMobile.init();
  return () => {
   selectedStoreMobile.clearSelectState();
   selectedBetTypeHelpInfo.unsubscribe();
   betTypesInfo.unsubscribe();
   showBetTypeInfo.unsubscribe();
   selectedStore.setDefaultSelectedBetType();
  };
 }, []);

 const betTypeForHelp = useMemo(() => {
  return [GENERAL_GAME_INFO, ...data]
 },[data, isLoading]);

 const helpBetType = useMemo(() => {
   return selectedItem.type === ABOUT_GAME ? selectedItem : betTypeInfo.find(item => item?.type === selectedItem?.type);
  },
  [selectedItem, betTypeInfo]
 );

 const t = useTranslation();
 if (isLoading) {
  return <Loading/>;
 }
 return (
  !is.empty(selectedItem) || showBetTypeInfo ? <GameHelpMobile helpBetType={helpBetType}/> :
   <div className="m-scroll-b">
    <div className="help-holder">
     <p className="help-h-title">
      <i className=""/>
      <span>{t('HELP')}</span>
      <i/>
     </p>
     <div className="help-m-content">
      {betTypeForHelp.map(item => {
       return <HelpBetTypeMobile betType={item} t={t}/>;
      })}
     </div>
    </div>
   </div>
 );
};

export default HelpLeftContentMobile;
