import React, { useEffect, useState } from 'react';
import BetOddButton from '../bet-odd-button';
import selectedStoreMobile from '../../state/selectedStateMobile';
import is from 'is_js';
import selectedStore from '../../state/selectedState';
import TimerWeb from '../timer/timer.Web';
import BetTypesMobile from '../mobile-components/betTypesMobile';
import { motion } from 'framer-motion';

const GameTitleMobile = ({ t, path, showBetType, selectedAnimal }) => {
 const [betTypes, setBetTypes] = useState([]);
 const [selectedBetType, setSelectedBetType] = useState([]);
 const [animationOn, setAnimationOn] = useState(selectedStore.initialState.animation);
 useEffect(() => {
  const partnerBetTypesSubscription = selectedStore.subscribe(data => setBetTypes(data.partnerBetTypes));
  const animation = selectedStore.subscribe(data => setAnimationOn(data.animation));
  const selectedBetTypesSubscription = selectedStore.subscribe(data => {
   if (data?.selectedBetType) {
    setSelectedBetType(data.selectedBetType);
   }
  });
  selectedStoreMobile.init();
  selectedStore.init();
  return () => {
   selectedStoreMobile.setShowBetType(false);
   partnerBetTypesSubscription.unsubscribe();
   selectedBetTypesSubscription.unsubscribe();
   animation.unsubscribe();
  };
 }, []);


 return (<div
   style={{ display: `${(selectedAnimal.length === selectedBetType?.animalsCount && selectedBetType.animalNumbersCount !== 4) ? 'none' : 'block'}` }}>
   {
    showBetType && <motion.div
     key="NewResultWeb"
     className="animated-element"
     transition={{ duration: animationOn ? 0.1 : 0 }}
     initial={{ transform: 'translate(140%, 0)' }}
     animate={{ transform: 'translate(0, 0)' }}
     exit={{ transform: 'translate(140%, 0)' }}

    >
     <BetTypesMobile data={betTypes} t={t}/>
    </motion.div>
   }


   {!showBetType && <div className="m-grid-title-row">
    <div className="game-top-r-b">
     <TimerWeb/>
     <div className="bet-type-container-m" onClick={() => selectedStoreMobile.setShowBetType(true)}>
      <div className="type-text-box-m">
       <p>{t(selectedBetType?.name)} <span className="icon-arrow_right"/></p>
      </div>
      {!is.empty(selectedBetType) && <BetOddButton odd={selectedBetType.odds}/>}
     </div>
    </div>
   </div>}
  </div>
 );
};

export default GameTitleMobile;
