import React, { useEffect, useRef, useState } from "react";
import usericon from "../../assets/images/player.svg";
import userIconBrazil from "../ulits/brazilCircleImages/22.png";
import UserConfigWeb from "../user-config-dropdown/UserConfig.WEB";
import selectedStoreMobile from "../../state/selectedStateMobile";
import selectedStore from "../../state/selectedState";
import { useOutsideClick } from "../../newCore/helpers/useClickOutside";
import postMessageService from "../../newCore/services/PostMessages/PostMessagesService";
import { isIOS } from "react-device-detect";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import demoState from '../../state/demoModeState';
import { useHistory, useLocation } from 'react-router';
import { queryGet } from '../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../newCore/constants/constants';

const HeaderMobile = ({ t, selectedAnimal, selectedBetType , path ,  demoMode , urlSearchParams}) => {
  const [openConfig, setOpenConfig] = useState(false);
  const [userBalance, setUserBalance] = useState(selectedStore.initialState.userBalance);
  const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
  const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);
  const [demoBalance ,setDemoBalance] = useState(demoState.initialState.balance);
  const [gameTypeLimits , setGameTypesLimits] = useState(selectedStore.initialState.gameTypeLimits);
  const gameId = queryGet(APP_CONSTANTS.GAME_ID);
  const url = urlSearchParams.split(`&gameTypeId=${gameId}`).join('');

  const settingsRef = useRef();
  const history = useHistory();

  useOutsideClick(settingsRef, event => {
    if (openConfig) {
      setOpenConfig(false);
    }
  });

  // * TOFIX
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  useEffect(() => {
    const limits = selectedStore.subscribe(data =>setGameTypesLimits(data.gameTypeLimits))
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    const balance = demoState.subscribe(data => setDemoBalance(data.balance))
    selectedStore.init();
    demoState.init();
    return () => {
      limits.unsubscribe();
      partnerSettings.unsubscribe();
      balance.unsubscribe()
    };
  }, []);

  // * TOFIX
  const [arrowIcon, setArrowIcon] = useState(selectedStoreMobile.initialState.arrowIcon);

  useEffect(() => {
    const arrowIcon = selectedStoreMobile.subscribe(data => setArrowIcon(data.arrowIcon));
    const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
    const userBalanceSub = selectedStore.subscribe(data => setUserBalance(data.userBalance));
    const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));


    selectedStoreMobile.init();
    return () => {
      userCurrency.unsubscribe();
      arrowIcon.unsubscribe();
      userBalanceSub.unsubscribe();
      isAuth.unsubscribe();
    };
  }, []);

  return (
    <div className="m-header-row">
      <div className={`header-m-holder ${arrowIcon ? "game" : ""}`}>
        <div className="h-m-icon-box">
            <span
              className="icon-lobby"
              onClick={() => {
                if (path!=='/'){
                  history.push({ pathname: '/', search:`${url}` });
                }else{
                postMessageService.goToHome();
                }
              }}
            />
        </div>
        <div className="balance-holder-m">
          { demoMode ?  <p className="balance-m-text">
            {t('BALANCE')}: <span>{demoBalance}Fun</span>
          </p> : isAuth ? (
            <p className="balance-m-text">
              {t("BALANCE")}: {toCurrency(userBalance?.data, userCurrency?.[0]?.name)}
            </p>
          ) : null}
          {
            demoMode ? <button onClick={()=>{
              if (isAuth){
                selectedStore.setDemoMode(false);
                selectedStore.setSelectedGameType(gameTypeLimits)
              }else{
                postMessageService.openLoginPopup();
              }
            }} className="real-play-btn">{t("PLAY_REAL")}</button> : null
          }
        </div>

        <div
          className="user-menu-holder-m"
          style={
            isIOS ? { pointerEvents: "auto" } : { pointerEvents: openConfig ? "none" : "auto" }
          }
          onClick={e => {
            setOpenConfig(isIOS ? !openConfig : true);
            e.stopPropagation();
          }}
        >
          <img src={partnerSettings?.data?.viewMode === 0 ? usericon : userIconBrazil} alt="" />
          <i className="icon-arrow_drop_down" />
        </div>

        {openConfig && (
          <React.Fragment ref={settingsRef}>
            <UserConfigWeb />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default HeaderMobile;
