import React, { memo, useEffect, useState } from "react";
import { DOMAIN } from "../../newCore/constants/environment";
import selectedStore from "../../state/selectedState";
import { pointNumber } from "../../newCore/helpers/pointBetNumbers";
import { checkedSelectedAnimal } from "../../newCore/helpers/helperFunctions";
import useTranslation from "../../newCore/helpers/useTranslation";
import * as brazilMainImages from "../ulits/brazilMainAnimals";

const Animal = memo(({ data, selectedAnimal, bettingNumbers, index ,selectedBetType:{addingNumbersCount , animalNumbersCount } }) => {
  const t = useTranslation();
  const { numbers, animalPicUrl, sortNumber } = data;

  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return () => {
      partnerSettings.unsubscribe();
    };
  }, []);

  const src =
    partnerSettings?.data?.viewMode === 0
      ? `${DOMAIN}/${animalPicUrl}`
      : brazilMainImages[`a${sortNumber}`];

  return (
    <div
      className={`g-item ${
        selectedAnimal?.[0]?.id === data.id ||
        selectedAnimal?.[1]?.id === data.id ||
        selectedAnimal?.[2]?.id === data.id ||
        (bettingNumbers?.animalIds && checkedSelectedAnimal(bettingNumbers?.animalIds, data))
          ? "g-item-border"
          : ""
      }`}
      style={{pointerEvents:`${
         (selectedAnimal?.[0]?.id === data.id ||
         selectedAnimal?.[1]?.id === data.id ||
         selectedAnimal?.[2]?.id === data.id ||
         (bettingNumbers?.animalIds && checkedSelectedAnimal(bettingNumbers?.animalIds, data)))&&(!addingNumbersCount && animalNumbersCount!==1)
          ? "none"
          : ""
        }`}}

      onClick={() => {
        selectedStore.selectAnimal(data)
        selectedStore.selectNumbers(data.numbers)
      }}
    >
      {/*active*/}
      <img src={src} alt="animal" />
      {pointNumber(bettingNumbers, numbers, data)}
      <span className="animal-number">{index < 10 ? "0" + index : index}.</span>
      <span className="animal-name">{t(data?.displayName.toUpperCase())}</span>
    </div>
  );
});

export default Animal;
