import { BehaviorSubject, of } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { doneSetter, pendingSetter } from "../../helpers/helperFunctions";
import is from "is_js";
import selectedStore from "../../../state/selectedState";

const END_POINTS = {
  prefix: "currencies"
};

class CurrenciesService {
  currencies = new BehaviorSubject(doneSetter([]));
  currenciesByCode = new BehaviorSubject(doneSetter([]));

  getCurrencies = () => {
    this.currencies.next(pendingSetter([]));
    FromFetchService.get(END_POINTS.prefix)
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
          return data;
        })
      )
      .subscribe( data => this.currencies.next({ isLoading: false, data }));
  };

  getCurrencyByCode = code => {
    this.currenciesByCode.next(pendingSetter([]));
    FromFetchService.get(`${END_POINTS.prefix}/${code}`)
      .pipe(catchError(error => of(error)))
      .subscribe(async data => this.currenciesByCode.next(doneSetter(await data.json())));
  };
}

const currenciesService = new CurrenciesService();

export default currenciesService;
