import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "../../assets/styles/date-picker.css";
import Query from "../../newCore/helpers/queryBuilder";
import { calendarQueryModel } from "../../newCore/constants/queryModels";
import selectedStore from "../../state/selectedState";
import {useOutsideClick } from "../../newCore/helpers/useClickOutside";
import Loading from "../Loading/loading";
import useTranslation from "../../newCore/helpers/useTranslation";
import selectedStoreMobile from "../../state/selectedStateMobile";
import LastResultListMobile from "./LastResultListMobile";
import is from "is_js";
import GameResultMobile from "./gameResultMobile";
import { queryGet } from "../../newCore/helpers/helperFunctions";
import useAnimals from "../../hooks/servicesHooks/Animals/Animals";
import gameService from "../../newCore/services/Games/games";
import { APP_CONSTANTS } from "../../newCore/constants/constants";
import NumpadForSearchGameResult from "../numPad/numpadForSearchGameResult";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInline from "../Loading/loadingInline";

const currentTimeString = new Date().toLocaleTimeString("en-GB");
const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};
const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

const sevenDaysBefore = new Date(today);
sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);

const LastResultsMobile = () => {
  const { animals } = useAnimals();
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showNumpad, setShowNumpad] = useState(selectedStore.initialState.showNumPad);
  const [lastResult, setLastResult] = useState(selectedStore.initialState.allGamesResult);
  const [gameResultsCount, setGameResultsCount] = useState(
    selectedStore.initialState.allGameResultsCount
  );
  const [showHash , setShowHash] = useState(selectedStore.initialState.showHash);


  const inputRef = useRef([]);
  // inputRef.current = new Array(2);
  const [showSearch, setShowSearch] = useState(false);

  const [skip , setSkip] = useState(0);
  const [take, setTake] = useState(30);
  const [searchValue, setSearchValue] = useState("");
  const [time, setTime] = useState({
    start: "00:00:00",
    end: currentTimeString
  });

  // const [allGameResults, setAllGameResults] = useState(selectedStore.initialState.allGameResults);
  const startTimeInput = useRef();
  const endTimeInput = useRef();
  const lastResultQuery = new Query(calendarQueryModel);

  const [selectedLastResult, setSelectedLastResult] = useState(
    selectedStoreMobile.initialState.selectedLastResult
  );
  const [animation , setAnimation] = useState(selectedStore.initialState.animation);

  const t = useTranslation();
  const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
  const gameTypeId = queryGet(APP_CONSTANTS.GAME_ID);
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  const handleChangeTime = e => {
    setIsFiltersChanged(true);
    setTime({ ...time, [e.target.name]: e.target.value });
  };

  const resetFilers = () => {
    selectedStore.clearGameResult();
    setIsFiltersChanged(false);
    setTime({
      start: null,
      end: null
    });
    setStartDate(new Date());
    setEndDate(tomorrow);
    setSearchValue("");
    startTimeInput.current.value = null;
    endTimeInput.current.value = null;
  };

  useEffect(() => {
    const showHash = selectedStore.subscribe(data=>setShowHash(data.showHash));
    setIsFiltersChanged(false);
    const animation = selectedStore.subscribe(data =>setAnimation(data.animation));

    // const allGameResult = selectedStore.subscribe(data => setAllGameResults(data.allGameResult));
    const shownumpadSubscription = selectedStore.subscribe(data => setShowNumpad(data.showNumPad));
    const selectedLastResult = selectedStoreMobile.subscribe(data =>
      setSelectedLastResult(data.selectedLastResult)
    );
    const gameResultSubscription = selectedStore.subscribe(data =>
      setLastResult(data.allGamesResult)
    );
    const resultsCount = selectedStore.subscribe(data =>
      setGameResultsCount(data.allGameResultsCount)
    );
    selectedStore.init();
    selectedStoreMobile.init();
    selectedStore.clearSlectedAnimal();
    return () => {
      animation.unsubscribe();
      showHash.unsubscribe();
      gameResultSubscription.unsubscribe();
      resultsCount.unsubscribe();
      selectedStore.clearGameResult();
      shownumpadSubscription.unsubscribe();
      // allGameResult.unsubscribe();
      selectedLastResult.unsubscribe();
    };
  }, []);

  useEffect(() => {
    gameService.getAllGameResults(
      lastResultQuery
        .gameId(searchValue)
        .partnerId(partnerId)
       .gameTypeId(gameTypeId)
        .skip(skip)
        .take(take)
        // .startTime(time.start)
        // .endTime(time.end)
        .toString(),
      startDate,
      endDate,
      time.start,
      time.end
    );
  }, [searchValue, time, startDate, endDate , skip , take,time]);

  useEffect(() => {
    if (skip>=30)
    gameService.getAllGameResults(
      lastResultQuery
        .gameId(searchValue)
        .partnerId(partnerId)
       .gameTypeId(gameTypeId)
       .skip(skip)
        .take(take)
        // .startTime(time.start)
        // .endTime(time.end)
        .toString(),
      startDate,
      endDate,
      time.start,
      time.end
    );
  }, [skip]);

  const fetchMoreData = () => {
    setSkip(skip + 30);
  };
  const handleOpenCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };
  const onChange = dates => {
    selectedStore.clearGameResult();
    setIsFiltersChanged(true);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setTake(30);
  };

  useOutsideClick(inputRef, () => {
    selectedStore.setShowNumPad(false);
    setShowSearch(false);
  });

  if (!is.empty(selectedLastResult)) {
    return <GameResultMobile result={selectedLastResult} showHash={showHash} />;
  }
  return (
    <>
      <div className="sticky-container">
        <div className={`history-head ${showSearch ? "search-visible" : ""}`}>
          {/*<div className="history-h-column icon" onClick={handleOpenCalendar}>*/}
          {/*  {isShowCalendar ? (*/}
          {/*    <i className="icon-close_filter history-h-icon animated" />*/}
          {/*  ) : (*/}
          {/*    <i className={`icon-filter history-h-icon filter`} />*/}
          {/*  )}*/}
          {/*</div>*/}
          <div className="history-h-column text">
            <span className="history-h-text">{t("GAME_RESULT")}</span>
          </div>
          <div className="history-h-column icon">
            {isShowCalendar ? (
              <i
                className={`icon-reset history-h-icon ${isFiltersChanged ? "active" : ""}`}
                onClick={resetFilers}
              />
            ) : (
              <i
                className={`icon-search history-h-icon search `}
                onClick={() => setShowSearch(true)}
              />
            )}
          </div>
          <div
            className="search-cnt"
            style={{
              transition: animation ? "240ms linear" : "0s"
            }}
          >
            <input
              type="text"
              placeholder={t("RESULT_ID")}
              value={searchValue}
              maxLength={19}
              ref={inputRef}
              onClick={() => {
                selectedStore.setShowNumPad(true);
                document.activeElement.blur();
              }}
            />
            <i
              className="icon-clear"
              onClick={() => {
                setShowSearch(false);
                selectedStore.setShowNumPad(false);
              }}
            />
          </div>
        </div>
        <div className={`history-date-picker-container ${isShowCalendar ? "open" : ""}`}>
          <div className="date-picker">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              minDate={new Date(sevenDaysBefore)}
              maxDate={new Date(tomorrow)}
              showDisabledMonthNavigation
              selectsRange
              inline
            />
          </div>
          <div className="date-picker-sort-columns">
            <div className="date-picker-sort-c">
              <div className="date-picker-sort-content">
                <i
                  className={`date-picker-sort-icon icon-arrow_left ${
                    time.start === "00:00:00" ? "" : "active"
                  }`}
                  style={{ pointerEvents: `${time.start === "00:00:00" ? "none" : "auto"}` }}
                  onClick={() => {
                    startTimeInput.current.stepDown(1);
                    setIsFiltersChanged(true);

                    setTime({
                      ...time,
                      start: startTimeInput.current.value
                    });
                  }}
                />
                <input
                  className="date-picker-sort-numbers"
                  type="time"
                  name="start"
                  onChange={handleChangeTime}
                  value={time.start}
                  placeholder="00:00:00"
                  step={10}
                  ref={startTimeInput}
                />
                <i
                  className="date-picker-sort-icon icon-arrow_right active"
                  onClick={() => {
                    startTimeInput.current.stepUp(1);
                    setIsFiltersChanged(true);

                    setTime({
                      ...time,
                      start: startTimeInput.current.value
                    });
                  }}
                />
              </div>
            </div>
            <div className="date-picker-sort-c center">-</div>
            <div className="date-picker-sort-c">
              <div className="date-picker-sort-content">
                <i
                  className={`date-picker-sort-icon icon-arrow_left ${
                    time.end === "00:00:00" ? "" : "active"
                  } `}
                  style={{ pointerEvents: `${time.end === "00:00:00" ? "none" : "auto"}` }}
                  onClick={() => {
                    endTimeInput.current.stepDown(1);
                    setTime({
                      ...time,
                      end: endTimeInput.current.value
                    });
                  }}
                />
                <input
                  className="date-picker-sort-numbers"
                  type="time"
                  name="end"
                  onChange={handleChangeTime}
                  value={time.end}
                  step={10}
                  placeholder="00:00:00"
                  ref={endTimeInput}
                />
                <i
                  className="date-picker-sort-icon icon-arrow_right active"
                  onClick={() => {
                    endTimeInput.current.stepUp(1);
                    setTime({
                      ...time,
                      end: endTimeInput.current.value
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {ReactDOM.createPortal(
        <NumpadForSearchGameResult
          showNumpad={showNumpad}
          inputRef={inputRef}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />,
        document.getElementById("root")
      )}

      {/*{data?.gameResults ? (*/}
      {/*  data.gameResults?.map(result => <LastResulListMobile result={result} />)*/}
      {/*) : (*/}
      {/*  <Loading />*/}
      {/*)}*/}
      {Array.isArray(lastResult) && !lastResult?.length ? (
        <Loading />
      ) : gameResultsCount === 0 ? (
        <div>{t("NO_MATCHING_RESULTS")} </div>
      ) : (
        <InfiniteScroll
          dataLength={lastResult?.length || 35}
          next={fetchMoreData}
          hasMore={lastResult?.length <= gameResultsCount}
          loader={
              <LoadingInline />
          }
          scrollableTarget="scroll-container-last-result-mob"
        >
          {lastResult?.map((result, index) => (
            <LastResultListMobile
              animals={animals}
              key={index}
              result={result}
              selectedLastResult={
                selectedLastResult?.data?.gameResults?.[0]
                  ? selectedLastResult?.data?.gameResults?.[0]
                  : selectedLastResult
              }
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default LastResultsMobile;
