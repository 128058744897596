import { Subject } from "rxjs";
import is from "is_js";
import signalRSubscription from '../newCore/services/SignalR/signalR';
import TimerHelper from '../newCore/helpers/timerHelper';
import { changeObjectKey } from '../newCore/helpers/helperFunctions';

const subject = new Subject();

const initialState = {
  partnerBetTypes: [],
  selectedBetType: [],
  selectedAnimal: [],
  selectedBetHistory: {},
  betAmount: 0,
  chooseAnimalAnimation: null,
  nextGame: {},
  selectedGame: null,
  betIsAdded: false,
  betError: "",
  gameResultWithHistory: undefined,
  gameResult: [],
  userBet: [],
  betTypesInfo: [],
  selectedNumbers: "",
  chooseNumberAnimation: null,
  writeNumberAnimation: null,
  showBetTypeInfo: false,
  showTotalWinPopup: false,
  betHistory: [],
  betHistoryCount: null,
  token: "",
  allGamesResult: [],
  allGameResultsCount: null,
  autoOpenResult: true,
  animation: true,
  showSearchinLastResult: false,
  showSearchinGameHistory: false,
  eachWinning: undefined,
  allWinning: 0,
  betHistoryArray: [],
  currency: [],
  userSettings: [],
  userCurrency: undefined,
  liveBets: [],
  liveBetsResults: [],
  newGameResult: null,
  hasUnseenBets: false,
  newGameResultModal: true,
  lastResultAnimated: false,
  showNewResult: false,
  userBalance: null,
  languages: null,
  isAuth: null,
  activeColors: false,
  showLiveBet: true,
  betHistoryIsLoading: true,
  selectedBetHistoryResult: {},
  partnerSettings: {},
  allGameResult: [],
  isShowConnectionLostPopup: false,
  initIsLoaded: false,
  initLoadingProgress: 0,
  a: 0,
  brazilImages: {},
  showNumPad: false,
  writenNumberByUser: {
    input1: "",
    input2: ""
  },
  selectedInput: "",
  firstResult: {},
  partnerTopWinners: [],
  partnerTopWinnersCount: 0,
  checkClick: "",
  showTerminalNumpad:false,
  showAnimalNumpad : false,
  gameTypes : [],
  userFavoriteBets : [],
  demoMode : false,
  gamesInLobby : [],
  selectedGameType:{},
  gameTypeLimits : {},
  currencyCode : '',
  gameTypesIds : [],
  liveBetsForLobby : [],
  rebetPopUp : false,
  doBet : false,
  dontShowPopUp:false,
  lastBet : {},
  hash : '',
  showHash : false,
  resultSalt:'',
  resultKey:'',
  lastRoundId : '',
  showNumPadForHash:false,
  connectionStart:false,
  betLimit: null,
  showDemoWin:false,
  showRealWin:false,
};

let state = initialState;

const selectedStore = {
  init: () => {
    subject.next(state);
  },
  setBetLimit:(limit)=>{
    state = {
      ...state,
      betLimit:limit
    };
    subject.next(state);
  },
  setShowDemoWin:(bool)=>{
    state = {
      ...state,
      showDemoWin:bool
    };
    subject.next(state);
  },
  setShowRealWin:(bool)=>{
    state = {
      ...state,
      showRealWin:bool
    };
    subject.next(state);
  },
  setDefaultSelectedBetType : () =>{
    state = {
      ...state,
      selectedBetType: state.partnerBetTypes?.[0]
    }
    subject.next(state)
  },
  setConnection:bool=>{
    state = {
      ...state ,
      connectionStart: bool
    };
    subject.next(state)
  },
  setKey:key=>{
    state = {
      ...state,
      resultKey: state.resultKey+''+key
    }
    subject.next(state)
  },
  setSalt:salt=>{
    state = {
      ...state,
      resultSalt: state.resultSalt+''+salt
    };
    subject.next(state)
  },
  clearSalt : ()=>{
    state ={
      ...state,
      resultSalt: ''
    }
    subject.next(state)
  },
  clearKey: ()=>{
    state ={
      ...state,
      resultKey: ''
    }
    subject.next(state)
  },
  setShowNumPadForHash:bool =>{
    state ={
      ...state,
      showNumPadForHash:bool
    };
    subject.next(state)
  },
  setResultSalt : (resultSalt)=>{
    state ={
      ...state,
      resultSalt,
    };
    subject.next(state)
  },
  setResultKey : (resultKey)=>{
    state ={
      ...state,
      resultKey,
    };
    subject.next(state)
  },
  setShowHash : bool=>{
    state = {
      ...state,
      showHash: bool
    };
    subject.next(state)
  },
  setHash : data =>{
    state = {
      ...state,
      hash:data.data
    };
    subject.next(state)
  },
  setLastBet : bet =>{
    state = {
      ...state,
      lastBet: bet
    };
    subject.next(state)
  },
  setDoBet:bool=>{
    state = {
      ...state,
      doBet: bool
    };
    subject.next(state)
  },
  setRebetPopUp : bool=>{
    state = {
      ...state,
      rebetPopUp: bool
    };
    subject.next(state)
  },
  setDontShowPopUp : bool=>{
    state = {
      ...state,
      dontShowPopUp: bool
    }
    subject.next(state)
  },
  setSelectedGameType:(gameType)=>{
    state = {
      ...state,
      selectedGameType: {...gameType},
      gameTypeLimits : gameType
    };
    subject.next(state)
  },
  setGamesInLobby : game=>{
    let index = state.gameTypes.gameTypes?.findIndex(item => item.id === game.gameTypeId);
     state.gameTypes.gameTypes[index].game = game
    state = {
      ...state,
      gameTypes: {
        gameTypes : [...state.gameTypes.gameTypes]
      },
    };
    subject.next(state)
  },
  setDemoMode : bool =>{
    state = {
      ...state,
      demoMode: bool
    };
    subject.next(state)
  },
  setGameTypes:data=>{
    let gameTypesId = data.data?.gameTypes?.map(item => item.id)
    state={
      ...state,
      gameTypes: data.data,
      gameTypesIds:gameTypesId
    };
    subject.next(state)
  },
  setShowAnimalNumpad : bool =>{
    state={
      ...state,
      showAnimalNumpad: bool
    }
    subject.next(state)
  },
  setShowTerminalNumpad:(bool)=>{
  state = {
    ...state,
    showTerminalNumpad: bool
  };
  subject.next(state)
},
  setCheckClick: a => {
    state = {
      ...state,
      checkClick: a
    };
    subject.next(state);
  },
  setPartnerTopWinners: data => {
    if (data?.topWiningReports)
      state = {
        ...state,
        partnerTopWinners: [...state.partnerTopWinners, ...data?.topWiningReports],
        partnerTopWinnersCount: data?.count
      };
    subject.next(state);
  },
  setSelectedInput: name => {
    state = {
      ...state,
      selectedInput: name
    };
    subject.next(state);
  },
  clearWritenNumberByUser: () => {
    state = {
      ...state,
      writenNumberByUser: {
        input1: "",
        input2: ""
      }
    };
    subject.next(state);
  },
  setWritenNumberByUser: value => {
    state = {
      ...state,
      writenNumberByUser: {
        ...state.writenNumberByUser,
        [state.selectedInput]: value
      }
    };
    subject.next(state);
  },

  setShowNumPad: bool => {
    state = {
      ...state,
      showNumPad: bool
    };
    subject.next(state);
  },
  setBrazilImages: obj => {
    state = {
      ...state,
      brazilImages: obj
    };
    subject.next(state);
  },

  setInitLoadingProgress: count => {
    state.a += count;
    let callsCount;
    if (state.isAuth) {
      callsCount = 9;
    } else if (state.isAuth === false) {
      callsCount = 7;
    }
    const loadingByPercent = 100 / (callsCount - state.a);
    state = {
      ...state,
      initLoadingProgress: loadingByPercent,
      initIsLoaded: loadingByPercent === 100
    };
    subject.next(state);
  },
  setConnectionLost: bool => {
    state = {
      ...state,
      isShowConnectionLostPopup: bool
    };
    subject.next(state);
  },

  setPartnerSettings: settings => {
    state = {
      ...state,
      partnerSettings: settings
    };
    subject.next(state);
  },
  selectFirstResult: result => {
    state = {
      ...state,
      firstResult: result?.data?.gameResults?.[0]
    };
    subject.next(state);
  },
  setShowLiveBet: bool => {
    state = {
      ...state,
      showLiveBet: bool
    };
    subject.next(state);
  },
  setIsAuth: bool => {
    state = {
      ...state,
      isAuth: bool
    };
    subject.next(state);
  },
  setLanguages: languages => {
    state = {
      ...state,
      languages: languages?.data
    };
    subject.next(state);
  },
  setNewGameResultModal: bool => {
    state = {
      ...state,
      newGameResultModal: bool
    };
    subject.next(state);
  },
  setUserBalance: userBalance => {
    state = {
      ...state,
      userBalance
    };
    subject.next(state);
  },
  setShowNewResult: bool => {
    state = {
      ...state,
      showNewResult: bool
    };
    subject.next(state);
  },
  lastResultAnimated: bool => {
    state = {
      ...state,
      lastResultAnimated: bool
    };
    subject.next(state);
  },
  setHasUnseenBets: bool => {
    state = {
      ...state,
      hasUnseenBets: bool
    };
    subject.next(state);
  },
  setBetError: error => {
    state = {
      ...state,
      betError: error
    };
    subject.next(state);
  },
  addLiveBets: data => {
    state = {
      ...state,
      liveBets: [...state.liveBets, data]
    };
    subject.next(state);
  },
  setLiveBetsForDemo: data => {
    state = {
      ...state,
      liveBetsForLobby: [...state.liveBetsForLobby, data]
    };
    subject.next(state);
  },
  clearLiveBetsForLobby: () => {
    state = {
      ...state,
      liveBetsForLobby: []
    };
    subject.next(state);
  },
  addLiveBetsResults: data => {
    state = {
      ...state,
      liveBetsResults: [...state.liveBetsResults, data]
    };
    subject.next(state);
  },
  clearLiveBets: () => {
    state = {
      ...state,
      liveBets: []
    };
    subject.next(state);
  },
  clearLiveBetsResults: () => {
    state = {
      ...state,
      liveBetsResults: []
    };
    subject.next(state);
  },
  setUserSettings: data => {
    state = {
      ...state,
      userSettings: data,
      userCurrency: state.currency?.filter(item => item.name === data?.currencyCode)
    };
    subject.next(state);
  },
  setCurrency: data => {
    state = {
      ...state,
      currency: data
    };
    subject.next(state);
  },
  setBetHistoryIsLoading:bool =>{
    state = {
      ...state,
      betHistoryIsLoading:bool,
    };
    subject.next(state)
  },
  setBetHistory: data => {
    state = {
      ...state,
      betHistoryIsLoading: data.isLoading
    };
    // subject.next(state)
    if (!is.empty(data.data)) {
      state = {
        ...state,
        betHistory: [...state.betHistory, ...data.data?.gameWithBets],

        // selectedBetHistoryResult: state.betHistory?.[0]?.result,
        betHistoryCount: data?.data?.count
      };
      subject.next(state);

      state = {
        ...state,
        selectedBetHistory:
          is.empty(state.selectedBetHistory) || state.selectedBetHistoryResult === undefined
            ? state.betHistory?.[0]?.bets?.[0]
            : state.selectedBetHistory,
        selectedBetHistoryResult:
          is.empty(state.selectedBetHistoryResult) || state.selectedBetHistoryResult === undefined
            ? state.betHistory?.[0]?.result
            : state.selectedBetHistoryResult
      };
      subject.next(state);
    }
  },
  clearTopWinners: () => {
    state = {
      ...state,
      partnerTopWinners: []
    };
    subject.next(state);
  },
  clearBetHistory: () => {
    state = {
      ...state,
      betHistory: [],
      selectedBetHistory: [],
      selectedBetHistoryResult: []
    };
    subject.next(state);
  },

  clearGameResult: () => {
    state = {
      ...state,
      allGamesResult: [],
      gameResult: []
    };
    subject.next(state);
  },
  setGameFromSocket : data =>{
    let changedData = changeObjectKey(data,'id','gameId');
    let index = state.allGamesResult.findIndex(item => item.gameId===changedData.gameId);
    if (index===-1 && state.allGamesResult.length){
      state = {
        ...state,
        allGamesResult: [changedData , ...state.allGamesResult],
        gameResult : changedData
      };
      subject.next(state)
    }
  },
  setGameResultFromSocket : data =>{
    let index = state.allGamesResult.findIndex(item => item.gameId===data.gameId);
    if (index>-1){
      state.allGamesResult.splice(index,1,data);
      state = {
        ...state,
        allGamesResult: [...state.allGamesResult]
      };
      subject.next(state);
    }
  },
  setAllGamesResult: (result) => {
      if (result.gameResults?.length) {
        state = {
          ...state,
          allGameResultsCount: result.count,
          allGamesResult: [...state.allGamesResult, ...result?.gameResults]
        };
        subject.next(state);
        state = {
          ...state,
          gameResult: is.empty(state.gameResult) ? state.allGamesResult?.[0] : state.gameResult
        };
        subject.next(state);
      } else {
        state = {
          ...state,
          allGameResultsCount: result.count
        };
        subject.next(state);
      }
  },

  selectGameResult: result => {
    state = {
      ...state,
      gameResult: result
    };
    subject.next(state);
  },

  setSelectedBetHistoryResult: data => {
    state = {
      ...state,
      selectedBetHistoryResult: data
    };
    subject.next(state);
  },
  setWriteNumberAnimation: bool => {
    state = {
      ...state,
      writeNumberAnimation: bool
    };
    subject.next(state);
  },
  setShowSearchinLastResult: bool => {
    state = {
      ...state,
      showSearchinLastResult: bool
    };
    subject.next(state);
  },
  setShowSearchinGameHistory: bool => {
    state = {
      ...state,
      showSearchinGameHistory: bool
    };
    subject.next(state);
  },
  setToken: token => {
    state = {
      ...state,
      token: token?.data
    };
    subject.next(state);
  },

  selectBetHistory: historyItem => {
    state = {
      ...state,
      selectedBetHistory: historyItem
    };
    subject.next(state);
  },

  setShowBetTypeInfo: bool => {
    state = {
      ...state,
      showBetTypeInfo: bool
    };
    subject.next(state);
  },

  setShowTotalWinPopup: bool => {
    state = {
      ...state,
      showTotalWinPopup: bool
    };
    subject.next(state);
  },

  selectNumbers: numbers => {
    const arrayFromNumbers = state.selectedNumbers?.split(",");
    if (state.selectedBetType.animalsCount === 1) {
      state = {
        ...state,
        selectedNumbers: numbers
      };
    } else if (arrayFromNumbers.length < state.selectedBetType.animalsCount * 4) {
      if (state.selectedNumbers) {
        state = {
          ...state,
          selectedNumbers: state.selectedNumbers + "," + numbers
        };
      } else {
        state = {
          ...state,
          selectedNumbers: numbers
        };
      }
    } else {
      const newArrayFromNumbers = arrayFromNumbers.slice(4);
      state = {
        ...state,
        selectedNumbers: newArrayFromNumbers.join() + "," + numbers
      };
    }
    subject.next(state);
  },
  setUserFavoriteBet: bet =>{
    state = {
      ...state,
      userFavoriteBets: Array.isArray(bet) ? [...bet] : [...state.userFavoriteBets,bet]
    };
    subject.next(state)
  },
  selectAnimal: (selectedAnimal) => {
    const betArray = state.userFavoriteBets;
    if (betArray.length) {
      let index = betArray.findIndex(item => item.betTypeId === state.selectedBetType.id);
      if (index>-1){

        if (state.selectedBetType.animalsCount===1 &&  state.selectedBetType.animalNumbersCount===1){

          state ={
            ...state,
            userFavoriteBets: [...betArray]
          };
        }else{
          betArray[index].animalIds = '';
          betArray[index].number = '';
      state ={
        ...state,
        userFavoriteBets: [...betArray],
      };
        }
      subject.next(state)
      }
    }
    let index = state.selectedAnimal.map(item => item.id).indexOf(selectedAnimal?.id);
    if (index === -1) {
      if (is.empty(selectedAnimal)) {
        state = { ...state };
      } else if (state.selectedBetType?.animalsCount > state.selectedAnimal.length) {
        state = {
          ...state,
          selectedAnimal: [...state.selectedAnimal, selectedAnimal]
        };
      } else {
        const newSelectedAnimal = state.selectedAnimal.slice(1);
        state = {
          ...state,
          selectedAnimal: [...newSelectedAnimal, selectedAnimal]
        };
      }
    }
    state = {
      ...state,
      userFavoriteBets: state.userFavoriteBets.filter(item => item.betTypeId !== state.selectedBetType.id )
    };

    subject.next(state);
  },
  setBet: bet => {
    state = {
      ...state,
      userBet: [...state.userBet,bet],
    }

    subject.next(state);
  },
  setBetTypesInfo: betTypesInfo => {
    state = {
      ...state,
      betTypesInfo
    };
    subject.next(state);
  },
  // game created

  selectGame: game => {
    let roundId = state.selectedGame?.id;
    state = {
      ...state,
      // lastRoundId:roundId,
      selectedGame: game,
      liveBets: game?.bets ? [...game?.bets] : [...state.liveBets]
    };
    subject.next(state);
  },
    setLastRoundId : id =>{
    state = {
      ...state ,
      lastRoundId: id
    }
    subject.next(state)
    },
  // game finished

  setNewGameResult: result => {
    state = {
      ...state,
      newGameResult: result
    };
    subject.next(state);
  },
  setNewGameResultColorsToActive: bool => {
    state = {
      ...state,
      activeColors: bool
    };
    subject.next(state);
  },
  // game calculated
  selectedGameResultWithHistory: result => {
    state = {
      ...state,
      gameResultWithHistory: result
    };
    subject.next(state);
  },
  setWinning: winning => {
    state = {
      ...state,
      eachWinning: winning,
      allWinning: winning?.reduce((a, b) => a + b.winningAmount, 0)
    };
    subject.next(state);
  },
  // bet adding

  setBetIsAdded: bool => {
    state = {
      ...state,
      betIsAdded: !!bool
    };

    subject.next(state);
  },
  // openFourChance: bool => {
  //   state = {
  //     ...state,
  //     isFourChance: bool
  //   };
  //   subject.next(state);
  // },

  chooseNumber: bool => {
    state = {
      ...state,
      chooseNumberAnimation: bool
    };
    subject.next(state);
  },
  chooseAnimal: bool => {
    state = {
      ...state,
      chooseAnimalAnimation: bool
    };
    subject.next(state);
  },

  selectedBetAmount: betAmount => {
    state = {
      ...state,
      betAmount
    };
    subject.next(state);
  },

  subscribe: setState => subject.subscribe(setState),
  setBetTypes: data => {
    state = {
      ...state,
      partnerBetTypes: data
    };
    subject.next(state);
  },
  selectBetType: selected => {
    state = {
      ...state,
      selectedAnimal: [],
      selectedNumbers: "",
      selectedBetType: selected
    };
    subject.next(state);
  },
  setAutoOpenResult: bool => {
    state = {
      ...state,
      autoOpenResult: bool
    };
    subject.next(state);
  },
  setAnimation: bool => {

    state = {
      ...state,
      animation: bool
    };
    subject.next(state);
  },
  clearSelectedState: () => {
    state = {
      ...state,
      selectedAnimal: [],
      selectedBetType: state.selectedBetType.type === 17 ? [] : state.selectedBetType,
      showBetTypeInfo: false,
      showTotalWinPopup: false,
      gameResultWithHistory: undefined
    };
    subject.next(state);
  },
  clearSlectedAnimal: () => {
    state = {
      ...state,
      selectedAnimal: [],
      selectedBetType: state.selectedBetType?.type === 17 ? {} : state.selectedBetType
    };
    subject.next(state);
  },
  clearUserBet: () => {
    state = {
      ...state,
      userBet: []
    };
    subject.next(state);
  },
  unsubscribe: () => subject.unsubscribe(),
  initialState,
  state
};

export default selectedStore;
