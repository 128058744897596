import React, { memo, useEffect, useState } from 'react';
import ChooseAnimalByBetType from "../components/chooseAnimalDependToBetType";
import NewResultWeb from "../components/last-result/newResult";
import GameResult from "../components/game-result/gameResult";
import GameBetHistory from "../components/game-bet-history/gameBetHistory";
import GameHelp from "../components/game-help/gameHelp";
import { motion, AnimatePresence } from "framer-motion";
import selectedStore from '../state/selectedState';


const GameCenterContainer = memo(
  ({
    path,
    selectedBetType,
    selectedAnimal,
    showBetTypeInfo,
    resultWithHistory,
    selectedLastResult,
    autoOpenResult,
    demoMode
  }) => {
   const [animationOn, setAnimationOn] = useState(selectedStore.initialState.animation);
   useEffect(() => {
    const animation = selectedStore.subscribe(data => setAnimationOn(data.animation));
    selectedStore.init();
    return () => {
     animation.unsubscribe();
    };
   }, []);


   return (
      <div
        className={`game-table-row ${
          selectedAnimal.length === +selectedBetType?.animalsCount ? "game-table-row-abled" : ""
        }`}
      >
        {(path === "game" && showBetTypeInfo !== true && !resultWithHistory) ||
        (path === "game" &&
          showBetTypeInfo !== true &&
         !autoOpenResult) ?(
          <ChooseAnimalByBetType
            selectedAnimal={selectedAnimal}
            selectedBetType={selectedBetType}
            demoMode={demoMode}
          />
        ) : null}
          <AnimatePresence>
              {path === "game" &&
              resultWithHistory &&
              !showBetTypeInfo &&
              autoOpenResult && (
                  <motion.div
                      key="NewResultWeb"
                      className="animated-element"
                      transition={{ duration: animationOn ?  0.1 : 0}}
                      // initial={{ transform: "translate(140%, 0)", transition: "all 240ms" }}
                      // animate={{ transform: "translate(0, 0)", transition: "all 240ms" }}
                      // exit={{ transform: "translate(140%, 0)", transition: "all 240ms" }}
                      initial={{ transform: "translate(140%, 0)" }}
                      animate={{ transform: "translate(0, 0)" }}
                      exit={{ transform: "translate(140%, 0)" }}

                  >
                      <NewResultWeb result={resultWithHistory} />
                  </motion.div>
              )}
          </AnimatePresence>

        {path === "lastResult" ? (
          <GameResult
            result={
              selectedLastResult
            }
          />
        ) : null}

          <AnimatePresence>
              {(path === "help" || showBetTypeInfo) && (
                  <motion.div
                      key="GameHelp"
                      className="animated-element"
                      transition={{ duration: animationOn ? 0.1 : 0}}
                      // initial={{ transform: "translate(140%, 0)", transition: "all 240ms" }}
                      // animate={{ transform: "translate(0, 0)", transition: "all 240ms" }}
                      // exit={{ transform: "translate(140%, 0)", transition: "all 240ms" }}
                      initial={{ transform: "translate(140%, 0)" }}
                      animate={{ transform: "translate(0, 0)" }}
                      exit={{ transform: "translate(140%, 0)" }}

                  >
                      <GameHelp />
                  </motion.div>
              )}
          </AnimatePresence>

        {path === "betHistory" && !showBetTypeInfo ? <GameBetHistory demoMode={demoMode}/> : null}
      </div>
    );
  }
);
export default GameCenterContainer;
