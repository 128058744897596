import React, { memo, useEffect, useState } from 'react';
import LobbyContest from './lobbyContests';
import selectedStore from '../../../state/selectedState';
import LiveBetsLobby from './lobbyLiveBets';
import TopWinnersLobby from './lobbyTopWinners';
import LiveBetsList from '../../all-bets/live-bets-list';

const LobbyRightContent = memo(({ t }) => {
 const [showLiveBet, setShowLiveBet] = useState(true);
 const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
 const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );
 const [currencies, setCurrencies] = useState(selectedStore.initialState.currency);


 useEffect(() => {
  const currencies = selectedStore.subscribe(data => setCurrencies(data.currency));
  const showLiveBet = selectedStore.subscribe(data => setShowLiveBet(data.showLiveBet));
  const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
  const partnerSettings = selectedStore.subscribe(data =>
   setPartnerSettings(data.partnerSettings)
  );
  selectedStore.init();
  return () => {
   currencies.unsubscribe();
   userCurrency.unsubscribe();
   partnerSettings.unsubscribe();
   showLiveBet.unsubscribe();
  };
 }, []);
 return (
  <div className="lobby-w-right-column">
   <div className="l-r-col-ins">
    <div className="l-r-col-ins-holder">
     <div className="left-col-top">
      <div className="left-col-top-ins">
      </div>
      <div className="left-col-tabs">
       <button className={`${showLiveBet ? 'active' : ''}`} onClick={() => selectedStore.setShowLiveBet(true)}>{t('LIVE_BETS')}</button>
       <button className= {`${showLiveBet ? '' : 'active'}`} onClick={() => selectedStore.setShowLiveBet(false)}>{t('TOP_WINNERS')}</button>
      </div>
     </div>
     <div className="left-col-middle">
      <div className="l-c-m-ins">
       <div className="l-c-m-ins-col"><p className="l-c-m-ins-col-p">{t('PLAYER_ID')}</p></div>
       <div className="l-c-m-ins-col"><p className="l-c-m-ins-col-p center">{t('BET')}</p></div>
       <div className="l-c-m-ins-col"><p
        className="l-c-m-ins-col-p right">{showLiveBet ? t('POSSIBLE_WIN') : t('WIN')}</p></div>
      </div>
     </div>

     <div className="left-col-bottom">
      <div className="scroll-container" id={'scroll-container-top-win'}>
       {
        showLiveBet ? <LiveBetsLobby partnerCurrency={partnerSettings?.data?.currencyCode} userCurrency={userCurrency} currencies={currencies}
        /> : <TopWinnersLobby partnerCurrency={partnerSettings?.data?.currencyCode} userCurrency={userCurrency}
        />
       }
      </div>
     </div>
    </div>
   </div>
  </div>
 );
});

export default LobbyRightContent;
