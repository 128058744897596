const appName = 'jdb';

class Domain {
 constructor(baseUrl) {
  this.baseUrl = baseUrl;
 }

 toString() {
  try {
   // return window.location.origin
   // .replace(appName, `${appName}-api`)

   return window.location.origin.replace(appName, `${appName}-api`);
  } catch (e) {
   return this.baseUrl;
  }
 }
}

export default Domain;
