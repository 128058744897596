import { useEffect, useState } from "react";
import partnerService from "../../../newCore/services/Partner/partner";
import { queryGet } from "../../../newCore/helpers/helperFunctions";
import { APP_CONSTANTS } from "../../../newCore/constants/constants";
import userService from "../../../newCore/services/User/user";
import tokenService from "../../../newCore/services/Token/token";
import gameService from "../../../newCore/services/Games/games";
import selectedStore from "../../../state/selectedState";
import betHistoryService from "../../../newCore/services/Bets/bet";
import Query from "../../../newCore/helpers/queryBuilder";
import { calendarQueryModel } from "../../../newCore/constants/queryModels";
import currenciesService from "../../../newCore/services/Currencies/currencies";
import languageService from "../../../newCore/services/Language/language";
import signalRSubscription from "../../../newCore/services/SignalR/signalR";
import demoState from '../../../state/demoModeState';
import is from 'is_js';
import RtlLanguages from '../../../newCore/constants/rtlLanguages';

const getLanguageCodeFromId = (id, languages) => {
  return languages?.find(item => item?.id === id)?.code;
};

const useApp = () => {
  const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);
  const [token, setToken] = useState(selectedStore.initialState.token);
  const [partnerSettings, setPartnerSettings] = useState(null);
  const [languages, setLanguages] = useState(selectedStore.initialState.languages);
  const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
  const casinoToken = queryGet(APP_CONSTANTS.TOKEN);
  const languageCode =
    queryGet(APP_CONSTANTS.LANGUAGE) ||
    queryGet(APP_CONSTANTS.LAN) ||
    getLanguageCodeFromId(partnerSettings?.data?.languageId, languages) ||
    "en";

  // useEffect(() => {
  //   if (partnerService.customizationConfig.getValue().data.cssUrl) {
  //     DOMHelper.addPartnerSkinning(partnerService.customizationConfig.getValue().data.cssUrl);
  //     DOMHelper.addBodyClassName(
  //       partnerService.customizationConfig.getValue().data.backgroundImageDayUrl,
  //       partnerService.customizationConfig.getValue().data.backgroundImageNightUrl
  //     );
  //   }
  // }, [partnerService.customizationConfig.getValue()]);
  //
  useEffect(() => {
    selectedStore.subscribe(data => setIsAuth(data.isAuth));
    selectedStore.subscribe(data => setToken(data.token));
    selectedStore.init();
  }, []);

  useEffect(() => {
    selectedStore.subscribe(data => setLanguages(data.languages));
    if (RtlLanguages.includes(languageCode)){
      document.documentElement.setAttribute('dir','rtl')
    }
    selectedStore.init();
  }, []);
  useEffect(() => {
    selectedStore.setPartnerSettings(partnerSettings);
  }, [partnerSettings]);

  useEffect(() => {
    if (isAuth === true && token?.accessToken) {
      userService.getUserSettings();
      userService.getUserBalance(true);
      partnerService.getPartnerBetTypes(partnerId);
      tokenService.attachTimer(token);
    }
    if (isAuth === false) {
      partnerService.getPartnerBetTypes(partnerId);
    }
  }, [isAuth, token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tokenSubscription = tokenService.tokenResult.subscribe(data =>
      selectedStore.setToken(data)
    );
    const demoBetsResult = betHistoryService.demoBet.subscribe(data=>{
      selectedStore.setLastRoundId(data?.result?.gameId)
      demoState.setBetHistory(data.result)
    });
    const topWinnersSubscription = partnerService.partnerTopWinning.subscribe(data =>
    selectedStore.setPartnerTopWinners(data.data)
    );
    // const lastResultSubscription = gameService.lastResults.subscribe(data =>
    //   selectedStore.selectGameResult(data)
    // );
    const selectedFirstResultSubscription = gameService.lastResults.subscribe(data =>
      selectedStore.selectFirstResult(data)
    );
    const languagesSubscription = languageService.languages.subscribe(data =>
      selectedStore.setLanguages(data)
    );
    const newGameResultSubscription = gameService.lastResults.subscribe(data => {
      return !is.empty(data?.data)
        ? selectedStore.setNewGameResult(data?.data)
        : null;
    });
    const allGamesResultSubscription = gameService.allGameResults.subscribe(data =>
      selectedStore.setAllGamesResult(data?.data)
    );

    // const allGameResultSubscription = gameService.allGameResults.subscribe(data =>
    //   selectedStore.setAllGameResult(data?.data)
    // );
    const gamesSubscription = gameService.games.subscribe(data =>{
      selectedStore.selectGame(data?.data)
    }
    );

    const gameResultWithHistorySubscription = betHistoryService.betHistoryByGameId.subscribe(data =>
      selectedStore.setWinning(data?.data?.betHistories)
    );
    const partnerBetTypesInfoSubscription = partnerService.partnerBetTypesInfo.subscribe(data =>
      selectedStore.setBetTypesInfo(data?.data)
    );
    const betHistorySubscription = gameService.betHistory.subscribe(data =>
      selectedStore.setBetHistory(data)
    );

    const betSubscription = betHistoryService.addBet.subscribe(data => {
      // if (data.status) {
      //   selectedStore.setBet(data);
      // }
    });
    const balanceSubscription = betHistoryService.addBet.subscribe(data => {
      if (data.status) {
        selectedStore.setUserBalance({ isLoading: false, data: data.result.userBalance });
        selectedStore.setLastBet(data.result?.bet)
      }
    });
    const betisAddedSubscription = betHistoryService.addBet.subscribe(data =>
      selectedStore.setBetIsAdded(data?.status)
    );
    const betErrorSubscription = betHistoryService.addBet.subscribe(data =>
      selectedStore.setBetError(data?.errorMessage)
    );
    const partnerBetTypeSubscription = partnerService.partnerBetTypes.subscribe(data =>
      selectedStore.setBetTypes(data?.data)
    );
    const selectedBetTypeSubscription = partnerService.partnerBetTypes.subscribe(data =>
      selectedStore.selectBetType(data?.data?.[0])
    );
    const currencySubscription = currenciesService.currencies.subscribe(data =>
      selectedStore.setCurrency(data.data)
    );
    const userSettingsSubscription = userService.userSettings.subscribe(data => {
      signalRSubscription.constructor.USER_ID = data?.data?.id;
      return selectedStore.setUserSettings(data.data);
    });
    const userBalanceSubscription = userService.userBalance.subscribe(data =>
      selectedStore.setUserBalance(data)
    );
    const partnerSettingsSubscription = partnerService.partnerSettings.subscribe(data =>
      setPartnerSettings(data)
    );
    const gameTypesSubscription = gameService.gameTypes.subscribe(data=>{
      selectedStore.setGameTypes(data)
    }
    );
    const hesh = gameService.hesh.subscribe(data =>{
      selectedStore.setHash(data)
    })

    return () => {
      hesh.unsubscribe()
      demoBetsResult.unsubscribe();
      gameTypesSubscription.unsubscribe();
      topWinnersSubscription.unsubscribe();
      selectedFirstResultSubscription.unsubscribe();
      // allGameResultSubscription.unsubscribe();
      balanceSubscription.unsubscribe();
      userBalanceSubscription.unsubscribe();
      languagesSubscription.unsubscribe();
      newGameResultSubscription.unsubscribe();
      // selectedBetHistoryItemSubscription.unsubscribe();
      // lastResultSubscription.unsubscribe();
      gameResultWithHistorySubscription.unsubscribe();
      partnerBetTypesInfoSubscription.unsubscribe();
      gamesSubscription.unsubscribe();
      betHistorySubscription.unsubscribe();
      betSubscription.unsubscribe();
      betisAddedSubscription.unsubscribe();
      tokenSubscription.unsubscribe();
      allGamesResultSubscription.unsubscribe();
      partnerBetTypeSubscription.unsubscribe();
      selectedBetTypeSubscription.unsubscribe();
      currencySubscription.unsubscribe();
      userSettingsSubscription.unsubscribe();
      betErrorSubscription.unsubscribe();
      partnerSettingsSubscription.unsubscribe();
    };
  }, []);

  const init = async () => {
    await partnerService.getPartnerSettings(partnerId);

    await tokenService.auth(partnerId, casinoToken);
    // gameService.getGames(partnerId);
    // gameService.getLastResults(
    //   lastResultQuery
    //     .partnerId(partnerId)
    //     .skip(0)
    //     .take(1)
    //     .toString()
    // );
    partnerService.getPartnerBetTypesInfo(partnerId);
    // partnerService.partnerBetTypesInfo.subscribe(data =>
    //   selectedStore.setBetTypesInfo(data?.data)
    // );
    // betHistoryService.betHistoryByGameId.subscribe(data =>
    //   selectedStore.selectedGameResultWithHistory(data?.data)
    // );
    // partnerService.getCustomizationConfig(partnerId);
    languageService.getTranslationsByID(languageCode === "pb" ? "pt-br" : languageCode);
    currenciesService.getCurrencies(partnerId);
    partnerService.getAnimals(partnerId);
  };

  return {
    init
  };
};
export default useApp;
