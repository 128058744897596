import React from "react";
import BetType from "../bet-type";
import useTranslation from '../../newCore/helpers/useTranslation';
import selectedStoreMobile from '../../state/selectedStateMobile';
const BetTypesMobile = ({ data, t }) => {
 return (
  <div className="bet-type-holder">
   <div className="bet-history-m-head">
    <i className="icon-arrow_back" onClick={()=>selectedStoreMobile.setShowBetType(false)}/>
    <span>{t('BET_TYPE')}</span>
    <i/>
   </div>

   {data.map(betType => (
    <BetType betType={betType} key={betType.id} t={t}/>
   ))}
  </div>
 );
};

export default BetTypesMobile;
