import { BehaviorSubject } from "rxjs";
import { doneSetter, pendingSetter } from "../../helpers/helperFunctions";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { createCalendarQuery } from "../../helpers/queryStringCreator";
import { switchMap, map } from "rxjs/operators";
import is from "is_js";
import selectedStore from "../../../state/selectedState";

const END_POINTS = {
  prefix: "Partners",
  animals: "animals",
  betTypes: "betTypes",
  betTypeGroups: "betTypeGroups",
  settings: "settings",
  helpItems: "helpItems",
  betTypeInfo: "betTypeInfo",
  customizationConfig: "customizationConfig",
  topWinning: "topWinning",
  topBetType: "topBetType"
};

class PartnerService {
  partnerTopBetType = new BehaviorSubject(doneSetter([]));
  partnerTopWinning = new BehaviorSubject(doneSetter([]));
  partnerSettings = new BehaviorSubject(doneSetter([]));
  partnerHelpItems = new BehaviorSubject(doneSetter([]));
  animals = new BehaviorSubject(doneSetter([]));
  partnerBetTypes = new BehaviorSubject(doneSetter([]));
  customizationConfig = new BehaviorSubject(doneSetter([]));
  partnerBetTypesInfo = new BehaviorSubject(doneSetter([]));

  getPartnerTopBetType = (partnerId, query) => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.topBetType}`;
    const startDate = createCalendarQuery(query);
    this.partnerTopBetType.next(pendingSetter([]));
    FromFetchService.get(path, [...startDate]).subscribe(async data =>
      this.partnerTopBetType.next(doneSetter(await data.json()))
    );
  };

  getPartnerTopWinning = (partnerId, queryData) => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.topWinning}`;
    // const startDate = createCalendarQuery(queryData);
    this.partnerTopWinning.next(pendingSetter([]));
    FromFetchService.get(path, queryData).subscribe(async data =>
      this.partnerTopWinning.next(doneSetter(await data.json()))
    );
  };

  getPartnerSettings = partnerId => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.settings}`;
    this.partnerSettings.next(pendingSetter([]));
    FromFetchService.get(path)
      .pipe(
        switchMap(async res => await res.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
          return data;
        })
        //   map(data =>
        // data
        //  .map(animal => {
        //    return {
        //      ...animal,
        //      sortNumber: +animal.name.substring(5, animal.name.length)
        //    };
        //  })
        //  .sort((animalOne, animalTwo) => {
        //    return animalOne.sortNumber - animalTwo.sortNumber;
        //  })
        //   )
      )
      .subscribe(data => this.partnerSettings.next({ isLoading: false, data }));
  };

  getPartnerBetTypes = partnerId => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.betTypes}`;
    this.partnerBetTypes.next(pendingSetter([]));
    FromFetchService.get(path, { active: true })
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
          return data;
        })
      )
      .subscribe(data => this.partnerBetTypes.next({ isLoading: false, data }));
  };

  getPartnerHelpItems = partnerId => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.betTypeInfo}`;
    this.partnerHelpItems.next(pendingSetter([]));
    FromFetchService.get(path).subscribe(async data =>
      this.partnerHelpItems.next(doneSetter(await data.json()))
    );
  };

  getAnimals = partnerId => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.animals}`;
    this.animals.next(pendingSetter([]));
    FromFetchService.get(path)
      .pipe(
        switchMap(async res => await res.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
          return data;
        }),
        map(data =>
          data
            .map(animal => {
              return {
                ...animal,
                sortNumber: +animal.name.substring(5, animal.name.length)
              };
            })
            .sort((animalOne, animalTwo) => {
              return animalOne.sortNumber - animalTwo.sortNumber;
            })
        )
      )
      .subscribe(data => this.animals.next({ isLoading: false, data }));
  };

  getCustomizationConfig = partnerId => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.customizationConfig}`;
    this.customizationConfig.next(pendingSetter([]));
    FromFetchService.get(path).subscribe(async data =>
      this.customizationConfig.next(doneSetter(await data.json()))
    );
  };

  getPartnerBetTypesInfo = partnerId => {
    const path = `${END_POINTS.prefix}/${partnerId}/${END_POINTS.betTypeInfo}`;
    this.partnerBetTypesInfo.next(pendingSetter([]));
    FromFetchService.get(path)
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
          return data;
        })
      )
      .subscribe(data => this.partnerBetTypesInfo.next({ isLoading: false, data }));
  };
}

const partnerService = new PartnerService();
export default partnerService;
