import React, {memo} from "react";
import SingleAnimal from "./choose-single-animal.WEB";
import MultiplyAnimal from "./MultiplyAnimal";

const Choose = memo(({ selectedBetType, selectedAnimal,mobile }) => {

  return (
    <div className="ch-numbers-b">

      { selectedAnimal.length === 1 ? (
        <SingleAnimal
          selectedBetType={selectedBetType}
          selectedAnimal={selectedAnimal[0]}
          mobile={mobile}
        />
      ) : (
        <MultiplyAnimal
          selectedBetType={selectedBetType}
          selectedAnimal={selectedAnimal}
        />
      )}
    </div>
  );
});

export default Choose;
