import React, { useEffect, useMemo } from "react";
import selectedStore from "../../../../state/selectedState";
import MultiPlyAnimalTableByFixedNumber from "../multiPlyAnimalTableByFixedNumber";

const ThreeAnimalByFixedNumbers = ({ selectedAnimal }) => {
  const betNumbers = useMemo(() => {
    return selectedAnimal.map(item => item.numbers).join(",");
  }, [selectedAnimal]);

  useEffect(() => {
    selectedStore.selectNumbers(betNumbers);
  }, [betNumbers]);
  return <MultiPlyAnimalTableByFixedNumber selectedAnimal={selectedAnimal} />;
};
export default ThreeAnimalByFixedNumbers;
