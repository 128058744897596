export const APP_CONSTANTS = {
 TOKEN: 'token',
 ACCESS: 'access',
 EXTERNAL_GAME_ID: 'gameid',
 PARTNER_ID: 'partnerid',
 EXIT_URL: 'exiturl',
 LANGUAGE: 'language',
 LAN: 'lan',
 CURRENCY: 'currency',
 IS_CASH_DESK: 'iscashdesk',
 GAME_ID: 'gametypeid'
};

export const APP_DEFAULT_VALUES = {
 LANGUAGE_ID: '5cb5a007066b7b62b8e1371b',
 LANGUAGE: 'en',
 PARTNER_ID: 1, //127
 EXTERNAL_GAME_ID: 1,
 CURRENCY: 'USD'
};


export const InitialSkipTakeForBetHistory = [
 { key: 'Skip', value: 0 },
 { key: 'Take', value: 30 }
];
export const EBetHistoryFilter = {
 status: 'Statuses'
};
export const EBetHistoryCategoriesValues = {
 WIN: 1,
 PENDING: 0,
 LOSE: 2,
 RETURNED: 3
};

export const queryConstant = {
 orderColumnName: {
  key: 'OrderColumnName',
  value: 'id'
 },
 orderByAsc: {
  key: 'OrderByAsc',
  value: false
 }
};
export const ROUTING = {
 HOME: '/',
 BET_HISTORY: '/bet-history',
 HELP: '/help',
 LAST_RESULT: '/last-result',
 PERSONAL_INFO: '/personal-info',
 NOT_FOUND: '*'
};


export const HeaderTypes = {
 contentType: 'Content-Type',
 timeZone: 'TimeZone',
 authorization: 'Authorization',
 cacheControl: 'Cache-Control'
};
export const ContentTypes = {
 applicationJson: 'application/json',
 formData: ''
};
export const TRANSLATION_HEADERS = {
 ApplicationId: 3
};

export const HEADERS = {
 Accept: 'Accept',
 ContentType: 'Content-Type',
 Authorization: 'Authorization',
 ApplicationJson: 'application/json',
 Urlencoded: 'application/x-www-form-urlencoded',
 TimeZoneKey: 'TimeZone',
 TimeZone: Number(new Date().toString().match(/[-\+]([0-9]+)\s/)[1].match(/([0-9]{2})/g).join('.'))
};
export const timerConstants = {
 seconds: 'seconds',
 minutes: 'minutes',
 hours: 'hours',
 days: 'days'
};
export const EDateConstants = {
 endDate: 'EndDate',
 startDate: 'StartDate'
};

export const ABOUT_GAME = 'ABOUT_GAME';

export const GENERAL_GAME_INFO = {
 name: 'ABOUT_GAME',
 type: 'ABOUT_GAME',
 descriptionTitle: 'DESCRIPTION_TITLE',
 descriptionText: 'DESCRIPTION_TEXT',
 drawResult: 'DRAW_RESULT',
 drawResultText: 'DRAW_RESULT_TEXT',
 betTypesOdds: 'BET_TYPES_ODDS',
 importantTitle: 'IMPORTANT_TITLE',
 importantText: 'IMPORTANT_TEXT',
};

export const DOMAIN = 'https://jdb-api-stage.betcoapps.com';

