import { BehaviorSubject } from "rxjs";
import { doneSetter, pendingSetter } from "../../helpers/helperFunctions";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { map, switchMap } from "rxjs/operators";
import is from "is_js";
import selectedStore from "../../../state/selectedState";

const END_POINTS = {
  prefix: "Users",
  betTypeSettings: "betTypeSettings",
  settings: "settings",
  balance: "balance",
  resetPassword: "ResetPassword",
  addPlayer: "addplayer"
};

class UserService {
  userSettings = new BehaviorSubject(doneSetter([]));
  userBalance = new BehaviorSubject(doneSetter([]));

  getUserSettings = () => {
    const path = `${END_POINTS.prefix}/${END_POINTS.settings}`;
    this.userSettings.next(pendingSetter([]));
    FromFetchService.get(path)
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data)) selectedStore.setInitLoadingProgress(1);
          return data;
        })
      )
      .subscribe(async data => this.userSettings.next({ isLoading: false, data }));
  };

  getUserBalance = (fromInit) => {
    const path = `${END_POINTS.prefix}/${END_POINTS.balance}`;
    this.userBalance.next(pendingSetter([]));
    FromFetchService.get(path)
      .pipe(
        switchMap(async data => await data.json()),
        map(data => data.result),
        map(data => {
          if (!is.empty(data) && fromInit) selectedStore.setInitLoadingProgress(1);
          return data;
        })
      )
      .subscribe(async data => this.userBalance.next({ isLoading: false, data }));
  };
}
const userService = new UserService();
export default userService;
