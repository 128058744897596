import {Subject } from 'rxjs';
import is from 'is_js';
import selectedStore from './selectedState';
const subject = new Subject();

const initialState = {
 userBet : [],
 balance : 500000,
 betResults : [],
 gameFinished: false,
 betHistory : [],
 betId : 1,
 winning : 0,
 showUserBetNumber:false,
 lastBet:{}
};

let state = initialState;

const demoState = {
 init: () => {
  subject.next(state);
 },
 clearDemoState : ()=>{
  state = {
   ...initialState
  };
  subject.next(state)
 },
 setLastBet:(bet)=>{
  state={
   ...state,
   lastBet: bet
  }
  subject.next(state)
 },
 setShowUserBetNumber : (bool)=>{
  state = {
   ...state,
   showUserBetNumber: bool
  }
  subject.next(state)
 },
 setWinning:()=>{
  state={
   ...state,
   winning: 0
  };
  subject.next(state)
 },
 setBetId:()=>{
  state = {
   ...state,
   betId : state.betId+1
  };
  subject.next(state)
 },
 setGameFinished:bool=>{
  state = {
   ...state,
   gameFinished: bool,
  };
  subject.next(state)
 },
 setBetHistory: data => {
  if (data){
   const winning = data.bets.reduce((acum,current) =>acum+current.winningAmount,0);
   state = {
    ...state,
    betHistory: [data,...state.betHistory.slice(1)],
    balance: Math.round10(state.balance+winning,-2),
    betResults : data.bets,
   };
   setTimeout(()=>{
    state = {
     ...state,
     showUserBetNumber: true
    };
    subject.next(state)
   },1500);
   setTimeout(()=>{
    state = {
     ...state,
     winning : winning
    };
    selectedStore.setShowDemoWin(true)
    subject.next(state);

   },3000);
   subject.next(state);
  }
  },

 setBet : bet =>{
  const betModel =  {
   bets:[],
   gameId:0,
   gameResults:{},
   totalBetWinningAmount:0
  };
  let betHistory ;
  if (state.betId>1){
   if (is.empty(state.betHistory[0].gameResults)){
   let pendingBet = state.betHistory[0];
   pendingBet.bets.unshift(bet);
    betHistory = pendingBet;
    betHistory = [betHistory , ...state.betHistory.slice(1)]
   }else{
    betHistory = {...betModel}
    betHistory.gameId = bet.gameId;
    betHistory.bets.unshift(bet);
    betHistory = [betHistory , ...state.betHistory.slice(0)]
   }
  }else{
  let bets = {
  ...betModel
  };
  bets.gameId = bet.gameId;
  bets.bets.unshift(bet);
   betHistory = [bets , ...state.betHistory.slice(1)]
  }
  state = {
   ...state,
   userBet: [bet,...state.userBet],
   lastBet : bet,
   balance: Math.round10(state.balance-bet.stakeAmount,-2),
   betHistory: betHistory
  };
  subject.next(state)
 },
 clearUserBet:()=>{
  state = {
   ...state,
   userBet:[]
  };
  subject.next(state)
 },
 clearBetHistory:()=>{
  state = {
   ...state,
   betHistory: [],
   balance:500000,
  };
  subject.next(state)
 },

 subscribe: setState => subject.subscribe(setState),
 unsubscribe: () => subject.unsubscribe(),
 initialState,
 state
};

export default demoState;
