import React, { memo, useEffect, useMemo, useState } from "react";
import Animal from "../animal";
import usePartner from "../../hooks/servicesHooks/Partner/partnerHook";
import selectedStore from "../../state/selectedState";
import selectedStoreMobile from "../../state/selectedStateMobile";
import Loading from "../Loading/loading";

const AnimalsTable = memo(({ selectedAnimal, selectedBetType }) => {
  const [bet, setBet] = useState(selectedStore.initialState.userFavoriteBets);
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );

  useEffect(() => {
    selectedStoreMobile.setArrowIcon(false);
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    const betSubscription = selectedStore.subscribe(data =>
      setBet(data.userFavoriteBets)
    );
    selectedStore.init();

    return () => {
      betSubscription.unsubscribe();
      partnerSettings.unsubscribe();
    };
  }, []);
  const {
    animals: { data, isLoading }
  } = usePartner();

  const bettingNumbers = useMemo(() => {
    const filterBetByBetType = bet?.filter(item => item?.betTypeId === selectedBetType?.id);
    return filterBetByBetType;
  }, [bet,selectedBetType]); // eslint-disable-line react-hooks/exhaustive-deps


  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="game-container">
      <div
        className={`game-items-holder ${partnerSettings?.data?.viewMode ? "brazilian-view" : ""}`}
      >
        {data.map((animal, i) => (
          <Animal
            index={i}
            data={animal}
            key={animal.id}
            selectedAnimal={selectedAnimal}
            bettingNumbers={bettingNumbers?.[0]}
            selectedBetType={selectedBetType}
          />
        ))}
      </div>
    </div>
  );
});

export default AnimalsTable;
