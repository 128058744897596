import React, { useEffect, useState } from "react";
import { MOBILE_SIZE } from "../newCore/constants/viewSizeConstants";
import GameWeb from "../pages/game/game.Web";
import GameMobile from "../pages/game/game.Mobile";
import { useSignalR } from "../hooks/servicesHooks/SignalR/useSignalR";
import selectedStore from "../state/selectedState";
import { isFirefox } from "react-device-detect";
import GameLoading from "../components/gameLoading/gameLoading";
import { useHistory } from "react-router-dom";
import gameService from '../newCore/services/Games/games';
import Lobby from '../components/lobby/lobby';
import signalRSubscription from '../newCore/services/SignalR/signalR';
import { queryGet } from '../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../newCore/constants/constants';
import Query from '../newCore/helpers/queryBuilder';
import { calendarQueryModel } from '../newCore/constants/queryModels';
import demoState from '../state/demoModeState';
import betHistoryService from '../newCore/services/Bets/bet';
import ConnectionLostPopup from "../components/ConectionLostPopup/ConectionLostPopup";


const GameContainer = ({ path,zoom , isWeb , showPopup }) => {
  const [animationOn, setAnimationOn] = useState(!!selectedStore.initialState.animation);
  const [gameFinished , setGameFinished] = useState(demoState.initialState.gameFinished);
  const [gameResult , setGameResult] = useState(selectedStore.initialState.newGameResult)
  const [demoBets , setDemoBets] = useState(demoState.initialState.userBet)
  const urlSearchParams = new URLSearchParams(window.location.search).toString();
  const [gameTypesIds , setGameTypesIds] = useState(selectedStore.initialState.gameTypesIds)
  const [selectedGameType , setSelectedGameType] = useState(selectedStore.initialState.selectedGameType)
  const [connection , setConnection] = useState(selectedStore.initialState.connectionStart)

  const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
  const gameId= queryGet(APP_CONSTANTS.GAME_ID);

  const lastResultQuery = new Query(calendarQueryModel);

  useEffect(()=>{
    if (Array.isArray(gameTypesIds) && gameTypesIds?.length){
      let removeId = gameTypesIds.filter(item => item!==selectedGameType.id)
      for (let i=0;i<removeId.length;i++){
        signalRSubscription.removeGroup(`GameTypeCreationGroup_${removeId[i]}`).then(data => data);
      }
    }
  },[gameTypesIds]);

  useEffect(()=>{
    if (selectedGameType.id && connection){
      signalRSubscription.joinGroup(`GameTypeCreationGroup_${selectedGameType.id}`)
    }
  },[connection])


  useEffect(() => {
    const connection = selectedStore.subscribe(data => setConnection(data.connectionStart));

    const selectedGame = selectedStore.subscribe(data => setSelectedGameType(data.selectedGameType))
    const gameTypes = selectedStore.subscribe(data=>setGameTypesIds(data.gameTypesIds));
    signalRSubscription.constructor.IsLobby=false;
    const gameFinishedSubscription = demoState.subscribe(data => setGameFinished(data.gameFinished));
    const gameResult = selectedStore.subscribe(data=>setGameResult(data.newGameResult));
    const demoBets = demoState.subscribe(data => setDemoBets(data.userBet));
    gameService.getLastResult(
     lastResultQuery
      .partnerId(partnerId)
      // .skip(0)
      // .take(1)
      .gameTypeId(gameId)
      .toString()
    );
    gameService.getGames(partnerId,gameId);
    // if (signalRSubscription?.isConnected()){
    // }
    const animationOn = selectedStore.subscribe(data => setAnimationOn(!!data.animation));
    selectedStore.init();
    demoState.init();
    return () => {
      if(signalRSubscription?.isConnected()){
        signalRSubscription.removeGroup(`Group_${gameId}`).then(data=>data)
      }
      connection.unsubscribe()
      selectedStore.setDemoMode(false);
      demoState.clearDemoState();
      selectedStore.setShowHash(false);
      signalRSubscription.constructor.IsLobby=true;
      selectedGame.unsubscribe();
      gameTypes.unsubscribe();
      selectedStore.setShowBetTypeInfo(false);
      selectedStore.setDemoMode(false);
      gameFinishedSubscription.unsubscribe();
      gameResult.unsubscribe();
      demoBets.unsubscribe();
      // if(signalRSubscription?.isConnected()){
      //   signalRSubscription.removeGroup(`Group_${gameId}`).then(data=>data)
      // }
      animationOn.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(()=>{
    if (connection && signalRSubscription?.isConnected()){
      signalRSubscription.joinGroup(`Group_${gameId}`).then(data=>data)
    }
    // return ()=>{
    //   if(signalRSubscription?.isConnected()){
    //     signalRSubscription.removeGroup(`Group_${gameId}`).then(data=>data)
    //   }
    // }
  },[connection]);


    useEffect(()=>{
      if(gameFinished && demoBets.length){
        betHistoryService.getdemoBet(demoBets,gameResult)
      }

    },[gameFinished]);

  // const _orientationChange = () => {
  //   window.addEventListener(
  //     "resize",
  //     () => {
  //       _onChangeIsWeb();
  //     },
  //     false
  //   );
  //
  //   if ("onorientationchange" in window) {
  //     window.addEventListener(
  //       "orientationchange",
  //       () => {
  //         _onChangeIsWeb();
  //       },
  //       false
  //     );
  //   }
  // };

  // const _onChangeIsWeb = () => {
  //   if (state.isWeb && window.innerWidth <= MOBILE_SIZE) {
  //     setState({
  //       ...state,
  //       isWeb: false
  //     });
  //   }
  //
  //   if (!state.isWeb && window.innerWidth > MOBILE_SIZE) {
  //     setState({
  //       ...state,
  //       isWeb: true
  //     });
  //   }
  // };

  // isWeb
  return (
    <>
      {showPopup ? <ConnectionLostPopup /> : null}
      {isWeb ?
        <GameWeb
        path={path}
        zoom={zoom}
        isWeb={isWeb}
        urlSearchParams={urlSearchParams}
        animation={animationOn}
        isFirefox={isFirefox}
       />
      : (
        <GameMobile
          path={path}
          isWeb={isWeb}
          urlSearchParams={urlSearchParams}
          animation={animationOn}
        />
      )}
    </>
  );
};
export default GameContainer;
