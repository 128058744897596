function createQuery(queryFields) { // TODO change this function
    // when queryFields is undefined
    if (!queryFields) {
        return '';
    }
    // when queryFields is string or number
    if (typeof queryFields === 'string' || typeof queryFields === 'number') {
        return `?${queryFields}`;
    }
    // when queryFields is array
    if (Array.isArray(queryFields)) {
        let query = '?';
        queryFields.forEach((queryField, index) => {
            if (index) {
                query += `&${queryField.key}=${queryField.value}`;
            } else {
                query += `${queryField.key}=${queryField.value}`;
            }
        });
        return query;
    }
    // when queryFields is object
    if (Object.keys(queryFields).length) {
        let searchQuery = '';
        for (const key in queryFields) {
            if (Array.isArray(queryFields[key])) {
                // eslint-disable-next-line no-loop-func
                queryFields[key].forEach((value) => {
                    searchQuery = `${searchQuery}&${key}=${value}`;
                });
            }
            /* check if query object prop is object too */
            else if (typeof queryFields[key] === 'object') {
                searchQuery = `${searchQuery}&${encodeObject(queryFields[key])}`;
            } else {
                searchQuery = `${searchQuery}&${key}=${queryFields[key]}`;
            }
        }
        return `?${searchQuery.substring(1)}`;
    }
    return '';
}
function encodeObject(obj) {
    let str = '';
    Object.entries(obj).forEach(([key, value]) => {
        if (value instanceof Object) {
            str += `${key}=${encodeURIComponent(JSON.stringify(value))}&`;
        } else {
            str += `${key}=${value}&`;
        }
    });
    if (str[str.length - 1] === '&') {
        str = str.slice(0, str.length - 1);
    }
    return str;
}
export default createQuery;
