import React from 'react';
import selectedStore from '../../state/selectedState';

const NumpadForTerminal = ({setIsFiltersChanged,inputRef,setSearchValue}) => {
 const handleSelectNumberFromNumPad = (number) => {
  selectedStore.clearBetHistory();
  setIsFiltersChanged(true);
  setSearchValue(prev => prev + number);
 };
 const clearSearchIdFromNumPad = () => {
  setSearchValue('');
 };
 return (
  <div className={`numpad`} ref={inputRef}>
   <span onClick={()=>handleSelectNumberFromNumPad(1)}>1</span>
   <span onClick={()=>handleSelectNumberFromNumPad(2)}>2</span>
   <span onClick={()=>handleSelectNumberFromNumPad(3)}>3</span>
   <span onClick={()=>handleSelectNumberFromNumPad(4)}>4</span>
   <span onClick={()=>handleSelectNumberFromNumPad(5)}>5</span>
   <span onClick={clearSearchIdFromNumPad} className="full">c</span>
   <span onClick={()=>handleSelectNumberFromNumPad(6)}>6</span>
   <span onClick={()=>handleSelectNumberFromNumPad(7)}>7</span>
   <span onClick={()=>handleSelectNumberFromNumPad(8)}>8</span>
   <span onClick={()=>handleSelectNumberFromNumPad(9)}>9</span>
   <span onClick={()=>handleSelectNumberFromNumPad(0)}>0</span>
   {/*<span>.</span>*/}
  </div>
 );
};

export default NumpadForTerminal