import React, {memo} from "react";
import ChooseTwoAnimal from "./TwoAnimal/ChooseTwoAnimal";
import ChooseThreeAnimal from "./ThreeAnimal/chooseThreeAnimal";

const MultiplyAnimal = memo(({ selectedBetType , selectedAnimal }) => {

  return (
    <>
      {selectedBetType.animalsCount === 2 ? (
        <ChooseTwoAnimal animalsCount={selectedBetType.animalsCount}  selectedAnimal={selectedAnimal} selectedBetType={selectedBetType}/>
      ) : (
        <ChooseThreeAnimal animalsCount={selectedBetType.animalsCount} selectedAnimal={selectedAnimal} selectedBetType={selectedBetType}/>
      )}
    </>
  );
});
export default MultiplyAnimal;
