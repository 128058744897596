import React, {memo, useEffect, useMemo} from "react";
import selectedStore from "../../../../state/selectedState";
import MultiPlyAnimalTableByFixedNumber from "../multiPlyAnimalTableByFixedNumber";

const TwoAnimalByFixedNumbers = memo(({ selectedAnimal }) => {
  const betNumbers = useMemo(() => {
    return selectedAnimal.map(item => item.numbers).join(",");
  }, [selectedAnimal]);

    useEffect(() => {
        selectedStore.selectNumbers(betNumbers);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
   <MultiPlyAnimalTableByFixedNumber selectedAnimal={selectedAnimal}/>
  );
});
export default TwoAnimalByFixedNumbers;
