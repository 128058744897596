import { useCallback } from 'react';

const regex = /^([0]{1}|([1-9]{1}\d*))(?:\.\d{0,2})?$/;

export function useAmountValidation(setBetAmount, limits) {
  const onBlur = useCallback(
    (event) => {
      let {
        target: { value },
      } = event;
      if (regex.test(value) || value === '' || +value === 0) {
        if (value.endsWith('.')) {
          value = value.slice(0, -1);
          if (Number(value) < limits?.minBet) {
            value = limits?.minBet;
          } else {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
      return setBetAmount(value);
    },
    [ setBetAmount, limits ],
  );

  const onChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
     const currencyFormat2 = /[^\d.]/g;//
     const val = Number(value.replace(currencyFormat2, ''));
      if (regex.test(val)) {
        return setBetAmount(val);
      }
      if (val === '') {
        return setBetAmount(val);
      }
    },
    [ setBetAmount ],
  );
  return { onChange, onBlur };
}
