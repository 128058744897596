import { Subject } from "rxjs";

const subject = new Subject();

const initialState = {
  selectedLastResult: {},
  selectedBetHistory: {},
  selectedBetTypeHelpInfo: {},
  showBetType: false,
  showTitle: true,
  arrowIcon: false,
  showUserBetNumber: false,
  hasUnseenBetsMobile: false
};

let state = initialState;

const selectedStoreMobile = {
  init: () => {
    subject.next(state);
  },
  subscribe: setState => subject.subscribe(setState),
  setHasUnseenBetsMobile: bool => {
    state = {
      ...state,
      hasUnseenBetsMobile: bool
    };
    subject.next(state);
  },
  setShowUserBetNumber: bool => {
    state = {
      ...state,
      showUserBetNumber: bool
    };
    subject.next(state);
  },
  setShowTitle: bool => {
    state = {
      ...state,
      showTitle: bool
    };
    subject.next(state);
  },
  setArrowIcon: bool => {
    state = {
      ...state,
      arrowIcon: bool
    };
    subject.next(state);
  },
  selectLastResult: selected => {
    state = {
      ...state,
      selectedLastResult: selected
    };
    subject.next(state);
  },
  selectBetHistory: selected => {
    state = {
      ...state,
      selectedBetHistory: selected
    };
    subject.next(state);
  },
  setShowBetType: bool => {
    state = {
      ...state,
      showBetType: bool
    };
    subject.next(state);
  },
  selectBetTypeHelpInfo: selected => {
    state = {
      ...state,
      selectedBetTypeHelpInfo: selected
    };
    subject.next(state);
  },
  clearSelectState: () => {
    state = {
      ...initialState
    };
    subject.next(state);
  },

  initialState,
  state
};

export default selectedStoreMobile;
