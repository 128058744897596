import React, { useEffect, useState } from "react";
import selectedStore from "../../state/selectedState";
import { EBetHistoryStatus } from "../../newCore/constants/enums";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import LastResultWeb from "../last-result/last-result.Web";
import selectedStoreMobile from "../../state/selectedStateMobile";
import useTranslation from "../../newCore/helpers/useTranslation";

const GameBetHistoryMobile = ({ selectedBetHistory, isFromBetHistory , demoMode , demoBet , setRepeatedBet , showPopUp}) => {
  const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
  const [betTypes, setBetTypes] = useState(selectedStore.initialState.partnerBetTypes);
  const [historyItemResult, setHistoryItemResult] = useState(
    selectedStore.initialState.selectedBetHistoryResult
  );
  const t = useTranslation();
  useEffect(() => {
    const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
    const partnerBetTypes = selectedStore.subscribe(data => setBetTypes(data.partnerBetTypes));
    const result = selectedStore.subscribe(data =>
      setHistoryItemResult(data.selectedBetHistoryResult)
    );
    selectedStore.init();
    return () => {
      partnerBetTypes.unsubscribe();
      userCurrency.unsubscribe();
      result.unsubscribe();
    };
  }, []);

  const handleClickShowBetTypeInfo = () => {
    const betTypeInfo = betTypes.find(bt => bt?.type === selectedBetHistory?.betType);
    selectedStoreMobile.selectBetTypeHelpInfo(betTypeInfo);
    selectedStore.selectBetType(betTypeInfo);
    selectedStore.setShowBetTypeInfo(true);
  };

  return (
    <>
      <div className="bet-history-m-holder">
        <div className="bet-history-m-head">
          <i className="icon-arrow_back" onClick={() => selectedStoreMobile.selectBetHistory({})} />
          <span>
            {t("ID")} {selectedBetHistory.id}
          </span>
          <i className="icon-reset rebet-icon" onClick={()=>{
            if (!showPopUp){
              selectedStore.setRebetPopUp(true);
              selectedStore.setDoBet(false);
              setRepeatedBet(selectedBetHistory)
            }else{
              demoBet(selectedBetHistory)
            }
          }}/>
        </div>
        <div className="bet-history-m-content">
          <div className="bet-history-m-c-inner game">
            <div className="bet-history-h-row">
              <span className={`bet-history-status status-${selectedBetHistory?.result}`}>
                {t(EBetHistoryStatus[selectedBetHistory?.result])}
              </span>
              <div className="ch-title-cnt" onClick={() => handleClickShowBetTypeInfo()}>
                <h3 className="ch-title-info">{t(selectedBetHistory?.betTypeName)}</h3>
                <span className="icon-info" />
              </div>

              <div className="bet-history-count-info">{selectedBetHistory?.number}</div>
            </div>
            <div className="bet-history-h-row">
              {/*{selectedBetHistory?.gameResult?.first && <h4 className="win-text">X{selectedBetHistory?.odds?.[0]} {t('BET_AMOUNT')}</h4>}*/}
              <h4 className={`win-text `}>
                {t("BET_SUM")}{" "}
                {demoMode ? `${selectedBetHistory?.stakeAmount* selectedBetHistory?.odds?.[0]} Fun`: toCurrency(selectedBetHistory?.stakeAmount, userCurrency?.[0]?.name)} X{" "}
                {selectedBetHistory?.odds?.[0]}
              </h4>
              <h5 className="money-v">
                {demoMode ? `${selectedBetHistory?.winningAmount ? selectedBetHistory?.winningAmount : '0 '}Fun` : toCurrency(selectedBetHistory?.winningAmount, userCurrency?.[0]?.name)}
              </h5>
            </div>
            <div className="bet-history-h-row">
              <div className="game-results-row">
                <div className="game-results-content">
                  <LastResultWeb
                    selectedBetHistory={selectedBetHistory}
                    betHistoryResult={historyItemResult}
                    isFromBetHistory={isFromBetHistory}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameBetHistoryMobile;
