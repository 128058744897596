import { fromFetch } from "rxjs/fetch";
import buildHeaders from "../../helpers/buildHeaders";
import HttpMethods from "../../../newCore/constants/httpMethods";
import ContentTypes from "../../../newCore/constants/contestTypes";
import createQuery from "../../../newCore/helpers/createQuery";
import { catchError, map } from "rxjs/operators";
import { DOMAIN, TRANSLATION_API } from "../../constants/environment";
import { of } from "rxjs";
import { PureComponent } from "react";
import selectedStore from "../../../state/selectedState";

class FromFetchService extends PureComponent {
  static DOMAIN = DOMAIN;
  static DOMAIN_API = this.DOMAIN + "/api";
  static TRANSLATION_API = TRANSLATION_API;
  static TOKEN = "";

  static get(url, queryData) {
    return this.request(`${url}${createQuery(queryData)}`);
  }

  static getTranslation(url, queryData, contentType) {
    const path = `${this.TRANSLATION_API}/${url}`;
    const headers = buildHeaders(contentType, true, this.TOKEN);

    const data$ = fromFetch(`${path}/${createQuery(queryData)}`, {
      headers: { ...headers, accept: "application/json", applicationid: 3 }
    }).pipe(catchError(of));
    return data$;
  }

  static post(url, data, contentType = ContentTypes.applicationJson, signal) {
    return this.request(`${url}`, HttpMethods.POST, data, contentType, signal);
  }

  static request(url, method, data, contentType, signal) {
    const headers = buildHeaders(contentType, true, this.TOKEN);
    const path = `${this.DOMAIN_API}/${url}`;
    const body = method !== HttpMethods.GET ? JSON.stringify(data) : null;
    const data$ = fromFetch(path, {
      method,
      headers,
      body,
      signal
    }).pipe(
      map(e => {
        if (!e.ok) {
          selectedStore.setConnectionLost(true);
        }
        return e;
      }),

      catchError(of)
    );
    return data$;
  }
  // static async request( url , method , data , contentType , signal){
  //     const headers = buildHeaders(contentType, true, this.TOKEN);
  //     const body = method !== HttpMethods.GET ? JSON.stringify(data) : null;
  //     const response = await this.uniqueRequest(url, method, headers, body, signal);
  //     const result = await response.json();
  //     return result;
  // }
  //

  // static async uniqueRequest(url  , method , headers , body , signal){
  //     const response = await fromFetch(`${this.DOMAIN_API}/${url}`, {
  //         method,
  //         headers,
  //         body,
  //         signal,
  //         credentials: INCLUDE_CREDENTIALS
  //     });
  //     // return errorHandler(response);
  //     return response
  // }
  // static get(url , queryData , contentType = ContentTypes.applicationJson , signal ){
  //     return this.request(`${url}/${createQuery(queryData)}`, HttpMethods.GET , null , contentType , signal);
  // }
  //
  // static post(url , data , contentType = ContentTypes.applicationJson , signal){
  //     return this.request(`${url}`, HttpMethods.POST, data, contentType, signal);
  // }
  //
  // static put(url , data , apiVersion = ApiVersions.V1, contentType = ContentTypes.applicationJson , signal){
  //     return this.request(`${apiVersion}/${url}`, HttpMethods.PUT, data, contentType, signal);
  // }
  //
  // static delete(url , data , apiVersion = ApiVersions.V1, contentType = ContentTypes.applicationJson , signal){
  //     return this.request(`${apiVersion}/${url}`, HttpMethods.DELETE, data, contentType, signal);
  // }
  //
}

export default FromFetchService;
