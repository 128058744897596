import React from "react";
import NavBar from "../../../components/navigation/NavBar";
import LeftContentMain from "../../../components/gameLeftContentMain";
import BetHistoryLeftContent from "../../../components/bet-history/betHistoryLeftContent";
import HelpLeftContent from "../../../components/help/helpLeftContent";
import LastResultsLeftContent from "../../../components/last-result/left-content";
import BetHistoryDemo from '../../../components/demoMode/betHistoryDemo';

const LeftContent = ({ path, urlSearchParams , demoMode }) => {

  const currentTimeString = new Date();
  return (
    <div className="left-col-b">
      <div className="grid-wrap-b">
        <div className="l-col-grid">
          <NavBar path={path} urlSearchParams={urlSearchParams} demoMode={demoMode}/>
          <div className="tab-content-row">
            <div className="scroll-b-holder">
              {path === "game" ? <LeftContentMain path={path}  /> : null}
              {path === "betHistory" ? demoMode ? <BetHistoryDemo/> : <BetHistoryLeftContent /> : null}
              {path === "lastResult" ? <LastResultsLeftContent curr={currentTimeString} /> : null}
              {path === "help" ? <HelpLeftContent /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftContent;
