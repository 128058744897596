import React from "react";

const BetOddButton = ({ odd }) => {
  const oddS =
    odd?.length > 1
      ? odd
          .sort((a, b) => a - b)
          .filter((item, index) => index === 0 || index === 3)
          .join("-")
      : odd;
  return (
    <button className="type-btn" >
      <span>X{oddS}</span>
    </button>
  );
};

export default BetOddButton;
