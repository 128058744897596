import React, { useEffect, useRef, useState } from 'react';
import ResultImg from "../../assets/images/game-result.png";
import LastResultWeb from '../last-result/last-result.Web';
import selectedStoreMobile from '../../state/selectedStateMobile';
import useTranslation from "../../newCore/helpers/useTranslation";
import selectedStore from '../../state/selectedState';
import HashCheckerMobile from '../hash/HashMobile';
import NumpadForHashMobile from '../numPad/numPadForHash';
import { useOutsideClick } from '../../newCore/helpers/useClickOutside';



const  GameResultMobile = (({result , showHash}) => {
  const [showNumpad , setShowNumpad] = useState(selectedStore.initialState.showNumPadForHash);
  const [checkInput , setCheckinput] = useState('')
  const numpadRef = useRef();
   useEffect(()=>{
    const showNumpad = selectedStore.subscribe(data => setShowNumpad(data.showNumPadForHash));
    selectedStore.init();
    return ()=>{
     showNumpad.unsubscribe();
     selectedStoreMobile.selectLastResult({})
    }
   },[]);
    const t = useTranslation();

  useOutsideClick(numpadRef,()=>{
   if (showNumpad){
    selectedStore.setShowNumPadForHash(false)
   }
  });

 return (
  <div className={`game-results-container game ${showHash ? 'hash-page':''}`}>

   <div className="game-results-row head">
    <div className="bet-history-m-head">
     <i className="icon-arrow_back" onClick={()=>{
      selectedStoreMobile.selectLastResult({});
      selectedStore.setShowHash(false)
     }}/>
     <span>{t('ID')} {' '} {result?.gameId}</span>
     <i className="hash-icon" onClick={()=>selectedStore.setShowHash(true)}/>
    </div>
    {
     showHash && <HashCheckerMobile t={t}  result={result} setCheckinput={setCheckinput}/>
    }
    {! showHash &&  <div className="game-results-r-inner">
     <img className="game-results-img" src={ResultImg} alt="" />
     <p className="game-results-text">{t('ID')} {result?.gameId}</p>
    </div>
    }

   </div>
   <div className="game-results-row">
    <div className="game-results-content">
     <LastResultWeb selectedLastResult={result} isFromLastResult />
    </div>
   </div>
   <NumpadForHashMobile numpadRef={numpadRef} showNumpad={showNumpad}  checkInput={checkInput}/>
  </div>

 );
});

export default GameResultMobile;
