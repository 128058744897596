import React, { memo, useEffect, useState } from 'react';
import ResultRowWeb from "./result-row.Web";
import { resultRows } from "../../newCore/helpers/resultRows";
import selectedStore from '../../state/selectedState';
import is from 'is_js';

const LastResultWeb = memo(({
  selectedLastResult,
  betHistoryResult,
  selectedBetHistory,
  isFromBetHistory,
  isFromLastResult
}) => {
  const rows = resultRows(betHistoryResult ? betHistoryResult : selectedLastResult);
  const [animation , setAnimation] = useState(selectedStore.initialState.animation)

 useEffect(()=>{
  const animation = selectedStore.subscribe(data => setAnimation(data.animation))
  return ()=>{
   animation.unsubscribe()
  }
 },[])

  return (
    <div className="last-result-container">
      <div className="last-r-title-time">
          <p className="res-b-title">
            {selectedLastResult
              ? is.empty(selectedLastResult) ? "" : new Date(selectedLastResult.endDate).toLocaleDateString()
              : new Date(selectedBetHistory?.date).toLocaleDateString()}
          </p>
        <p>
         {selectedLastResult
          ? is.empty(selectedLastResult) ? "" : new Date(selectedLastResult.endDate).toLocaleTimeString()
          : new Date(selectedBetHistory?.date).toLocaleTimeString()}
        </p>
      </div>

      <div className="last-r-list-holder">
        {rows.map((item, index) => (
          <ResultRowWeb
            index={index}
            item={item}
            key={index}
            selectedBetHistory={selectedBetHistory}
            isFromBetHistory={isFromBetHistory}
            isFromLastResult={isFromLastResult}
            animation={animation}
          />
        ))}
      </div>
    </div>
  );
});

export default LastResultWeb;
