import React, { useEffect, useState } from "react";
import BetOddButton from "../bet-odd-button";
import selectedStore from "../../state/selectedState";
import selectedStoreMobile from "../../state/selectedStateMobile";

const BetType = ({ betType, t }) => {
  const [selectedBetType, setSelectedBetType] = useState(selectedStore.state.selectedBetType);

  useEffect(() => {
    const betTypeSubscription = selectedStore.subscribe(setSelectedBetType);
    selectedStore.init();
    return () => {
      betTypeSubscription.unsubscribe();
    };
  }, []);

  const handleClickShowBetTypeInfo = betType => {
    selectedStore.selectBetType(betType);
    selectedStore.setShowBetTypeInfo(true);
    selectedStoreMobile.setShowBetType(false);
    selectedStoreMobile.selectBetTypeHelpInfo(betType);
  };

  const handleSelectBetType = () => {
    selectedStore.selectBetType(betType);
    selectedStore.setShowBetTypeInfo(false);
    selectedStoreMobile.setShowBetType(false);
  };
  return (
    <div
      className={`type-grid ${
        betType?.type === selectedBetType?.selectedBetType?.type ? "active" : ""
      }`}
      style={{ pointerEvents: "auto" }}
    >
      <div className="n-type-holder" onClick={handleSelectBetType}>
        <div className="type-name-box">
          <h4 className="user-n" title={t(betType?.name)}>
            {t(betType?.name)}
          </h4>
          <BetOddButton odd={betType?.odds} />
        </div>
      </div>
      <div
        className="info-i-holder"
        style={{ pointerEvents: "auto" }}
        onClick={() => {
          handleClickShowBetTypeInfo(betType)
        }}
      >
        <span className="icon-info" />
      </div>
    </div>
  );
};

export default BetType;
