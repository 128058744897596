import React, { useEffect, useState } from 'react';
import selectedStore from '../../state/selectedState';
import { DOMAIN } from '../../newCore/constants/environment';
import selectedStoreMobile from '../../state/selectedStateMobile';
import useTranslation from '../../newCore/helpers/useTranslation';
import * as brazilHelpImages from './BrazilianHelpImages';
import AboutGameInfo from '../aboutGameInfo';
import { ABOUT_GAME } from '../../newCore/constants/constants';

const GameHelpMobile = ({ helpBetType }) => {
 const t = useTranslation();

 const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );
 const [betTypes, setBetTypes] = useState(selectedStore.initialState.partnerBetTypes);

 useEffect(() => {
  const partnerSettings = selectedStore.subscribe(data =>
   setPartnerSettings(data.partnerSettings)
  );
  const betTypesSub = selectedStore.subscribe(data => setBetTypes(data.partnerBetTypes));
  selectedStore.init();
  return () => {
   partnerSettings.unsubscribe();
   betTypesSub.unsubscribe();
  };
 }, []);

 const src = partnerSettings?.data?.viewMode === 0 ? `${DOMAIN}/${helpBetType?.imageUrl}` : brazilHelpImages[helpBetType?.betTypeName];

 useEffect(() => {
  selectedStore.setShowBetTypeInfo(true);
  return () => {
   selectedStore.setShowBetTypeInfo(false);
  };
 }, []);
 return (
  <>
   {
    helpBetType.type === ABOUT_GAME ? <div className="game-help-new-section game">
     <p className="help-h-title">
      <i
       className="icon-arrow_back"
       onClick={() => {
        selectedStoreMobile.selectBetTypeHelpInfo({});
        selectedStore.setShowBetTypeInfo(false);
       }}
      />
      <span>{t(helpBetType?.name)}</span>
      <i/>
     </p>
     <AboutGameInfo t={t} betTypes={betTypes} aboutGame={helpBetType}/>
    </div> : <div className="game-help-container game">
     <div className="game-help-row">
      <p className="help-h-title">
       <i
        className="icon-arrow_back"
        onClick={() => {
         selectedStoreMobile.selectBetTypeHelpInfo({});
         selectedStore.setShowBetTypeInfo(false);
        }}
       />
       <span>{t(helpBetType?.betTypeName)}</span>
       <i/>
      </p>
      <div className="game-h-inner">
       <div className="game-help-r-empty">
        <img
         style={{ width: '100%' }}
         src={src}
         alt="animal"
        />
       </div>
      </div>
     </div>
     <div className="game-help-row">
      <div className="game-help-r-info">
       {/*<h3 className="game-help-r-i-title">{t(helpBetType[0]?.betTypeName)}</h3>*/}
       <p className="game-help-r-i-text">{t(helpBetType?.descriptions?.[0])}</p>
      </div>
     </div>
    </div>
   }
  </>

 );
};

export default GameHelpMobile;
