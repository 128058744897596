import React, { memo, useEffect, useState } from "react";
import SingleNumberWeb from "../SingleAnimal/single-number.Web";
import { DOMAIN } from "../../../newCore/constants/environment";
import useTranslation from "../../../newCore/helpers/useTranslation";
import selectedStore from "../../../state/selectedState";

const MultiPlyAnimalTable = memo(
  ({ selectedAnimal, selectedNumberGroup, setSelectedNumberGroup }) => {
    const [partnerSettings, setPartnerSettings] = useState(
      selectedStore.initialState.partnerSettings
    );
    const [brazilImagesCircle, setBrazilImagesCircle] = useState(
      selectedStore.initialState.brazilImages
    );
    useEffect(() => {
      const partnerSettings = selectedStore.subscribe(data =>
        setPartnerSettings(data.partnerSettings)
      );
      const brazilImages = selectedStore.subscribe(data =>
        setBrazilImagesCircle(data.brazilImages)
      );

      selectedStore.init();
      return () => {
        partnerSettings.unsubscribe();
        brazilImages.unsubscribe();
      };
    }, []);
    const t = useTranslation();
    return (
      <div className="ch-numbers-cnt">
        <p className="numbers-ch-title">
          {t("CHOOSE")} {t("NUMBER")}
        </p>
        <br />
        {selectedAnimal.map((item, index) => (
          <div
            className={`ch-numbers-box ${
              index !== 0 && !selectedNumberGroup[index - 1] ? "ch-numbers-box-active" : ""
            }`}
          >
            <SingleNumberWeb isImg>
              <img
                className="ch-numbers-b-img"
                src={
                  partnerSettings?.data?.viewMode === 0
                    ? `${DOMAIN}/${item.animalCirclePicUrl}`
                    : `${brazilImagesCircle[`a${item.sortNumber}`]}`
                }
                alt=""
              />
            </SingleNumberWeb>
            {item.numbers.split(",").map(number => (
              <SingleNumberWeb
                selectedNumber={selectedNumberGroup[index]}
                setSelectedNumber={setSelectedNumberGroup[index]}
              >
                {number}
              </SingleNumberWeb>
            ))}
          </div>
        ))}
      </div>
    );
  }
);
export default MultiPlyAnimalTable;
