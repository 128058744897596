import React, { useEffect, useMemo, useState } from 'react';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../../newCore/constants/constants';
import Query from '../../../newCore/helpers/queryBuilder';
import { topWinningModel } from '../../../newCore/constants/queryModels';
import selectedStore from '../../../state/selectedState';
import partnerService from '../../../newCore/services/Partner/partner';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingInline from '../../Loading/loadingInline';
import TopWinnersList from '../../player-statistic/live-bet-statistic';
import { toCurrency } from '../../../newCore/helpers/betActionHelpers';

const TopWinnersLobby = ({ userCurrency, partnerCurrency }) => {
 const [skip, setSkip] = useState(0);
 const [take] = useState(30);
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
 let topWinnersQuery = new Query(topWinningModel);

 const [partnerTopWinners, setPartnerTopWinners] = useState([]);
 const [partnerBetTypes, setPartnerBetTypes] = useState(
  selectedStore.initialState.partnerBetTypes
 );
 const [topWinnersCount, setTopWinnersCount] = useState(
  selectedStore.initialState.partnerTopWinnersCount
 );

 useEffect(() => {
  const partnerBetTypes = selectedStore.subscribe(data =>
   setPartnerBetTypes(data.partnerBetTypes)
  );
  const partnerWinning = selectedStore.subscribe(data =>
   setPartnerTopWinners(data.partnerTopWinners)
  );
  const topWinnersCountSubscription = selectedStore.subscribe(data =>
   setTopWinnersCount(data.partnerTopWinnersCount)
  );
  selectedStore.init();
  return () => {
   selectedStore.clearTopWinners();
   partnerWinning.unsubscribe();
   partnerBetTypes.unsubscribe();
   topWinnersCountSubscription.unsubscribe();
  };
 }, []);

 useEffect(() => {
  partnerService.getPartnerTopWinning(
   partnerId,
   topWinnersQuery
    .skip(skip)
    .take(take)
    .toString()
  );
 }, [skip]); // eslint-disable-line react-hooks/exhaustive-deps

 const fetchMoreData = () => {
  setSkip(skip + 30);
 };

 const partnerTopWinnersByBetType = useMemo(() => {
  const partnerBetTypesId = partnerBetTypes.map(item => item.id);
  return partnerTopWinners?.filter(item => partnerBetTypesId?.includes(item.betTypeId));
 }, [partnerTopWinners, partnerBetTypes]);





 return (
  <>
   <InfiniteScroll
    dataLength={partnerTopWinnersByBetType.length}
    next={fetchMoreData}
    hasMore={partnerTopWinnersByBetType.length <= topWinnersCount}
    loader={
      <LoadingInline/>
    }
    scrollableTarget="scroll-container-top-win"
   >
    {!!partnerTopWinnersByBetType?.length &&
    partnerTopWinnersByBetType.map((data, index) => (
     <div className="left-col-g-row" key={index}>
      <div className="left-col-g-row-in"><p
       className="left-col-g-row-in-p">{`${data.userId}`?.[0]}***{`${data.userId}`?.[`${data.userId}`?.length - 1]}</p>
      </div>
      <div className="left-col-g-row-in"><p className="left-col-g-row-in-p center">  {
       toCurrency(Math.round10(data?.stakeAmount, -2),userCurrency?.[0]?.name || partnerCurrency)
      }</p></div>
      <div className="left-col-g-row-in"><p className="left-col-g-row-in-p right">
       {
        toCurrency(Math.round10(data.winningAmount, -2),userCurrency?.[0]?.name || partnerCurrency)
       }
      </p></div>
     </div>)
    )
    }
   </InfiniteScroll>
  </>
 );
};
export default TopWinnersLobby;
