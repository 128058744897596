import React from "react";

const LoadingInline = (balance) => {
  return (
    <div key="" className={`loading-cnt ${balance ? "inline" : "inline"}`}>
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default LoadingInline;
