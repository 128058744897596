import React, { memo, useEffect, useState } from "react";
import { DOMAIN } from "../../../../newCore/constants/environment";
import SingleNumberWeb from "../single-number.Web";
import selectedStore from "../../../../state/selectedState";
import * as brazilMainImages from "../../../ulits/brazilMainAnimals";

const ChooseFourNumber = memo(({ selectedAnimal }) => {
  useEffect(() => {
    selectedStore.selectNumbers(selectedAnimal.numbers);
  }, [selectedAnimal.numbers]);
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    selectedStore.init();
    return () => {
      partnerSettings.unsubscribe();
    };
  }, []);
  const src =
    partnerSettings?.data?.viewMode === 0
      ? `${DOMAIN}/${selectedAnimal.animalPicUrl}`
      : brazilMainImages[`a${selectedAnimal?.sortNumber}`];

  return (
    <div className="ch-numbers-b ch-numbers-b-fixed">
      <img className="ch-numbers-img" src={src} alt="" />
      {/*<p className="numbers-ch-title">Choose Number</p>*/}
      <div className="ch-numbers-box">
        {selectedAnimal.numbers?.split(",").map((item, index) => (
          <SingleNumberWeb key={index} active>
            {item}
          </SingleNumberWeb>
        ))}
      </div>
    </div>
  );
});

export default ChooseFourNumber;
