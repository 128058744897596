import React, { useEffect, useMemo, useState } from 'react';
import ResultImg from "../../assets/images/game-result.png";
import LastResultWeb from "../../components/last-result/last-result.Web";
import useTranslation from "../../newCore/helpers/useTranslation";
import GameResultSkeleton from './gameResultSkeleton';
import { ReactComponent as ReactLogo } from '../../assets/images/ets.svg';
import selectedStore from '../../state/selectedState';


const GameResult = ({ result}) => {
const gameResult = useMemo(()=>{
    return result?.data?.gameResults?.[0]
        ? result?.data?.gameResults?.[0]
        : result
},[result]);
 const [gameResultsCount, setGameResultsCount] = useState(
  selectedStore.initialState.allGameResultsCount
 );
useEffect(()=>{
 const resultCount = selectedStore.subscribe(data=>setGameResultsCount(data.allGameResultsCount))
 return ()=>{
  resultCount.unsubscribe()
 }
},[]);
  const t = useTranslation();


if (gameResultsCount===0){
    return<div className="bet-history-holder">
        <div className="img-bet-history-wrapper">
         <ReactLogo className="img-bet-history"/>
        </div>
    </div>
}
  return (
   <div className="game-results-container">
    <i className="hash-icon web" onClick={()=>selectedStore.setShowHash(true)}/>
    {
     gameResultsCount===null? <GameResultSkeleton/> :
      <>
      <div className="game-results-row">
       <img className="game-results-img" src={ResultImg} alt=""/>
       <p className={`game-results-text ${gameResult?.gameId ? '' : 'skeleton'}`}>{t('ID')} {gameResult?.gameId}</p>
      </div>
      <div className="game-results-row">
     <div className="game-results-content">
     <LastResultWeb selectedLastResult={gameResult} isFromLastResult={true}/>
     </div>
     </div>
    </>
    }
   </div>
  );
};

export default GameResult;
