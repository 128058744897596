import React, { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import '../../assets/styles/date-picker.css';
import Query from '../../newCore/helpers/queryBuilder';
import { calendarQueryModel } from '../../newCore/constants/queryModels';
import { queryGet } from '../../newCore/helpers/helperFunctions';
import selectedStore from '../../state/selectedState';
import LastResulList from './last-result-list';
import { isFirefox } from 'react-device-detect';

import {useOutsideClick } from '../../newCore/helpers/useClickOutside';
import useTranslation from '../../newCore/helpers/useTranslation';
import Loading from '../Loading/loading';
import useAnimals from '../../hooks/servicesHooks/Animals/Animals';
import { APP_CONSTANTS } from '../../newCore/constants/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import gameService from '../../newCore/services/Games/games';
import LoadingInline from '../Loading/loadingInline';
import NumpadForTerminal from '../numPad/numpadForTerminal';

const currentTimeString = new Date().toLocaleTimeString('en-GB', {
 hour12: false
});


const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

const sevenDaysBefore = new Date(today);
sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);

const LastResultsLeftContent = ({ curr }) => {
 const { animals } = useAnimals();
 const [isShowCalendar, setIsShowCalendar] = useState(false);
 const [startDate, setStartDate] = useState();
 const [endDate, setEndDate] = useState();
 const [showSearch, setShowSearch] = useState(selectedStore.initialState.showSearchinLastResult);
 const [skip, setSkip] = useState(0);
 const [take, setTake] = useState(30);
 const [searchValue, setSearchValue] = useState('');
 const [time, setTime] = useState({
  start: '00:00:00',
  end: curr.toLocaleTimeString("en-GB")
 });

  const inputRef = useRef([]);
  // inputRef.current = new Array(2);

 const startTimeInput = useRef();
 const endTimeInput = useRef();
 const lastResultQuery = new Query(calendarQueryModel);
 const isCashDesk = queryGet(APP_CONSTANTS.IS_CASH_DESK) === 'true';

 // const {
 //   allGameResults: { isLoading, data },
 //   getAllGameResults
 // } = useLastResult();

 const [lastResult, setLastResult] = useState(selectedStore.initialState.allGamesResult);
 const [gameResultsCount, setGameResultsCount] = useState(
  selectedStore.initialState.allGameResultsCount
 );
 const [animation , setAnimation] = useState(selectedStore.initialState.animation)

 const [selectedLastResult, setSelectedLastResult] = useState(
  selectedStore.initialState.gameResult
 );
 const [isFiltersChanged, setIsFiltersChanged] = useState(false);
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
 const gameTypeId = queryGet(APP_CONSTANTS.GAME_ID)
 const t = useTranslation();

 const handleChangeTime = e => {
  selectedStore.clearGameResult();

  setIsFiltersChanged(true);
  setTime({ ...time, [e.target.name]: e.target.value });
 };
 const onChange = dates => {
  selectedStore.clearGameResult();
  setIsFiltersChanged(true);
  const [start, end] = dates;
  setStartDate(start);
  setEndDate(end);
  setTake(30);
 };
 // const handleScroll = () => {
 //   setSkip(skip + take);
 // };

 const handleSearch = e => {
  const reg = /^[0-9\b]+$/;
  if (
   e.target.value === '' ||
   inputRef.current.value === '' ||
   reg.test(e.target.value) ||
   reg.test(inputRef.current.value)
  ) {
   selectedStore.clearGameResult();
   setIsFiltersChanged(true);
   setSearchValue(e.target.value);
  }
 };
 const resetFilers = () => {
  setIsFiltersChanged(false);
  setTime({
   start: '00:00:00',
   end: currentTimeString
  });
  setStartDate(new Date());
  setEndDate(tomorrow);
  setSearchValue('');
  startTimeInput.current.value = '00:00:00';
  endTimeInput.current.value = currentTimeString;
 };

 // useEffect(() => {
 //   if (skip + take >= data?.count) {
 //     setHasMore(false);
 //   } else {
 //     setHasMore(true);
 //   }
 // }, [skip, take, data]);

 useEffect(() => {
  selectedStore.setShowBetTypeInfo(false);

  setIsFiltersChanged(false);
  const animation = selectedStore.subscribe(data =>setAnimation(data.animation))

  const selectedResultSubscription = selectedStore.subscribe(data =>
   setSelectedLastResult(data.gameResult)
  );
  const gameResultSubscription = selectedStore.subscribe(data =>
   setLastResult(data.allGamesResult)
  );
  const showSearch = selectedStore.subscribe(data => setShowSearch(data.showSearchinLastResult));
  const resultsCount = selectedStore.subscribe(data =>
   setGameResultsCount(data.allGameResultsCount)
  );
  selectedStore.init();
  selectedStore.clearSlectedAnimal();
  return () => {
   animation.unsubscribe()
   selectedStore.clearGameResult();
   selectedResultSubscription.unsubscribe();
   showSearch.unsubscribe();
   gameResultSubscription.unsubscribe();
   resultsCount.unsubscribe();
   selectedStore.setShowSearchinLastResult(false)
  };
 }, []);

 useEffect(() => {
  gameService.getAllGameResults(
   lastResultQuery
    .gameId(searchValue)
    .partnerId(partnerId)
    .gameTypeId(gameTypeId)
    .skip(skip)
    .take(take)
    // .startTime(time.start)
    // .endTime(time.end)
    .toString(),
   startDate,
   endDate,
   time.start,
   time.end
  );
 }, [startDate, endDate, skip, take, searchValue, time]); // eslint-disable-line react-hooks/exhaustive-deps

 const handleOpenCalendar = () => {
  setIsShowCalendar(!isShowCalendar);
 };
 useOutsideClick(inputRef, () => {
   if (showSearch){
   selectedStore.setShowSearchinLastResult(false)
   }
 });

 const fetchMoreData = () => {
  setSkip(skip + 30);
 };
 useEffect(() => {
  if (skip >= 30) {
   gameService.getAllGameResults(
    lastResultQuery
     .gameId(searchValue)
     .partnerId(partnerId)
     .gameTypeId(gameTypeId)
     .skip(skip)
     .take(take)
     // .startTime(time.start)
     // .endTime(time.end)
     .toString(),
    startDate,
    endDate,
    time.start,
    time.end
   );
  }
 }, [skip]); // eslint-disable-line react-hooks/exhaustive-deps

 const showActive = useMemo(()=>{
  const userMinute = time.end.split(':')[1];
  const userSeconds = time.end.split(':')[2];
  const minuteNow = new Date().toLocaleTimeString("en-GB").split(':')[1];
  const secondNow = new Date().toLocaleTimeString("en-GB").split(':')[2];
  if (userMinute > minuteNow){
   return true
  }
  if (userMinute=== minuteNow && userSeconds >= secondNow){
   return  true
  }
  return  false
 },[time]);

 return (
  <>
   <div className="scroll-container" id="scroll-container-last-result">
    <div className="sticky-container">
     <div className={`history-head ${showSearch ? 'search-visible' : ''}`}>
      {/*<div className="history-h-column icon" onClick={handleOpenCalendar}>*/}
      {/* {isShowCalendar ? (*/}
      {/*  <i className="icon-close_filter history-h-icon animated"/>*/}
      {/* ) : (*/}
      {/*  <i*/}
      {/*   className={`icon-filter history-h-icon filter ${*/}
      {/*    isFiltersChanged ? 'active' : ''*/}
      {/*   }`}*/}
      {/*  />*/}
      {/* )}*/}
      {/*</div>*/}
      <div className="history-h-column text">
       <span className="history-h-text">{t('GAME_RESULT')}</span>
      </div>
      <div
       className={`history-h-column icon ${
        !isFiltersChanged && isShowCalendar ? 'disabled' : ''
       }`}
      >
       {isShowCalendar ? (
        <i
         className={`icon-reset history-h-icon ${isFiltersChanged ? 'active' : ''}`}
         onClick={resetFilers}
        />
       ) : (
        <i
         className={`icon-search history-h-icon search `}
         onClick={() => selectedStore.setShowSearchinLastResult(true)}
        />
       )}
      </div>
      <div
       className="search-cnt"
       style={{ transition: animation ? null : '0s' }}
      >
       <input
        type="text"
        placeholder={t('GAME_ID')}
        onChange={handleSearch}
        value={searchValue}
        ref={inputRef}
        maxLength={19}
        onClick={()=>selectedStore.setShowSearchinLastResult(true)}
       />
       <i
        className="icon-clear"
        onClick={() => selectedStore.setShowSearchinLastResult(false)}
       />
      </div>
     </div>
     <div className={`history-date-picker-container ${isShowCalendar ? 'open' : ''}`}>
      <div className="date-picker">
       <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        minDate={new Date(sevenDaysBefore)}
        maxDate={new Date(today)}
        showDisabledMonthNavigation
        selectsRange
        inline
       />
      </div>
      <div className={`date-picker-sort-columns ${isFirefox ? 'moz' : ''}`}>
       <div className="date-picker-sort-c">
        <div className="date-picker-sort-content">
         <i
          className={`date-picker-sort-icon icon-arrow_left ${
           time.start === '00:00:00' ? '' : 'active'
          }`}
          style={{ pointerEvents: `${time.start === '00:00:00' ? 'none' : 'auto'}` }}
          onClick={() => {
           startTimeInput.current.stepDown(1);
           setIsFiltersChanged(true);
           selectedStore.clearGameResult();
           setTime({
            ...time,
            start: startTimeInput.current.value
           });
          }}
         />
         <input
          className="date-picker-sort-numbers"
          type="time"
          name="start"
          onChange={handleChangeTime}
          value={time.start}
          placeholder="00:00:00"
          step={10}
          ref={startTimeInput}
         />
         <i
          className="date-picker-sort-icon icon-arrow_right active"
          onClick={() => {
           startTimeInput.current.stepUp(1);
           setIsFiltersChanged(true);
           selectedStore.clearGameResult();

           setTime({
            ...time,
            start: startTimeInput.current.value
           });
          }}
         />
        </div>
       </div>
       <div className="date-picker-sort-c center">-</div>
       <div className="date-picker-sort-c">
        <div className="date-picker-sort-content">
         <i
          className={`date-picker-sort-icon icon-arrow_left ${
           time.end === '00:00:00' ? '' : 'active'
          }`}
          style={{ pointerEvents: `${time.end === '00:00:00' ? 'none' : 'auto'}` }}
          onClick={() => {
           selectedStore.clearGameResult();
           endTimeInput.current.stepDown(1);
           setTime({
            ...time,
            end: endTimeInput.current.value
           });
          }}
         />
         <input
          className="date-picker-sort-numbers"
          type="time"
          name="end"
          onChange={handleChangeTime}
          value={time.end}
          step={10}
          placeholder="00:00:00"
          ref={endTimeInput}
         />
         <i
          className={`date-picker-sort-icon icon-arrow_right ${showActive ? 'passive' : 'active'}`}
          onClick={() => {
           selectedStore.clearGameResult();
           endTimeInput.current.stepUp(1);
           setTime({
            ...time,
            end: endTimeInput.current.value
           });
          }}
         />
        </div>
       </div>
      </div>
     </div>
     {
      isCashDesk && showSearch &&
      <NumpadForTerminal inputRef={inputRef} setIsFiltersChanged={setIsFiltersChanged} setSearchValue={setSearchValue}/>

     }
    </div>

    {gameResultsCount === null ? (
     <Loading/>
    ) : gameResultsCount === 0 ? (
     <div className="history-c-title" style={{ display: 'block', textAlign: 'center' }}>{t('NO_MATCHING_RESULTS')}</div>
    ) : lastResult ? (
     <InfiniteScroll
      dataLength={lastResult.length}
      next={fetchMoreData}
      hasMore={lastResult.length <= gameResultsCount}
      loader={
        <LoadingInline/>
      }
      scrollableTarget="scroll-container-last-result"
     >
      {lastResult?.map((result, index) => (
       <LastResulList
        animals={animals}
        key={index}
        result={result}
        selectedLastResult={selectedLastResult}
       />
      ))}
     </InfiniteScroll>
    ) : null}

   </div>
  </>
 );
};

export default LastResultsLeftContent;
