import React, {memo} from "react";
import SingleAnimalWithFixedNumber from "./SingleAnimal/SingleAnimalWithFixedNumber/SingleAnimalWithFixedNumber";
import SingleAnimalWithInput from "./SingleAnimal/SingleAnimalWithInput/SingleAnimalWithInput";

const SingleAnimal = memo(({ selectedAnimal, selectedBetType ,mobile }) => {
  return (
    <>
      {selectedBetType?.addingNumbersCount ? (
        <SingleAnimalWithInput
          addingNumbersCount={selectedBetType?.addingNumbersCount}
          selectedAnimal={selectedAnimal}
          selectedBetType={selectedBetType}
          mobile={mobile}
        />
      ) : (
        <SingleAnimalWithFixedNumber
          selectedAnimal={selectedAnimal}
          selectedBetType={selectedBetType}
        />
      )}
    </>
  );
});

export default SingleAnimal;
