import React, { memo, useEffect, useRef, useState } from 'react';
import SingleNumberWeb from "../single-number.Web";
import ChooseSingleNumber from "../SingleAnimalWithFixedNumber/ChooseSingleNumber";
import { DOMAIN } from "../../../../newCore/constants/environment";
import OneInputField from "./OneInputField/oneInputField";
import TwoInputsFields from "./TwoInputsField/twoInputsField";
import selectedStore from "../../../../state/selectedState";
import useTranslation from "../../../../newCore/helpers/useTranslation";
import * as brazilMainImages from "../../../ulits/brazilMainAnimals";
import AnimalNumpad from '../../../AnimalNumpad/AnimalNumpad';
import { queryGet } from '../../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../../../newCore/constants/constants';

const SingleAnimalWithInput = memo(({ addingNumbersCount, selectedAnimal,mobile }) => {
  const [value, setValue] = useState({
    input1: "",
    input2: ""
  });
  const [inputValueMobile , setInputValueMobile] = useState(selectedStore.initialState.writenNumberByUser)
  const [showAnimalNumpad , setShowAnimalNumpad] = useState(selectedStore.initialState.showAnimalNumpad)
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  const isCashDesk = queryGet(APP_CONSTANTS.IS_CASH_DESK)==='true';

  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    const animalNumpad = selectedStore.subscribe(data =>setShowAnimalNumpad(data.showAnimalNumpad));
    const inputValue = selectedStore.subscribe(data => setInputValueMobile(data.writenNumberByUser));
    selectedStore.init();
    return () => {
      selectedStore.setShowAnimalNumpad(false);
      animalNumpad.unsubscribe();
      selectedStore.clearWritenNumberByUser();
      partnerSettings.unsubscribe();
      inputValue.unsubscribe()
    };
  }, []);

  const [selectedNumber, setSelectedNumber] = useState(null);
  const [backToNumberSelection, setBackToNumberSelection] = useState(false);


  const t = useTranslation();

  const src =
    partnerSettings?.data?.viewMode === 0
      ? `${DOMAIN}/${selectedAnimal.animalPicUrl}`
      : brazilMainImages[`a${selectedAnimal?.sortNumber}`];

  const handleChange = e => {
    const reg = /^[0-9\b]+$/;
    if ((e.target.value.length <= 1 && e.target.value === "") || reg.test(e.target.value)) {
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    var numbers ;
    if (mobile || isCashDesk){
      numbers = [inputValueMobile.input1,inputValueMobile.input2,selectedNumber].join("")
    }else{
      numbers = [value.input1, value.input2, selectedNumber].join("");
    }
    selectedStore.selectNumbers(numbers);
  }, [value,inputValueMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <img className="ch-numbers-img" src={src} alt="" />
      <p className="numbers-ch-title">{selectedNumber ? t("WRITE_NUMBER") : t("CHOOSE_NUMBER")}</p>
      <div className="ch-numbers-box">
        {selectedNumber && backToNumberSelection ? (
          <>
            {addingNumbersCount === 1 ? (
              <OneInputField isCashDesk={isCashDesk} handleChange={handleChange} value={value} mobile={mobile} inputValueMobile={inputValueMobile} />
            ) : (
              <TwoInputsFields isCashDesk={isCashDesk} handleChange={handleChange} value={value} mobile={mobile}  inputValueMobile={inputValueMobile}/>
            )}

            <SingleNumberWeb
              selected
              green
              setBackToNumberSelection={setBackToNumberSelection}
              backToNumberSelection={backToNumberSelection}
            >
              {selectedNumber[0]}
            </SingleNumberWeb>
            <SingleNumberWeb
              selected
              green
              setBackToNumberSelection={setBackToNumberSelection}
              backToNumberSelection={backToNumberSelection}
            >
              {selectedNumber[1]}
            </SingleNumberWeb>
          </>
        ) : (
          <ChooseSingleNumber
            selectedAnimal={selectedAnimal}
            setSelectedNumberForInput={setSelectedNumber}
            setBackToNumberSelection={setBackToNumberSelection}
            selectedNumberForInput={true}
          />
        )}
        {
         showAnimalNumpad && isCashDesk && !mobile && <AnimalNumpad />
        }

      </div>
    </>
  );
});
export default SingleAnimalWithInput;
