import React, { useEffect, useMemo, useState } from "react";
import selectedStore from "../../../../state/selectedState";
import MultiPlyAnimalTable from "../multiPlyAnimalTable";

const ThreeAnimalWithNumberSelection = ({ selectedAnimal, animalsCount }) => {
  const [selectedNumberInFirstRow, setSelectedNumberInFirstRow] = useState("");
  const [selectedNumberInSecondRow, setSelectedNumberInSecondRow] = useState(
    ""
  );
  const [selectedNumberInThirdRow, setSelectedNumberInThirdRow] = useState("");

  const selectedNumberGroup = useMemo(() => {
    return [
      selectedNumberInFirstRow,
      selectedNumberInSecondRow,
      selectedNumberInThirdRow
    ];
  }, [
    selectedNumberInFirstRow,
    selectedNumberInSecondRow,
    selectedNumberInThirdRow
  ]);

  const setSelectedNumberGroup = useMemo(() => {
    return [
      setSelectedNumberInFirstRow,
      setSelectedNumberInSecondRow,
      setSelectedNumberInThirdRow
    ];
  }, []);

  useEffect(() => {
    if (selectedNumberGroup?.[0]){
      selectedStore.selectNumbers(selectedNumberGroup.join(","));
    }else{
      selectedStore.selectNumbers(selectedNumberGroup.join(""));
    }  }, [selectedNumberGroup]);

  return (
    <MultiPlyAnimalTable
      selectedAnimal={selectedAnimal}
      selectedNumberGroup={selectedNumberGroup}
      setSelectedNumberGroup={setSelectedNumberGroup}
      animalsCount={animalsCount}
    />
  );
};
export default ThreeAnimalWithNumberSelection;
