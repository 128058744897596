import React, { useEffect, useState } from 'react';
import selectedStore from '../../state/selectedState';
import gameService from '../../newCore/services/Games/games';
import { copyToClipboard } from '../../newCore/helpers/copyFunction';
import Toaster from '../toaster';

const HashCheckerMobile = ({ result, t , setCheckinput}) => {
 const [resultSalt, setResultSalt] = useState(selectedStore.initialState.resultSalt);
 const [resultKey, setResultKey] = useState(selectedStore.initialState.resultKey);
 const [hash, setHash] = useState(selectedStore.initialState.hash);


 const checkResult = () => {
  const formData = {
   key: resultKey,
   salt: resultSalt
  };
  gameService.checkHash(formData);
 };

 useEffect(() => {
  const resultSalt = selectedStore.subscribe(data => setResultSalt(data.resultSalt));
  const resultKey = selectedStore.subscribe(data => setResultKey(data.resultKey));
  const hash = selectedStore.subscribe(data => setHash(data.hash));
  return () => {
   selectedStore.setResultSalt('');
   selectedStore.setResultKey('');
   selectedStore.setHash('');
   hash.unsubscribe();
   resultKey.unsubscribe();
   resultSalt.unsubscribe();
  };
 }, []);

 return (
  <div className="hash-m-cnt">
   <div className="hash-search-block">
    <input type="text" placeholder="key" className="key-sait-inp" value={resultKey}
           onChange={(e) => selectedStore.setResultKey(e.target.value)} onClick={()=>{
     document.activeElement.blur();
     selectedStore.setShowNumPadForHash(true);
     setCheckinput('key')
    }}/>
    <input type="text" placeholder="sait" className="key-sait-inp" value={resultSalt}
           onChange={(e) => selectedStore.setResultSalt(e.target.value)} onClick={()=>{
     document.activeElement.blur();
     selectedStore.setShowNumPadForHash(true);
     setCheckinput('salt')

    }}/>
    <button className={`search-btn ${resultSalt && resultKey ? '' : 'disabled'}`} onClick={checkResult}>
     <i className="icon-search"/>
    </button>
   </div>
   <div className="hash-search-block">
    <input type="text" placeholder="hash code" className="key-sait-inp" value={hash}
           onChange={(e) => selectedStore.setHash(e.target.value)} onClick={()=>{
     document.activeElement.blur();
    }}/>
   </div>
   <div className="hash-search-block">
    <div className="search-block-item" onClick={() => {
     if (result.resultKey) {
      selectedStore.setResultKey(result.resultKey);
      selectedStore.setResultSalt(result.resultSalt);
     }
    }}>
     <p>{result.resultKey}</p>
    </div>
    <div className="search-block-item" onClick={() => {
     if (result.resultSalt) {
      selectedStore.setResultKey(result.resultKey);
      selectedStore.setResultSalt(result.resultSalt);
     }
    }}>
     <p>{result.resultSalt}</p>
    </div>
    <button className="search-btn blue" onClick={() => {
     if (result.resultKey && result.resultKey) {
      selectedStore.setResultKey(result.resultKey);
      selectedStore.setResultSalt(result.resultSalt);
     }
    }}>
     <i className="icon-search-up"/>
    </button>
   </div>
   <div className="hash-search-block" onClick={() => {
    copyToClipboard(result.resultHash);
    Toaster.success(t('HASH_CODE_COPIED'));
   }}>
    <div className="search-block-item">
     <p>{result.resultHash}</p>
     <i className="icon-hash-code"/>
    </div>
   </div>
  </div>
 );
};

export default HashCheckerMobile;
