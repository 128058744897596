import React from 'react';
import AnimalByNumber from './animal-by-number';
import { possibleWinBalls } from '../../newCore/helpers/draw-green-balls-in-bet-history';

const ResultRowWeb = ({
                       index,
                       item: numbers,
                       selectedBetHistory,
                       animated,
                       isFromBetHistory,
                       activeBalls,
                       isFromLastResult,
                       animation
                      }) => {
 const isSelectedInfo = possibleWinBalls(selectedBetHistory);
 const isCorrectRow =
  isFromBetHistory && !!isSelectedInfo && selectedBetHistory?.result === 2
   ? isSelectedInfo?.rowNumber?.includes(index)
   : false;

 const possibleWinBallsColor = i =>
  isFromLastResult || activeBalls || (isCorrectRow && i > 3 - isSelectedInfo.ballsCount);

 const winRowsIndexes =
  selectedBetHistory?.result === 1
   ? selectedBetHistory?.wonInfo?.wonNumberAndIndex?.map(item => item.index - 1)
   : [];

 const winRowsNumbers =
  selectedBetHistory?.result === 1
   ? selectedBetHistory?.wonInfo?.wonNumberAndIndex?.map(item => item.wonNumber)
   : [];

 const findWinNumbersCount = numbers =>
  winRowsIndexes?.includes(index) && selectedBetHistory?.betType !== 18
   ? numbers.substring(winRowsNumbers[0].length).length
   : null;

 const findWinNumbersForLuckyAnimal = numbers =>
  winRowsIndexes?.includes(index) && selectedBetHistory?.betType === 18
   ? winRowsNumbers?.includes(numbers.substring(0, 2))
   ? [0, 1]
   : [2, 3]
   : null;
 return (
  <div className="single-res-row">
   <div className="row-number-holder">{index + 1}<sup>&#8728;</sup></div>
   <div className="numbers-type-row">
   {numbers && <div
     className={`ball-line-container  ${
      animated && animation ? 'animation' : ''
     }`}
    >
     {numbers?.split('').map((item, i) => {
      return (
       <div
        className={`ball ${possibleWinBallsColor(i) ? 'active' : ''} ${
         3 - findWinNumbersCount(numbers) < i ? 'green' : ''
        } ${findWinNumbersForLuckyAnimal(numbers) && findWinNumbersForLuckyAnimal(numbers)?.includes(i) ? 'green' : ''} `}
        key={i}
       >
        <span>{item}</span>
       </div>
      );
     })}
     <AnimalByNumber
      number={numbers}
      fillBalls={isCorrectRow}
      selectedBetHistory={selectedBetHistory}
      activeBalls={activeBalls}
      isFromLastResult={isFromLastResult}
      winRowsIndexes={winRowsIndexes?.includes(index)}
     />
    </div>}
   </div>
  </div>
 );
};

export default ResultRowWeb;
