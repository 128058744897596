import React, { useEffect, useState, useRef, memo, useMemo } from 'react';
import selectedStore from "../../state/selectedState";
import "../../assets/styles/date-picker.css";
import useTranslation from "../../newCore/helpers/useTranslation";
import selectedStoreMobile from "../../state/selectedStateMobile";
import is from "is_js";
import GameBetHistoryMobile from '../mobile-components/gameBetHistoryMobile';
import demoState from '../../state/demoModeState';
import Toaster from '../toaster';


const BetHistoryDemoMobile = memo(({ hasUnseenBets }) => {
 const [selectedItem, setSelectedItem] = useState(
  selectedStoreMobile.initialState.selectedBetHistory
 );
 const [betHistory, setBetHistory] = useState(demoState.initialState.betHistory);
 const [animation , setAnimation] = useState(selectedStore.initialState.animation)
 const [showPopUp , setShowPopUp] = useState(selectedStore.initialState.dontShowPopUp);
 const [repeatedBet , setRepeatedBet] = useState({});
 const [selectedGame, setSelectedGame] = useState(selectedStore.initialState.selectedGame);
 const [ demoBalance , setDemoBalance] = useState(demoState.initialState.balance);
 const [betIdForDemoBet, setBetIdForDemoBet] = useState(demoState.initialState.betId);
 const [betIsAdded, setBetIsAdded] = useState(selectedStore.initialState.betIsAdded);
 const [error, setError] = useState(selectedStore.initialState.betError);
 const [doBet , setDoBet] = useState(selectedStore.initialState.doBet);
 const [roundId , setRoundId] = useState(selectedStore.initialState.lastRoundId);

 const t = useTranslation();

 useEffect(() => {
  const roundId = selectedStore.subscribe(data=>setRoundId(data.lastRoundId));
  const doBet = selectedStore.subscribe(data => setDoBet(data.doBet));
  const error = selectedStore.subscribe(data => setError(data.betError));
  const betisAdded = selectedStore.subscribe(data => setBetIsAdded(data.betIsAdded));
  const animation = selectedStore.subscribe(data =>setAnimation(data.animation))
  selectedStore.setHasUnseenBets(false);
  const showPopup = selectedStore.subscribe(data => setShowPopUp(data.dontShowPopUp));
  const selectedBetHistory = selectedStoreMobile.subscribe(data =>
   setSelectedItem(data.selectedBetHistory)
  );
  const betId = demoState.subscribe(data => setBetIdForDemoBet(data.betId));
  const balance = demoState.subscribe(data =>setDemoBalance(data.balance));
  const selectedGame = selectedStore.subscribe(data => setSelectedGame(data.selectedGame));
  const betHistory = demoState.subscribe(data => setBetHistory(data.betHistory));
  selectedStore.clearSlectedAnimal();
  selectedStore.setNewGameResultModal(true);
  selectedStoreMobile.init();
  selectedStore.init();
  demoState.init();
  return () => {
   roundId.unsubscribe();
   doBet.unsubscribe();
   error.unsubscribe();
   betisAdded.unsubscribe();
   betId.unsubscribe();
   balance.unsubscribe();
   selectedGame.unsubscribe();
   showPopup.unsubscribe();
   animation.unsubscribe();
   selectedStore.clearBetHistory();
   selectedStore.selectBetHistory({});
   selectedBetHistory.unsubscribe();
   betHistory.unsubscribe();
  };
 }, []);

 const betHistoryByGame = useMemo(()=>{
  const arr = betHistory?.reduce((acum,current)=>{
   current['totalBetWinningAmount'] = current.bets?.reduce((acum,current)=>acum+current.winningAmount,0);
   acum.push(current);
   return acum
  },[]);
  return arr
 },[betHistory]);

 const reBet = (betHistory) =>{
  const bet = {
   gameId: selectedGame.id,
   betTypeId: betHistory.betTypeId,
   betTypeName:betHistory.betTypeName,
   number: betHistory.number,
   stakeAmount: betHistory.stakeAmount,
   odds: betHistory.odds,
   betType : betHistory.betType,
   id:betIdForDemoBet,
   animalIds:betHistory.animalIds
  };
  if (demoBalance<bet.stakeAmount){
   Toaster.error(t('LOW_BALANCE'));
  }else{
   demoState.setBet(bet);
   demoState.setBetId()
   Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
  }
 };

 useEffect(()=>{
  if (doBet){
   reBet(repeatedBet)
  }
 },[doBet]);

 useEffect(() => {
  if (betIsAdded) {
   Toaster.success(t('YOUR_BET_HAS_BEEN_ACCEPTED'));
   selectedStore.setBetIsAdded(false);
   selectedStoreMobile.setHasUnseenBetsMobile(true);
  }
  if (error) {
   Toaster.warning(t(error));
   selectedStore.setBetError('');
  }
 }, [betIsAdded, error, demoBalance]);


 if (!is.empty(selectedItem)) {
  return <GameBetHistoryMobile selectedBetHistory={selectedItem} isFromBetHistory={true} demoMode demoBet={reBet} showPopUp={showPopUp} setRepeatedBet={setRepeatedBet}/>;
 }

 return (
  <>
   <div className="history-holder" style={{ position: "relative" }}>
    <div className="sticky-container">
     <div className={`history-head`}>
      {/*<div className={`history-h-column icon`}>*/}
      {/*  <i*/}
      {/*   className={`icon-filter history-h-icon filter ${hasUnseenBets ? "active" : ""}`}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="history-h-column text">
       <span className="history-h-text">{t("BET_HISTORY")}</span>
      </div>
      <div className={`history-h-column icon`}>
        <i
         className={`icon-search history-h-icon search `}
        />
      </div>
      <div
       className="search-cnt"
       style={{ transition: animation ? null : "0s" }}
      >
       <i
        className="icon-clear"
        onClick={() => {
         selectedStore.setShowNumPad(false);
        }}
       />
      </div>
     </div>
     <div className={"date-picker-m"}>
      <div className={`history-date-picker-container`}>
       <div
        className={`history-sort-row`}
       >
        <div className="h-sort-r">
         <span> {t('SORT')}</span>
        </div>
       </div>
      </div>
     </div>
    </div>
      {betHistoryByGame?.map((item,index) => (
       <div className="history-content" key={item?.gameId}>
        <div className="history-c-title">
                  <span>
                    {t("ROUND_ID")} {item?.gameId}
                  </span>
         <span>
                    {t("TOTAL")}{isNaN(item?.totalBetWinningAmount)? '0' : item?.totalBetWinningAmount}

                  </span>
        </div>
        {item?.bets?.map(historyItem => (
         <div
          className={`history-c-row status-${historyItem.result} ${
           selectedItem?.id === historyItem?.id ? "active" : ""
          }`}
          key={historyItem?.id}

         >
          {/*add class status-(1-3) and active*/}
          <span onClick={() => {
           selectedStoreMobile.selectBetHistory(historyItem);
           selectedStore.setSelectedBetHistoryResult(item?.gameResults);
          }}>
                      {t("ID")} {historyItem.id}
                    </span>
          <span>{historyItem.winningAmount}</span>
          <i className="circle"/>
          {roundId===item.gameId ?  <button onClick={()=>{
           if (!showPopUp){
            selectedStore.setRebetPopUp(true);
            selectedStore.setDoBet(false);
            setRepeatedBet(historyItem)
           }else{
            reBet(historyItem)
           }
          }}>
           <i className="rebet-icon icon-reset"/>
          </button> : null}
         </div>
        ))}
       </div>
      ))}
    {is.empty(betHistory) ? (
     <div className="history-c-title" style={{ display: "block", textAlign: "center" }}>
      {t("NO_MATCHING_RESULTS")}
     </div>
    ) : null}
   </div>
  </>
 );
});

export default BetHistoryDemoMobile;
