import React, { useEffect, useState } from 'react';
import BallsM from '../../../assets/images/BalsM.png';
import NavigationMobile from '../../navigation/navigation.Mobile';
import HeaderInLobby from './headerMobileInLobby';
import MobileContests from './mobileContests';
import NavigationInLobby from './navigationInLobby';
import LiveBetsLobby from '../web/lobbyLiveBets';
import gameService from '../../../newCore/services/Games/games';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../../newCore/constants/constants';
import LobbyRightContent from '../web/lobbyRightContent';

const LobbyMobile = ({ t, urlSearchParams , isAuth, viewMode , currencyCode }) => {
 const [showLiveBets, setShowLiveBets] = useState(false);

 useEffect(()=>{
  document.querySelector('html').classList.add('lobby')
 return ()=>{
  document.querySelector('html').classList.remove('lobby')
 }
 },[])

 return (
  <div className="mobile-wrapper">
   <HeaderInLobby t={t}/>
   {
    showLiveBets ? <LobbyRightContent t={t}/> : <MobileContests viewMode={viewMode} t={t} urlSearchParams={urlSearchParams} currencyCode={currencyCode} isAuth={isAuth}/>
   }
   <NavigationInLobby t={t} urlSearchParams={urlSearchParams} setShowLiveBets={setShowLiveBets} showLiveBets={showLiveBets}/>
  </div>
 );
};

export default LobbyMobile;
