import React, { useEffect, useState } from "react";
import usePartner from "../../hooks/servicesHooks/Partner/partnerHook";
import BetTypes from "../../components/bet-types/index";
import TimerWeb from "../timer/timer.Web";

import LastResultWeb from "../last-result/last-result.Web";
import selectedStore from "../../state/selectedState";
import NewGameResultLeftContent from "../game-result/newGameResultLefContent";
import useTranslation from "../../newCore/helpers/useTranslation";
import Loading from "../Loading/loading";

const LeftContentMain = () => {
  const [gameResult, setGameResult] = useState(selectedStore.initialState.newGameResult);
  const t = useTranslation();
  const [isShowLastResult, setIsShowLastResult] = useState(true);
  const {
    partnerBetTypes: { isLoading, data }
  } = usePartner();

  useEffect(() => {
    const gameResultSubscription = selectedStore.subscribe(data =>
      setGameResult(data.newGameResult)
    );
    selectedStore.setDefaultSelectedBetType();
    selectedStore.init();

    return () => {
      gameResultSubscription.unsubscribe();
    };
  }, []);
  return isLoading ? (
    <Loading/>
  ) : (
    <div className="has-sticky-carcas">
      <div className="has-sticky-head">
        <TimerWeb />
        <div className="last-game-drop" onClick={() => setIsShowLastResult(!isShowLastResult)}>
          <div className="last-game-d-inner">
            <span>{t("LAST_GAME")}</span>
            {isShowLastResult ? (
              <i className="icon-arrow_drop_down" />
            ) : (
              <i className="icon-arrow_drop_down up" />
            )}
          </div>
        </div>



          <NewGameResultLeftContent
           isShowLastResult={isShowLastResult}
            selectedLastResult={
              gameResult?.data?.gameResults?.[0] ? gameResult?.data.gameResults?.[0] : gameResult
            }
            t={t}
          />

        <div className="b-type-title">
          <h2 className="title-text-v">{t("BET_TYPE")}</h2>
        </div>
      </div>
      <div className="has-sticky-content">
        <div className="has-sticky-c-inner">
          <div className="scroll-container">
            <BetTypes data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftContentMain;
