import React from 'react';
import talisman_img1 from '../../../assets/images/24-06.png';
import { withRouter } from 'react-router-dom';
import selectedStore from '../../../state/selectedState';
import Countdown,{zeroPad} from 'react-countdown';
import TimerHelper from '../../../newCore/helpers/timerHelper';
import LoadingInline from '../../Loading/loadingInline';
import { toCurrency } from '../../../newCore/helpers/betActionHelpers';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../../newCore/constants/constants';
import { PartnerIdList } from '../../../newCore/constants/partnerIdList';
import postMessageService from '../../../newCore/services/PostMessages/PostMessagesService';

const GameTypeInLobby = ({ urlSearchParams, gameType, t, history, game, currencyCode, isAuth }) => {
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
 const isHideDemoButton = PartnerIdList.includes(+partnerId)

 const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
   return <LoadingInline />;
  } else {
   return (
    <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
   );
  }
 };
 return (<>
   {
    game ? <div className="left-c-b-ins-item">
     <div className="c-b-ins-i-t-ins">
      <div className="c-b-ins-i-t-ins-left"><i className="icon-ic-time"/></div>
      <div className="c-b-ins-i-t-ins-right"><p className="time-p">{gameType.dueTime}{t('SECOND')}</p></div>
     </div>
     <div className="left-c-b-i-inner">
      {/*<div className="left-c-b-ins-i-img">*/}
      {/* <div className="left-c-b-ins-i-img-ins">*/}
      {/*  <img src={talisman_img1} alt="" />*/}
      {/* </div>*/}
      {/*</div>*/}
      <p className="b-ins-max-m">{t('MAX_WIN')}</p>
      <p className="b-wr-money-p"><span>{toCurrency(gameType.maxWin, currencyCode)}</span></p>
      <p className="left-c-b-ins-i-timer">
       {game?.state ? <span>00:00</span> :
        <Countdown key={game?.id}
                   renderer={renderer}
                   date={game.endDate}
        />
       }
      </p>
      <div className="b-mon-min-max">
        <div className="b-min-l-con">
         <p className="b-min-l-con-p">{t('MIN')}:</p>
        </div>
        <div className="b-min-r-con">
         <p className="b-min-r-con-p">{toCurrency(gameType.minBet, currencyCode)}</p>
        </div>

      </div>
      <div className="b-mon-min-max">
        <div className="b-min-l-con">
         <p className="b-min-l-con-p">{t('MAX')}:</p>
        </div>
        <div className="b-min-r-con">
         <p className="b-min-r-con-p">{toCurrency(gameType.maxBet, currencyCode)}</p>
        </div>
      </div>
      <div className="left-c-b-ins-i-btn">
       <button className="btn-pl-n play-now" onClick={() => {
        if (isAuth) {
         history.push({ pathname: '/game', search: `${urlSearchParams}&gameTypeId=${gameType.id}` });
         selectedStore.setSelectedGameType(gameType);
        } else {
         postMessageService.openLoginPopup();
        }
       }}>{t('PLAY_NOW')}</button>
      </div>
      {
       !isHideDemoButton && (<div className="left-c-b-ins-i-btn">
        <button className="btn-pl-n demo" onClick={() => {
         history.push({ pathname: '/game', search: `${urlSearchParams}&gameTypeId=${gameType.id}` });
         selectedStore.setDemoMode(true);
         selectedStore.setSelectedGameType(gameType, true);
        }}>{t('DEMO')}</button>
       </div>)
      }
     </div>
    </div> : null}
  </>
 );
};

export default withRouter(GameTypeInLobby);
