import React from "react";
import selectedStore from '../../state/selectedState';


const AnimalNumpad = () => {
  return (
    <>
      <div className="animal-numpad">
        <span onClick={()=>selectedStore.setWritenNumberByUser('1')}>1</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser('2')}>2</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser('3')}>3</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser('4')}>4</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser(5)}>5</span>
        <span className="icon-close has-icon" onClick={()=>selectedStore.setShowAnimalNumpad(false)}/>
        <span onClick={()=>selectedStore.setWritenNumberByUser(6)}>6</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser(7)}>7</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser(8)}>8</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser(9)}>9</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser(0)}>0</span>
        <span onClick={()=>selectedStore.setWritenNumberByUser('')}>C</span>
      </div>
    </>
  );
};

export default AnimalNumpad;
