import React from 'react';

const AboutGameInfo = ({ t, aboutGame, betTypes }) => {
 return (
   <div className="scroll-b-holder">
    <div className="scroll-container">
     <div className="game-help-info-row">
      <h3>{t(aboutGame.descriptionTitle)}</h3>
      <p>{t(aboutGame.descriptionText)}</p>
     </div>
     <div className="game-help-info-row">
      <h3>{t(aboutGame.drawResult)}</h3>
      <p>{t(aboutGame.drawResultText)}</p>
     </div>
     <div className="game-help-info-row">
      <h3>{t(aboutGame.importantTitle)}</h3>
      <p>{t(aboutGame.importantText)}</p>
     </div>
     <div className="game-help-table-container">
      <h3>{t(aboutGame.betTypesOdds)}</h3>
      <div className="game-help-table">
       <div className="game-help-table-head">
        <span>{t('BET_TYPE')}</span>
        <span>{t('ODD')}</span>
       </div>
       <div className="game-help-table-content">
        {
         betTypes.map(betType => {
          return (
           <div className="game-help-table-row" key={betType.id}>
            <div className="game-help-table-column left">
             <span>{t(betType.name)}</span>
            </div>
            <div className="game-help-table-column right">
             <span>x{t(betType?.odds?.[0])}</span>
            </div>
           </div>
          );
         })
        }
       </div>
      </div>
     </div>
    </div>
   </div>
 );
};
export default AboutGameInfo;
