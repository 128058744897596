import React, { memo, useEffect, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import ChooseNumberWeb from "../choose-number/choose-number.Web";
import AnimalsTable from "../animals-table";
import selectedStore from '../../state/selectedState';
import selectedStoreMobile from '../../state/selectedStateMobile';

const ChooseAnimalByBetType = memo(({ demoMode , selectedAnimal, selectedBetType, mobile }) => {
 const [animationOn, setAnimationOn] = useState(selectedStore.initialState.animation);
 useEffect(() => {
  const animation = selectedStore.subscribe(data => setAnimationOn(data.animation));
  selectedStore.init();
  return () => {
   animation.unsubscribe();
  };
 }, []);

 return (
    <>
      <AnimatePresence>
        {selectedAnimal.length === selectedBetType?.animalsCount && selectedBetType.animalNumbersCount!==4 ? (
          <motion.div
            key="ChooseNumberWeb"
            className="animated-element"
            transition={{ duration: animationOn ? 0.1 : 0}}
            // initial={{ transform: "translate(140%, 0)", transition: "all 240ms" }}
            // animate={{ transform: "translate(0, 0)", transition: "all 240ms" }}
            // exit={{ transform: "translate(140%, 0)", transition: "all 240ms" }}
            initial={{ transform: "translate(140%, 0)" }}
            animate={{ transform: "translate(0, 0)" }}
            exit={{ transform: "translate(140%, 0)" }}

          >
            <ChooseNumberWeb demoMode={demoMode} selectedAnimal={selectedAnimal} selectedBetType={selectedBetType} mobile={mobile}/>
          </motion.div>
        ) : (
          <motion.div
            key="AnimalsTable"
            className="animated-element"
            transition={{ duration: animationOn ?  0.1 : 0}}
            initial={{ transform: "translate(-140%, 0)" }}
            animate={{ transform: "translate(0, 0)" }}
            exit={{ transform: "translate(-140%, 0)" }}
          >
            <AnimalsTable selectedAnimal={selectedAnimal} selectedBetType={selectedBetType} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
});
export default ChooseAnimalByBetType;
