import React from "react";
import ThreeAnimalByFixedNumbers from "./threeAnimalbyFixedNumbers";
import ThreeAnimalWithNumberSelection from "./threeAnimalWithNumberSelection";

const ChooseThreeAnimal = ({ selectedAnimal, selectedBetType, animalsCount }) => {
  return (
    <>
      {selectedBetType.animalNumbersCount === 4 ? (
        <ThreeAnimalByFixedNumbers selectedAnimal={selectedAnimal} />
      ) : (
        <ThreeAnimalWithNumberSelection animalsCount={animalsCount} selectedAnimal={selectedAnimal} />
      )}
    </>
  );
};
export default ChooseThreeAnimal;
