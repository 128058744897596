import React, { useEffect, useState } from 'react';
import selectedStore from '../../../state/selectedState';
import GameType from './gameType';
import gameService from '../../../newCore/services/Games/games';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS, GENERAL_GAME_INFO } from '../../../newCore/constants/constants';
import TalismanLogo from '../../../assets/images/talisman.png';
import Jogo from '../../../assets/images/jogo.png';
import { useHistory } from 'react-router';

const MobileContests = ({ urlSearchParams, t, viewMode, currencyCode, isAuth }) => {
  const [ gameTypes, setGameTypes ] = useState(selectedStore.initialState.gameTypes);
  const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
  const history = useHistory();

  useEffect(() => {
    gameService.getGameTypes(partnerId, currencyCode);
  }, []);

  useEffect(() => {
    const gameTypes = selectedStore.subscribe(data => setGameTypes(data.gameTypes));
    return () => {
      gameTypes.unsubscribe();
    };
  }, []);

  return (
    <div className="lobby-mobile">
      <span className="icon-info for-lobby mobile" onClick={() => {
       history.push({
        pathname: '/help',
        search: `${urlSearchParams}&gameTypeId=${gameTypes?.gameTypes?.[0]?.id}`,
       });
       selectedStore.selectBetType(GENERAL_GAME_INFO);
       selectedStore.setSelectedGameType(gameTypes?.gameTypes?.[0], !isAuth);
      }}/>
      <div className="talisman-lobby-wrapper">
         {
          gameTypes.gameTypes?.map(data => {
           return <GameType gameType={data} game={data.game} urlSearchParams={urlSearchParams} t={t}
                            key={data.id} currencyCode={currencyCode} isAuth={isAuth}/>;
          })
         }
      </div>
    </div>
  );
};

export default MobileContests;
