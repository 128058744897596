import React, { useEffect, useState } from "react";
import selectedStore from "../../state/selectedState";
import { EBetHistoryStatus } from "../../newCore/constants/enums";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import useTranslation from "../../newCore/helpers/useTranslation";
import { ReactComponent as ReactLogo } from "../../assets/images/ets.svg";
import is from "is_js";
import BetHistorySkeleton from "./gameBetHistorySkeleton";
import selectedStoreMobile from "../../state/selectedStateMobile";
import BetHistoryResult from "../betHistoryResult/betHistoryResult";
import { motion } from 'framer-motion';

const GameBetHistory = ({demoMode}) => {
  const t = useTranslation();
  const [selectedBetHistory, setSelectedBetHistory] = useState(
    selectedStore.initialState.selectedBetHistory
  );

  const [betHistoryCount, setBetHistoryCount] = useState(
    selectedStore.initialState.betHistoryCount
  );

  const [userCurrency, setUserCurrency] = useState(selectedStore.initialState.userCurrency);
  const [betTypes, setBetTypes] = useState(selectedStore.initialState.partnerBetTypes);
  const [historyItemResult, setHistoryItemResult] = useState(
    selectedStore.initialState.selectedBetHistoryResult
  );
  const [animationOn, setAnimationOn] = useState(selectedStore.initialState.animation);
  useEffect(() => {
    const animation = selectedStore.subscribe(data => setAnimationOn(data.animation));
    selectedStore.init();
    return () => {
      animation.unsubscribe();
    };
  }, []);


  useEffect(() => {
    const selectedBetHistory = selectedStore.subscribe(data =>
      setSelectedBetHistory(data.selectedBetHistory)
    );
    const betHistoryCount = selectedStore.subscribe(data =>
      setBetHistoryCount(data.betHistoryCount)
    );

    const userCurrency = selectedStore.subscribe(data => setUserCurrency(data.userCurrency));
    const partnerBetTypes = selectedStore.subscribe(data => setBetTypes(data.partnerBetTypes));
    const result = selectedStore.subscribe(data =>
      setHistoryItemResult(data.selectedBetHistoryResult)
    );
    selectedStore.init();
    return () => {
      selectedBetHistory.unsubscribe();
      userCurrency.unsubscribe();
      partnerBetTypes.unsubscribe();
      result.unsubscribe();
      betHistoryCount.unsubscribe();
    };
  }, []);

  const handleClickShowBetTypeInfo = () => {
    const betTypeInfo = betTypes.find(bt => bt?.type === selectedBetHistory?.betType);
    selectedStoreMobile.selectBetTypeHelpInfo(betTypeInfo);
    selectedStore.selectBetType(betTypeInfo);
    selectedStore.setShowBetTypeInfo(true);
  };

  return (
    <motion.div
       key="BetHistoryWeb"
       className="animated-element"
       transition={{ duration: animationOn ? 0.1 : 0 }}
       initial={{ transform: 'translate(-140%, 0)' }}
       animate={{ transform: 'translate(0, 0)' }}
       exit={{ transform: 'translate(-140%, 0)' }}
    >
      <div className="bet-history-holder">
        {selectedBetHistory ? (
          <>
            <div className="bet-history-h-row">
              <span
                className={`bet-history-status status-${selectedBetHistory?.result} ${
                  (selectedBetHistory?.result || selectedBetHistory?.result === 0)||(!is.empty(selectedBetHistory) && !selectedBetHistory?.result) ? "" : "skeleton"
                }`}
              >
                {t(EBetHistoryStatus[selectedBetHistory?.result])}
              </span>
              <div className="ch-title-cnt" onClick={() => handleClickShowBetTypeInfo()}>
                <h3
                  className={`ch-title-info ${selectedBetHistory?.betTypeName ? "" : "skeleton"}`}
                >
                  {t(selectedBetHistory?.betTypeName)}
                  <span className="icon-info" />
                </h3>
              </div>
              <div
                className={`bet-history-count-info ${selectedBetHistory?.number ? "" : "skeleton"}`}
              >
                {selectedBetHistory?.number}
              </div>
            </div>
            <div className="bet-history-h-row">
              { demoMode ? <h4 className={`win-text `}>
                 {t("BET_SUM")}{" "}
                 {selectedBetHistory?.stakeAmount}{' Fun'} X{" "}
                 {selectedBetHistory?.odds?.[0]}
               </h4> :
                selectedBetHistory?.stakeAmount &&
              <h4 className={`win-text `}>
                {t("BET_SUM")}{" "}
                {toCurrency(selectedBetHistory?.stakeAmount, userCurrency?.[0]?.name)} X{" "}
                {selectedBetHistory?.odds?.[0]}
              </h4>
              }
              {
                demoMode ?  <h5 className="money-v">
                   {selectedBetHistory?.winningAmount ? selectedBetHistory?.winningAmount : '0 ' }{'Fun'}
                 </h5>  :
                (selectedBetHistory?.winningAmount || selectedBetHistory?.winningAmount === 0) ?
                <h5 className="money-v">
                  {toCurrency(selectedBetHistory?.winningAmount, userCurrency?.[0]?.name)}
                </h5> : null
              }
            </div>
          </>
        ) : betHistoryCount===null && !demoMode?  (
          <BetHistorySkeleton />):null
        }
        {betHistoryCount===0 || (demoMode && !selectedBetHistory) ? (
          <div className="img-bet-history-wrapper">
            <ReactLogo className="img-bet-history" />
          </div>
        ) : null}

        {historyItemResult && betHistoryCount!==0? (
          <div className="bet-history-h-row">
            <div className="game-results-row">
              <div className="game-results-content">
                <BetHistoryResult
                  isFromBetHistory={true}
                  selectedBetHistory={selectedBetHistory}
                  betHistoryResult={historyItemResult}
                  isFromLastResult={false}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default GameBetHistory;
