import { BehaviorSubject } from "rxjs";
import {
  doneSetter,
  generateBetHistoryQueryParams,
  pendingSetter
} from "../../helpers/helperFunctions";
import FromFetchService from "../../global/fromFetchService/fromFetchService";
import { InitialSkipTakeForBetHistory } from "../../constants/constants";
import { queryConstant } from "../../constants/constants";

const END_POINTS = {
  prefix: "Bets",
  betHistory: "betHistory",
  demoBet : 'DemoBet',
};
const getDay = day => new Date(day);
const nextDay = day => new Date(getDay(day));

class BetService {
  betHistory = new BehaviorSubject(doneSetter([]));
  betHistoryByGameId = new BehaviorSubject(doneSetter([]));
  addBet = new BehaviorSubject({});
  demoBet = new BehaviorSubject({});

  bet = ({ selectedGame, selectedBetType }, betAmount, userWritingNumber, animalId) => {
    const path = `${END_POINTS.prefix}`;

    const queryData = {
      stakeAmount: +betAmount,
      gameId: selectedGame.id,
      type: selectedBetType.type,
      betTypeId: selectedBetType.id,
      betTypeName: selectedBetType.name,
      number: userWritingNumber,
      odds: selectedBetType.odds,
      animalIds: animalId
    };

    FromFetchService.post(path, queryData).subscribe(async data =>
      this.addBet.next(await data.json())
    );
  };

  getdemoBet = (demoBet , gameResult) => {
    const path = `${END_POINTS.prefix}/${END_POINTS.demoBet}`;
    const queryData = {
          gameResults : {
            first:gameResult.first,
            second:gameResult.second,
            third:gameResult.third,
            fourth:gameResult.fourth,
            fifth:gameResult.fifth,
            endDate:gameResult.endDate
          },
      gameId:gameResult.gameId,
      bets:demoBet
    };
    FromFetchService.post(path,queryData).subscribe(async data =>
    this.demoBet.next(await data.json())
    )
  };

  getBetHistory = (queryParams, startDate, endDate) => {
    const path = `${END_POINTS.prefix}/${END_POINTS.betHistory}`;
    if (startDate === endDate) {
      let start = getDay(startDate);
      let next = nextDay(start);
      next.setDate(next.getDate() + 1);
      endDate = next.toLocaleDateString();
    }
    const query =
      startDate && endDate
        ? `${queryParams}&StartDate=${startDate}&EndDate=${endDate}`
        : queryParams;
    this.betHistory.next(pendingSetter([]));
    FromFetchService.get(path, query).subscribe(async data =>
      this.betHistory.next(doneSetter(await data.json()))
    );
  };
  getBetHistoryByGameId = queryParams => {
    const path = `${END_POINTS.prefix}/${END_POINTS.betHistory}`;
    this.betHistoryByGameId.next(pendingSetter([]));
    let query = [queryParams];

    FromFetchService.get(path, query).subscribe(async data =>
      this.betHistoryByGameId.next(doneSetter(await data.json()))
    );
  };

  filterBetHistory = queryParams => {
    const queryArray = generateBetHistoryQueryParams(queryParams);
    const path = `${END_POINTS.prefix}/${END_POINTS.betHistory}`;
    this.betHistory.next(pendingSetter([]));
    FromFetchService.get(path, [
      ...queryArray,
      ...InitialSkipTakeForBetHistory,
      queryConstant.orderColumnName,
      queryConstant.orderByAsc
    ]).subscribe(async data => this.betHistory.next(doneSetter(await data.json())));
  };
}

const betHistoryService = new BetService();
export default betHistoryService;
