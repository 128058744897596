import { useEffect, useState } from 'react';
import signalRSubscription from '../../../newCore/services/SignalR/signalR';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import TimerHelper from '../../../newCore/helpers/timerHelper';
import selectedStore from '../../../state/selectedState';
import betHistoryService from '../../../newCore/services/Bets/bet';
import selectedStoreMobile from '../../../state/selectedStateMobile';
import { APP_CONSTANTS } from '../../../newCore/constants/constants';
import demoState from '../../../state/demoModeState';
import { UTCToLocalDate } from '../../../newCore/helpers/utcToLocalDate';

export const useSignalR = () => {
 const [data] = useState(null);
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);

 const store = {
  partnerId,
  isLobby: true
 };

 useEffect(() => {
  signalRSubscription.subscribeConnection(store).then(data =>data)
 }, []);

 return { data };
};


useSignalR.gameCreated = (store, data) => {
 if (!!data) {
  if (signalRSubscription.constructor.IsLobby) {
   selectedStore.setGamesInLobby(data);
  } else {
   selectedStore.selectGame(data);
  }

 }
 setTimeout(() => {
  demoState.setGameFinished(false);
  selectedStoreMobile.setHasUnseenBetsMobile(false);
 }, 500);
};

useSignalR.gameFinished = (store, data) => {
 selectedStore.setGameResultFromSocket(data.result);
 const newData = { ...data.result, endDate: data.endDate };
 selectedStore.setNewGameResult(newData);
 selectedStore.selectedGameResultWithHistory(newData);
 selectedStore.setNewGameResultColorsToActive(true);
 selectedStore.setShowNewResult(true);
 selectedStore.lastResultAnimated(true);
 setTimeout(() => {
  selectedStore.setNewGameResultColorsToActive(false);
 }, 7000);
 setTimeout(() => {
  demoState.setGameFinished(true);
 }, 100);
 if (signalRSubscription.constructor.USER_BET) {
  setTimeout(() => {
   selectedStoreMobile.setShowUserBetNumber(true);
  }, 1800);
 }
 setTimeout(() => {
  selectedStore.setShowNewResult(false);
 }, 2000);

 setTimeout(() => {
  selectedStore.lastResultAnimated(false);
 }, 3500);

 setTimeout(() => {
  selectedStoreMobile.setShowUserBetNumber(false);
 }, 4000);

 setTimeout(() => {
  selectedStore.selectedGameResultWithHistory();
  demoState.clearUserBet();
  selectedStore.setShowDemoWin(false)
 }, 5000);
};

useSignalR.gameCalculated = ({ id }, data) => {
 setTimeout(()=>{
  selectedStore.setShowRealWin(true);
 },3000)
 if (signalRSubscription.constructor.USER_BET && signalRSubscription.constructor.USER_ID) {
  betHistoryService.getBetHistoryByGameId({
   key: 'GameId',
   value: data
  });
  selectedStore.setHasUnseenBets(true);

  setTimeout(() => {
   selectedStore.setShowTotalWinPopup(true);
  }, 3000);

  setTimeout(() => {
   selectedStore.clearUserBet();
   selectedStore.setShowTotalWinPopup(false);
  }, 5000);
  signalRSubscription.constructor.USER_BET=false

 }
 setTimeout(()=>{
  selectedStore.clearLiveBets();
  selectedStore.setShowRealWin(false);
  selectedStore.clearLiveBetsResults();
 },5000)
};

useSignalR.liveBets = data => {
 selectedStore.setBet(data);
 selectedStore.addLiveBets(data);
 selectedStore.setLiveBetsForDemo(data)
};

useSignalR.liveBetsResults = data => {
 selectedStore.addLiveBetsResults(data);
};

useSignalR.gameCanceled = (store, data) => {
};

useSignalR.betResultChanged = (store, data) => {
 // console.log("betResultChanged");
 // if (window.location.pathname !== '/bet-history') store.dispatch(rxAddHistoryCount());
 // if (data.result === EBetHistoryCategoriesValues.WIN) store.dispatch(cntrlGetUserBalance());
};
