import {useState , useEffect} from 'react';
import userService from "../../../newCore/services/User/user";

const useUser = () =>{
  const [userBalance , setUserBalance ] = useState(userService.userBalance.getValue());
  useEffect(()=>{
      const userBalanceSubscription = userService.userBalance.subscribe(setUserBalance);
      return ()=>{
          userBalanceSubscription.unsubscribe()
      }
  }); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        userBalance,
        getUserBalance : userService.getUserBalance,
    }
};
export default useUser
