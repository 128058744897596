import Domain from "./domain";

let DOMAIN = null;
let TRANSLATION_API = null;
let DOMAIN_API = null;

if (process.env.REACT_APP_ENV === "production") {
  DOMAIN = new Domain("https://api.betcolottery.com");
  TRANSLATION_API = "https://translations.rpd.cloud/api/v1";
  DOMAIN_API = new Domain("https://api.betcolottery.com");
} else {
  DOMAIN = "https://jdb-api-stage.betcoapps.com";
  TRANSLATION_API = "https://rpd-translations-stage.betcoapps.com/api/v1";
}
//https://rpd-translations-stage.betcoapps.com/api/v1
//https://rpd-translations.betcoapps.com/api/v1

// export const API = `${DOMAIN}/api`;
export { DOMAIN, TRANSLATION_API, DOMAIN_API };

// const Environment = {
//   development: "development",
//   production: "production",
//   staging: "staging"
// };

export const env = process.env.REACT_APP_ENV;

export const INCLUDE_CREDENTIALS = "same-origin";
// export let DOMAIN = "https://jdb-api-stage.betcoapps.com";
// export let DOMAIN_PROD = "https://api.betcolottery.com";
// export let TRANSLATION_API = "https://rpd-translations-stage.betcoapps.com/api/v1";
// export let TRANSLATION_API_PROD = "https://translations.rpd.cloud/api/v1";

// if (env === Environment.production) {
//   DOMAIN = DOMAIN_PROD;
//   DOMAIN_API = `${DOMAIN}/api`; //production
//   TRANSLATION_API = TRANSLATION_API_PROD;
//
// } else if (env === Environment.staging) {
//   DOMAIN = window.location.origin;
//   DOMAIN_API = `${DOMAIN}/api`; //staging
//
// } else if (env === Environment.development) {
//   DOMAIN = window.location.origin;
//   DOMAIN_API = `${DOMAIN}/api`; // development
// }
