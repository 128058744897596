import React, { memo, useEffect, useState } from "react";
import SingleNumberWeb from "../SingleAnimal/single-number.Web";
import { DOMAIN } from "../../../newCore/constants/environment";
import selectedStore from "../../../state/selectedState";

const MultiPlyAnimalTableByFixedNumber = memo(({ selectedAnimal }) => {

  // * TOFIX
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  const [brazilImagesCircle, setBrazilImagesCircle] = useState(
    selectedStore.initialState.brazilImages
  );
  useEffect(() => {
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    const brazilImages = selectedStore.subscribe(data => setBrazilImagesCircle(data.brazilImages));

    selectedStore.init();
    return () => {
      partnerSettings.unsubscribe();
      brazilImages.unsubscribe();
    };
  }, []);

  return (
    <div className="ch-numbers-cnt ch-numbers-cnt-fixed">
      {selectedAnimal.map(item => (
        <div className="ch-numbers-box">
          <SingleNumberWeb isImg>
            <img className="ch-numbers-b-img" src={partnerSettings?.data?.viewMode === 0
             ? `${DOMAIN}/${item.animalCirclePicUrl}` : `${brazilImagesCircle[`a${item.sortNumber}`]}`} alt="" />
          </SingleNumberWeb>
          {item.numbers.split(",").map(number => (
            <SingleNumberWeb active={true}>{number}</SingleNumberWeb>
          ))}
        </div>
      ))}
    </div>
  );
});
export default MultiPlyAnimalTableByFixedNumber;
