import React, { useEffect, useState } from "react";
import selectedStore from "../../state/selectedState";
import userService from "../../newCore/services/User/user";
import useTranslation from "../../newCore/helpers/useTranslation";
import { toCurrency } from "../../newCore/helpers/betActionHelpers";
import demoState from '../../state/demoModeState';

const TotalWinPopupWeb = ({ winning , demoWinning , demoMode}) => {
  const t = useTranslation();
  const [currency, setCurrency] = useState(
    selectedStore.initialState.userCurrency
  );
  useEffect(() => {
    if (!demoMode){
    userService.getUserBalance();
    }
    selectedStore.subscribe(data => setCurrency(data.userCurrency));
    setTimeout(()=>{
      demoState.setWinning(0)
    },2000)
  }, []);
  return (
   <>

  { demoMode ?     <div className="total-win-popup">
    <div className="total-win-popup-cnt">
      <i className="circle-big" />
      <i className="circle-small" />
      <div className="total-win-popup-cnt-inner">
        <span className="total-win-title">{t("TOTAL_WIN")}</span>
        <p className="total-win-money">
          { toCurrency(Math.round10(demoWinning,-2))}{' Fun'}
        </p>
        <button
         className="total-win-button"
         onClick={() => {
           demoState.setWinning(0)
         }}
        >
          {t("OK")}
        </button>
      </div>
    </div>
  </div> :  (winning && currency?.[0]?.name)  ? <div className="total-win-popup">
    <div className="total-win-popup-cnt">
      <i className="circle-big" />
      <i className="circle-small" />
      <div className="total-win-popup-cnt-inner">
        <span className="total-win-title">{t("TOTAL_WIN")}</span>
        <p className="total-win-money">
        {  toCurrency(Math.round10(winning, -2), currency?.[0]?.name)}
        </p>
        <button
         className="total-win-button"
         onClick={() => {
           selectedStore.clearSelectedState();
         }}
        >
          {t("OK")}
        </button>
      </div>
    </div>
  </div>  : null
}
   </>
  );
};

export default TotalWinPopupWeb;
