import React, { useEffect, useRef, useState } from 'react';
import SingleNumberWeb from "./SingleAnimal/single-number.Web";
import { useDidUpdate } from '../../newCore/helpers/helperFunctions';
import selectedStore from '../../state/selectedState';

const FourChanceComponent = ({ selectedBetType , t }) => {
  const [value, setValue] = useState({
    first: "",
    second: "",
    third: "",
    fourth: ""
  });
  const textInput1 = useRef(false);
  const textInput2= useRef(false);
  const textInput3 = useRef(false);

  const handleChange = e => {
    if (e.target.value.length <= 1) {
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  useEffect(()=>{
    let numbers = Object.values(value).join('')
    selectedStore.selectNumbers(numbers)
  },[value])

  useDidUpdate(()=>{
    if (value.first){
      textInput1.current.focus()
    }
    if (value.first && value.second){
      textInput2.current.focus()
    }if (value.first && value.second && value.third){
      textInput3.current.focus()
    }
  },[value])

  const generateRandom = () => {
    setValue({
      first: Math.floor(Math.random() * 10),
      second: Math.floor(Math.random() * 10),
      third: Math.floor(Math.random() * 10),
      fourth: Math.floor(Math.random() * 10)
    });
  };
  return (
    <div className="ch-numbers-b">
      <div className="four-chance-cnt">
        <div className="four-chance-head-row">
          <SingleNumberWeb>
            <input
              name="first"
              onChange={handleChange}
              type="text"
              className="four-chance-input"
              value={value.first}
              autoFocus={!value.first}
            />
          </SingleNumberWeb>
          <SingleNumberWeb>
            <input
              name="second"
              onChange={handleChange}
              type="text"
              className="four-chance-input"
              value={value.second}
              ref={textInput1}

            />
          </SingleNumberWeb>
          <SingleNumberWeb>
            <input
              name="third"
              onChange={handleChange}
              type="text"
              className="four-chance-input"
              value={value.third}
              ref={textInput2}
            />
          </SingleNumberWeb>
          <SingleNumberWeb>
            <input
              name="fourth"
              onChange={handleChange}
              type="text"
              className="four-chance-input"
              value={value.fourth}
              ref={textInput3}
            />
          </SingleNumberWeb>
          <i
            className="four-chance-game-icon icon-random"
            onClick={() => generateRandom()}
          />
        </div>
        <div className="four-chance-row">
          <div className="four-chance-r-column">
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.first}</SingleNumberWeb>
            </div>
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.second}</SingleNumberWeb>
            </div>
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.third}</SingleNumberWeb>
            </div>
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.fourth}</SingleNumberWeb>
            </div>
          </div>
          <div className="four-chance-r-column">
            <span className="four-chance-r-text">
              x {selectedBetType?.odds[3]}
            </span>
          </div>
        </div>
        <div className="four-chance-row">
          <div className="four-chance-r-column">
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.second}</SingleNumberWeb>
            </div>
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.third}</SingleNumberWeb>
            </div>
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.fourth}</SingleNumberWeb>
            </div>
          </div>
          <div className="four-chance-r-column">
            <span className="four-chance-r-text">
              x {selectedBetType?.odds[2]}
            </span>
          </div>
        </div>
        <div className="four-chance-row">
          <div className="four-chance-r-column">
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.third}</SingleNumberWeb>
            </div>
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.fourth}</SingleNumberWeb>
            </div>
          </div>
          <div className="four-chance-r-column">
            <span className="four-chance-r-text">
              x {selectedBetType?.odds[1]}
            </span>
          </div>
        </div>
        <div className="four-chance-row">
          <div className="four-chance-r-column">
            <div className="four-chance-r-item">
              <SingleNumberWeb>{value.fourth}</SingleNumberWeb>
            </div>
          </div>
          <div className="four-chance-r-column">
            <span className="four-chance-r-text">
              x {selectedBetType?.odds[0]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourChanceComponent;
