import React, { memo, useEffect, useRef, useState } from 'react';
import GameLogo from "../../../components/game-logo";
import BetAction from "../../../components/bet-action/bet-action.Web";
import selectedStore from "../../../state/selectedState";
import TotalWinPopupWeb from "../../../components/total-win-popup/total-win-popup.WEB";
import GameCenterContainer from "../../../container/GameCenterContainer";
import demoState from '../../../state/demoModeState';
import RebetPopUp from '../../../components/modal/reBet';
import useTranslation from '../../../newCore/helpers/useTranslation';
import { useOutsideClick } from '../../../newCore/helpers/useClickOutside';
import { bet } from '../../../newCore/helpers/betActionHelpers';

const MainContent = memo(({ path , demoMode }) => {
  const [selectedAnimal, setSelectedAnimal] = useState([]);
  const [selectedBetType, setSelectedBetType] = useState(selectedStore.state.selectedBetType);
  const [showBetTypeInfo, setShowBetTypeInfo] = useState(
    selectedStore.initialState.showBetTypeInfo
  );
  const [limits, setLimits] = useState({})
  const [amount, setAmount] = useState('');
  const [selectedGameType, setSelectedGameType] = useState(selectedStore.initialState.selectedGameType);

  const [demoWinning , setDemoWinning] = useState(demoState.initialState.winning)
  const [showTotalWinPopup, setShowTotalWinPopup] = useState(
    selectedStore.initialState.showTotalWinPopup
  );
  const [resultWithHistory, setResultWithHistory] = useState(
    selectedStore.initialState.gameResultWithHistory
  );
  const [selectedLastResult, setSelectedLastResult] = useState(
    selectedStore.initialState.gameResult
  );
  const [winning, setWinning] = useState(selectedStore.initialState.allWinning);
  const [chooseAnimal, setChooseAnimal] = useState(
    selectedStore.initialState.chooseAnimalAnimation
  );
  const [chooseNumber, setChooseNumber] = useState(
    selectedStore.initialState.chooseNumberAnimation
  );
  const [writeNumber, setWriteNumber] = useState(selectedStore.initialState.writeNumberAnimation);
  const [autoOpenResult, setAutoOpenResult] = useState(selectedStore.initialState.autoOpenResult);
  const [repeatBet , setRepeatBet] = useState(selectedStore.initialState.rebetPopUp);
  const t = useTranslation()
  const hidePopUp = useRef();

  const setBetAmount = (arg) =>{
      if (arg !== undefined){
        setAmount(arg)
      }
  };
  useEffect(()=>{
    setBetAmount(selectedGameType.minBet);
    setLimits({
      minBet:selectedGameType?.minBet,
      maxBet:selectedGameType?.maxBet,
    });
  },[selectedGameType.id]);
  useEffect(()=>{
    selectedStore.selectedBetAmount(amount)
  },[amount])
  useEffect(() => {
    const repeatBet = selectedStore.subscribe(data => setRepeatBet(data.rebetPopUp));
    const selectedGameTypeSub = selectedStore.subscribe(data => {
      setSelectedGameType(data.selectedGameType)
    });
    const demoWin = demoState.subscribe(data => setDemoWinning(data.winning))
    const selectedBetType = selectedStore.subscribe(data =>
      setSelectedBetType(data.selectedBetType)
    );
    const allWinning = selectedStore.subscribe(data => setWinning(data.allWinning));
    const selectedAnimal = selectedStore.subscribe(data => setSelectedAnimal(data.selectedAnimal));
    const showBetTypeInfo = selectedStore.subscribe(data =>
      setShowBetTypeInfo(data.showBetTypeInfo)
    );
    const chooseAnimal = selectedStore.subscribe(data =>
      setChooseAnimal(data.chooseAnimalAnimation)
    );
    const chooseNumberAnimation = selectedStore.subscribe(data =>
      setChooseNumber(data.chooseNumberAnimation)
    );
    const writeNumberAnimation = selectedStore.subscribe(data =>
      setWriteNumber(data.writeNumberAnimation)
    );
    const showTotalWinPopup = selectedStore.subscribe(data =>
      setShowTotalWinPopup(data.showTotalWinPopup)
    );
    const gameResultWithHistory = selectedStore.subscribe(data =>
      setResultWithHistory(data.gameResultWithHistory)
    );
    const autoOpenResult = selectedStore.subscribe(data => setAutoOpenResult(data.autoOpenResult));
    const gameResult = selectedStore.subscribe(data => setSelectedLastResult(data.gameResult));
    selectedStore.init();
    demoState.init();
    return () => {
      selectedGameTypeSub.unsubscribe()
      repeatBet.unsubscribe();
      demoWin.unsubscribe();
      selectedAnimal.unsubscribe();
      selectedBetType.unsubscribe();
      allWinning.unsubscribe();
      showBetTypeInfo.unsubscribe();
      chooseAnimal.unsubscribe();
      chooseNumberAnimation.unsubscribe();
      writeNumberAnimation.unsubscribe();
      showTotalWinPopup.unsubscribe();
      gameResultWithHistory.unsubscribe();
      autoOpenResult.unsubscribe();
      gameResult.unsubscribe();
    };
  }, []);

  useOutsideClick(hidePopUp, () => {
    if (repeatBet){
      selectedStore.setRebetPopUp(false)
    }
  });
  return (
   <div className="center-col-b">
      <div className="game-grid-holder ">
        <div
          className={`g-grid-container ${
            chooseAnimal || chooseNumber || writeNumber ? "disabled-type-1" : ""
          }`}
        >
          {/*add class "disabled-type-1"*/}
          <GameLogo
            selectedAnimal={selectedAnimal?.[0]}
            path={path}
            showBetTypeInfo={showBetTypeInfo}
            selectedBetType={selectedBetType}
          />
          <GameCenterContainer
            path={path}
            selectedBetType={selectedBetType}
            selectedAnimal={selectedAnimal}
            showBetTypeInfo={showBetTypeInfo}
            resultWithHistory={resultWithHistory}
            selectedLastResult={selectedLastResult}
            autoOpenResult={autoOpenResult}
            demoMode={demoMode}
          />
          {((path === "game" && !showBetTypeInfo && !resultWithHistory) ||
            (path === "game" && !showBetTypeInfo && !autoOpenResult)) && <BetAction
           demoMode={demoMode}
           betAmount={amount}
           setBetAmount={setBetAmount}
           selectedGameType={selectedGameType}
           limits={limits}
          />}
          {((path === "game" && winning !== 0 && showTotalWinPopup)||( path==='game' && demoWinning)) ? (
            <TotalWinPopupWeb winning={winning} demoWinning={demoWinning} demoMode={demoMode}/>
          ) : null}

         {repeatBet  ? <RebetPopUp t={t} hidePopUp={hidePopUp}/> : null}
        </div>
      </div>
    </div>
  );
});

export default MainContent;
