import React, { useEffect, useRef, useState } from "react";
import selectedStore from "../../state/selectedState";
import {useOutsideClick } from "../../newCore/helpers/useClickOutside";
import useTranslation from "../../newCore/helpers/useTranslation";
import postMessageService from "../../newCore/services/PostMessages/PostMessagesService";

const UserConfigWeb = ({ openConfig, setOpenConfig }) => {
  const [autoOpenResult, setAutoOpenResult] = useState(selectedStore.initialState.autoOpenResult);
  const settingsRef = useRef();

  useOutsideClick(settingsRef, event => {
    if (openConfig) {
      setOpenConfig(false);
    }
  });
  const [animation, setAnimation] = useState(selectedStore.initialState.animation);
  const t = useTranslation();

  useEffect(() => {
    const autoOpenResult = selectedStore.subscribe(data =>
      setAutoOpenResult(data.autoOpenResult)
    );
    const animation = selectedStore.subscribe(data =>
      setAnimation(data.animation)
    );
    selectedStore.init();
    return () => {
      autoOpenResult.unsubscribe();
      animation.unsubscribe();
    };
  }, []);

  return (
    <div
      className="user-config-dropdown"
      ref={settingsRef}
    >
      <div className="user-config-d-row">
        <p className="user-config-title">{t("ANIMATIONS")}</p>
        <div className="user-config-d-r-inner">
          <div className="user-config-d-r-column">
            {/*<p className="switcher-txt">{animation ? t("OFF") : t("ON")}</p>*/}
          </div>
          <div className="user-config-d-r-column">
            <div
              className={`switcher ${animation ? "active" : ""}`}
              onClick={() => selectedStore.setAnimation(!animation)}
            />
          </div>
        </div>
      </div>
      <div className="user-config-d-row">
        <p className="user-config-title">{t("AUTOMATIC_ RESULT_OPENING")}</p>
        <div className="user-config-d-r-inner">
          <div className="user-config-d-r-column">
            {/*<p className="switcher-txt">{autoOpenResult ? t("OFF") : t("ON")}</p>*/}
          </div>
          <div className="user-config-d-r-column">
            <div
              className={`switcher ${autoOpenResult ? "active" : ""}`}
              onClick={() => selectedStore.setAutoOpenResult(!autoOpenResult)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserConfigWeb;
