import React, { memo, useEffect, useState } from "react";
import ResultRowWeb from "./result-row.Web";
import { resultRows } from "../../newCore/helpers/resultRows";
import ResultImg from "../../assets/images/game-result.png";
import useTranslation from "../../newCore/helpers/useTranslation";
import selectedStore from "../../state/selectedState";

const NewResultWeb = memo(({ result }) => {
  const rows = resultRows(result);
  const t = useTranslation();
  const [animated, setAnimated] = useState(selectedStore.initialState.lastResultAnimated);
  const [animation , setAnimation] = useState(selectedStore.initialState.animation)

  useEffect(() => {
    const lastResultAnimated = selectedStore.subscribe(data =>
      setAnimated(data.lastResultAnimated)
    );
    selectedStore.setNewGameResultModal(false);
    const animation = selectedStore.subscribe(data => setAnimation(data.animation))
    selectedStore.init();
    return () => {
      animation.unsubscribe()
      lastResultAnimated.unsubscribe();
      setTimeout(()=>{
        selectedStore.setNewGameResultModal(true)
        selectedStore.setHasUnseenBets(false)
      },2000)
    };
  }, []);
  return (
    <>
      <div className={`game-results-container`} id={"new-result"}>
        <div className="game-results-row">
          <img className="game-results-img" src={ResultImg} alt="" />
          <p className="game-results-text">{t("NEW_RESULT")}</p>
          <p className="game-results-text" style={{ fontSize: "18px" }}>
            {t("ID")} {result?.gameId}
          </p>
          {/*<p className="game-results-text">*/}
          {/*  {t("ID")} {result?.gameId}*/}
          {/*</p>*/}
        </div>
        <div className="game-results-row">
          <div className="game-results-content">
            <div className="last-result-container">
              <div className="last-r-title-time">
                {/*<p className="res-b-title">{t("ID")} {result?.gameId}</p>*/}
                {/*<p className="res-b-time">*/}
                {/*  {!is.empty(result) ? new Date(result?.endDate).toLocaleTimeString() : ""}*/}
                {/*</p>*/}
              </div>

              <div className="last-r-list-holder">
                {rows.map((item, index) => (
                  <ResultRowWeb
                    index={index}
                    item={item}
                    key={index}
                    animated={animated}
                    activeBalls
                    animation={animation}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default NewResultWeb;
