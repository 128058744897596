import React, { useState } from 'react';
import animal from '../../assets/images/Animals/16.png';
import Toaster from '../toaster';
import { resultRows } from '../../newCore/helpers/resultRows';
import AnimalByNumber from './animalByNumber';
import selectedStore from '../../state/selectedState';
import { copyToClipboard } from '../../newCore/helpers/copyFunction';

const HashRow = ({t , gameResult , animals , partnerSettings}) =>{
 const rows = resultRows(gameResult);


 return(
  <div className="result-popup-t-row">
   <div className="result-popup-t-row-cell type-1">
    <span>{t('ID')} {gameResult.gameId}</span>
   </div>
   <div className={`result-popup-t-row-cell type-2`}>
    {rows[0] && <div className="animals-item">
     <div className="mini-animals-row" >
      {
       rows.map(row =>(
        <AnimalByNumber number={row} animals={animals} partnerSettings={partnerSettings}/>
       ))
      }
     </div>
     <i className="icon-arrow_drop_down animals-i-icon"/>
     <div className="animals-i-tooltip">
      <div className="animals-i-t-column">
       <span>1.</span>
       <p>{gameResult.first}</p>
      </div>
      <div className="animals-i-t-column">
       <span>2.</span>
       <p>{gameResult.second}</p>
      </div>
      <div className="animals-i-t-column">
       <span>3.</span>
       <p>{gameResult.third}</p>
      </div>
      <div className="animals-i-t-column">
       <span>4.</span>
       <p>{gameResult.fourth}</p>
      </div>
      <div className="animals-i-t-column">
       <span>5.</span>
       <p>{gameResult.fifth}</p>
      </div>
     </div>
    </div>}
   </div>
   <div className="result-popup-t-row-cell common-hover">
    <div className="result-popup-t-row-c-i-table">
     <div className="result-popup-t-row-c-i-t-cell" onClick={()=>{
      if (gameResult.resultKey){
      selectedStore.setResultKey(gameResult.resultKey);
      selectedStore.setResultSalt(gameResult.resultSalt)
      }
     }}>
      <span title={gameResult.resultKey}>{gameResult.resultKey}</span>
     </div>
     <div className="result-popup-t-row-c-i-t-cell" onClick={()=>{
      if (gameResult.resultSalt){
       selectedStore.setResultKey(gameResult.resultKey);
       selectedStore.setResultSalt(gameResult.resultSalt)
      }
     }}>
      <div className="result-popup-t-row-c-inner">
       <span title={gameResult.resultSalt}>{gameResult.resultSalt}</span>
       <i className="icon-search-up" />
      </div>
     </div>
    </div>

   </div>
   <div className="result-popup-t-row-cell type-3" title={gameResult.resultHash} onClick={()=>{
    copyToClipboard(gameResult.resultHash);
    Toaster.success(t('HASH_CODE_COPIED'));
   }}>
    <div className="result-popup-t-row-c-inner">
     <span  >{gameResult.resultHash}</span>
     <i className="icon-hash-code" />
    </div>
   </div>
  </div>
 )
}

export default HashRow