import React from "react";

const TopWinnersList = ({ bet }) => {

  return (
    <div className="st-content-info ">
      {/*add class selected*/}
      <p>
        {`${bet.userId}`?.[0]}***{`${bet.userId}`?.[`${bet.userId}`?.length - 1]}
      </p>
      <p>X {bet.odds} </p>
      <p className="ellipsis" title={`${Math.round10(bet?.stakeAmount, -2)}`}>
        <span>
          {
            Math.round10(bet?.stakeAmount, -2)
            }
        </span>
      </p>
      <p className="ellipsis" title={`${Math.round10( Math.round10(bet?.stakeAmount, -2)*bet?.odds, -2)}`}>
        <span>
          {
            Math.round10( Math.round10(bet?.stakeAmount, -2)*bet?.odds, -2)
          }
        </span>
      </p>
      {/*classname win*/}
    </div>
  );
};

export default TopWinnersList;

//
// betId: 212506
// stakeAmount: 100
// userName: "p****2"
// winningAmount: 285000
