import PlayerStatisttic from "../player-statistic";
import React, { memo, useEffect, useMemo, useState } from "react";
import selectedStore from "../../state/selectedState";
import LiveBetsList from "./live-bets-list";
import is from "is_js";
import * as _ from "lodash";
import selectedStoreMobile from "../../state/selectedStateMobile";
import demoState from '../../state/demoModeState';
import PlayerStatistticDemo from '../demoMode/playerStatistticDemo';

const LiveBets = memo(({ userSettings , demoMode }) => {
  const [partnerBetTypes, setPartnerBetTypes] = useState(
    selectedStore.initialState.partnerBetTypes
  );
  const [betResult, setBetResult] = useState(selectedStore.initialState.eachWinning);
  const [demoBets , setDemoBets] = useState(demoState.initialState.userBet)
  const [liveBets, setLiveBets] = useState(selectedStore.initialState.liveBets);
  const [currency, setCurrency] = useState(selectedStore.initialState.userCurrency);
  const [currencies, setCurrencies] = useState(selectedStore.initialState.currency);
  const [showDemoWin, setShowDemoWin] = useState(selectedStore.initialState.showDemoWin);
  const [showRealWin, setShowRealWin] = useState(selectedStore.initialState.showRealWin);
  const [showTotalWinPopup, setShowTotalWinPopup] = useState(selectedStore.initialState.showTotalWinPopup);
  const [partnerSettings, setPartnerSettings] = useState(
    selectedStore.initialState.partnerSettings
  );
  const [demoBetResult , setDemoBetResult] = useState(demoState.initialState.betResults)
  useEffect(() => {
    selectedStoreMobile.setHasUnseenBetsMobile(false);
    const demoBetsResult = demoState.subscribe(data => setDemoBetResult(data.betResults));
    const currencies = selectedStore.subscribe(data => setCurrencies(data.currency));
    const showDemoWin = selectedStore.subscribe(data => setShowDemoWin(data.showDemoWin));
    const showRealWin = selectedStore.subscribe(data => setShowRealWin(data.showRealWin));
    const userCurrency = selectedStore.subscribe(data => setCurrency(data.userCurrency));
    const partnerSettings = selectedStore.subscribe(data =>
      setPartnerSettings(data.partnerSettings)
    );
    const liveBets = selectedStore.subscribe(data => setLiveBets(data.liveBets));

    const partnerBetTypes = selectedStore.subscribe(data =>
      setPartnerBetTypes(data.partnerBetTypes)
    );
    const eachWinning = selectedStore.subscribe(data => setBetResult(data.eachWinning));
    const showTotalWinPopup = selectedStore.subscribe(data => setShowTotalWinPopup(data.showTotalWinPopup));
    const demoBets = demoState.subscribe(data => setDemoBets(data.userBet))
    demoState.init()
    selectedStore.init();
    return () => {
      showRealWin.unsubscribe();
      showDemoWin.unsubscribe();
      showTotalWinPopup.unsubscribe();
      demoBetsResult.unsubscribe()
      userCurrency.unsubscribe();
      demoBets.unsubscribe();
      liveBets.unsubscribe();
      partnerBetTypes.unsubscribe();
      eachWinning.unsubscribe();
      currencies.unsubscribe();
      partnerSettings.unsubscribe();
    };
  }, []);

  const countOfEmptyBoxes = () => {
    const userBet = liveBets?.filter(item => item.userId === userSettings.id);

    if (userBet.length > 0 && userBet?.length < 15) {
      return 15 - userBet.length;
    } else if (!userBet?.length) {
      return 15;
    } else {
      return 0;
    }
  };

  const allLiveBets = useMemo(() => {
    const partnerBetTypesId = partnerBetTypes.map(item => item.id);
    if (userSettings.id) {
      return liveBets
        ?.filter(item => item.userId !== userSettings.id)
        .filter(item => partnerBetTypesId?.indexOf(item.betTypeId) !== -1);
    } else {
      return liveBets?.filter(item => partnerBetTypesId?.indexOf(item.betTypeId) !== -1);
    }
  }, [liveBets, userSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      { demoMode ? demoBets?.map((bet,index)=>(
       <PlayerStatistticDemo
        bet={is.empty(partnerBetTypes) ? null : bet}
        key={index}
        betResult={demoBetResult}
        liveBets={liveBets}
        showWin={showDemoWin}
       />
      )) : !!liveBets.length &&
        liveBets
          ?.filter(item => item.userId === userSettings.id)
          ?.reverse()
          ?.map((data, index) => (
            <PlayerStatisttic
              bet={is.empty(partnerBetTypes) ? null : data}
              key={index}
              userSettings={userSettings}
              betResult={betResult}
              currencyName={currency?.[0]?.name}
              currencies={currencies}
              liveBets={liveBets}
              showWin={showRealWin}
              showTotalWinPopup={showTotalWinPopup}
            />
          ))}
      {!!liveBets.length &&
        allLiveBets.length !== 0 &&
        allLiveBets.map((liveBetItem, index) => (
          <LiveBetsList
            key={index}
            liveBetItem={liveBetItem}
            partnerBetTypes={is.empty(partnerBetTypes) ? [] : partnerBetTypes}
            currencies={currencies}
            currencyName={currency?.[0]?.name}
            partnerCurrency={partnerSettings?.data?.currencyCode}
            showWin={demoMode ? showDemoWin : showRealWin }
          />
        ))}
      {_.range(countOfEmptyBoxes()).map((_, index) => (
        <div className="st-content-info" key={index} />
      ))}
    </>
  );
});
export default LiveBets;
