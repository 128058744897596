import React, { useEffect, useState } from 'react';
import usericon from '../../assets/images/player.svg';
import userIconBrazil from '../ulits/brazilCircleImages/22.png';
import UserConfigWeb from '../user-config-dropdown/UserConfig.WEB';
import selectedStore from '../../state/selectedState';
import { toCurrency } from '../../newCore/helpers/betActionHelpers';
import useTranslation from '../../newCore/helpers/useTranslation';
import LoadingInline from '../Loading/loadingInline';
import { isIOS } from 'react-device-detect';
import demoState from '../../state/demoModeState';
import { queryGet } from '../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../newCore/constants/constants';
import { useHistory } from 'react-router';
import postMessageService from '../../newCore/services/PostMessages/PostMessagesService';

const UserInfo = ({ balance, settings, currency, demoMode }) => {
 const [openConfig, setOpenConfig] = useState(false);
 const [isAuth, setIsAuth] = useState(selectedStore.initialState.isAuth);
  const [partnerSettings, setPartnerSettings] = useState(
  selectedStore.initialState.partnerSettings
 );
  const [gameTypeLimits , setGameTypesLimits] = useState(selectedStore.initialState.gameTypeLimits)
 const [demoBalance ,setDemoBalance] = useState(demoState.initialState.balance)

  useEffect(() => {
  const partnerSettings = selectedStore.subscribe(data =>
   setPartnerSettings(data.partnerSettings)
  );
  const limits = selectedStore.subscribe(data =>setGameTypesLimits(data.gameTypeLimits))
   const isAuth = selectedStore.subscribe(data => setIsAuth(data.isAuth));
   const balance = demoState.subscribe(data => setDemoBalance(data.balance))
  selectedStore.init();
   demoState.init()
  return () => {
   limits.unsubscribe()
   partnerSettings.unsubscribe();
    isAuth.unsubscribe();
    balance.unsubscribe()
  };
 }, []);


 const t = useTranslation();

 return (
  <div className="user-balance-row">
   <div className="u-id-balance-holder">

    <div className="u-id-v">
     {demoMode ? null : <div
      className="user-nav-icon"
      style={{ pointerEvents: openConfig ? 'none' : 'auto' }}
      onClick={() => setOpenConfig(isIOS ? !openConfig : true)}
     >
      <img className="user-nav-avatar" src={partnerSettings?.data?.viewMode === 0 ? usericon : userIconBrazil} alt=""/>
      <i className="icon-arrow_drop_down"/>
     </div>}
     {/*<span>{t('DEMO')}</span>*/}
     {demoMode ? <button onClick={()=>{
      if (isAuth){
       selectedStore.setDemoMode(false)
       selectedStore.setSelectedGameType(gameTypeLimits)
      }else{
       postMessageService.openLoginPopup();
      }
      }} className="real-play-btn">{t('PLAY_REAL')}</button>:
      isAuth ? (
       <span>
              {t('ID')} {settings?.id}
            </span>
      ) : (
       null
      )}
    </div>



    {demoMode ?  <div className="balance-v">
      {t('BALANCE')}: <span>{demoBalance}Fun</span>
    </div> : isAuth ? (
     balance?.isLoading ? (
      <LoadingInline right balance/>
     ) : (
      <div className="balance-v">
       {t('BALANCE')}: <span>{toCurrency(balance?.data, currency?.name)}</span>
      </div>
     )
    ) : null}

    {openConfig && <UserConfigWeb openConfig={openConfig} setOpenConfig={setOpenConfig}/>}
   </div>
  </div>
 );
};

export default UserInfo;
