import React, {memo} from "react";
import ChooseSingleNumber from "./ChooseSingleNumber";
import ChooseFourNumber from "./ChooseFourNumber";

const SingleAnimalWithFixedNumber = memo(({ selectedAnimal, selectedBetType }) => {
  return (
    <>
      {selectedBetType?.animalNumbersCount === 1 ? (
        <ChooseSingleNumber selectedAnimal={selectedAnimal} />
      ) : (
        <ChooseFourNumber selectedAnimal={selectedAnimal} />
      )}
    </>
  );
});
export default SingleAnimalWithFixedNumber;
