import { bet, betHelpers } from './betActionHelpers';
import Toaster from '../../components/toaster';
import postMessageService from '../services/PostMessages/PostMessagesService';
import selectedStore from '../../state/selectedState';

export const addBet = (selectedBetType,
                       selectedAnimal,
                       selectedNumber,
                       selectedGame,
                       betAmount,
                       demoMode,
                       userBalance,
                       isAuth,
                       demoBalance,
                       setBetAmount,
                       selectedGameType,
                       betIdForDemoBet,
                       bettingNumbers,
                       showSpinner,
                       userFavoriteBet,
                       t,
) => {
 if (demoMode) {
  if (betAmount <= demoBalance) {
   if (+selectedGameType.minBet > +betAmount){
    selectedStore.setBetLimit({
     message:'MINIMUM_BET',
     type:0,
    });
    setTimeout(()=>{
     selectedStore.setBetLimit(null)
    },1000)
   }else if(+selectedGameType.maxBet < +betAmount){
    selectedStore.setBetLimit({
     message:'MAXIMUM_BET',
     type:1
    });
    setTimeout(()=>{
     selectedStore.setBetLimit(null)
    },1000)
   }else{
   betHelpers(
    selectedBetType,
    selectedAnimal,
    selectedNumber,
    selectedGame,
    betAmount,
    bettingNumbers,
    showSpinner,
    userFavoriteBet,
    demoMode,
    betIdForDemoBet
   );
   }
  } else {
   Toaster.error(t('LOW_BALANCE'));
  }
 } else if (isAuth) {
  if (userBalance?.data >= betAmount) {
   if (+selectedGameType.minBet > +betAmount){
    selectedStore.setBetLimit({
     message:'MINIMUM_BET',
     type:0,
    });
    setTimeout(()=>{
     selectedStore.setBetLimit(null)
    },1000)
   }else if(+selectedGameType.maxBet < +betAmount){
    selectedStore.setBetLimit({
     message:'MAXIMUM_BET',
     type:1
    });
    setTimeout(()=>{
     selectedStore.setBetLimit(null)
    },1000)
   }else{
    betHelpers(
     selectedBetType,
     selectedAnimal,
     selectedNumber,
     selectedGame,
     betAmount,
     bettingNumbers,
     showSpinner,
     userFavoriteBet,
     demoMode,
     betIdForDemoBet
    );
    if (betAmount[betAmount.length - 1] === '.') {
     setBetAmount(betAmount.slice(betAmount.length - 2, 1));
    }
   }
  } else {
   Toaster.error(t('LOW_BALANCE'));
  }
 } else if (!demoMode) {
  postMessageService.openLoginPopup();
 }
};
