import React, { useEffect, useState } from 'react';
import selectedStore from '../../state/selectedState';

const RebetPopUp = ({ t , hidePopUp}) =>{

 return (
  <div className="rebet-popup" ref={hidePopUp}>
   <div className="rebet-popup-content">
    <i className="icon-reset rebet-icon"/>
    <p>{t('YOU_WANT_TO_REPEAT_THE_BET')}?</p>
    <div className="rebet-popup-c-buttons">
     <button onClick={()=>selectedStore.setRebetPopUp(false)}>{t('NO')}</button>
     <button onClick={()=>{
      selectedStore.setDoBet(true)
      selectedStore.setRebetPopUp(false)
     }}>{t('YES')}</button>
    </div>
   </div>
   <div className="rebet-popup-show-again">
    <input type="checkbox" id="show" onChange={(e)=>selectedStore.setDontShowPopUp(e.target.checked)}/>
    <label htmlFor="show">{t('DONT_SHOW_AGAIN')}</label>
   </div>
  </div>
 )
}
export default RebetPopUp
