import React, {memo} from "react";

import TwoAnimalWithNumberSelection from "./twoAnimalWithNumberSelection";
import TwoAnimalByFixedNumbers from "./twoAnimalByFixedNumbers";

const ChooseTwoAnimal = memo(({ selectedAnimal, selectedBetType, animalsCount }) => {
  return (
    <>
      {selectedBetType.animalNumbersCount === 4 ? (
        <TwoAnimalByFixedNumbers selectedAnimal={selectedAnimal} />
      ) : (
        <TwoAnimalWithNumberSelection animalsCount={animalsCount} selectedAnimal={selectedAnimal} />
      )}
    </>
  );
});
export default ChooseTwoAnimal;
