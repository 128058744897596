import React, { useEffect, useState } from 'react';
import selectedStore from '../../../state/selectedState';
import { toCurrency } from '../../../newCore/helpers/betActionHelpers';

const LiveBetsLobby = ({ userCurrency, partnerCurrency , currencies}) => {
 const [liveBets, setLiveBets] = useState(selectedStore.initialState.liveBetsForLobby);

 useEffect(() => {
  const liveBetsSubscription = selectedStore.subscribe(data => setLiveBets(data.liveBetsForLobby));
  selectedStore.init();
  return () => {
   liveBetsSubscription.unsubscribe();
  };
 }, []);

 const findRateByCurrencyCode =
  currencies?.find(c => c.name === userCurrency?.[0]?.name)?.rate ||
  currencies?.find(c => c.name === partnerCurrency)?.rate;

 const convertCurrencyToUSD = amount => {
  return +amount / +findRateByCurrencyCode;
 };
 return (
  <>
   {
    liveBets.map((bet,index) => {
     return (
      <div className="left-col-g-row" key={index}>
       <div className="left-col-g-row-in"><p className="left-col-g-row-in-p">{`${bet.userId}`?.[0]}***
        {`${bet.userId}`?.[`${bet.userId}`?.length - 1]}</p></div>
       <div className="left-col-g-row-in"><p
        className="left-col-g-row-in-p center">{toCurrency(Math.round10(convertCurrencyToUSD(bet?.defaultRateStakeAmount), -2), userCurrency?.[0]?.name || partnerCurrency)}</p>
       </div>
       <div className="left-col-g-row-in"><p
        className="left-col-g-row-in-p right">{toCurrency(Math.round10(convertCurrencyToUSD(bet?.defaultRateStakeAmount) * bet?.odds[0], -2), userCurrency?.[0]?.name || partnerCurrency)}</p>
       </div>
      </div>
     );
    })
   }
  </>
 );
};
export default LiveBetsLobby;