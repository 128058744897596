import React from 'react';
import BallsM from '../../../assets/images/BalsM.png';
import { useHistory } from 'react-router';
import selectedStore from '../../../state/selectedState';
import Countdown, { zeroPad } from 'react-countdown';
import TimerHelper from '../../../newCore/helpers/timerHelper';
import LoadingInline from '../../Loading/loadingInline';
import { toCurrency } from '../../../newCore/helpers/betActionHelpers';
import { queryGet } from '../../../newCore/helpers/helperFunctions';
import { APP_CONSTANTS } from '../../../newCore/constants/constants';
import { PartnerIdList } from '../../../newCore/constants/partnerIdList';
import postMessageService from '../../../newCore/services/PostMessages/PostMessagesService';

const GameType = ({ gameType, urlSearchParams, t , game , currencyCode, isAuth }) => {
 const history = useHistory();
 const partnerId = queryGet(APP_CONSTANTS.PARTNER_ID);
 const isHideDemoButton = PartnerIdList.includes(+partnerId)

 const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
   return <LoadingInline />;
  } else {
   return (
    <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
   );
  }
 };
 return (
  <div className="play-container">
   <div className="play-inner-cont">
    <p className="timer">
     <div className="timer-c">
      <span className="icon icon-timer icon-ic-time"/>
      <span>{gameType.dueTime}{t('SECOND')}</span>
     </div>
     <p className="small-text">{t('MAX_WIN')}</p>
    </p>
    <p className="amount">{toCurrency(gameType.maxWin,currencyCode)}</p>
    <p className="time">
     {game.state ? <span>00:00</span> :
      <Countdown key={game?.id}
                 date={game.endDate}
                 // children={<LoadingInline/>}
                 renderer={renderer}
      />
     }
    </p>  {/*add remove passive class*/}
    <div className="play-c-buttons-row">
     <div className="play-cont-button-wr">
      <button className="play-cont-button pl-n" onClick={() => {
       if (isAuth) {
        history.push({
         pathname: '/game',
         search: `${urlSearchParams}&gameTypeId=${gameType.id}`
        });
        selectedStore.setSelectedGameType(gameType)
       } else {
        postMessageService.openLoginPopup();
       }
      }}>{t('PLAY_NOW')}</button>
     </div>
     {
      !isHideDemoButton && (<div className="play-cont-button-wr">
       <button className="play-cont-button de" onClick={()=>{
        history.push({ pathname: '/game', search:`${urlSearchParams}&gameTypeId=${gameType.id}` });
        selectedStore.setDemoMode(true);
        selectedStore.setSelectedGameType(gameType,true);
       }}>{t('DEMO')}</button>
      </div>)
     }
    </div>
   </div>
   <div className="play-inner-text-cont">
    <p className="small-text text">
     <span>{t('MIN')}:</span>
     <span>{toCurrency(gameType.minBet,currencyCode)}</span>
    </p>
    <p className="small-text">
     <span>{t('MAX')}:</span>
     <span> {toCurrency(gameType.maxBet,currencyCode)}</span>
    </p>
   </div>
  </div>
 );
};

export default GameType;
