import { EBetHistoryFilter, EBetHistoryCategoriesValues } from "../constants/constants";
import { createCalendarQuery } from "./queryStringCreator";
import { useEffect, useRef } from "react";

export const pendingSetter = data => ({
  isLoading: true,
  data: data
});

export  const changeObjectKey = (object, key, modifiedKey) => {
  var deletedValue = object[key];
  delete object[key];
  return {
    ...object,
    [modifiedKey]: deletedValue
  }
}


export const useDidUpdate = (callback, deps) => {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, deps);
};

export const doneSetter = data => ( {
  isLoading: false,
  data: data.result ? data.result : []
});

export const queryGet = param => {
  return new URLSearchParams(window.location.search.toLowerCase()).get(param);
};

export const generateBetHistoryQueryParams = queryObject => {
  const {
    date: { startDate, endDate },
    filters = [],
    id
  } = queryObject;
  const filtersArray = [];
  let idQuery = "";

  const dateQuery = createCalendarQuery(startDate, endDate);

  const filtersList = Object.keys(filters);
  filtersList.forEach(elem => {
    filters[elem] &&
      filtersArray.push({
        key: EBetHistoryFilter.status,
        value: EBetHistoryCategoriesValues[elem]
      });
  });

  if (typeof id === "number" && id >= 0) {
    idQuery = { key: "Id", value: id };
    return [...dateQuery, ...filtersArray, idQuery];
  }
  return [...dateQuery, ...filtersArray];
};

export const checkedSelectedAnimal = (betAnimalIds,animalsId) =>{
    return betAnimalIds?.split(',').some(item => +item===animalsId.id)
}
